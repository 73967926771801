import axios from 'axios' // import axios
import baseUrl from './baseUrl'
import EventBus from './../components/EventBus'
import store from '../store'
import { isApigwEnv } from '../utils/General'
import { isAflacMember } from '../configs/constants'
import { baseDirectory } from '../configs/environments'

const RUN_ENV = process.env.REACT_APP_RUN_ENV;

const service = axios.create({
  baseURL: baseUrl,
  // timeout: 5000,
  // headers: { 'Content-Type': 'application/json' },
})

service.interceptors.request.use(config => {
  config.headers['Cache-Control'] = 'no-store, no-cache';
  config.headers['Accept'] = 'application/json';
  config.headers["Authorization"] = "bearer " + store.getState().vAgent.access_token;
  config.headers['run-env'] = RUN_ENV;
  config.params = config.params || {};
  config.params['timestamp'] = `${new Date().getTime()}`;
  // config.headers['x-aanet-user'] = 'P0146549';
  // config.headers['x-aanet-group'] = 'G12000002001,G5NAPO,G600AAAAA000002';

  if ('prod' !== RUN_ENV || 'stage' !== RUN_ENV) {
    config.headers['x-aanet-user'] = store.getState().vAgent.request_header_user_id;
    config.headers['x-aanet-group'] = store.getState().vAgent.request_header_group_id;
  }
  return config;
})

service.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response) {
    if (error.response.status && (error.response.status === 401)) {
      // EventBus.dispatch('show_snack', { message: 'トークンの有効期限が切れました', type: "error" })
      // EventBus.dispatch('show_snack', { message: error.response.data.message !== '' ? error.response.data.message : "トークンの有効期限が切れました", type: "error" })
      // store.dispatch({ type: 'ACCESS_TOKEN', access_token: '' });
      // store.dispatch({ type: 'USERNAME', username: '' });
      // store.dispatch({ type: 'AGENT_COMPANY', agent_company: {} });
      if (!isApigwEnv()) {
        EventBus.dispatch('show_snack', { message: 'Unauthorized', type: "error" })
        return window.location.href = baseDirectory + '/Logout'   // logout 
      }
      document.open();
      document.write("<p>このサイトは利用者が限定されており、閲覧することはできません。");
      document.close();
    }
  }
  return Promise.reject(error);
})

export default service
