import axios from 'axios'
import { mstDBUrl, environment, baseDirectory } from '../configs/environments'
import { encodeBase64 } from '../utils/encrypt'

function getAllTraineesForAgentCompany(params) {
    return new Promise((resolve, reject) => {
        let ENV = environment;
        if ('production' === ENV) {
            let url = '/proxy/xas/v1/mst/api/v1/slsmn-sts/getAllTraineesForAgentCompany';
            let real_params = {};
            if (params) {
                real_params = params;
            } else {
                real_params = {
                    agntCde: '2610441',
                    agstCde: '003'
                };
            }
            callMstDB('GET', url, real_params).then((result) => {
                resolve(result);
            })
        } else if (!ENV || 'development' === ENV) {
            let url = '/dev/xas-1/mst/api/v1/slsmn-sts/getAllTraineesForAgentCompany';
            let real_params = {};
            if (params) {
                real_params = params;
            } else {
                real_params = {
                    agntCde: '2610441',
                    agstCde: '003'
                };
            }
            callMstDB('GET', url, real_params).then((result) => {
                resolve(result);
            })
        } else if (!ENV ||'staging' === ENV) {
            let url = '/st/proxy/xas/v1/mst/api/v1/slsmn-sts/getAllTraineesForAgentCompany';
            let real_params = {};
            if (params) {
                real_params = params;
            } else {
                real_params = {
                    agntCde: '2610441',
                    agstCde: '003'
                };
            }
            callMstDB('GET', url, real_params).then((result) => {
                resolve(result);
            })
        }
    })
}

function getEmployeeInfo(params){
    return new Promise((resolve, reject) => {
        let ENV = environment;
        if ('production' === ENV) {
            let url = '/proxy/xas/v1/mst/api/v1/slsmn-sts';
            callMstDB("GET",url, params).then(res=>{
                resolve(res)
            }).catch(e =>{
                reject(e);
            })
        }else if (!ENV || 'development' === ENV) {
            let url = '/dev/xas-1/mst/api/v1/slsmn-sts';
            callMstDB("GET",url, params).then(res=>{
                resolve(res)
            }).catch(e =>{
                reject(e);
            })
        }else if (!ENV ||'staging' === ENV) {
            let url = '/st/proxy/xas/v1/mst/api/v1/slsmn-sts';
            callMstDB("GET",url, params).then(res=>{
                resolve(res)
            }).catch(e =>{
                reject(e);
            })
        }
    })
}

function callMstDB(method, subUrl, params) {
    return new Promise((resolve, reject) => {
        console.log('Obtain user data from stage env...')
        let real_url = '';
        let ENV = environment;
        if ('staging' === ENV || 'production' === ENV) {
            real_url = mstDBUrl + '/apigw/va2roleplay/auth';
        } else {
            real_url = mstDBUrl + '/auth';
        }
        let authUrl = subUrl.split('/')[subUrl.split('/').length - 1];
        const service = axios.create({
            baseURL: real_url,
            timeout: 5000,
            headers: { 'Content-Type': 'application/json' },
        })

        const salt = encodeBase64('202107220302');
        service({
            method: 'GET',
            url: `/api/v1/${authUrl}`,
            params: {
                salt: salt,
                method: method,
                params: params,
                url: subUrl
            }
        }).then(result => {
            if (result.data) {
                resolve(result.data)
            } else {
                reject();
            }
        })
    })
}

export {
    getAllTraineesForAgentCompany,
    getEmployeeInfo
}

