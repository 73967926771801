import React, {useState} from 'react';
import BackButton from '../../../components/Buttons/BackButton';
import GeneralButton from '../../../components/Buttons/GeneralButton'
import Rating from '@material-ui/lab/Rating';
import Chip from '@material-ui/core/Chip';
import {Dropdown} from '../../../components/Dropdowns/PassFailDropdown';
import { useHistory,useLocation  } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import StarSelect from '../../../assets/images/star_select.svg';
import StarPending from '../../../assets/images/star_pending.svg';
import StarNoSelect from '../../../assets/images/star_no_select.svg';
import Sidebar from '../../../components/Sidebar';
import { connect } from 'react-redux';
import ChatLog from '../History/ChatLog';
import { Row, Col } from 'reactstrap';
import LogoutButton from '../../../components/Buttons/LogoutButton'
import styles from'./styles.module.css';
import  {useEffect} from 'react';
import {personEval, aiEval, passStar} from '../../../utils/mapState'
import {updateHumanEval} from '../../../api/api'
import { isApigwEnv } from '../../../utils/General'

const  criteria_option  = [
    {id: 0, name: 'x'},
    {id: 1, name: 'o'}
];
const Detail = ({ isOpen, aiScoreResultList, location, match, access_token }) =>{

    // let queryData = location.query
    // const [section, setSection] = useState(location.query)
    console.log(aiScoreResultList);
    let section = {}
    aiScoreResultList.forEach((item, index) => {
        if (item.sectionId === parseInt(match.params.id)) {
            section = item
        }
    })

    const history = useHistory();
    const { t } = useTranslation();
    let located = useLocation();
    let lastId = 0;

    const autoId = (prefix='ai_detail-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
    }, []);

    const handleUpdate = (e) => {
        let paramList = []
        section.criteriaResult.forEach(item => {
            let param = {
                rolePlayId: item.rolePlayId,
                themeId: item.themeId,
                scenarioId: item.scenarioId,
                sectionId: item.sectionId,
                criteriaId: item.criteriaId,
                humanEval: item.humanEval
            }
            paramList.push(param)
        })
        updateHumanEval(paramList).then(res => {
            history.goBack()
        })
    }
    console.log('section',section)

    const handleSelect = (value, aiEval, index) => {
        if (value.value === 1) {
            document.getElementsByClassName('star_img')[index].src = StarSelect
        } else if (value.value === 0) {
            document.getElementsByClassName('star_img')[index].src = StarNoSelect
        } else {
            if (aiEval === 1) {
                document.getElementsByClassName('star_img')[index].src = StarSelect
            } else {
                document.getElementsByClassName('star_img')[index].src = StarNoSelect
            }
        }
        let paramArr = value.id.split('_')
        let criteriaIndex = paramArr[0]
        let criteriaVal = parseInt(paramArr[1])
        section.criteriaResult[criteriaIndex].humanEval = criteriaVal
        let passCount = 0
        let unPassCount = 0
        for (let i = 0; i < section.criteriaResult.length; i++) {
            if (section.criteriaResult[i].humanEval === 1) {
                passCount += 1
            } else if (section.criteriaResult[i].humanEval === 0){
                unPassCount += 1
            } else {
                if (section.criteriaResult[i].aiEval === 1) {
                    passCount += 1
                } else {
                    unPassCount += 1
                }
            }
        }
        let sum = 0
        let passIndex = 0
        let unPassIndex = 0
        while (sum < section.criteriaResult.length) {
            while (passIndex < passCount) {
                document.getElementsByClassName('star_img_sum')[sum].src = StarSelect
                passIndex++
                sum++
            }
            while (unPassIndex < unPassCount) {
                document.getElementsByClassName('star_img_sum')[sum].src = StarNoSelect
                unPassIndex++
                sum++
            }
        }
    }
    let allmatchedKeywords = [];
    return(
        <>
        <Sidebar />
        <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
            <div className="main-content-inr">
                <Row>
                    <Col lg="12" className="cmn-logout-btn-sec">
                        <h3 className="text-left d-flex justify-content-between">{t('AI score result.check_criteria_edit')}</h3>
                        <LogoutButton title={t('general.button.logout')} className={'font-14'} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="10">
                        <div className="cmn-bg-box">
                            <Row className="mb-32">
                                <Col>
                                    <BackButton id={autoId()} className="back-btn font-14 mr-3" title={t('general.button.go_back')} onClick={history.goBack}/>
                                    <GeneralButton id={autoId()} className="cmn-btn font-14" title={t('general.button.done')} onClick={(e) => handleUpdate(e)}/>
                                </Col>
                            </Row>
                            <div className={`cmn-bg-box-inr ${styles.ai_score_detail}`}>
                                <div className={`${styles.ai_score_detail_up} mb-3 pb-3`}>
                                    <Row className="px-4">
                                        <label for="inputEmail3" className="col-sm-2 col-form-label font-weight-bold">{section.sectionName}</label>
                                        <div className="col-sm-10">
                                            {passStar(section.passCount, section.unPassCount)}
                                        </div>
                                    </Row>
                                </div>
                                <div className="px-4 mb-3">
                                    <p id={autoId()}  className="font-14 font-weight-bold mb-4">{t('AI score result.score_detail')}</p>
                                    <div className="table-responsive no-overflow-table">
                                        <table id={autoId()}  className={`table cmn-table  ${styles.ai_score_detail_table} w-100 text-center`}>
                                            <thead>
                                                <tr id={autoId()}>
                                                    <th id={autoId()} >{t('AI score result.check_criteria')}</th>
                                                    <th id={autoId()} >{t('AI score result.evaluation')}</th>
                                                    <th id={autoId()} >{t('AI score result.person_scoring')}</th>
                                                    <th id={autoId()} >{t('AI score result.ai_scoring')}</th>
                                                    <th id={autoId()} >{t('AI score result.keywords')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {section.criteriaResult.map((criteriaItem, criteriaIndex) => {
                                                    // allmatchedKeywords.push(...criteriaItem.matchedKeywords)
                                                    allmatchedKeywords.push(...criteriaItem.matchedKeywords, ...criteriaItem.matchedKeywordSynonyms ? JSON.parse(criteriaItem.matchedKeywordSynonyms).map(item => `${item.keyword},${item.synonyms}`).toString().split(",") : [])
                                                    return (
                                                        <tr key={criteriaIndex} id={autoId()} >
                                                            <td id={autoId()} >{criteriaItem.criteriaName}</td>
                                                            <td id={autoId()} >
                                                                <img className="star_img" src={criteriaItem.evaluation === "true" ? StarSelect : StarNoSelect} alt="Star Select"/>
                                                            </td>
                                                            <td id={autoId()} >
                                                                <Dropdown  onSelect={(value) => handleSelect(value, criteriaItem.aiEval, criteriaIndex)} className="form-control font-14" selectedData={personEval(criteriaItem.humanEval)} items={criteria_option} dropdown_id={criteriaIndex}/>
                                                            </td>
                                                            <td id={autoId()} >{aiEval(criteriaItem.aiEval, criteriaItem.aiScoreFlag)}</td>
                                                            <td id={autoId()} className="text-left">
                                                                {criteriaItem.keywords.map((keywordsItem, keywordIndex) => (<Chip id={autoId()} key={keywordIndex} label={keywordsItem} className={criteriaItem.matchedKeywords.includes(keywordsItem) ? styles.match_item : styles.not_match_item} />))}
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                
                                </div>
                                <div className="px-4">
                                    <Row>
                                        <Col  xl="12" lg="12" sm="12">
                                            <p id={autoId()}  className="font-14 font-weight-bold mb-3">{t('AI score result.utterance')} </p>
                                            <ChatLog traineeChat={section.traineeChat} trainerChat={section.trainerChat} allmatchedKeywords={[...new Set(allmatchedKeywords)]} />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        aiScoreResultList: state.aiResultScore.aiScoreResultList,
        access_token: state.vAgent.access_token,
    }
}

export default connect(stateToProps, null)(Detail);