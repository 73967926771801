const initialState = {
  totalTalk: 0,
  totalPercent: 0,
  totalPassCount: 0,
  totalCount: 0,
  goodPoint: '',
  improvePoint: '',
  crossMatter: '',
  examScoreResultList: []
}
const examResultScore = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SET_EXAM_SCORE_RESULT":
      return { ...state, ...action.paramData }
    case "RESET_EXAM_SCORE_RESULT":
      return  initialState; 
    default:
      return state
  }
}

export default examResultScore