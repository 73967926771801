import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {  Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import FeedbackCard from '../../../components/Cards/FeedbackCard';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import RecordActionButton from '../../../components/Buttons/RecordActionButton';
import BackButton from '../../../components/Buttons/BackButton';
import ConversationLog from './ConversationLog'
import ChatLog from '../History/ChatLog';
import store from "../../../store";
import {updateFeedback, updateRolePlayEndTime, pauseRecording, resumeRecording, finishRolePlay} from '../../../api/api';
import './styles.css'
import ChatApi from "../../../api/chatApi";
import { isAflacMemberOrBranchManagerOrTrainerManagerOrSystemOwner } from '../../../configs/constants';
const FeedbackSection = ({goodPoint,improvePoint,crossMatter, feedBack,role,recordingStatus,rolePlayId,SET_AI_SCORE_RESULT,SET_NEW_ROLEPLAY,urls,traineeFeedBackChat,trainerFeedBackChat,guestFeedBackChat,reloadTimes, setReloadTimes}) => {

    // SET_NEW_ROLEPLAY({recordingStatus: 1})
    const [editFlag, setEditFlag] = React.useState(false);
    const [openConversation, setOpenConversation] = React.useState(false);
    const [gPoint, setgPoint] = React.useState(goodPoint);
    const [iPoint, setiPoint] = React.useState(improvePoint);
    const [cMatter, setcMatter] = React.useState(crossMatter);
    const [noRepeat, setNoRepeat ] = React.useState(true);
    const [gperror, setGPError] = useState(false);
    const [iperror, setIPError] = useState(false);
    const [cmerror, setCMError] = useState(false);

    const [gpmiceOpen, setGPMiceOpen] = useState(false);
    const [ipmiceOpen, setIPMiceOpen] = useState(false);
    const [cmmiceOpen, setCMMiceOpen] = useState(false);


    setTimeout(()=>{
        if(reloadTimes == 1){
            setgPoint(goodPoint);
            setiPoint(improvePoint);
            setcMatter(crossMatter);
        }
        setReloadTimes(reloadTimes += 1)
    }, 1000)

    const dispatch = useDispatch();
    const { t } = useTranslation();
    let lastId = 0;
    const autoId = (prefix='feedback_section-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    const handleEditFlag = () =>{
        setEditFlag(true);
    }
    const onBack = () =>{
        setgPoint(goodPoint);
        setiPoint(improvePoint);
        setcMatter(crossMatter);

        setEditFlag(false);

        setGPError(false);
        setIPError(false);
        setCMError(false);

        setGPMiceOpen(false);
        setIPMiceOpen(false);
        setCMMiceOpen(false);

        setNoRepeat(true);
        dispatch({ type: 'initTranscriptGoodPoint', transcriptGoodPoint: goodPoint });
        dispatch({ type: 'initTranscriptImprovePoint', transcriptImprovePoint: improvePoint });
        dispatch({ type: 'initTranscriptCrossMatter', transcriptCrossMatter: crossMatter });
        ChatApi.endConnection();

    }
    const handleUpdateFeedback = () => {
        ChatApi.endConnection();
        if((gPoint && gPoint.length > 600)){
            setGPError(true);
            return;
        }
        if(iPoint && iPoint.length > 600){
            setIPError(true);
            return;
        }
        if(cMatter && cMatter.length > 600){
            setCMError(true);
            return;
        }
        setGPMiceOpen(false);
        setIPMiceOpen(false);
        setCMMiceOpen(false);
        setNoRepeat(true);
        let param = {}
        param["goodPoint"] = gPoint;
        param["improvePoint"] = iPoint;
        param["crossMatter"] = cMatter;
        SET_AI_SCORE_RESULT(param)
        setEditFlag(false);
        updateFeedback(rolePlayId, param).then(res => {
        })
    }

    const handlePause = () => {
        SET_NEW_ROLEPLAY({recordingStatus: 2})
        pauseRecording(urls.pause)
    }

    const handleResume = () => {
        SET_NEW_ROLEPLAY({recordingStatus: 1})
        resumeRecording(urls.resume)
    }

    const handleFinished = () => {
        SET_NEW_ROLEPLAY({recordingStatus: 0})
        updateRolePlayEndTime(rolePlayId).then(res => {
            finishRolePlay(urls.finished)
        })
    }
    return(
        <div className="cmn-bg-box-inr mb-32">
            <Row className="mb-3 align-items-center">
                <Col className="d-inline">
                    <span id={autoId()} className="text-left font-weight-bold mb-0">{t('AI score result.feedback')}</span>
                    {
                        recordingStatus !== 0 ?
                        <span>
                            {
                                recordingStatus === 1?
                                <RecordActionButton title="一時停止" onClick={() => handlePause()} className="px-3 py-1 ml-2" id="pause_recording"/>
                                :
                                <RecordActionButton title="記録を再開" onClick={() => handleResume()} className="px-3 py-1 ml-2" id="resume_recording"/>
                            }
                            <RecordActionButton title="記録を終了" onClick={() => handleFinished()} className="px-3 py-1 ml-2" id="end_recording"/>
                        </span>
                        :""

                    }

                </Col>

                {isAflacMemberOrBranchManagerOrTrainerManagerOrSystemOwner() ?
                    editFlag?
                    <Col className="text-right">
                        <BackButton id={autoId()} className="back-btn mr-3" title={t('general.button.return')} onClick={onBack} />
                        <GeneralButton id={autoId()} onClick={handleUpdateFeedback} className="cmn-btn font-weight-normal" title="完了" />
                    </Col>
                    :
                    <Col className="text-right">
                        <GeneralButton className="cmn-btn font-14 w-auto px-3 button-shadow" onClick={handleEditFlag} title={t('general.button.edit')}></GeneralButton>
                    </Col>
                    : ""}
            </Row>
            <Row className="medium-padding-box">
                <Col className="mb-3" lg="6" md="6">
                    <FeedbackCard id={autoId()} headerTitle={t('AI score result.good_point')}  limit={601} recordingStatus={recordingStatus} editFlag={editFlag} paramKey={"goodPoint"} content={gPoint} setContent={setgPoint} noRepeat={noRepeat} setNoRepeat={setNoRepeat} error={gperror} setError={setGPError} miceOpen={gpmiceOpen} setMiceOpen={setGPMiceOpen} className="good_point_height"></FeedbackCard>
                </Col>
                <Col className="mb-3" lg="6" md="6">
                    <FeedbackCard id={autoId()} headerTitle={t('AI score result.improvement_point')} limit={601} recordingStatus={recordingStatus} editFlag={editFlag} paramKey={"improvePoint"}  content={iPoint} setContent={setiPoint} noRepeat={noRepeat} setNoRepeat={setNoRepeat} error={iperror} setError={setIPError} miceOpen={ipmiceOpen} setMiceOpen={setIPMiceOpen} className="improvement_point_height"></FeedbackCard>
                </Col>
            </Row>
            <Row className="mt-2 mb-3">
                <Col>
                    <FeedbackCard id={autoId()} headerTitle={t('AI score result.correspondence_matter')} limit={601} recordingStatus={recordingStatus} editFlag={editFlag} paramKey={"crossMatter"}  content={cMatter} setContent={setcMatter} noRepeat={noRepeat} setNoRepeat={setNoRepeat} error={cmerror} setError={setCMError} miceOpen={cmmiceOpen} setMiceOpen={setCMMiceOpen} className="cMatter_point_height"></FeedbackCard>
                </Col>
            </Row>
            <div className={`${openConversation ? "border-radious-none" : "border-radious"}`}>
                <button  id={autoId()} className={`conversation_button  ${openConversation ? "border-radious-none" : ""} `} onClick={() => setOpenConversation(!openConversation)}><span className="font-weight-bold"> {t('AI score result.conversation_history')}</span> <span className={`icon  ${openConversation ? "show" : "hide"} `}></span></button>
                <div className={`conversation_content  ${openConversation ? "show" : "hide"} `}>
                    <Row>
                        <Col xl="12" lg="12" sm="12">
                            <ConversationLog traineeChat={traineeFeedBackChat} trainerChat={trainerFeedBackChat} guestChat={guestFeedBackChat} />
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        recordingStatus: state.startRolePlay.recordingStatus,
        rolePlayId: state.startRolePlay.rolePlayId,
        urls: state.startRolePlay.urls
    }
}

const mapDispatchToProps = dispatch => ({
    SET_AI_SCORE_RESULT: (res) => dispatch({type: 'SET_AI_SCORE_RESULT', paramData: res}),
    SET_NEW_ROLEPLAY: (res) => dispatch({type: 'SET_NEW_ROLEPLAY', paramData: res}),
})
export default connect(mapStateToProps, mapDispatchToProps)(FeedbackSection);
// export default FeedbackSection;
