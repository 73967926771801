import React, { useEffect } from 'react';
import EditAccordion from './EditAccordion'

const EditKeywordSelection = ({ keywords, setKeywords, newORKeyword, setNewORKeyword, editState, expanded, setExpanded, selected }) => {

    useEffect(() => {
        !editState && setExpanded(false);
    }, [editState])

    let lastId = 0;
    const autoId = (prefix = 'editing-condition-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    return (
        <div className={`cmn-scroll-bar px-2`}>
            {keywords && keywords[0].sections.map((items, key) => {
                return (
                    <div className="edit-keywords-accordian mr-md-4 mr-2" key={key}>
                        <h5 className="mb-3 font-14" id={`section_title_${key}`}>{items.name}</h5>
                        {items.criteria.map((item, index) => {
                            return (
                                <EditAccordion
                                    items={items}
                                    item={item}
                                    expanded={expanded} setExpanded={setExpanded}
                                    keywords={keywords} setKeywords={setKeywords}
                                    newORKeyword={newORKeyword} setNewORKeyword={setNewORKeyword}
                                    key={index}
                                    index={index}
                                    selected={selected} 
                                    editState={editState}
                                    id={autoId()} />
                            );
                        })}
                    </div >
                );
            })}
        </div >
    );
}


export default EditKeywordSelection;