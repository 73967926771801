import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import delete_icon from '../../../assets/images/delete_icon.png'
import delete_disabled from '../../../assets/images/delete_disabled.png'
import bookmark_blue from '../../../assets/images/bookmark_blue.png'
import bookmark_white from '../../../assets/images/bookmark_white.png'
import bookmark_disabled from '../../../assets/images/bookmark_disabled.png'
import styles from './styles.module.css';
import PTOPTable from '../PTOPTable';
import ConfirmDialog from '../../Dialog/ConfirmDialog';
import EventBus from '../../EventBus';
import {timeFormat} from '../../../utils/General';
import { isAflacMemberOrBranchManager, isAflacMemberOrBranchManagerOrTrainerManager, isSystemOwner, isTrainerManager } from '../../../configs/constants';
import {updateFavoriteRolePlay,deleteRolePlayDetail,checkRolePlayExist} from '../../../api/api';

const TraineeDetailByDateTable = ({ TableClassName, TableDivClassName, table_id,traineeByDate,type,getAIScoreParam,getExamScoreParam,setTraineeByDate,evaluator_name, flag, setFlag }) => {
    const { t } = useTranslation();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [bookmarkDialogOpen, setBookmarkDialogOpen] = useState(false);
    const [rolePlayId, setRolePlayId] = useState(0);
    let noData = <tr><td colSpan="100%">{ !flag ? t('general.text.loading') : t('general.text.no data available')}</td></tr>;
    let ColumnHeader = [];
    if (isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner()) {
         ColumnHeader = [
            [
                t('associate_summary.type'),
                t('associate_summary.date'),
                t('associate_summary.rater'),
                t('associate_summary.learning_theme'),
                t('associate_summary.play_time'),
                t('associate_summary.rate'),
                t('associate_summary.point_out'),
                t('associate_summary.correspondence_matter'),
                '削除',
                'お気に入り'
            ],
            ["", "", "", "FA", "FF", "P", "C"]
        ];
    }else{
         ColumnHeader = [
            [
                t('associate_summary.type'),
                t('associate_summary.date'),
                t('associate_summary.rater'),
                t('associate_summary.learning_theme'),
                t('associate_summary.play_time'),
                t('associate_summary.rate'),
                t('associate_summary.point_out'),
                t('associate_summary.correspondence_matter')
            ],
            ["", "", "", "FA", "FF", "P", "C"]
        ];
    }
   
    const passFailEvil = (passFailEvil) => {
        switch (passFailEvil) {
            case 0:
                return 'x'
            case 1:
                return '〇'
            case 2:
                return '-'
            default:
                return ''
        }
      }
    const handleClick = (trainee) => {
        if(trainee.is_exam === false){
            return getAIScoreParam(trainee.RSD_ROLEPLAY_ID,trainee.LT_THEME_ID,trainee.SCC_SC_ID)
        } else {
            return getExamScoreParam(trainee.RSD_ROLEPLAY_ID,trainee.LT_THEME_ID,trainee.SCC_SC_ID)
        }
    }
    const saveBookmarkParam = (rpId) =>{
        setRolePlayId(rpId);
        setBookmarkDialogOpen(true);
    }
    const saveDeleteParam = (rpId) =>{
        setRolePlayId(rpId);
        setConfirmOpen(true);
    }
    const deleteRolePlay = ()=>{
        RolePlayExist(1);
    }
    const deletedActionRolePlay = () => {
        setFlag(false)
        deleteRolePlayDetail(rolePlayId)
        .then(res => {
            if (res.status == 202) {
                EventBus.dispatch('show_snack', { message: "操作成功", type: 'success' })
                var data = traineeByDate.filter(item => item.RSD_ROLEPLAY_ID !== rolePlayId);
                setTraineeByDate(data)
                setFlag(true)
            }

        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: "更新に失敗しました", type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }
    const bookmarkRolePlay = ()=>{
        RolePlayExist(2);
    }
    const bookmarkActionRolePlay = () => {
        const favoriteFlag = true;
        setFlag(false)
        updateFavoriteRolePlay(rolePlayId,favoriteFlag)
        .then(res => {
            if (res.status == 200) {
                EventBus.dispatch('show_snack', { message: res.data.message, type: 'success' })
                const oldData = [...traineeByDate]
                oldData.forEach(function (item) {
                    if (item.RSD_ROLEPLAY_ID === rolePlayId) {
                        return item.FAVORITE_FLAG = favoriteFlag;
                    } else {
                        return item;
                    }
                });
                setTraineeByDate(oldData)
                setFlag(true)
            }

        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: "更新に失敗しました", type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }
    const RolePlayExist = (action)=>{
        checkRolePlayExist(rolePlayId).then(res=>{
            if(res.status === 200){
                if(action == 1){
                    deletedActionRolePlay();
                }else{
                    bookmarkActionRolePlay();
                }
            }
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' })
            }
            else if (err.response.status === 404) {
                EventBus.dispatch('show_snack', { message: "選択したロープレが募集人別サマリー画面から削除されています。", type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }
    return (
        <>
           <div className={`${TableDivClassName} ${traineeByDate && traineeByDate.length > 6?"cmn-table-scroll":"" } table-responsive`}>
                <table className={`${TableClassName} cmn-table table`} id={table_id}>
                    <ConfirmDialog
                        title="このレコードを削除しますか？"
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={deleteRolePlay}
                    ></ConfirmDialog>
                    <ConfirmDialog
                        title="このレコードをお気に入り登録 しますか？"
                        open={bookmarkDialogOpen}
                        setOpen={setBookmarkDialogOpen}
                        onConfirm={bookmarkRolePlay}
                    ></ConfirmDialog>
                    <thead className="text-center">
                        {ColumnHeader.map((headerloop, index) =>
                            <tr key={index} id={`${table_id}_heading_${index}`}>
                                {
                                    headerloop.map((data, sindex) => {
                                        if (data === 'FA' || data === 'FF' || data === 'P' || data === 'C') {
                                            return (
                                                <th key={"heading_" +index + sindex} id={`${table_id}_heading_${index}${sindex}`} className="align-middle w-auto">{data}</th>
                                            )
                                        } else if (index == 0 && sindex == 6) {
                                            return (
                                                <th key={"heading_" + index + sindex} id={`${table_id}_heading_${index}${sindex}`} className="align-middle" colSpan="4">{data}</th>
                                            )
                                        } else if (data != '指摘事項' && data != '') {
                                            return (
                                                <th key={"heading_" + index + sindex} id={`${table_id}_heading_${index}${sindex}`} className="align-middle" rowSpan="2">{data}</th>
                                            )
                                        } else {
                                            
                                        }
                                    }
                                    )
                                }
                            </tr>
                        )
                        }
                    </thead>
                    <tbody>
                        {
                            traineeByDate && traineeByDate.length === 0 ? noData :
                            traineeByDate && traineeByDate
                            .sort((a, b) => (b.RSD_ROLEPLAY_ID - a.RSD_ROLEPLAY_ID))
                            .map((trainee, index) => {
                                let loop = 0;
                                const Show_String_Length = 20;
                                return (
                                    < tr key={index} className={`${(traineeByDate.length <= 6)? '' : ((traineeByDate.length - 1 === index) || (traineeByDate.length - 2 === index) || (traineeByDate.length - 3 === index) || (traineeByDate.length - 4 === index) || (traineeByDate.length - 5 === index) || (traineeByDate.length - 6 === index))? 'hover-bottom' : ''} `}>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="td-120 text-left">{trainee.is_exam === false?'トレーニング​​':'検定'}</td>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="date-td text-right">
                                            <a onClick={handleClick(trainee)} className={`text-primary font-weight-bold ${styles.text_underline}`}>
                                            {Moment(trainee.ImplementationDate).format('YYYY/MM/DD')}
                                            </a>
                                        </td>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="td-120">{trainee.trainer_name}</td>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="td-120">{trainee.theme_name}</td>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="td-120">{timeFormat(trainee.duration)}</td>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="td-120">{trainee.achievement_rate}</td>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="td-60">
                                            <span className="description-link">{passFailEvil(trainee.FA.result)}
                                                <PTOPTable passFailEvil={passFailEvil} ptpData={trainee.FA}/>
                                            </span>
                                        </td>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="td-60">
                                            <span className="description-link">{passFailEvil(trainee.FF.result)}
                                                <PTOPTable passFailEvil={passFailEvil} ptpData={trainee.FF}/>
                                            </span>
                                        </td>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="td-60">
                                            <span className="description-link">{passFailEvil(trainee.P.result)}
                                                <PTOPTable passFailEvil={passFailEvil} ptpData={trainee.P}/>
                                            </span>
                                        </td>
                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="td-60">
                                            <span className="description-link last-cmn-description">{passFailEvil(trainee.C.result)}
                                                <PTOPTable passFailEvil={passFailEvil} ptpData={trainee.C}/>
                                            </span>
                                        </td>

                                        <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className="text-left" width="15%">
                                            <span className="description-link last-cmn-description">
                                                {trainee.correspodence_matter && trainee.correspodence_matter.length > Show_String_Length ? trainee.correspodence_matter.substring(0, Show_String_Length) + "..." : trainee.correspodence_matter}
                                                {trainee.correspodence_matter && trainee.correspodence_matter.length > Show_String_Length?<p className="description-text cmn-description w-100" style={{wordWrap :'break-word'}}>{trainee.correspodence_matter}</p>:""}
                                            </span>
                                        </td>
                                        {
                                            isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner()?
                                            [<td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className={`td-120 ${styles.action}`}>
                                                   { isTrainerManager() && evaluator_name !== trainee.trainer_name ?
                                                        <img src={delete_disabled}></img>
                                                    :
                                                        <img src={delete_icon} onClick={()=>saveDeleteParam(trainee.RSD_ROLEPLAY_ID)}></img>
                                                    }
                                            </td>]
                                            :""
                                        }
                                        {
                                            (isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner())?
                                            [
                                                <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} className={`td-120 ${styles.action}`}>
                                                {
                                                    (isAflacMemberOrBranchManager() || isSystemOwner()) && evaluator_name !== trainee.trainer_name|| isTrainerManager() && evaluator_name !== trainee.trainer_name? 
                                                    
                                                    <img src={bookmark_disabled}></img>
                                                    :
                                                    <img src={trainee.FAVORITE_FLAG ? bookmark_blue:bookmark_white} onClick={()=> !trainee.FAVORITE_FLAG ? 
                                                            saveBookmarkParam(trainee.RSD_ROLEPLAY_ID)
                                                            :""}></img>
                                                    
                                                }
                                                </td>
                                            ]
                                            :""
                                        }
                                    </tr>
                                )
                            }
                            )
                        }
                    </tbody>
                </table>
            </div>
        </>

    );
};

export {
    TraineeDetailByDateTable
};
