import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';


import colors from '../../themes/colors';

const useStyles = makeStyles({
  root: {
    padding: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 2,
    width: 20,
    height: 20,
    border: '1px solid #D9D9D9',
// boxShadow: colors.checkbox.icon.box_shadow,
backgroundColor: '#fff',
    // boxShadow: colors.checkbox.icon.box_shadow,
    // backgroundColor: colors.checkbox.icon.background_color,
    backgroundImage: colors.checkbox.icon.background_image,
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: colors.checkbox.icon.hover.background_color,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: colors.checkbox.icon.disabled.background,
    },
  },
  checkedIcon: {
    backgroundColor: colors.checkbox.checked_icon.background_color,
    backgroundImage: colors.checkbox.checked_icon.background_image,
    '&:before': {
      display: 'block',
      width: 20,
      height: 20,
      backgroundImage: colors.checkbox.checked_icon.before.background_image,
      backgroundRepeat: 'no-repeat',
      backgroundPositionX: -1,
      backgroundPositionY: -2,
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: colors.checkbox.checked_icon.hover.background_color,
    },
  },
});

const StyledCheckbox = (props) => {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}


export default StyledCheckbox;