import React from 'react';

import styles from './styles.module.css';

const SearchTextbox = ({ placeholder, className, style, onChange, text, inputtype, autoFocus, id, icon, onKeyPress,disabled,reset_icon,resetOnClick }) => {
    return (
        <div className={`${styles.general_textbox} ${className}`} style={style}>
             <img src={icon} className={styles.icon} />
            <input id={id} className={`${styles.input} ${className}`} disabled={disabled} placeholder={placeholder} onChange={onChange} value={text} type={inputtype} autoFocus={autoFocus} onKeyPress={onKeyPress} />
            <img src={reset_icon} className={styles.reset_icon} onClick={resetOnClick}/>
        </div>
    )
}

export default SearchTextbox;