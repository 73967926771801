import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import BackButton from '../../../components/Buttons/BackButton';
import URLTextbox from '../../../components/Textboxes/URLTextboxes';
import HowToGetUrlImg from '../../../assets/images/step3_img.png';
import './styles.css';
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { startNewRolePlayInit, startNewRolePlaySave } from '../../../api/api';
import EventBus from '../../../components/EventBus'
import pasteIcon from '../../../assets/images/paste.png'
import deleteIcon from '../../../assets/images/delete.png'
import instructions from '../../../assets/images/help_instructions.svg'
import {va2PrivateDNS} from "../../../configs/environments";

const Step3 = ({ className, style, onBack, onNext, setLoadingOpen, isLoadingOpen, user_id, trainee, policy, insurance, isClicked, sectionCriteria, SET_NEW_ROLEPLAY, selectedCompanyName, recruiter, agentCompanyCode, branchCode }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [isGetUrlBoxOpen, setGetUrlBoxOpen] = React.useState(false);
    const [teamsUrlValue, setTeamsUrlValue] = React.useState("");

    let lastId = 0;

    const autoId = (prefix = 'rp3-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    const onChangeHandler = event => {
        setTeamsUrlValue(event.target.value);
        EventBus.dispatch('close_snack')
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleTeamTraining()
        }
    };
    const handleTeamTraining = () => {
        let teamsUrl = teamsUrlValue
        let callId = uuidv4()
        let trainerId = user_id
        let traineeId = trainee
        let themeId = policy
        let scenario = insurance
        let customerId = isClicked
        let rolePlayDetailParam = sectionCriteria
        let startSectionId = rolePlayDetailParam.filter(item => item.checked)[0].sectionId
        let sectionIdStr = rolePlayDetailParam.filter(item => item.checked).map(item => item.sectionId).toString()
        let data = {
            callId,
            trainerId,
            traineeId,
            themeId,
            teamsUrl,
            scenario,
            customerId,
            rolePlayDetailParam,
            startSectionId,
            sectionIdStr,
            agentCompanyCode,
            branchCode
        }
        if (!teamsUrlValue) {
            EventBus.dispatch('show_snack', { message: 'Teams会議のURLを入力してください', type: 'error' })
            return
        }
        let domain = va2PrivateDNS === '' ? `${document.location.origin}/apigw/va2roleplay` : va2PrivateDNS
        let initData = {
            "url": teamsUrl,
            "sectionId": startSectionId,
            "callback": `${domain}/backend/uploadSpeech`
        }
        startNewRolePlayInit(initData).then(res => {
            let callId = res.data.callId
            data.callId = callId
            SET_NEW_ROLEPLAY({ urls: res.data.urls })
            // res.podAddress
            startNewRolePlaySave(data).then(res2 => {
                let paramData = {
                    rolePlayId: res2.data.rolePlayId,
                    callId: callId
                }
                SET_NEW_ROLEPLAY(paramData)
                // if (teamsUrl !== "") {
                history.push("/MicrosoftTeamTraning");
                window.open(teamsUrl, "_blank")
                // }
            })
        }).catch(err => {
            if (err.response.status === 500) {
                EventBus.dispatch('show_snack', { message: 'Teamsのリンクが無効になっています', type: 'error' })
            } else if (err.response.status === 409) {
                EventBus.dispatch('show_snack', { message: 'ボットはすでに会議に参加しています', type: 'error' })
            } else {
                console.log(err.response)
            }
        })
    }
    const onPaste = (e) => {
        teamsUrlValue === '' ?
            navigator.clipboard.readText()
                .then(text => {
                    document.getElementById("rp3-4").value = text;
                    setTeamsUrlValue(text);
                    EventBus.dispatch('close_snack')
                })
                .catch(err => {
                    console.error('Failed to read clipboard contents: ', err);
                })
            : setTeamsUrlValue('')
    }
    return (
        <>
            <div className="cmn-bg-box" id="opening-microsoft-team">
                <p className="mb-4 font-weight-bold" id="selected_company_and_recruiter">アソシエイツ名：{selectedCompanyName[0].companyName} <span className="mx-3 d-md-inline d-none">|</span>
                    募集人名：{recruiter}</p>
                <Row className="mb-32">
                    <Col>
                        <BackButton id={autoId()} className="back-btn mr-3" title={t('general.button.return')} onClick={onBack} />
                        <GeneralButton id={autoId()} className="cmn-btn" title={t('general.button.next')} onClick={handleTeamTraining} />
                    </Col>
                </Row>
                <div className={`cmn-bg-box-inr  star-new-role-step star-new-role-step3 ${isGetUrlBoxOpen ? "get-url-box-out" : "get-url-box-in"} `}>
                    <Row>
                        <Col lg="12">
                            <h6 className="font-weight-bold d-flex mb-3" id="start_of_teams" name="start_of_teams"> {t('roleplay.step 3.title')} </h6>
                            <div className="d-flex align-items-center url-box-input px-0 mb-4">
                                <label id={autoId()} className="mb-0 label-box font-weight-bold">  {t('roleplay.step 3.Teams URL')} :</label>
                                <URLTextbox
                                    id={autoId()}
                                    placeholder={t('roleplay.step 3.select')}
                                    className={`font-14  ${teamsUrlValue === '' ? 'url-box' : 'pastedurl-box'}`}
                                    onKeyPress={handleKeyDown}
                                    onChange={onChangeHandler}
                                    icon={teamsUrlValue === '' ? pasteIcon : deleteIcon}
                                    value={teamsUrlValue}
                                    onPaste={onPaste}
                                />
                            </div>
                            <div className={`get-url-box ${isGetUrlBoxOpen ? "border-radious-none" : "border-radious"}`}>
                                <button id={autoId()} className={`get-url-button  ${isGetUrlBoxOpen ? "border-radious-none" : ""} `} onClick={() => setGetUrlBoxOpen(!isGetUrlBoxOpen)}><span><span className="font-14 font-weight-bold">ヘルプ：</span>URLの取得方法 </span> <span id="arrow_btn" className={`icon  ${isGetUrlBoxOpen ? "show" : "hide"} `}></span></button>
                                <div className={`get-url-content  ${isGetUrlBoxOpen ? "show" : "hide"} `}>
                                    <Row className="no-gutters help-box">
                                        <Col lg="4" className="">
                                            <div>
                                                <h5 className="font-14 help">[{t('roleplay.step 3.Procedure')}]</h5>
                                                {/* <img src={instructions} alt="instructions" /> */}
                                                <ul className="instruction-list">
                                                    <li id={autoId()} name="instruction_1" className="d-flex  text-align-left"><span className="pr-1">①</span>{t('roleplay.step 3.instruction 1')}</li>
                                                    <li id={autoId()} name="instruction_2" className="d-flex  text-align-left"><span className="pr-1">②</span>{t('roleplay.step 3.instruction 2')}</li>
                                                    <li id={autoId()} name="instruction_3" className="d-flex  text-align-left"><span className="pr-1">③</span>{t('roleplay.step 3.instruction 3')} </li>
                                                    <li id={autoId()} name="instruction_4" className="d-flex  text-align-left"><span className="pr-2">④</span>{t('roleplay.step 3.instruction 4')} </li>
                                                    <li id={autoId()} name="instruction_5" className="d-flex  text-align-left"><span className="pr-1">⑤</span>{t('roleplay.step 3.instruction 5')} </li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col lg="8">
                                            <img id={autoId()} name="dropdown_icon" src={HowToGetUrlImg} className="m-auto d-block mw-100" />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
};
const mapStateToProps = state => {
    return {
        user_id: state.vAgent.user_id
    }
}
const mapDispatchToProps = dispatch => ({
    SET_NEW_ROLEPLAY: (res) => dispatch({ type: "SET_NEW_ROLEPLAY", paramData: res })
})

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
