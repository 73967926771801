import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css'
import { GeneralDropdown } from '../../Dropdowns/GeneralDropdown'

const Tab = ({ className, visibleTab, setVisibleTab, selected, setSelected, learningThemes, scenarioByTheme }) => {
    const { t } = useTranslation();
    let lastId = 0;
    const autoId = (prefix = 'theme-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        visibleTab === undefined && learningThemes && setVisibleTab(learningThemes[0].id)
    }, [learningThemes])

    useEffect(() => {
        scenarioByTheme && setSelected({ id: scenarioByTheme[0].id, name: scenarioByTheme[0].name })
    }, [scenarioByTheme])

    const onhandleScenerio = (e) => {
        setSelected({ id: e.value, name: e.textContent })
    }

    const onhandleThemes = (value) => {
        setVisibleTab(value)
    }

    return (
        <div className={`${styles.tabs} ${className} `}>
            <ul className={`${styles.tabs_titles}  mb-24`}>
                {learningThemes && learningThemes.map((item) => (
                    item.isActive ?
                        <li key={item.id} onClick={() => onhandleThemes(item.id)} className={` ${styles.tab_title} ${visibleTab === item.id && styles.tab_title__active} }`} id={autoId()} >
                            {item.name}
                        </li>
                        :
                        <li key={item.id} className={` ${styles.tab_title}  }`} id={autoId()}>
                            {item.name}
                        </li>
                ))
                }
            </ul>
            <div className={`${styles.tab_content}`} id="themes_content">
                <label className={`font-weight-bold col-lg-2 col-md-3 col-sm-12 mb-0 pr-md-0 ${styles.header}`} id="theme_label">{t('edit check criteria.scenerio')}<span className="text-align-right">:</span></label>
                <div className={`col-lg-5 col-md-9 col-sm-12 ${styles.scenario_dropdown} `}>
                    <GeneralDropdown
                        isStatic={true}
                        staticData={scenarioByTheme}
                        onSelect={onhandleScenerio}
                        className={`font-10 ${styles.tab_dropdown}`}
                        selectedData={selected && selected.name}
                        dropdown_id="theme_dropdown"
                        secnerioBox={true}
                        secnerioText={t('general.text.please select a scenario')} />
                </div>
            </div>
        </div>
    )
}
export default Tab;
