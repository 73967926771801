import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import ResponsiveDialog from './../../Popup/ImagePopup';
import styles from './styles.module.css';
import { getCustomerList } from '../../../api/api'
import { connect } from 'react-redux';


const CustomerCard = ({ className, style, item, text, url, isClicked, setIsClicked, policy, insurance, id, attributesFlag = false, SET_NEW_ROLEPLAY, disabled = false }) => {
    const { t, i18n } = useTranslation();
    const [customerList, setCustomerList] = useState([]);
    useEffect(() => {
        if (attributesFlag) {
            let data = {
                attributesFlag,
                trainerFlag: 0
            }
            getCustomerList(data).then(res => {
                if (res.data.length > 0) {
                    let tempCustomerList = res.data;
                    setCustomerList(tempCustomerList)
                    setIsClicked(tempCustomerList[0].cust_code)
                    SET_NEW_ROLEPLAY(tempCustomerList[0].cust_code)
                }
            })
            return
        }
        if (!policy || !insurance) {
            return
        }
        let data = {
            attributesFlag,
            trainerFlag: 1,
            themeId: policy,
            scenarioId: insurance
        }
        getCustomerList(data).then(res => {
            if (res.data.length > 0) {
                let tempCustomerList = res.data;
                setCustomerList(tempCustomerList)
                if (isClicked === '') {
                    setIsClicked(tempCustomerList[0].cust_code)
                    SET_NEW_ROLEPLAY(tempCustomerList[0].cust_code)
                } else {
                    // setIsClicked(tempCustomerList[0].cust_code)
                    SET_NEW_ROLEPLAY(isClicked)
                }

            }
        })

    }, [policy, insurance])

    const setCustomerId = (customerId) => {
        !disabled && setIsClicked(customerId)
        !disabled && SET_NEW_ROLEPLAY(customerId)
    }

    return (
        <div className={` col-lg-12`} style={style}>
            <Row className={`small-padding-box ${styles.customer_card_row}`}>
                {
                    customerList.map((item, index) => (
                        <Col lg={{ size: 4, offset: 0 }} md={{ size: 6 }} sm="6" key={index} className={`d-flex mb-32`}>
                            <div className={`${styles.customer_card} ${isClicked == item.cust_code ? styles.active : ""}  ${disabled && className}`} onClick={() => setCustomerId(item.cust_code)}>
                                <img id={`img-${id}${index}`} src={item.cpd_prof_icon_img} className="img-fluid" alt="" />
                                <div className="justify-content-center my-auto">
                                    <p>
                                        <span id={`cn-${id}${index}`} className="mr-2">{item.cust_name}</span>
                                        <span id={`ca-${id}${index}`} className="mr-2">{item.cust_age}{t('roleplay.step 1.age')}</span>
                                        <span id={`cg-${id}${index}`} >{item.cust_gender}</span>
                                    </p>
                                    <p><span id={`cc-${id}${index}`} >{item.cpd_prof_title}</span></p>
                                    <ResponsiveDialog text={text} url={item.cpd_prof_img} customerDetail={item} className={`${styles.details} ${disabled && styles.grey_text}`} download={item.cpd_prof_pdf_url} disabled={disabled} id={`customer_${item.cust_code}`} />
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </div>
    )
};
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
    }
}
const mapDispatchToProps = dispatch => ({
    SET_NEW_ROLEPLAY: (res) => dispatch({ type: 'SET_NEW_ROLEPLAY', paramData: { customerId: res } })
})
export default connect(stateToProps, mapDispatchToProps)(CustomerCard);