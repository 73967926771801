import React, { useRef, useState, useEffect } from 'react';
import up_arrow from '../../../assets/images/up_arrow.png';
import down_arrow from '../../../assets/images/down_arrow.png';
import classes from './styles.module.css';

const Dropdown = ({ items, placeholder, onSelect, className, selectedData, dropdown_id }) => {
    const list = useRef();
    const elementRef = useRef(null);
    
    const [height, setHeight] = useState();
    const [open, setOpen] = useState(false);
    selectedData = (selectedData) ? selectedData : '';
    const [selectedText, setSelectedText] = useState(selectedData);

        const onOpen = () => {
        setOpen(true);
        list.current.focus();
    }

    const close = () => {
        setOpen(false);
    }

    const itemClick = (e) => {
        setSelectedText(e.target.textContent);
        setOpen(false);
        onSelect(e.target);
    }

    return (
        <div className={`${classes.select_box} ${className} ${open ? classes.select_box_radius : ''}`}>
            <div onClick={onOpen} className={'d-flex justify-content-between  align-items-center'}>
                <span>{selectedText === '' ? placeholder : selectedText}</span>
                <img src={`${open ? up_arrow : down_arrow} `} className={`${classes.arrows} `} />
            </div>
            <div
                tabIndex="0"
                ref={list}
                onBlur={close}
            >
                <ul className={`${open ? classes.showbox : classes.hidebox}`} ref={elementRef} >
                    {
                        items && items.map((item, index) => (
                            <li key={index} value={item.id} id={`${dropdown_id}_${item.id}`} onClick={itemClick}>
                                {item.name}
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export { Dropdown };