import React, { useRef } from 'react';
import { CSVLink } from "react-csv";
import GeneralButton from '../../../components/Buttons/GeneralButton'
const PeriodCSVExport = (props) => {
      let {data,excelRef,activeSlider,filterSelectedASCode,filterTheme,multiselectedTrainees,from,to,fileName} = props;
     
      const csvData = [];
      csvData.push(["Filter By "])
      const asFilter = filterSelectedASCode.map((x) => x.name).join(', ');
      const themeFilter = filterTheme.map((x) => x.name).join(', ');
      const traineeFilter = multiselectedTrainees.length > 0? multiselectedTrainees.map((x) => x.name).join(', '):"-";
      
      csvData.push(["種別",activeSlider === 1?"AS分析":"AS比較"])
      csvData.push(["AS コード",asFilter])
      csvData.push(["学習テーマ",themeFilter])
      csvData.push(["募集人選択",traineeFilter])
      csvData.push(["入社月",from.year+"/"+from.month+"~"+to.year+"/"+to.month])
      csvData.push([])
      csvData.push(["実施者","期間(月)"])

        for (let i = 0; i < data.length; i += 1) {
          for (var key in data[i]) {
            key !== "date" &&
            csvData.push([
              data[i].date,
              data[i][key]
            ]);
          }
        }

      const exportCSV = {
            filename : `${fileName}.csv`,
            data : csvData,
        }
    return(
        <CSVLink {...exportCSV} ref={excelRef} className="d-none">
            <GeneralButton id="export_csv" className="cmn-btn font-weight-normal" title="出力" />
        </CSVLink>
    )
}
export default PeriodCSVExport;