import {
    USERNAME,
    ACTIVE,
    OPEN,
    SCENARIO,
    AGENT_COMPANY,
    EMPLOYEE_ID,
    ROLE,
    USER_ID,
    RECENT_PATH,
    RECRUITER,
    RECRUITER_ID,
    RESET_STORE_VAGENT,
    ACCESS_TOKEN,
    COMPANY_CODE,
    BRANCH_CODE,
    ACTIVE_CODE,
    COMPANY,
    SELECTED_COMPANY_AT_SELECTION_OF_RECRUITER,
    LABEL_AT_ANALYSIS,
    REQUEST_HEADER_GROUP_ID,
    REQUEST_HEADER_USER_ID,
    SYNC_STATUS,
    RECRUITER_DETAIL,
    COMPANY_AT_RECRUITER_DETAIL,
    SELECTED_COMPANY_AT_LEARNING_THEME
}
    from '../constants';

const initialState = {
    username: "",
    isActive: false,
    isOpen: false,
    scenario: {},
    agent_company: '',
    employee_id: "",
    role: "",
    user_id: '',
    recent_path: '',
    recruiter: '',
    recruiter_id: '',
    access_token: '',
    company_code: '',
    branch_code: '',
    active_code: '',
    company: '',
    company_at_selection_of_recruiter: '',
    label_at_analysis: '',
    request_header_user_id: '',
    request_header_group_id: '',
    syncStatus: false,
    recruiter_detail: '',
    company_at_recruiter_detail: '',
    company_at_learning_theme : ''
};

export function vAgent(state = initialState, action) {
    switch (action.type) {
        case USERNAME:
            return { ...state, username: action.username };
        case ACTIVE:                                                 // for sidebar 
            return { ...state, isActive: action.isActive };
        case OPEN:
            return { ...state, isOpen: action.isOpen };
        case SCENARIO:
            return { ...state, scenario: action.scenario };
        case AGENT_COMPANY:
            return { ...state, agent_company: action.agent_company };
        case EMPLOYEE_ID:
            return { ...state, employee_id: action.employee_id };
        case ROLE:
            return { ...state, role: action.role };
        case USER_ID:
            return { ...state, user_id: action.user_id };
        case RECENT_PATH:
            return { ...state, recent_path: action.recent_path };
        case RECRUITER:
            return { ...state, recruiter: action.recruiter };
        case RECRUITER_ID:
            return { ...state, recruiter_id: action.recruiter_id };
        case RESET_STORE_VAGENT:
            return initialState;
        case ACCESS_TOKEN:
            return { ...state, access_token: action.access_token };
        case COMPANY_CODE:
            return { ...state, company_code: action.company_code };
        case BRANCH_CODE:
            return { ...state, branch_code: action.branch_code };
        case ACTIVE_CODE:
            return { ...state, active_code: action.active_code };
        case COMPANY:
            return { ...state, company: action.company };
        case SELECTED_COMPANY_AT_SELECTION_OF_RECRUITER:
            return { ...state, company_at_selection_of_recruiter: action.company_at_selection_of_recruiter };
        case LABEL_AT_ANALYSIS:
            return { ...state, label_at_analysis: action.label_at_analysis };
        case REQUEST_HEADER_USER_ID:
            return { ...state, request_header_user_id: action.request_header_user_id };
        case REQUEST_HEADER_GROUP_ID:
            return { ...state, request_header_group_id: action.request_header_group_id };
        case SYNC_STATUS:
            return { ...state, syncStatus: action.syncStatus };
        case RECRUITER_DETAIL:
            return { ...state, recruiter_detail: action.recruiter_detail };
        case COMPANY_AT_RECRUITER_DETAIL:
            return { ...state, company_at_recruiter_detail: action.company_at_recruiter_detail };
            case SELECTED_COMPANY_AT_LEARNING_THEME:
                return { ...state, company_at_learning_theme: action.company_at_learning_theme };
        default:
            return state;
    }
}