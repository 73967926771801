import React, { useState, useEffect } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import StarSelect from '../../../assets/images/star_select.svg';
import StarPending from '../../../assets/images/star_pending.svg';
import StarNoSelect from '../../../assets/images/star_no_select.svg';
import styles from './styles.module.css';
import { changeDateformat, timeFormat,padLeadingZeros } from '../../../utils/General';
import { setRecruiter, setRecruiterId, setSelectedCompanyCode, setRecruiterForDetail, setSelectedCompanyForDetail } from '../../../store/actions';
import { connect } from 'react-redux';
import { COMPANY_WITH_BRANCHES_URL } from "../../../configs/urlConstants"
import { getUserList } from "../../../api/api";
import { isTrainerManagerOrTrainee } from '../../../configs/constants';

const TraineeTable = ({ TableClassName, TableDivClassName, table_id, traineeByAgentId, filterTrainee, filterTheme, setRecruiter, setRecruiterId, filterCode, active, uniqueCode, companyCode, setSelectedCompanyCode, setRecruiterForDetail, setSelectedCompanyForDetail, isLoading }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [code, setCode] = useState();
    const [branch, setBranch] = useState();
    const [list, setList] = useState();
    let data, allTrainees;
    const onRecruiter = (name, id, companyCode, branchCode) => {
        setRecruiter(name)
        setRecruiterId(id)
        setCode(companyCode)
        setBranch(branchCode)
        setRecruiterForDetail({ id: id, name: name })
    }

    useEffect(() => {
        let sevenCode = '', threeCode = '';
        code && getUserList(COMPANY_WITH_BRANCHES_URL).then(res => {
            data = res.data.map(item => { return { id: item.code, name: padLeadingZeros(item.code,7) + '/' + item.name, branches: item.branches } })
            sevenCode = data.filter(item => parseInt(item.id) === parseInt(code));
            threeCode = branch && sevenCode[0].branches.filter(value => parseInt(value.code) === parseInt(branch))
            if(branch) {
                setSelectedCompanyCode([...sevenCode, { branch: threeCode }])
                setSelectedCompanyForDetail([...sevenCode, { branch: threeCode }])
            }else {
                setSelectedCompanyCode(sevenCode)
                setSelectedCompanyForDetail(sevenCode)
            }
            history.push('/RecruiterSummaryDetail')
        })
    }, [code])

    let loop = 0;

    const ColumnHeader = [
        ["", "", t('associate_summary.working_theme_headers.working_theme'), t('associate_summary.working_theme_headers.all_staff_efforts')],
        [
            t('associate_summary.working_theme_headers.associates_code'),
            t('associate_summary.working_theme_headers.full_name'),
            t('associate_summary.working_theme_headers.learning_theme'),
            t('associate_summary.working_theme_headers.no_of_times'),
            t('associate_summary.working_theme_headers.total_times'),
            t('associate_summary.working_theme_headers.max_achievement_rate'),
            t('associate_summary.working_theme_headers.first_implementation_date'),
            t('associate_summary.working_theme_headers.no_of_times_2'),
            t('associate_summary.working_theme_headers.total_times_2'),
            t('associate_summary.working_theme_headers.latest_implementation_date'),
        ]
    ];


    const noData = <tr className={`${styles.no_data} text-center`}><td colSpan="100%">{isLoading ? t('general.text.loading') : t('general.text.no data available')}</td></tr>;
    useEffect(() => {
        if (!filterCode && filterTrainee === undefined && filterTheme === undefined) {
            allTrainees = traineeByAgentId
            // .sort((a, b) => parseInt(b.workingtheme.Achivement_rate.match(/(\d+)/)) - parseInt(a.workingtheme.Achivement_rate.match(/(\d+)/)))
            // .sort((a, b) => a.name - b.name)
            setList(allTrainees)

        } else if (filterCode && filterTrainee === undefined && filterTheme === undefined) {
            allTrainees = traineeByAgentId.filter(person => (active === 1 ? filterCode.includes(person.companyCode) : filterCode.includes(person.companyCode + '-' + person.branchCode)))
            // allTrainees = allTrainees.sort((a, b) => parseInt(b.workingtheme.Achivement_rate.match(/(\d+)/)) - parseInt(a.workingtheme.Achivement_rate.match(/(\d+)/)))
            // .sort((a, b) => a.name - b.name)
            setList(allTrainees)

        } else if (filterCode && filterTrainee !== undefined && filterTheme === undefined) {
            allTrainees = traineeByAgentId.filter(person => (person.id === filterTrainee && (active === 1 ? filterCode.includes(person.companyCode) : filterCode.includes(person.companyCode + '-' + person.branchCode))))
            // allTrainees = allTrainees.sort((a, b) => parseInt(b.workingtheme.Achivement_rate.match(/(\d+)/)) - parseInt(a.workingtheme.Achivement_rate.match(/(\d+)/)))
            // .sort((a, b) => a.name - b.name)
            setList(allTrainees)

        } else if (filterCode && filterTrainee === undefined && filterTheme !== undefined) {
            allTrainees = traineeByAgentId.filter(person => (person.workingtheme.learningtheme === filterTheme) && (active === 1 ? filterCode.includes(person.companyCode) : filterCode.includes(person.companyCode + '-' + person.branchCode)))
            // allTrainees = allTrainees.sort((a, b) => parseInt(b.workingtheme.Achivement_rate.match(/(\d+)/)) - parseInt(a.workingtheme.Achivement_rate.match(/(\d+)/)))
            // .sort((a, b) => a.name - b.name)
            setList(allTrainees)

        } else if (!filterCode && filterTrainee !== undefined && filterTheme === undefined) {
            allTrainees = traineeByAgentId.filter(person => (person.id === filterTrainee))
            // allTrainees = allTrainees.sort((a, b) => parseInt(b.workingtheme.Achivement_rate.match(/(\d+)/)) - parseInt(a.workingtheme.Achivement_rate.match(/(\d+)/)))
            // .sort((a, b) => a.name - b.name)
            setList(allTrainees)

        } else if (!filterCode && filterTrainee !== undefined && filterTheme !== undefined) {
            allTrainees = traineeByAgentId.filter(person => ((person.id === filterTrainee) && (person.workingtheme.learningtheme === filterTheme)))
            // allTrainees = allTrainees.sort((a, b) => parseInt(b.workingtheme.Achivement_rate.match(/(\d+)/)) - parseInt(a.workingtheme.Achivement_rate.match(/(\d+)/)))
            // .sort((a, b) => a.name - b.name)
            setList(allTrainees)

        } else if (!filterCode && filterTrainee === undefined && filterTheme !== undefined) {
            allTrainees = traineeByAgentId.filter(person => (person.workingtheme.learningtheme === filterTheme))
            // allTrainees = allTrainees.sort((a, b) => parseInt(b.workingtheme.Achivement_rate.match(/(\d+)/)) - parseInt(a.workingtheme.Achivement_rate.match(/(\d+)/)))
            // .sort((a, b) => a.name - b.name)
            setList(allTrainees)
        } else {
            allTrainees = traineeByAgentId.filter(person => (person.id === filterTrainee) && (person.workingtheme.learningtheme === filterTheme) && (active === 1 ? filterCode.includes(person.companyCode) : filterCode.includes(person.companyCode + '-' + person.branchCode)))
            // allTrainees = allTrainees.sort((a, b) => parseInt(b.workingtheme.Achivement_rate.match(/(\d+)/)) - parseInt(a.workingtheme.Achivement_rate.match(/(\d+)/)))
            // .sort((a, b) => a.name - b.name)
            setList(allTrainees)
        }
    }, [traineeByAgentId, active, filterCode, filterTheme, filterTrainee])



    return (
        <>
            <div className={`${TableDivClassName} table-responsive ${styles.trainee_table}`}>
                <table className={`${TableClassName} cmn-table table`} id={table_id}>
                    <thead>
                        {ColumnHeader.map((headerloop, index) =>
                            <tr key={index} id={`${table_id}_heading_${index}`}>
                                {
                                    headerloop.map((header, sindex) => {
                                        if (index === 0 & sindex === 1 || index === 0 & sindex === 0) {
                                            return (
                                                <th className="text-center no-fill" key={index + sindex}></th>
                                            )
                                        } else if (index === 0 && sindex === 2) {
                                            return (
                                                <th className="text-center" colSpan="5" key={index + sindex} id={`${table_id}_heading_${index}${sindex}`}>{header}</th>
                                            )
                                        } else if (index === 0 && sindex === 3) {
                                            return (
                                                <th className="text-center" colSpan="4" key={index + sindex} id={`${table_id}_heading_${index}${sindex}`}>{header}</th>
                                            )
                                        } else if (index === 1 && sindex === 2) {
                                            return (
                                                <th className="text-center" colSpan="2" key={index + sindex} id={`${table_id}_heading_${index}${sindex}`}>{header}</th>
                                            )
                                        } else {
                                            return (<th className="text-center" key={index + sindex} id={`${table_id}_heading_${index}${sindex}`} >{header}</th>
                                            )
                                        }
                                    }
                                    )
                                }
                            </tr>
                        )
                        }
                    </thead>
                    <tbody className="cmn-scroll-bar">
                        {
                            list && list.length === 0 ? noData :
                                list && list.map((trainee, index) => {
                                    { loop = 0 }
                                    return (
                                        <tr key={index}>
                                            <td key={index + loop} id={`${table_id}_body_${index}${loop++}`}>{padLeadingZeros(trainee.companyCode,7)}{(active === 2 || isTrainerManagerOrTrainee()) && '-' + trainee.branchCode}</td>
                                            <td className="RobotoRegular font-10 font-weight-bold text-nowrap text-nowrap" key={index + loop} id={`${table_id}_body_${index}${loop++}`} ><Link onClick={() => onRecruiter(trainee.name, trainee.id, trainee.companyCode, trainee.branchCode)} to="#" className="text-primary  text_underline" >{trainee.name}</Link></td>
                                            <td key={index + loop} id={`${table_id}_body_${index}${loop++}`}>{trainee.workingtheme.learningtheme}</td>
                                            <td className="star-rating text-left" key={index + loop++} id={`${table_id}_body_${index}${loop++}`}>
                                                {(trainee.stars[1] === 1) ? <img src={StarSelect} alt="Star Select" /> :
                                                    (trainee.stars[1] === 2) ? <img src={StarPending} alt="Star Pending" /> :
                                                        (trainee.stars[1] === 0) ? <img src={StarNoSelect} alt="Star No Select" /> : ''
                                                }
                                                {(trainee.stars[2] === 1) ? <img src={StarSelect} alt="Star Select" /> :
                                                    (trainee.stars[2] === 2) ? <img src={StarPending} alt="Star Pending" /> :
                                                        (trainee.stars[2] === 0) ? <img src={StarNoSelect} alt="Star No Select" /> : ''
                                                }
                                                {(trainee.stars[3] === 1) ? <img src={StarSelect} alt="Star Select" /> :
                                                    (trainee.stars[3] === 2) ? <img src={StarPending} alt="Star Pending" /> :
                                                        (trainee.stars[3] === 0) ? <img src={StarNoSelect} alt="Star No Select" /> : ''
                                                }
                                                {(trainee.stars[4] === 1) ? <img src={StarSelect} alt="Star Select" /> :
                                                    (trainee.stars[4] === 2) ? <img src={StarPending} alt="Star Pending" /> :
                                                        (trainee.stars[4] === 0) ? <img src={StarNoSelect} alt="Star No Select" /> : ''
                                                }
                                                {(trainee.stars[5] === 1) ? <img src={StarSelect} alt="Star Select" /> :
                                                    (trainee.stars[5] === 2) ? <img src={StarPending} alt="Star Pending" /> :
                                                        (trainee.stars[5] === 0) ? <img src={StarNoSelect} alt="Star No Select" /> : ''
                                                }
                                            </td>
                                            <td key={index + loop} id={`${table_id}_body_${index}${loop++}`}>{trainee.workingtheme.playnumberoftimes}{t('associate_summary.times')}</td>
                                            <td key={index + loop} id={`${table_id}_body_${index}${loop++}`}>{timeFormat(trainee.workingtheme.totaltime)}</td>
                                            <td key={index + loop} id={`${table_id}_body_${index}${loop++}`}>{trainee.workingtheme.Achivement_rate}</td>
                                            <td key={index + loop} id={`${table_id}_body_${index}${loop++}`}>{trainee.Allstatusofeffort.firstimplementationdate !== '' ? changeDateformat(trainee.Allstatusofeffort.firstimplementationdate) : '-'}</td>
                                            <td key={index + loop} id={`${table_id}_body_${index}${loop++}`}>{trainee.Allstatusofeffort.playnumberoftimes}{t('associate_summary.times')}</td>
                                            <td key={index + loop} id={`${table_id}_body_${index}${loop++}`}>{timeFormat(trainee.Allstatusofeffort.totaltime)}</td>
                                            <td key={index + loop} id={`${table_id}_body_${index}${loop++}`}>{trainee.Allstatusofeffort.lastimplementationdate !== '' ? changeDateformat(trainee.Allstatusofeffort.lastimplementationdate) : '-'}</td>
                                        </tr>
                                    )
                                })
                        }

                    </tbody>
                    <tfoot></tfoot>

                </table>
            </div>
        </>

    );


};



const dispatchToProps = dispatch => {
    return {
        setRecruiter: (recruiter) => {
            dispatch(setRecruiter(recruiter));
        },
        setRecruiterId: (recruiter_id) => {
            dispatch(setRecruiterId(recruiter_id));
        },
        setSelectedCompanyCode: (company) => {
            dispatch(setSelectedCompanyCode(company));
        },
        setRecruiterForDetail: (recruiter_detail) => {
            dispatch(setRecruiterForDetail(recruiter_detail));
        },
        setSelectedCompanyForDetail: (company_at_recruiter_detail) => {
            dispatch(setSelectedCompanyForDetail(company_at_recruiter_detail));
        }
    }
}
export default connect(null, dispatchToProps)(TraineeTable);



