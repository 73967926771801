const initialState = {
  rolePlayId: '',
  callId: '',
  themeId: '',
  scenarioId: '',
  customerId: '',
  currentSectionIndex: 0,
  urls: {
    call: '',
    pause: '',
    resume: '',
    divided: '',
    finished: ''
  },
  recordingStatus: 0
}
const startRolePlay = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SET_NEW_ROLEPLAY":
      return { ...state, ...action.paramData }
    case "RESET_NEW_ROLEPLAY":
      return initialState;
    default:
      return state
  }
}

export default startRolePlay