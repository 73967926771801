import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import styles from './styles.module.css';
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import EventBus from '../../../components/EventBus';
import {timeFormat} from '../../../utils/General';
import PTOPTable from '../../../components/Table/PTOPTable'
import {setRecruiter, setRecruiterId,setSelectedCompanyCode} from '../../../store/actions'
import {updateFavoriteRolePlay,checkRolePlayExist,checkFavoriteRolePlayExist} from '../../../api/api';
import CSVExport from '../csvExport';
import { isAflacMemberOrBranchManager, isSystemOwner, isTrainerManager } from '../../../configs/constants';

const FavoriteTableList = ({favoriteData,getAIScoreParam,getExamScoreParam,refresh,setRefresh,evaluator_name,setRecruiter, setRecruiterId,setSelectedCompanyCode,asCodeData,agent_company_info,selectAllASCode,multiselectedTrainer,multiselectedTrainee, flag }) => {
    const { t } = useTranslation();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [rolePlayId, setRolePlayId] = useState(0);
    const [agentCompany, setAgentCompany] = useState([]);
    const [trainees, setTrainee] = useState([]);
    const [trainers, setTrainer] = useState([]);
    const noData = <tr><td colSpan="100%" className="text-center">{!flag ? t('general.text.loading') : t('general.text.not applicable')}</td></tr>;
    let lastId = 0;
    const autoId = (prefix = 'fav_table_') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    const passFailEvil = (passFailEvil) => {
        switch (passFailEvil) {
            case 0:
                return 'x'
            case 1:
                return '〇'
            case 2:
                return '-'
            default:
                return ''
        }
      }
      useEffect(() => {
        setAgentCompany(selectAllASCode)
      }, [selectAllASCode])
      useEffect(() => {
        setTrainer(multiselectedTrainer)
      }, [multiselectedTrainer])
      useEffect(() => {
        setTrainee(multiselectedTrainee)
      }, [multiselectedTrainee])
    const handleClick = (trainee) => {
        setRecruiter(trainee.Trainee_name)
        setRecruiterId(trainee.RPD_TRAINEE_ID)
        if(isAflacMemberOrBranchManager() || isSystemOwner()){
            setSelectedCompanyCode(asCodeData.filter(item => parseInt(item.id) === parseInt(trainee.AgentCompanyCode)))
        }else{
            setSelectedCompanyCode([{id:agent_company_info.code, name: agent_company_info.code+'/'+agent_company_info.name}])
        }
        if(trainee.is_exam === false){
            return getAIScoreParam(trainee.RSD_ROLEPLAY_ID,trainee.LT_THEME_ID,trainee.SCC_SC_ID)
        } else {
            return getExamScoreParam(trainee.RSD_ROLEPLAY_ID,trainee.LT_THEME_ID,trainee.SCC_SC_ID)
        }
    }
    const saveBookmarkParam = (rpId) =>{
        setRolePlayId(rpId);
        setConfirmOpen(true);
    }
    const updateBookmark = () => {
        checkRolePlayExist(rolePlayId).then(res=>{
            if(res.status === 200){
                FavoriteRolePlayExist();
            }
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' })
            }
            else if (err.response.status === 404) {
                EventBus.dispatch('show_snack', { message: "選択したロープレが募集人別サマリー画面から削除されています。", type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }
    const FavoriteRolePlayExist = () =>{
        checkFavoriteRolePlayExist(rolePlayId).then(res=>{
            if(res.status === 200){
                removeFavorite();
            }
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }
    const removeFavorite = () =>{
        const favoriteFlag = false;
        updateFavoriteRolePlay(rolePlayId,favoriteFlag)
        .then(res => {
            if (res.status == 200) {
                EventBus.dispatch('show_snack', { message: res.data.message, type: 'success' })
                setRefresh(!refresh);
            }

        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: "更新に失敗しました", type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }
    return (
        <>
            <div className="cmn-bg-box-inr mb-4">
                <div className={` ${favoriteData && favoriteData.length > 6?"cmn-table-scroll":"" } no-overflow-table table-responsive`}>
                    <ConfirmDialog
                        title="このレコードのお気に入り登録 を解除しますか？"
                        open={confirmOpen}
                        setOpen={setConfirmOpen}
                        onConfirm={updateBookmark}
                    ></ConfirmDialog>
                    <table className={` cmn-table table`} id="favorite-table">
                        <thead className="text-center">
                            <tr>
                                <th rowSpan={2} className="align-middle">No</th>
                                <th rowSpan={2} className="align-middle">登録者</th>
                                <th rowSpan={2} className="align-middle">募集人名</th>
                                <th rowSpan={2} className="align-middle">実施日</th>
                                <th rowSpan={2} className="align-middle">学習テーマ</th>
                                <th rowSpan={2} className="align-middle">プレイ時間</th>
                                <th rowSpan={2} className="align-middle">達成率</th>
                                <th colSpan={4}>指摘事項</th>
                                <th rowSpan={2} className="align-middle">評価者</th>
                                <th rowSpan={2} className="align-middle">アクション</th>
                            </tr>
                            <tr>
                                <th>FA</th>
                                <th>FF</th>
                                <th>P</th>
                                <th>C</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                favoriteData && favoriteData.length === 0 ? noData :
                                favoriteData && favoriteData
                                // .sort((a, b) => (b.RSD_ROLEPLAY_ID - a.RSD_ROLEPLAY_ID))
                                .map((item, index) => {
                                    return (
                                        <tr key={index} className={`text-center ${confirmOpen && (item.RSD_ROLEPLAY_ID === rolePlayId) ? styles.bg_danger : '' }`}>
                                            <td id={autoId()}>
                                                <a onClick={()=>handleClick(item)} className={`text-primary font-weight-bold`} id={`go_result_link${+index}`}>
                                                    <u>{index+1}</u>
                                                </a>
                                            </td>
                                            <td id={autoId()}>
                                                {item.Roleplayer_name}
                                            </td>
                                            <td id={autoId()} className="td-120 text-nowrap">{item.Trainee_name}</td>
                                            <td id={autoId()} className="date-td text-right">
                                                {Moment(item.ImplementationDate).format('YYYY/MM/DD')}
                                            </td>
                                            <td id={autoId()} className="td-120">{item.Theme_name}</td>
                                            <td id={autoId()} className="td-120">{timeFormat(item.duration)}</td>
                                            <td id={autoId()} className="td-120">{item.achivement_rate}</td>
                                            <td id={autoId()} className="td-60">
                                                <span className="description-link">{passFailEvil(item.FA.result)}
                                                    <PTOPTable passFailEvil={passFailEvil} ptpData={item.FA}/>
                                                </span>
                                            </td>
                                            <td id={autoId()} className="td-60">
                                                <span className="description-link">{passFailEvil(item.FF.result)}
                                                    <PTOPTable passFailEvil={passFailEvil} ptpData={item.FF}/>
                                                </span>
                                            </td>
                                            <td id={autoId()} className="td-60">
                                                <span className="description-link">{passFailEvil(item.P.result)}
                                                    <PTOPTable passFailEvil={passFailEvil} ptpData={item.P}/>
                                                </span>
                                            </td>
                                            <td id={autoId()} className="td-60">
                                                <span className="description-link last-cmn-description">{passFailEvil(item.C.result)}
                                                    <PTOPTable passFailEvil={passFailEvil} ptpData={item.C}/>
                                                </span>
                                            </td>
                                            <td id={autoId()}>
                                                {item.Evaluator_name}
                                            </td>
                                            <td>
                                                {
                                                    (isAflacMemberOrBranchManager() || isSystemOwner()) && evaluator_name === item.Evaluator_name || isTrainerManager() && evaluator_name === item.Evaluator_name?
                                                    <a onClick={()=>saveBookmarkParam(item.RSD_ROLEPLAY_ID)} className={`text-primary font-weight-bold ${styles.text_underline}`} id={`remove_favorite_${+index}`}>
                                                        解除
                                                    </a>
                                                    :<span className="text-muted" id={`remove_favorite_${+index}`}>解除</span>
                                                }
                                                
                                            </td>
                                        </tr>
                                    )
                                }
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <CSVExport favoriteData={favoriteData} agentCompany={agentCompany} trainees={trainees} trainers={trainers}/>
        </>

    );
};
const stateToProps = state => {
    return {
        agent_company_info: state.vAgent.agent_company
    }
}
const dispatchToProps = dispatch => {
    return {
        setRecruiter: (recruiter) => {
            dispatch(setRecruiter(recruiter));
        },
        setRecruiterId: (recruiter_id) => {
            dispatch(setRecruiterId(recruiter_id));
        },
        setSelectedCompanyCode: (company) => {
            dispatch(setSelectedCompanyCode(company));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(FavoriteTableList);
