import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import search_glyphicon from '../../../assets/images/search-glyphicon.svg';
import { getUserList } from '../../../api/api'
import classes from './styles.module.css';
import StyledCheckbox from '../../Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GeneralButton from '../../Buttons/GeneralButton';
import BackButton from '../../Buttons/BackButton';
import GeneralTextbox from '../../Textboxes/GeneralTextbox'
import down_arrow from '../../../assets/images/down_arrow.png';
import EventBus from '../../EventBus'

const MultiselectDropdown = ({ placeholder, onSelect, className, defaultChecked = false, dropdown_id, isStatic = false, staticData, url, selectAll = false, selectAllText, multiSelectedData, setMultiSelectedData, params, activeDropdown, setActiveDropdown, message, setSelectedData }) => {
    const { t } = useTranslation();
    const list = useRef();
    const wrapperRef = useRef(null);
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = useState(false);
    const [isOPen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState();
    const [allData, setAllData] = useState();
    const [allUsers, setAllUsers] = useState([]);
    const [disabled, setDisabled] = useState(false);
    useEffect(() => {
        if (isStatic) {
            setUserList(staticData)
            setAllUsers(staticData)
            setAllData({ checked: true, newlyAdded: false })
        } else {
            if (defaultChecked) {
                getUserList(url, params).then(res => {
                    setAllUsers(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }))
                    setUserList(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }))
                    setAllData({ checked: true, newlyAdded: false })
                })
            } else {
                getUserList(url, params).then(res => {
                    setAllUsers(res.data.map(item => { return { id: item.id, name: item.name, checked: false, newlyAdded: true, found: true } }))
                    setUserList(res.data.map(item => { return { id: item.id, name: item.name, checked: false, newlyAdded: true, found: true } }))
                })
            }
        }
    }, [staticData, url, params])

    const onOpen = () => {
        activeDropdown === undefined && !isOPen && setOpen(true);
        activeDropdown === undefined && !isOPen && setIsOpen(true)
        activeDropdown === undefined && !isOPen && list.current.focus();
        setActiveDropdown(true)
    }

    const itemClick = (e) => {
        EventBus.dispatch('close_snack')
        setAllUsers(allUsers.map(data => {
            if (parseInt(data.id) === parseInt(e.target.value)) {
                data.checked = e.target.checked ? true : false;
            }
            return data
        }))

        let array = userList.map(data => {
            if (parseInt(data.id) === parseInt(e.target.value)) {
                data.checked = e.target.checked ? true : false;
            }
            return data
        })
        setUserList(array)
    }

    const onSearch = (e) => {
        setSearchText(e.target.value);
    }

    const onCancel = (e) => {
        EventBus.dispatch('close_snack')
        let newArray = [...allUsers]
        let array = allUsers.map(data => {
            data.checked = (data.newlyAdded) ? false : true;
            data.found = true
            return data
        })
        setUserList(array)
        setOpen(false);
        setIsOpen(false)
        setSearchText(undefined)
        JSON.stringify(newArray) !== JSON.stringify(array) && setMultiSelectedData(array && array.filter(data => data.checked === true))
        setActiveDropdown(undefined)
    }

    const onApply = (e) => {
        if (allUsers.filter(item => item.checked).length !== 0) {
            let newArray = [...allUsers]
            newArray = newArray.map(data => { data.newlyAdded = (data.checked) ? false : true; data.found = true; return data })
            setUserList(newArray)
            setMultiSelectedData(newArray && newArray.filter(data => data.checked === true))

            setOpen(false);
            setIsOpen(false)
            setSearchText(undefined)
            setActiveDropdown(undefined)
        } else {
            EventBus.dispatch('show_snack', { message: message, type: "error" })
        }

    }
    const onSelectAll = (e) => {
        setAllData({ checked: e.target.checked, newlyAdded: true, previousValue: !e.target.checked })
        let array = []
        if (!searchText || searchText.length === 0) {
            array = [...allUsers]
            array.map(data => {
                data.checked = (e.target.checked) ? true : false;
                return data
            })
            setUserList(array)
            setAllUsers(array)
        } else if (searchText && searchText.length > 0)
            array = userList.map(item => {
                if (item.found) {
                    item.checked = e.target.checked
                }
                return item
            })
        setUserList(array)
    }

    useEffect(() => {
        if (open) {
            searchText && searchText.length > 0 ?
                setUserList(userList.map(item => {
                    item.found = (item.name.toLowerCase().includes(searchText.toLowerCase())) ? true : false
                    return item
                }))
                : setUserList(userList.map(item => {
                    item.found = true
                    return item
                }))
        }
    }, [searchText])

    useEffect(() => {
        if (allData) {
            let array = [...userList]
            searchText && searchText.length > 0 ?
                setAllData({ checked: parseInt(array && array.filter(item => item.checked === true && item.found === true).length) === parseInt(array && array.filter(item => item.found === true).length) ? true : false, newlyAdded: true })
                : setAllData({ checked: array && array.filter(item => item.checked).length === array.length ? true : false, newlyAdded: true })
        }
    }, [userList])

    useEffect(() => {
        let newArray = [...allUsers]
        newArray.filter(item => item.checked).length === 0 ? setDisabled(true) : setDisabled(false)
        setSelectedData && setSelectedData(allUsers)
    }, [allUsers, userList])


    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            const concernedElement = document.getElementById(`${dropdown_id}_multi___section`);
            if (dropdown_id && concernedElement && concernedElement.contains(e.target)) {
                // console.log("clicked inside");
            } else {
                dropdown_id && document.getElementById(`${dropdown_id}_cancel_btn`) && document.getElementById(`${dropdown_id}_cancel_btn`).click()
                let newArray = [...allUsers]
                dropdown_id && document.getElementById(`${dropdown_id}_cancel_btn`) &&  newArray.filter(item => item.checked).length !== 0 && setDisabled(false)
                // console.log("clicked outside");
            }
        });
    }, [wrapperRef]);

    return (
        <div className="position-relative" id={`${dropdown_id}_multi___section`} ref={wrapperRef}>
            <div className={`${classes.select_box} ${className} ${open ? classes.select_box_radius : ''}`} id={`${dropdown_id}_main_div`}>
                <div onClick={onOpen} className={'d-flex align-items-center h-100'}>
                    {/* <GeneralTextbox className={`${classes.search_text} ${multiSelectedData && multiSelectedData.filter(item => item.checked).length !== 0 && !searchText ? classes.search_text_width : classes.modify_search_text_width}`} onChange={onSearch} placeholder={multiSelectedData && multiSelectedData.filter(item => item.checked).length === 0 ? placeholder : ''} autoFocus={true} disabled={!open} /> */}
                    <GeneralTextbox
                        className={`${classes.search_text} ${allUsers.length > 0 && allUsers.filter(item => item.checked).length !== 0 && !searchText ? classes.search_text_width : ''}`}
                        onChange={onSearch}
                        placeholder={allUsers && allUsers.filter(item => item.checked).length === 0 ? placeholder : ''}
                        autoFocus={true} disabled={!open}
                        style={{ 'width': allUsers && allUsers.filter(item => item.checked).length === 0 ? '' : searchText && searchText.length * 16 + 'px' }}
                        text={open ? searchText : ''}
                        id={`${dropdown_id}`} />
                    {
                        allUsers.filter(item => item.checked).length > 0 &&
                        <div className={allUsers.length !== allUsers.filter(item => item.checked).length ? classes.selected_item : ''} >
                            <span id={`${dropdown_id}_select_all_lb_text`}>
                                {allUsers.length === allUsers.filter(item => item.checked).length ?
                                    selectAllText : allUsers.filter(item => item.checked).length + '項目選択'}
                            </span>
                        </div>
                    }
                    {(!searchText || searchText.length === 0) ? <img alt="icon" id={`${dropdown_id}_img`} src={`${open ? search_glyphicon : down_arrow} `} className={`${classes.arrows} `} /> : ''}

                </div>
            </div>
            <div tabIndex="0" ref={list} >
                <div className={`${open ? classes.showbox : classes.hidebox}  ${classes.list}`} >
                    <ul className={`${classes.min_height} ${selectAll && classes.select_all_list} styled_scrollbar`} id={`${dropdown_id}_list`}>
                        {selectAll && userList && userList.filter(item => item.found === true).length > 0 && <li key='default' value="0" id={`${dropdown_id}0`}>
                            <FormControlLabel
                                style={styles.root}
                                control={<StyledCheckbox
                                    value={0}
                                    id="select_all"
                                    onClick={onSelectAll}
                                    checked={allData && allData.checked ? true : false}
                                />}
                                label={<span style={styles.selectAllLabel} id={`${dropdown_id}_select_all_item`}>{selectAllText}</span>}
                            />
                        </li>}

                        {userList && userList.filter(item => item.found === true).map((item, index) => (
                            <li key={index} value={item.id} id={`${dropdown_id}${item.id}`} >
                                <FormControlLabel
                                    style={styles.root}
                                    control={<StyledCheckbox
                                        value={item.id}
                                        id={item.name}
                                        onClick={itemClick}
                                        checked={item.checked}
                                    />}
                                    label={<span style={styles.label} id={`${dropdown_id}_item_${index}`}>{item.name}</span>}
                                />
                            </li>
                        ))
                        }
                    </ul>
                    <div className={classes.footer}>
                        <BackButton className="back-btn mr-3" title={t('general.button.return')} onClick={onCancel} id={`${dropdown_id}_cancel_btn`} />
                        <GeneralButton className={`cmn-btn ${disabled && disabled === true ? classes.gray : ''}`} title={t('edit keywords.apply')} onClick={onApply} disabled={disabled && disabled} id={`${dropdown_id}_apply_btn`} />
                    </div>
                </div>

            </div>
        </div>
    )
}
const styles = {
    root: {
        marginLeft: 0,
        marginBottom: 0
    },
    label: {
        marginButtom: 0,
        fontSize: '14px',
        paddingLeft: '8px',
        fontFamily: 'RobotoRegular'
    },
    selectAllLabel: {
        marginButtom: 0,
        fontSize: '14px',
        paddingLeft: '8px',
        fontFamily: 'MeiryoRegular'
    }
};
export { MultiselectDropdown };