import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import YesButton from '../../Buttons/YesButton';
import NoButton from '../../Buttons/NoButton';
import cancel from '../../../assets/images/cancel.png';
import { useTranslation } from 'react-i18next';
import './styles.css'
const ConfirmDialog = (props) => {
  const { title, open, setOpen, onConfirm, onCancel, className, alert = false, onReturn, id } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
        if (typeof onCancel === 'function') {
          onCancel();
        }
      }}
      aria-labelledby="confirm-dialog"
      PaperProps={{ className: "confirm_dialog_width" }}
    >
      <DialogTitle id="confirm-dialog" className="p-0 text-right">
        <img src={cancel} alt="Cancel" className="cancel" onClick={() => {
          setOpen(false);
          if (typeof onCancel === 'function') {
            onCancel();
          }
        }} />
      </DialogTitle>
      <DialogContent className={`font-weight-bold font-14 ${className}`} id={`${id ? id + '_text' : 'comfirm_text'}`}>{title}</DialogContent>
      <DialogActions>
        {alert ? <YesButton id="alert" className="yes_btn font-14 py-3 mb-2" title={t('general.button.return to test result input')} onClick={() => {
          setOpen(false);
          // onReturn();
        }} />
          : <>
            <YesButton id="apply" className="yes_btn font-14 py-3 mb-2" title={t('general.button.yes')} onClick={() => {
              setOpen(false);
              onConfirm();
            }} />
            <NoButton id="cancel" className="no_btn font-14 py-3" title={t('general.button.no')} onClick={() => {
              setOpen(false);
              if (typeof onCancel === 'function') {
                onCancel();
              }
            }} />
          </>
        }

      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
