import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '../../RadioButtons';
import { Dropdown } from '../../Dropdowns/PassFailDropdown'
import ConfirmDialog from '../../Dialog/ConfirmDialog'
import ScenarioDialog from '../../Dialog/ScenarioDialog'
import styles from './styles.module.css';
import down_arrow from '../../../assets/images/down_arrow.png'
import { defaultCriteriaOption, timeFormat,formatYearMonth } from '../../../utils/General';
import { isAflacMemberOrBranchManager, isAflacMemberOrBranchManagerOrTrainerManager, isTrainerManager, isTrainee, isSystemOwner } from '../../../configs/constants'

const TraineeDetailTable = ({ TableClassName, TableDivClassName, table_id, traineeById, role_id, user_id, updateExam,SET_NEW_EXAM,refresh,setRefresh}) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmExam, setConfirmExam] = useState(false);
    const [openScenario, setOpenScenario] = useState(false);
    const [examStatus, setExamStatus] = useState();
    const [examId, setExamId] = useState();
    const [checkItem, setCheckItem] = useState();
    const [scenarioId, setScenarioId] = useState();
    const [scenario, setScenario] = useState([]);
    let loop = 0;
    const noData = <tr><td colSpan="100%">{t('general.text.no data available')}</td></tr>;
    let lastId = 0;
    const autoId = (prefix = 'td-table') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    const passFailEvil = (passFailEvil) => {
        switch (parseInt(passFailEvil)) {
            case 1:
                return 'ー'
            case 2:
                return 'x'
            case 3:
                return '△'
            case 4:
                return '〇'
            default:
                return ''
        }
    }
    const openDialog = (themeId,examId,examStatus,index) => {
        SET_NEW_EXAM({ themeId })
        SET_NEW_EXAM({ examId })
        SET_NEW_EXAM({ examStatus })
        setScenario(traineeById[index].examScenarios);
        setCheckItem(traineeById[index].examScenarios[0].id);
        setScenarioId(traineeById[index].examScenarios[0].id);
        setConfirmOpen(isTrainee() ? false : true);
    }
    const toOpenScenario = () => {
        setOpenScenario(true);
    }
    const handlePassParam = (examStatus,examId) => {
        setExamStatus(examStatus)
        setExamId(examId)
    }
    const toExam = () => {
        SET_NEW_EXAM({ scenarioId })
        history.push('StartExam');
    }
    const handleChangeScenairoItem = (event) => {
        setCheckItem(event.target.value)
        setScenarioId(parseInt(event.target.value))
    }
    const saveExamStatus = () => {
        updateExam(examStatus,examId);
    }
    const handleCancel = () =>{
        setRefresh(!refresh);
    }
    const scenarioUI = scenario.map((item, index) => {
        return (
            <FormControlLabel key={index} value={item.id}
                control={<Radio
                    checked={checkItem == item.id}
                    onChange={handleChangeScenairoItem}
                    value={item.id}
                    name="radio-button"
                    color="default"
                    id={autoId()}
                />} label={<span className={`MeiryoRegular font-14 ${checkItem == item.id ? 'font-weight-bold' : ''}`}>{item.name}</span>} />
        )
    })
    var activeItem = 0;
    var allPassResult = traineeById.every(item=>item.examStatus === 4);
    if (allPassResult === true) {
        activeItem = -1;
    }else{
        var filterReExaminationResult = traineeById.findIndex((item)=>item.examStatus === 3);
        if (filterReExaminationResult !== -1) {
            activeItem = filterReExaminationResult;
        }else{
            var filterFailResult = traineeById.findIndex((item)=>item.examStatus === 2);
            if (filterFailResult !== -1) {
                activeItem = filterFailResult;
            }else{
                var filterNotStartResult = traineeById.findIndex((item)=>item.examStatus === 1);
                if (filterNotStartResult !== -1) {
                    activeItem = filterNotStartResult;
                }
            }
        }
    }
    // const notStartExamCount = traineeById.filter(item => item.LearningTheme.result === 2);
    return (
        <div className={TableDivClassName}>
            <ConfirmDialog
                title="検定結果を入力しますか？"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={toOpenScenario}
            ></ConfirmDialog>
            <ConfirmDialog
                title="保存しますか？"
                open={confirmExam}
                setOpen={setConfirmExam}
                onConfirm={saveExamStatus}
                onCancel={handleCancel}
            ></ConfirmDialog>
            <ScenarioDialog
                title="シナリオを選択してください"
                open={openScenario}
                setOpen={setOpenScenario}
                onConfirm={toExam}
                children={scenarioUI}
            ></ScenarioDialog>
            <table className={`${TableClassName} cmn-table table`} id={table_id}>
                <thead>
                    <tr>
                        {traineeById.length !== 0?
                        <th className="no-fill" id={`${table_id}_heading_11`} rowSpan="2"></th>
                        :""}
                        <th className='align-middle' id={`${table_id}_heading_12`} rowSpan="2">{t('associate_summary.learning_theme')}</th>
                        <th id={`${table_id}_heading_13`} colSpan="3">{t('associate_summary.role_playing')}</th>
                        <th id={`${table_id}_heading_14`} colSpan="4">{t('associate_summary.exam')}</th>
                    </tr>
                    <tr>
                        <th id={`${table_id}_heading_21`}>{t('associate_summary.target_time')}</th>
                        <th id={`${table_id}_heading_22`}>{t('associate_summary.play_time')}</th>
                        <th id={`${table_id}_heading_23`}>{t('associate_summary.achievement_rate')}</th>
                        <th id={`${table_id}_heading_24`}>ステータス</th>
                        <th id={`${table_id}_heading_25`}>{t('associate_summary.schedult_test_month')}</th>
                        <th id={`${table_id}_heading_26`}>{t('associate_summary.test_date')}</th>
                        <th id={`${table_id}_heading_27`}>{t('associate_summary.pass_fail')}</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        traineeById && traineeById.length === 0 ? noData :
                            traineeById && traineeById
                                .map((value, index) => {
                                    return (
                                        <tr key={index} className={activeItem === index ? `${styles.active}` : ""}>
                                            <td key={`${index}${loop}`} className="font-weight-bold" id={`${table_id}_body_${index}${loop++}`}>{t(`step ${index + 1}`)}</td>
                                            <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`}>{value.themeName}</td>
                                            <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} >{timeFormat(value.targetTime)}</td>
                                            <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} >{timeFormat(value.duration)}</td>
                                            <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} >{value.maxAchieveRate}</td>
                                            {value.examStatus === 1 && activeItem === index?
                                                <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} onClick={()=>openDialog(value.themeId,value.examId,value.examStatus,index)}>{isTrainee() ? '未実施' : <a className="text-underline">未実施</a> } </td>
                                            :value.examStatus === 1?
                                            <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} >未実施</td>
                                            :value.examStatus === 2?
                                                <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} onClick={()=>openDialog(value.themeId,value.examId,value.examStatus,index)}>{isTrainee() ? '再検定' :<a className="text-underline">再検定</a> }</td>
                                            :value.examStatus=== 3?
                                                <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} onClick={()=>openDialog(value.themeId,value.examId,value.examStatus,index)}>{isTrainee() ? '再検定中' :<a className="text-underline">再検定中</a> }</td>
                                            :
                                            <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} >完了</td>
                                            }
                                            <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} >{formatYearMonth(value.expectedEndYearMonth).year}年{formatYearMonth(value.expectedEndYearMonth).month}月​</td>
                                            <td key={`${index}${loop}`} id={`${table_id}_body_${index}${loop++}`} >{value.examEndDate?value.examEndDate:'-'}</td>
                                            <td key={`${index}${loop}`} className='px-0' id={`${table_id}_body_${index}${loop++}`}>
                                                {isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner()? 
                                                        value.examStatus === 1 && activeItem === index?
                                                            <Dropdown onSelect={(val) => {setConfirmExam(true);handlePassParam(val.value,value.examId)}} className={`${styles.general_dropdown03} font-14`} items={defaultCriteriaOption(value.examStatus)} selectedData={passFailEvil(value.examStatus)} dropdown_id={`pass_fail${index}${loop++}`} />
                                                        :value.examStatus === 4 && (isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner())?
                                                            <Dropdown onSelect={(val) => {setConfirmExam(true);handlePassParam(val.value,value.examId)}} className={`${styles.general_dropdown03} font-14`} items={defaultCriteriaOption(value.examStatus)} selectedData={passFailEvil(value.examStatus)} dropdown_id={`pass_fail${index}${loop++}`} />
                                                        :value.examStatus === 4 || value.examStatus === 1?
                                                            <div className={`${styles.disable_div}`}>
                                                                <div className="w-100  text-center">
                                                                    <span className={styles.pass_fail_value}>{passFailEvil(value.examStatus)}</span>
                                                                    <img src={down_arrow} className={styles.down_arrow} alt="Down arrow image"></img>
                                                                </div>
                                                            </div>
                                                        :
                                                            <Dropdown onSelect={(val) => {setConfirmExam(true);handlePassParam(val.value,value.examId)}} className={`${styles.general_dropdown03} font-14`} items={defaultCriteriaOption(value.examStatus)} selectedData={passFailEvil(value.examStatus)} dropdown_id={`pass_fail${index}${loop++}`} />
                                                    : passFailEvil(value.examStatus)
                                                }
                                            </td>
                                        </tr>
                                    )
                                })
                    }
                </tbody>
            </table>
        </div>
    );
};

export {
    TraineeDetailTable
};