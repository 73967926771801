import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Row, Col } from 'reactstrap';
import GeneralButton from '../../../components/Buttons/GeneralButton'
import Chip from '@material-ui/core/Chip';
import ChatLog from './ChatLog'
import {NavLink } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StarSelect from '../../../assets/images/star_select.svg';
import StarPending from '../../../assets/images/star_pending.svg';
import StarNoSelect from '../../../assets/images/star_no_select.svg';
import {personEval, aiEval, passStar} from '../../../utils/mapState';
import { isAflacMemberOrBranchManagerOrTrainerManager, isSystemOwner } from '../../../configs/constants'
import {formatTime, NanoSecondToMinute} from '../../../utils/DateFormat'
import './history.less'
import classes from './styles.module.css'
import {stringFormat} from '../../../utils/General'
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import KeywordPopUp from '../KeywordPopUp'
import bookmark_img from '../../../assets/images/saved_bookmark.svg'
  

    const History = ({aiScoreResult,role_id,totalDurTime}) => {
    const history = useHistory();

    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const [confirmOpen,setConfirmOpen] = React.useState(false);

    const [keywords, setKeywords] = React.useState([]);
    const [keywordsJSON, setKeywordsJSON] = React.useState([]);
    const [matchedKeywords, setMatchedKeywords] = React.useState([]);
    // const [bookmark, setBookmark] = React.useState(0);  // it must be deleted later . It is used to show/hide bookmark icon
    
    let lastId = 0;

    const autoId = (prefix='chat_history-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    const handleChange = (panel, id) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        const parentElement  =  document.getElementById(`msger_chat_content_${id}`);
        !isExpanded && parentElement.scrollTo(0,0);
    };

    const editScoreResult = (section) => {
        history.push({pathname: `/ai_scoring_result/${section.sectionId}`, query: section})
    }
    const handleClickOpen = (keywords, keywordsJSON, matchedKeywords) => {
        setKeywords(keywords)
        setKeywordsJSON(keywordsJSON ? JSON.parse(keywordsJSON) : [])
        setMatchedKeywords(matchedKeywords)
        setConfirmOpen(true);
    }
    const handleClose = () => {
        setConfirmOpen(false);
    };
    const getAIPassFailState = (criteriaName,aiScoreFlag) => {
        let nssToMinute = NanoSecondToMinute(totalDurTime);
        let minutes= nssToMinute.toFixed(2);
        if (aiScoreFlag === 0) {
            return '対象外'
        }
        if (criteriaName === "10分以内にロープレが完了している" && minutes <= 10.00) {
            return "〇";
        }else if(criteriaName === "15分以内にロープレが完了している" && minutes <= 15.00){
            return "〇";
        }else {
            return "-";
        }
    }
    const onBookmarkClick = (id, event) => {
        setExpanded(false);
        if(document.getElementById(`bookmarkPosition_${id}`)){
            const el = document.getElementById(`bookmarkPosition_${id}`).offsetTop;
            const parentElement  =  document.getElementById(`msger_chat_content_${id}`);
            parentElement.scrollTo(0,el);
        }
    }

    const noExpanded = (event) => {
       event.stopPropagation()
    }

    var element = document.getElementsByClassName("MuiAccordionSummary-expandIcon");
    for(let i = 0; i < element.length; i++) {
        element[i].onclick = function(event) {
            var chats = document.getElementsByClassName("msger_chat");
            for(let j = 0; j < chats.length; j++) {
                chats[i].scrollTo(0,0);
            }
        }
    }

    return(
        <div className={classes.root}>
            {aiScoreResult.map((item, index) => {
                const allmatchedKeywords = [];
                return (
                    <Accordion id={autoId()} key={index} className={`history ${classes.accordianHead} MeiryoRegular`}>
                        <AccordionSummary className={ `${item.sectionStatus === 0 ? `gray_background ${classes.accordiansummaryHead}` : `${classes.accordiansummaryHead}`}` }
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={`panel${index}bh-content`}
                            id={`panel${index}bh-header`}
                        >
                            <span id={autoId()} className={`${classes.heading} d-flex flex-wrap align-items-center`} onClick={noExpanded} onFocus={noExpanded}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className={`font-14 font-weight-bold MeiryoRegular ${classes.section_name}`} onClick={noExpanded} onFocus={noExpanded}>{item.sectionName}</div>
                                    <div className={`d-flex`}>
                                    {item.sectionStatus === 1 ? 
                                    <Button
                                        id="underscoring"
                                        className="mate_btn mr-2"
                                        variant="contained"
                                        color="primary"
                                        onClick={noExpanded} onFocus={noExpanded}
                                    >{"採点中"}</Button> : item.sectionStatus === 2 ?
                                    <Button
                                        id="inprogress"
                                        className={"mate_btn_primary mr-2"}
                                        variant="contained"
                                        color="primary"
                                        onClick={noExpanded} onFocus={noExpanded}
                                    >{"実施中"}</Button> : item.sectionStatus === 3 ?
                                    <Button
                                        id="complete"
                                        className={"mate_btn mr-2"}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<DoneIcon />}
                                        onClick={noExpanded} onFocus={noExpanded}
                                    >{"完了"}</Button> : ''
                                }
                                {item.isBookMarked && <img src={bookmark_img} alt="bookmark" id="bookmark" onClick={ () => onBookmarkClick(item.bookMarkedAt) }/>}
                                </div>
                                </div>
                            </span>
                            <span id={autoId()} className={`${classes.secondaryHeading} d-flex align-items-center`} onClick={noExpanded} onFocus={noExpanded}>
                                <Row className="MeiryoRegular w-100">
                                    <Col lg={3} className="font-14">{item.passCount}/{item.passCount + item.unPassCount}({item.passPercent})</Col>
                                    <Col lg={7} className="text-left">
                                        {passStar(item.passCount, item.unPassCount)}
                                    </Col>
                                    <Col lg={2} className="font-14">{formatTime(item.durTime)}</Col>
                                </Row>
                            </span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <span className="w-100">
                                <Row className="mb-3 align-items-center">
                                    <Col xl="10" lg="8" sm="8">
                                        <p className="font-14 font-weight-bold MeiryoRegular mb-0">{t('AI score result.score_detail')}</p>
                                    </Col>
                                    {isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner() ?
                                    <Col xl="2" lg="4" sm="4" className="text-right">
                                        <GeneralButton id={autoId()} className="cmn-btn font-14 w-auto px-3 button-shadow" onClick={() => editScoreResult(item)} title={t('general.button.edit')}></GeneralButton>
                                    </Col>
                                    :""}
                                </Row>
                                <Row className="mb-3">
                                    <Col xl="12" lg="12">
                                        <div className="table-responsive">
                                            <table id={autoId()} className="cmn-table table MeiryoRegular text-center font-14">
                                                <tbody>
                                                    <tr id={autoId()} className="cmn-bg-box">
                                                        <th id={autoId()} style={{width : "150px"}}>{t('AI score result.check_criteria')}</th>
                                                        <th id={autoId()} style={{width : "50px"}}>{t('AI score result.evaluation')}</th>
                                                        <th id={autoId()} >{t('AI score result.person_scoring')}</th>
                                                        <th id={autoId()} >{t('AI score result.ai_scoring')}</th>
                                                        <th id={autoId()} colSpan={2}>{t('AI score result.keywords')}</th>
                                                    </tr>
                                                    {item.criteriaResult.map((criteriaItem, criteriaIndex) => {
                                                        allmatchedKeywords.push(...criteriaItem.matchedKeywords, ...criteriaItem.matchedKeywordSynonyms ? JSON.parse(criteriaItem.matchedKeywordSynonyms).map(item => `${item.keyword},${item.synonyms}`).toString().split(",") : [])
                                                        // allmatchedKeywords.push(...criteriaItem.matchedKeywords)
                                                        let evaluation = '';
                                                        if (criteriaItem.evaluation === "true") {
                                                            evaluation = "true";
                                                        } else if(criteriaItem.aiEval === 1) {
                                                            evaluation = "true";
                                                        }else{
                                                            evaluation = "false"
                                                        }
                                                        return (
                                                            <tr key={criteriaIndex}>
                                                                <td id={autoId()} >{stringFormat(criteriaItem.criteriaName)}</td>
                                                                <td id={autoId()} >
                                                                    <img src={evaluation === "true" ? StarSelect : StarNoSelect} alt="Star Select"/>
                                                                </td>
                                                                <td id={autoId()} className="text-center">{criteriaItem.criteriaName === "10分以内にロープレが完了している" ||criteriaItem.criteriaName === "15分以内にロープレが完了している"?"対象外":personEval(criteriaItem.humanEval)}</td>
                                                                <td id={autoId()} >{aiEval(criteriaItem.aiEval,criteriaItem.aiScoreFlag)}</td>
                                                                <td id={autoId()} className="text-left">
                                                                    {criteriaItem.keywords.map((keywordsItem, keywordIndex) => (<Chip id={autoId()} key={keywordIndex} label={keywordsItem} className={criteriaItem.matchedKeywords.includes(keywordsItem) ? classes.match_item : classes.not_match_item} />))}
                                                                </td>
                                                                <td id={autoId()}>
                                                                    {
                                                                        criteriaItem.keywords[0] === "-"?
                                                                        <p><u>{t('AI score result.details')}</u></p>
                                                                        :
                                                                        <p variant="outlined" color="primary" onClick={() => handleClickOpen(criteriaItem.keywords, criteriaItem.keywordsJSON, criteriaItem.matchedKeywords)} className={`${classes.ai_dialog_text}`}>{t('AI score result.details')}</p>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className=" align-items-center">
                                    <Col xl="12" lg="12" sm="12">
                                        <p className="font-14 font-weight-bold MeiryoRegular mb-3">{t('AI score result.utterance')} </p>
                                        <ChatLog 
                                            traineeChat={item.traineeChat} 
                                            trainerChat={item.trainerChat} 
                                            traineeName={item.traineeName} 
                                            trainerName={item.trainerName} 
                                            allmatchedKeywords={[...new Set(allmatchedKeywords)]}
                                            isBookMarked={item.isBookMarked}
                                            bookMarkedAt={item.bookMarkedAt}
                                        />
                                    </Col>
                                </Row>
                            </span>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
            <KeywordPopUp
                keywords={keywords}
                keywordsJSON={keywordsJSON}
                matchedKeywords={matchedKeywords}
                open={confirmOpen}
                setOpen={setConfirmOpen}
                handleClose={handleClose}
            ></KeywordPopUp>
        </div>
    )
}


export default History;