import React from 'react';
import { useTranslation } from 'react-i18next';
import GeneralButton from '../../components/Buttons/GeneralButton';
import { Row, Col } from 'reactstrap';
import styles from './styles.module.css';

const AllCriteria = ({ className, style, onEditScenerio, keywords, setKeywords }) => {

    const { t } = useTranslation();

    let lastId = 0;

    const autoId = (prefix = 'all-crireria-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    return (
        <div className="cmn-bg-box-inr p-32" id={autoId()}>
            <GeneralButton id={`edit-btn`} className="cmn-btn mb-4" title={t('general.button.edit')} onClick={onEditScenerio} />
            <Row>
                <Col lg="9">
                    <div className={`cmn-scroll-bar px-2`}>
                        {keywords && keywords.length > 0 && keywords[0].sections.map((value, index) => {
                            return (
                                <>
                                    {!value.deleteFlag && <h5 className={`font-14 mb-3`} key={index} id={`section_title_${index}`}> {value.sectionName} </h5>}
                                    {!value.deleteFlag && <div className="mr-md-4 mr-2" key={index + 'criteria'} id={`section_list_${index}`}>
                                        {value.criteria.map((val, key) => {
                                            return (
                                                <div className={`cmn-bg-box-inr ${styles.criteria_box} p-3 rounded-0 ${key === value.criteria.length - 1 ? 'mb-4' : 'mb-3'} ${!val.checkedFlag && styles.grey} `} key={index + '_' + key} id={`criteria_${index}_${key}`}>
                                                    <p className="text-align-center font-12 mb-0" id={`criteria_name_${index}_${key}`} key={val.id}> {val.name} </p>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>}
                                </>
                            )
                        })
                        }
                    </div>
                </Col>
            </Row>
        </div>
    )
};

export default AllCriteria;