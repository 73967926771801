import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import search_icon from '../../assets/images/search_icon.png'
import reset_icon from '../../assets/images/reset_icon.png'
import Sidebar from '../../components/Sidebar'
import { setRecentPath } from '../../store/actions';
import { isTrainerManagerOrTrainee } from '../../configs/constants';
import LogoutButton from '../../components/Buttons/LogoutButton';
import BackButton from '../../components/Buttons/BackButton';
import SearchTextbox from '../../components/Textboxes/SearchTextBox';
import GeneralButton from '../../components/Buttons/YesButton';
import SynonymsKeywordSection from './SynonymsKeywordSection';
import eventBus from '../../components/EventBus';
import {getSynonyms} from '../../api/api'
import { isApigwEnv } from '../../utils/General'

const SynonymByKeywordPage = ({access_token,isOpen,location, setRecentPath}) =>{
    const { t } = useTranslation();
    const history = useHistory();
    const [reset, makeAllReset] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [keywords, setKeywords] = useState([]);
    const [allKeywords, setAllKeywords] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState("");
    let lastId = 0;
    const autoId = (prefix = 'synomys-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);
    }, []);
    useEffect(() => {
        getSynonyms().then(res=>{
            setAllKeywords(res.data);
            setKeywords(res.data);
            if (searchKeyword.length > 0) {
                var data = res.data.filter((item)=> item.keywordName.includes(searchKeyword));
                setKeywords(data);
            }
        })

    }, [refresh]);
    const getSearchKeyword = (e) => {
        setSearchKeyword(e.target.value);
        e.target.value.length === 0 && setKeywords(allKeywords);

    }
    const resetSearch = () => {
        setSearchKeyword("");
        setKeywords(allKeywords);
    }
    const SearchByKeyword = () => {
        if (searchKeyword.length > 0) {
            var data = allKeywords.filter((item)=> item.keywordName.includes(searchKeyword));
            setKeywords(data);
        }else{
            eventBus.dispatch('show_snack', { message: 'キーワードを入力の上、検索ボタンをクリックしてください。', type: 'error' });
        }

    }
    return(
        <>
            <Sidebar/>
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr synomys" id="synomys">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="synonym_list_title">
                                類義語登録
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id={autoId()} reset={reset} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="cmn-bg-box ">
                                <Row>
                                    <Col xl="11" className="order-xl-0 order-1">
                                        <div className="cmn-bg-box-inr mb-4">
                                            <label className="font-weight-bold mb-2" id="lb_search_keyword">検索キーワード</label>
                                            <Row className="smallest-padding-box8">
                                                <Col  lg="8" md="9" sm="9">
                                                    <SearchTextbox onChange={getSearchKeyword} text={searchKeyword} icon={search_icon} placeholder="ここに入力してください" reset_icon={searchKeyword && searchKeyword.length > 0 && reset_icon} resetOnClick={resetSearch} id="inp_search_keyword"/>
                                                </Col>
                                                <Col lg="4" md="3" sm="3">
                                                    <GeneralButton id={autoId()} className="cmn-btn font-weight-normal h-100" title="検索" onClick={SearchByKeyword}/>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="cmn-bg-box-inr p-0">
                                            <SynonymsKeywordSection keywords={keywords} setKeywords={setKeywords} refresh={refresh} setRefresh={setRefresh}/>
                                        </div>
                                    </Col>
                                    <Col  xl="1" className={`back-btn-box  text-right`}>
                                        <BackButton id={autoId()} className="back-btn mb-3 w-100 font-14" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        agent_id: state.vAgent.agent_id,
        access_token: state.vAgent.access_token
    }
}
const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
    }
}
export default connect(stateToProps, dispatchToProps)(SynonymByKeywordPage);