import React, { useEffect,useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatTime } from '../../../utils/DateFormat';

const SectionOne = (props) => {
    let {totalTalk,utterTime,listenTime,utterRate,averageSpeed,trainerAverageSpeed,interruptTimes,silenceTimes,lowerConversationTimes} = props;
    const { t } = useTranslation();
    const [percentageTraineeAndTrainer, setpercentageTraineeAndTrainer] = useState(0)
    let lastId = 0;
    const autoId = (prefix = 'talklisten-section-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        let percentageCalculation =Math.ceil((averageSpeed-trainerAverageSpeed) * 100 / trainerAverageSpeed);
        setpercentageTraineeAndTrainer(percentageCalculation||0)
    }, [averageSpeed,trainerAverageSpeed])
    return(
        <div className="table-responsive">
            <table id={autoId()} className="table cmn-table AI-scroe-result-detail-table text-center">
                <thead>
                    <tr id={autoId()} >
                        {/* <th id={autoId()}>{t('AI score result.total_talk_time')}</th> */}
                        <th id={autoId()}>{t('AI score result.talk')}</th>
                        <th id={autoId()}>{t('AI score result.listen')}</th>
                        <th id={autoId()}>合計</th>
                        <th id={autoId()}>Talk/Listen率</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id={autoId()}>
                        {/* <td id={autoId()}>{formatTime(totalTalk)}</td> */}
                        <td id={autoId()}>{formatTime(utterTime)}</td>
                        <td id={autoId()}>{formatTime(listenTime)}</td>
                        <td id={autoId()}>{formatTime(totalTalk)}</td>
                        <td id={autoId()}>{utterRate ? `${utterRate}%` : '0%'}</td>
                    </tr>
                    <tr>
                        <th colSpan={4} id={autoId()}>発話速度</th>
                    </tr>
                    <tr>
                        <th colSpan={2} id={autoId()}>募集人役</th>
                        <th colSpan={2} id={autoId()}>お客様役</th>
                    </tr>
                    <tr>
                        <td colSpan={2} id={autoId()} width="50%"> {averageSpeed||'0.0'} 文字/秒 <br/>（お客様役より{isFinite(percentageTraineeAndTrainer) ?percentageTraineeAndTrainer:0}％速い） </td>
                        <td colSpan={2} id={autoId()}>{trainerAverageSpeed||'0.0'} 文字/秒</td>
                    </tr>
                    <tr>
                        <td colSpan={4} className="p-0">
                            <table className="table cmn-table text-center double-tb-inner">
                                <tr>
                                    <th width="33%" align="center">被り回数</th>
                                    <th width="33%" align="center">沈黙回数</th>
                                    <th width="33%" align="center">ラリー回数</th>
                                </tr>
                                <tr>
                                    <td width="33%" align="center">{interruptTimes ? `${interruptTimes}回` : '0回'}</td>
                                    <td width="33%" align="center">{silenceTimes ? `${silenceTimes}回` : '0回'}</td>
                                    <td width="33%" align="center">{lowerConversationTimes ? `${lowerConversationTimes}回` : '0回'}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default SectionOne;