const initialState = {
    transcriptGoodPoint: "",
    transcriptImprovePoint: "",
    transcriptCrossMatter: "",
    micStatus: false, // 用来标识，用户点击任意录音键后，录音结果浏览器缓存不再跟随请求刷新重置
    originalGoodPoint: "",
    originalImprovePoint: "",
    originalCrossMatter: "",
};

const sttResult = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case "updateMicStatus":
            return {
                ...state,
                micStatus: action.micStatus
            };
        case "resetMicStatus":
            return {
                ...state,
                micStatus: false
            };
        case "addTranscriptGoodPoint":
            return {
                ...state,
                innerTranscriptGP: state.innerTranscriptGP + action.transcriptGoodPoint
            };
        case "addTranscriptImprovePoint":
            return {
                ...state,
                innerTranscriptIP: state.innerTranscriptIP + action.transcriptImprovePoint
            };
        case "addTranscriptCrossMatter":
            return {
                ...state,
                innerTranscriptCM: state.innerTranscriptCM + action.transcriptCrossMatter
            };
        case "initTranscriptGoodPoint":
            return {
                ...state,
                innerTranscriptGP: action.transcriptGoodPoint ? action.transcriptGoodPoint : ""
            }
        case "initTranscriptImprovePoint":
            return {
                ...state,
                innerTranscriptIP: action.transcriptImprovePoint ? action.transcriptImprovePoint : ""
            }
        case "initTranscriptCrossMatter":
            return {
                ...state,
                innerTranscriptCM: action.transcriptCrossMatter ? action.transcriptCrossMatter : ""
            }
        case "resetSpeechToText":
            return initialState;

        default:
            return state;
    }
};

export default sttResult;
