import React, { createRef, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import Sidebar from './../../components/Sidebar';
import LogoutButton from './../../components/Buttons/LogoutButton';
import { LearningAndAchievementTable } from './../../components/Table/LearningThemeAndAchievementTable';
import CustomerCard from './../../components/Cards/CustomerCard';
import styles from './styles.module.css';
import { useHistory } from "react-router-dom";
import { setRecentPath } from '../../store/actions'
import ResponsiveDialog from './../../components/Popup/ImagePopup'
import CustomerProfileForTainer from '../../assets/images/customer_profile_for_trainer.png';
import CustomerProfileForTainee from '../../assets/images/customer_profile_for_trainee.png';
import CustomerListPdfForTrainer from '../../assets/pdf/trainer/顧客属性一覧.pdf';
import CustomerListPdfForTrainee from '../../assets/pdf/trainee/顧客属性一覧.pdf';
import BackButton from '../../components/Buttons/BackButton'
import { isApigwEnv } from '../../utils/General';
import { isTrainee } from '../../configs/constants';

const CustomerAttributesPage = ({ access_token, user, isOpen, location, setRecentPath, syncStatus }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [isClicked, setIsClicked] = useState('');

    let lastId = 0;

    const autoId = (prefix = 'customer-attributes-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        setRecentPath(location.pathname);
    }, [syncStatus]);

    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr" id="customer-attributes">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="customer_attributes">
                                {t('confirm customer attributes.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout_btn" />
                        </Col>
                    </Row>
                    <div className="cmn-bg-box">
                        <Row className="mb-2">
                            <Col xl="10" className="order-xl-0 order-1">
                                <h6 className="font-weight-bold mb-2 mb-xl-0" id="learning_themes_and_achievement_criteria"> {t('confirm customer attributes.table.title')}</h6>
                            </Col>
                            <Col xl="2" className={`back-btn-box text-right`}>
                                <BackButton id="go_back" className="back-btns" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                            </Col>
                        </Row>
                        <Row className="mb-32">
                            <Col xl="11">
                                <LearningAndAchievementTable TableDivClassName="" table_id="learning_and_achievement_table" />
                            </Col>
                        </Row>
                        <Row className="mb-4">
                            <label className={`font-weight-bold col-lg-2 col-md-3 col-sm-12 mb-0 pr-md-0 ${styles.header} ${styles.header_2} `} id="customer_attribute_selection">{t('confirm customer attributes.list of customer attributes.title')}<span className="text-align-right">:</span></label>
                            <Col lg="10" md="9" sm="12" className="pl-md-4">
                                <ResponsiveDialog url={isTrainee() ? CustomerProfileForTainee : CustomerProfileForTainer} text={t('roleplay.step 1.Customer Attributes Menu')} className={`font-weight-bold font-16 ${styles.active_text}`} download={isTrainee() ? CustomerListPdfForTrainee : CustomerListPdfForTrainer} id="customer_attribute_selection_popup" />
                            </Col>
                        </Row>
                        <Row>
                            <CustomerCard id={autoId()} className="" text={t('roleplay.step 1.display')} isClicked='customer' attributesFlag={true} setIsClicked={setIsClicked} />
                        </Row>
                    </div>
                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
        syncStatus: state.vAgent.syncStatus,
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(CustomerAttributesPage);