import React, { useState,useEffect } from 'react';
import styles from './styles.module.css'
import chat_icon from '../../../../assets/images/chat_icon.png'
import bot_person from '../../../../assets/images/bot_person.png'

import rolePlayDetail from '../../../../staticData/RolePlayDetail.json'
const ConversationLog = ({traineeChat,trainerChat,guestChat}) =>{
    const [traineeData, settraineeData] = useState([])
    const [trainerData, settrainerData] = useState([])
    const [guestData, setguestData] = useState([])
    useEffect(() => {
        setguestData(guestChat??[]);
        settraineeData(traineeChat??[]);
        settrainerData(trainerChat??[]);
    }, [guestChat,traineeChat,trainerChat])
    const traineeChatjsonToArray = traineeData.length>0 && JSON.parse(traineeData[0].chat).length > 0 ? JSON.parse(traineeData[0].chat) : null;
    const addTypeToTrainee = traineeChatjsonToArray?traineeChatjsonToArray.filter(item => item.text).map((item) => {
        var o = Object.assign({},item)
        o.type = "trainee"
        o.name = traineeData[0].userName
        return o
    }):[];
    const trainerChatjsonToArray = trainerData.length > 0&& JSON.parse(trainerData[0].chat).length > 0 ? JSON.parse(trainerData[0].chat) : null;
    const addTypeToTrainer = trainerChatjsonToArray?trainerChatjsonToArray.filter(item => item.text).map((item) => {
        var o = Object.assign({},item)
        o.type = "trainer"
        o.name = trainerData[0].userName
        return o
    }):[];
    let addTypeToGuest = [];
    for (let index = 0; index < guestData.length; index++) {
        const guestChatjsonToArray = JSON.parse(guestData[index].chat).length > 0 ? JSON.parse(guestData[index].chat) : null;
        addTypeToGuest =addTypeToGuest.concat(
            guestChatjsonToArray?guestChatjsonToArray.filter(item => item.text).map((item) => {
                var o = Object.assign({},item)
                o.type = "guest"
                o.name = guestData[index].userName
                return o
            }):[]
        ) 
    }
   
    const mergeChat = [...addTypeToTrainee, ...addTypeToTrainer, ...addTypeToGuest]
    const chatResults = mergeChat.sort((a, b) =>  a.time > b.time ? 1: -1)
    return(
        <div className={`${styles.main_div}`}>
            <div className={`${styles.title_div}`}>
                <img src={chat_icon} className={styles.chat_icon} alt="" />
                <span className="ml-3"><b>チャット履歴</b></span>
            </div>
            <div className={`${styles.body_div} ${styles.msger}`}>
                <div className={styles.msger_chat}>
                    {
                        chatResults.map((item,index) => {
                            return (
                                <div key={index}>
                                    {["trainer", "guest"].includes(item.type)?
                                        <div className={`${styles.msg} ${styles.left_msg} my-1`}>
                                            <div className={`${styles.msg_img} d-flex flex-wrap align-items-center`}>
                                                <img src={bot_person} className={`${styles.bot_person} d-block mx-auto`} alt=""></img>
                                            </div>

                                            <div className={styles.msg_bubble}>
                                                <div className={styles.msg_info}>
                                                <div className={styles.msg_info_name}>{item.name}</div>
                                                </div>

                                                <div className={styles.msg_text}>
                                                    {item.text}
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div className={`${styles.msg} ${styles.right_msg} my-1`}>
                                            <div className={styles.msg_bubble}>
                                                <div className={styles.msg_info}>
                                                    <div className={styles.msg_info_name_right}>{item.name}</div>
                                                </div>
                                                <div className={styles.msg_text}>
                                                    {item.text}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ConversationLog;