import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import styles from './styles.module.css';
import StyledCheckbox from '../../components/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import GeneralButton from '../../components/Buttons/GeneralButton';
import arrow_left_white from '../../assets/images/arrow-left-white.svg';
import arrow_right_white from '../../assets/images/arrow-right-white.svg';

const EditSelectionOfRecruiter = ({ allUsers, setAllUsers, editState, onMovedToInactive, onMovedToActive }) => {
    const { t } = useTranslation();
  
    const onSelectionOfUsers = (e) => {
        let newArray = allUsers && allUsers.map(user => {
            if (parseInt(user.userId) === parseInt(e.target.value)) {
                user.checked = e.target.checked
                // user.newlyAdded = !user.newlyAdded
            }
            return user
        })
        setAllUsers(newArray)
    }

    return (
        <Row>
            <Col className={` pr-lg-3 mb-3`} lg={6} sm={12} id="inactive_users_section">
                <div className={`${styles.selection_recruiter_box}`} id="inactive_users_list">
                    <div className={`${styles.selection_recruiter_header} font-weight-bold`} id="inactive_users">
                        {t('selection of recruiter.registration recruiter name')}
                    </div>
                    <div className={`${styles.selection_recruiter_body} cmn-scroll-bar`} id="inactive_users_content">
                        {allUsers && allUsers.filter(item => item.newlyAdded === false).length === 0 ?
                            <div id="no_data">対象ASコードの募集人利用データがありません。</div>
                            : allUsers && allUsers.filter(item => item.newlyAdded === false).map((user, index) => (
                                <div key={index}   id={`inactive_recruiter_${index}`}>
                                    {!editState ? user.userName
                                        :
                                        <FormControlLabel
                                            style={styles.root}
                                            control={<StyledCheckbox
                                                value={user.userId}
                                                id={`edit_inactive_recruiter_${index}`}
                                                onClick={onSelectionOfUsers}
                                                checked={user.checked}
                                            />}
                                            label={<span style={classes.label} id={`edit_inactive_recruiter_name_${index}`}>{user.userName}</span>}
                                        />}
                                </div>
                            ))}
                    </div>
                </div>
                {editState && <GeneralButton
                    id="move_to_active_users"
                    className={`cmn-btn font-weight-normal mt-4 ${allUsers && allUsers.filter(user => user.checked && (user.newlyAdded === false)).length === 0 && styles.gray}`}
                    title={<span className={`d-flex justify-content-center`} id="active_user_btn">{t('selection of recruiter.add to')}<img className={`ml-2`} alt="add to" src={arrow_left_white} id="active_user_icon" /></span>}
                    disabled={allUsers && allUsers.filter(user => user.checked && (user.newlyAdded === false)).length > 0 ? false : true}
                    onClick={onMovedToActive} />}
            </Col>

            <Col className={` pl-lg-3`} lg={6} md={12} id="active_users_section">
                <div className={`${styles.selection_recruiter_box}`} id="active_users_list">
                    <div className={`${styles.selection_recruiter_header} font-weight-bold`} id="active_users">
                        {t('selection of recruiter.persons to be trained')}
                    </div>
                    <div className={`${styles.selection_recruiter_body} cmn-scroll-bar`}  id="active_users_content">
                        {allUsers && allUsers.filter(item => item.newlyAdded === true).length === 0 ?
                            <div>対象ASコードの募集人利用データがありません。</div>
                            : allUsers && allUsers.filter(item => item.newlyAdded === true).map((user, index) => (
                                <div key={index} id={`active_recruiter_${index}`}>
                                    {!editState ? user.userName
                                        :
                                        <FormControlLabel
                                            style={styles.root}
                                            control={<StyledCheckbox
                                                value={user.userId}
                                                id={`edit_active_recruiter_${index}`}
                                                onClick={onSelectionOfUsers}
                                                checked={user.checked}
                                            />}
                                            label={<span style={classes.label}  id={`edit_active_recruiter_name_${index}`}>{user.userName}</span>}
                                        />}
                                </div>
                            ))}
                    </div>
                </div>

                {editState && <GeneralButton
                    id="exclusion_from_active_user"
                    className={`cmn-btn font-weight-normal mt-4 ${allUsers && allUsers.filter(user => user.checked && (user.newlyAdded === true)).length === 0 && styles.gray}`}
                    title={<span className={`d-flex justify-content-center`} id="inactive_user_btn"><img className={`mr-2`} alt="exclusion" src={arrow_right_white} id="inactive_user_icon" />{t('selection of recruiter.exclusion')}</span>}
                    disabled={allUsers && allUsers.filter(user => user.checked && (user.newlyAdded === true)).length > 0 ? false : true}
                    onClick={onMovedToInactive} />}
            </Col>
        </Row>
    )
}
const classes = {
    root: {
        marginLeft: 0,
        marginBottom: 0
    },
    label: {
        marginButtom: 0,
        marginLeft: 0,
        fontSize: '14px',
        paddingLeft: '16px',
        fontFamily: 'MeiryoRegular'
    }
};
export default EditSelectionOfRecruiter;