const COMPANY_CODES_URL = '/backend/agentCompanies/uniqueCodeAndNames';
const COMPANY_WITH_BRANCHES_URL = '/backend/agentCompanies/forFilter';
const LEARNING_THEMES_URL = '/backend/learningThemes';
const ALL_TRAINEE = '/backend/trainees';
const TRAINEE_LIST_BY_AGENT_COMPANY = '/backend/trainees/forListOfAgentCompanyCode';
const TRAINER_LIST_BY_AGENT_COMPANY = '/backend/trainers/forListOfAgentCompanyCode';
const LEARNING_THTMES_BY_MULTI_AGENT_COMPANIES = '/backend/learningThemes/getForListOfAgentCodes';
const LEARNING_THTME_PATTERNS_BY_MULTI_AGENT_COMPANIES = '/backend/learningThemes/patternsForMultiAgentCompanyCode';
const TRAINER_LIST_BY_MULTI_AGENT_COMPANIES = '/backend/trainees/forMultipleAgentCompanyCode';
const ACCESS_ANALYSIS_GET_ROLEPALY_COUNT = '/backend/accessAnalysis/getRoleplayCountForTheme';
const ACCESS_ANALYSIS_GET_ROLEPALY_TIME = '/backend/accessAnalysis/getRoleplayTimeForTheme';
const ACCESS_ANALYSIS_GET_TOTAL_USER_COUNT_FOR_THEME = '/backend/accessAnalysis/getTotalUserCountForTheme';
const ACCESS_ANALYSIS_GET_ACTIVE_USER_COUNT_FOR_THEME = '/backend/accessAnalysis/getActiveUserCountForTheme';
const ACCESS_ANALYSIS_GET_INACTIVE_USER_COUNT_FOR_REPORT = '/backend/accessAnalysis/getInactiveUserCountForReport';

//time period config
const MONTHLY = "monthly";
const QUARTELY = "quarterly";
const HALF_YEARLY = "halfyearly";
const ANNUALLY = "yearly";

export {
    COMPANY_CODES_URL,
    COMPANY_WITH_BRANCHES_URL,
    LEARNING_THEMES_URL,
    ALL_TRAINEE,
    TRAINEE_LIST_BY_AGENT_COMPANY,
    TRAINER_LIST_BY_AGENT_COMPANY,
    LEARNING_THTMES_BY_MULTI_AGENT_COMPANIES,
    LEARNING_THTME_PATTERNS_BY_MULTI_AGENT_COMPANIES,
    TRAINER_LIST_BY_MULTI_AGENT_COMPANIES,
    ACCESS_ANALYSIS_GET_ROLEPALY_COUNT,
    ACCESS_ANALYSIS_GET_ROLEPALY_TIME,
    MONTHLY,
    QUARTELY,
    HALF_YEARLY,
    ANNUALLY,
    ACCESS_ANALYSIS_GET_TOTAL_USER_COUNT_FOR_THEME,
    ACCESS_ANALYSIS_GET_ACTIVE_USER_COUNT_FOR_THEME,
    ACCESS_ANALYSIS_GET_INACTIVE_USER_COUNT_FOR_REPORT
}
