import React from 'react';
import GeneralButton from '../../components/Buttons/GeneralButton';
import ongoingStyle from './style.module.css';
import bookmarkImg from '../../assets/images/bookmark.svg'
import savedBookmarkImg from '../../assets/images/saved_bookmark.svg'
import EventBus from '../../components/EventBus'

const OngoingScenerio = ({ className, text, title, bookmark, setBookmark, bookmarkTime, setBookmarkTime }) => {
    const onHandleBookmark = () => {
        EventBus.dispatch('close_snack')
        if(bookmark) {
            EventBus.dispatch('show_snack', { message: "しおりが上書きされました。", type: 'success' }) 
        }else {
            setBookmark(true)
            EventBus.dispatch('show_snack', { message: "しおり登録できました。", type: 'success' }) 
        }
        const event = new Date();
        setBookmarkTime(event.toISOString());
    }
    return (
        <div className={`cmn-bg-box-inr p-3`} key={text} id={text}>
            <div className={ongoingStyle.box}>
                <p className="text-align-center font-12 mb-0" id="ongoing_scenario">{text}</p>
                <div className={`d-flex`}>
                    <GeneralButton title={title} className={`font-14 ${ongoingStyle.in_progress_button} ${className} mr-2`} id={"ongoing_scenario_btn"} />
                    <img src={bookmark ? savedBookmarkImg : bookmarkImg} alt="bookmark" onClick={onHandleBookmark} id={"ongoing_scenario_bookmark"} />
                </div>
            </div>
        </div>
    )
}

export default OngoingScenerio;