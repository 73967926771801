import { ResponsiveLine } from '@nivo/line';


const TalkListenChart = (props) => {
    const {data} = props;
    const gridData = [0,2,4,6,8,10,12,14,16,18,20,22,24]

    const findMaxValue = () => {
        const concactData = data.map(item => item.data.map(subItem => parseInt(subItem.x))).flat();
        return Math.max(...concactData);
    }
    const mValue = findMaxValue();

    const closest = gridData.reduce((a, b) => {
        return Math.abs(b - mValue) < Math.abs(a - mValue) ? b : a;
    });
    if (findMaxValue() < 24) {
        let nextIndex = gridData.indexOf(closest)+1;
        for (let index = nextIndex; index < gridData.length; index++) {
            data.length>0 && data[0].data.push({x:gridData[index]})
            data.length > 0 && data[1].data.push({x:gridData[index]})
        }
    }
    const gridXValues = () =>{
        const gridValuedata=[],maxValue = findMaxValue();
        for (let index = 0; index <= maxValue ; index++) {
            if (index % 2 === 0) {
                gridValuedata.push(index);
            }
        }
        return gridValuedata
        
    }
    return(
        <ResponsiveLine
        data={data}
        colors={d => d.color}
        margin={{
        top: 30,
        right : 12,
        bottom: 50,
        left: 60
        }}
        width = {1000}
        xScale={{ "type": "linear"}}
        yScale={{
        type: 'linear',
        stacked: false,
        min: -1.5,
        max: 1.5
        }}
        enableArea={true}
        enablePoints={false}
        minY="auto"
        maxY="auto"
        stacked={true}
        curve="linear"
        axisBottom={{
            orient: "bottom",
            tickSize: 5,
            tickPadding: 10,
            tickRotation: 0,
            tickValues : findMaxValue()/2,
            format: value => value +"分"
        }}
        axisLeft={null}
        dotSize={10}
        dotColor="inherit:darker(0.3)"
        dotBorderWidth={2}
        dotBorderColor="#ffffff"
        enableDotLabel={true}
        dotLabel="y"
        dotLabelYOffset={-12}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        gridXValues={gridXValues()}
        gridYValues={[1.5,1,0.5,0,-0.5,-1,-1.5]}
       
    />
    )
    };

export default TalkListenChart;
