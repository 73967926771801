import React, { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import Slide from '@material-ui/core/Slide';
import { useTranslation } from 'react-i18next';
import eventBus from '../EventBus';

const SnackBar = () => {
    const { t } = useTranslation();
    let key = "";
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useEffect(() => {
        eventBus.on("show_snack", (data) => {
            key = data.key;
            enqueueSnackbar(data.message, {
                variant: data.type,
                autoHideDuration: 5000,
                preventDuplicate: true,
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                TransitionComponent: Slide,
            });
        });

        eventBus.on("close_snack", () => {
            closeSnackbar();
        });

    }, []);

    return (
        <>
        </>
    );
}

export default SnackBar;