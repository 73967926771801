import { Bar } from '@nivo/bar'
import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import styles from './styles.module.css';

const indexBy = "date";
const TotalLabels = ({ bars, yScale }) => {   // returns a list of total value labels for stacked bars
  const labelMargin = 20;                    // space between top of stacked bars and total label

  return bars.map(({ data: { data, indexValue }, x, width }, i) => {
    const total = Object.keys(data)          // sum of all the bar values in a stacked bar
      .filter(key => key !== indexBy)        //f ilter out whatever your indexBy value is
      .reduce((a, key) => a + data[key], 0);

    return (
      <g
        transform={`translate(${x}, ${yScale(total) - labelMargin})`}
        key={`${indexValue}-${i}`}
      >
        <text
          className="MeiryoRegular"        // add any class to the label here
          x={width / 2}
          y={labelMargin / 2}
          textAnchor="middle"
          alignmentBaseline="central"
          style={{                         // add any style to the label here
            fill: "rgb(51, 51, 51)", fontSize: "8"
          }}
        >
          {total === 0 ?total.toLocaleString()+"%":""}
        </text>
      </g>
    );
  });
};

const DigitalizationChart = (props) => {
  const type = props.type;
  let data = props.data
  let  values = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90,100]
  const [tickValue, setTickValue] = useState(values)

  const formatLegendValue = (value) => {
    if (value.length > 8 && value.includes("月")) {
      let first = value.slice(0, value.indexOf("月") + 1)
      let second = value.slice(value.indexOf("月") + 1)
      let finalStr = [];
      finalStr.push(
        <tspan x={3} dy={0} key={1}>{first}</tspan>
      )
      finalStr.push(
        <tspan x={0} dy={15} key={2}>{second}</tspan>
      )
      return finalStr;
    } else {
      return value;
    }
  }

  const colors = props.colors;
  const labels = props.keys;
  const themes = props.data;

  return (
    <div className={` ${styles.scrollbar}`} id='styled_scrollbar' >
      <div>
      <Bar
        {...props}
        margin={{ top: 50, right: 15, bottom: 50, left: 50 }}
        layers={["grid", "axes", "bars", TotalLabels, "markers", "legends"]}
        indexBy="date"
        padding={0.6}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        minValue={0}
        maxValue={tickValue && tickValue[tickValue.length - 1]}
        axisTop={null}
        axisRight={null}
        enableLabel={true}
        label={d => d.value > 0 && `${d.value}%`}
        axisBottom={{
          tickSize: 5,
          tickPadding: 0,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: 32,
          format: (v) => {
            return themes.length > 8 ? (
              formatLegendValue(v)
            ) : (v);
          }
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 10,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: -40,
          tickValues: tickValue,
          format: (val) => val.toLocaleString() + type

        }}
        theme={{
          axis: {
            ticks: {
              line: {
                stroke: "rgba(0, 165, 217, 0.2)"
              },
              text: {
                fontSize: 8,
                fontFamily: 'MeiryoRegular',
                  fontWeight: 'bolder'
              },
              minWidth: 50
            }
          },
          grid: {
            line: {
              stroke: "rgba(0, 165, 217, 0.2)",
            }
          }
        }}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
       <div class={`${styles.list} d-flex justify-content-center flex-wrap`}>
        {labels && colors && colors.map((color, i) => {
          return (<div class="d-flex mx-3"><div className={`${styles.square} mr-1`} style={{ "background": color }}></div>{labels[i]}</div>)
        }
        )}
      </div>
      </div>
    </div>
  )

}

export default DigitalizationChart;
