import React from 'react';


import styles from './styles.module.css';

const URLTextbox = ({ placeholder, className, style, onKeyPress, onChange, id, icon, disabled, onPaste, value }) => {
    return (
        <div className={`${styles.general_textbox}  ${styles.right_inner_addon}  ${className}`} style={style} >
            {disabled
                ? <input id={id} className={`${styles.input}`} placeholder={placeholder} onKeyPress={onKeyPress} onChange={onChange} value={value} disabled />
                : <input id={id} className={`${styles.input}`} placeholder={placeholder} onKeyPress={onKeyPress} onChange={onChange} value={value} />}
            {icon && <img src={icon} alt={icon} onClick={onPaste} />}
        </div>
    )
}

export default URLTextbox;