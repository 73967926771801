import React, { useState, useEffect,useRef } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import Sidebar from '../../components/Sidebar';
import { setRecentPath } from '../../store/actions';
import LogoutButton from '../../components/Buttons/LogoutButton';
import BackButton from '../../components/Buttons/BackButton';
import {GeneralDropdown} from '../../components/Dropdowns/GeneralDropdown';
import {MultiselectDropdown} from '../../components/Dropdowns/MultiSelectDropdown';
import {TimePeriodDropdown} from '../../components/Dropdowns/TimePeriodDropdown';
import DigitalizationChart from '../../components/Charts/DigitalizationChart';
import CSVExport from './csvExport'
import filterData from '../../staticData/TimePeriod.json';
import groupedLearningTheme from '../../staticData/groupedLearningTheme.json';
import {isTrainerManagerOrTrainee} from '../../configs/constants'
import {COMPANY_CODES_URL,LEARNING_THTMES_BY_MULTI_AGENT_COMPANIES,MONTHLY,QUARTELY,HALF_YEARLY,ANNUALLY} from '../../configs/urlConstants';
import {getLearningThemeOrTraineeForMultiAgentCode,getUserList,getDigitalizationData,getGroupedLearningThemeLists} from '../../api/api';
import styles from './styles.module.css'
import { checkAverageLearningTheme,getDateRangeData,getLearningThemeBasedOnASCode } from '../../utils/mapState';
import { groupBy,isApigwEnv, getDurationTime, millisToHours, getLast12MonthsInterval, getLast13MonthsWithYear } from '../../utils/General';
const Digitalization = ({ access_token, isOpen, location,setRecentPath }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const timePeriod =[{'id':1,'name':"月別"},{'id':2,'name':"四半期"},{'id':3,'name':"半期"},{'id':4,'name':"年度"}];
    const quarterlyLabel = ["1-3月（","4-6月 (","7-9月 (","10-12月 ("]
    const quarterlyLabel2 = [" 1Ｑ)"," 2Ｑ)"," 3Ｑ)"," 4Ｑ)"]
    const halfYearlyLabel = ["1-6月（","7-12月（"]
    const halfYearlyLabel2 = ["上期）","下期)"]
    const [reset, makeAllReset] = useState(false);
    const [selectedTime,setSelectedTime] =useState(timePeriod[0]);
    const [selectedTimePeriod,setSelectedTimePeriod] =useState(timePeriod[0].id);
    const [periodStatus,setPeriodStatus] =useState(MONTHLY);
    const [selectedLearningTheme,setSelectedLearningTheme] =useState([]);
    const [learningTheme,setLearningTheme] =useState([]);
    const [allLearningTheme,setAllLearningTheme] =useState([]);
    const [filterTheme,setfilterTheme] =useState([]);
    const [asCodeData, setAsCodeData] = useState([]);
    const [multiselectedASCode, setMultiSelectedASCode] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState();
    const [timePeriodData, setTimePeriodData] = useState(filterData.byMonth);
    let currentDate = new Date();
    let date = getLast13MonthsWithYear(11);
    const [to, setTo] = useState({ "year": date[0].year, "month": date[0].month });
    const [from, setFrom] = useState({ "year": date[date.length - 1].year, "month": date[date.length - 1].month });
    const ref = useRef(null);
    const [props, setProps] = useState();
    const [width, setWidth] = useState(0);
    const [digitalizationData, setDigitalizationData] = useState([]);
    const [timeData, setTimeData] = useState([]);
    const [padding, setPadding] = useState(0);
    let lastId = 0;
    const autoId = (prefix = 'digit-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);
        getUserList(COMPANY_CODES_URL).then(res => {
            setAsCodeData(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            setMultiSelectedASCode(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)));
        })
        getGroupedLearningThemeLists().then(res => {
            setAllLearningTheme(res.data);
            // setAllLearningTheme(groupedLearningTheme);
        })
    }, []);

    useEffect(() => {
        if (multiselectedASCode.length > 0 && allLearningTheme.length>0) {
            var filterByASCode  = allLearningTheme.filter(function(array_el){
                return multiselectedASCode.filter(function(anotherOne_el){
                    return anotherOne_el.id == array_el.agentCode;
                }).length != 0
                });
            let result = getLearningThemeBasedOnASCode(filterByASCode);
            let data = [];
            result.length > 0 && result.map(item => {
                data.push({id : item.themeId,name : item.themeName})
            })
            let latestResult = checkAverageLearningTheme(data,filterByASCode);
            setLearningTheme(latestResult);
        }
    }, [multiselectedASCode,allLearningTheme])
    useEffect(() => {
        getData()
    }, [from,to,filterTheme])
    const getData  =()=>{
        const filterLearningTheme = filterTheme.reduce((a, o) => ( a.push(o.id), a), [])
        const filteredASCode = multiselectedASCode.reduce((a, o) => ( a.push(o.id), a), [])
        const fromTime = getDurationTime(selectedTimePeriod, from, 'from')
        const toTime = getDurationTime(selectedTimePeriod, to, 'to')
        let params = {
            groupByKey : periodStatus,
            from : fromTime,
            to : toTime,
            learningThemeId : filterLearningTheme,
            agentCodeList : filteredASCode
        };
        if(params.groupByKey && params.from && params.to && params.learningThemeId.length > 0 && params.agentCodeList.length > 0){
            getDigitalizationData(params).then( res => {
                if (res.status) {
                    let formatData = [],timeFormatData = [];
                    const groupedByAa = groupBy(res.data, 'aa_group_by');
                    // const groupedByAa = groupBy(DigitalizationBackendSample, 'aa_group_by');
                    groupedByAa.map(item => {
                        let paramObj = {};
                        if (params.groupByKey === MONTHLY) {
                            Object.assign(paramObj,{'date':item.length>0 ?item[0].aa_year+'年'+item[0].aa_group_by+'月':''});
                        }else if(params.groupByKey === QUARTELY){
                            Object.assign(paramObj,{'date':item.length>0 ?quarterlyLabel[item[0].aa_group_by-1]+item[0].aa_year+quarterlyLabel2[item[0].aa_group_by-1]:''});
                        }else if(params.groupByKey === HALF_YEARLY){
                            Object.assign(paramObj,{'date':item.length>0 ?halfYearlyLabel[item[0].aa_group_by-1]+item[0].aa_year+halfYearlyLabel2[item[0].aa_group_by-1]:''});
                        }else{
                            Object.assign(paramObj,{'date':item.length>0 ?item[0].aa_year+'年':''});
                        }
                        Object.assign(paramObj,{'AI':0});
                        item.map(subItem => {
                            subItem.role_name === "Aflac Member"?
                            Object.assign(paramObj,{'AF社員':millisToHours(subItem.total_roleplay_time) })
                            :Object.assign(paramObj,{'AS管理者':millisToHours(subItem.total_roleplay_time)})
                        })
                        let timeObj = {"date":paramObj.date,"AI" : 0,'AS管理者':paramObj.AS管理者,'AF社員':paramObj.AF社員};
                        timeFormatData.push(timeObj);
                        let total=0,
                        AI = paramObj.AI,
                        aflacMember = paramObj.AF社員,
                        trainerManager = paramObj.AS管理者;
                        total = AI + aflacMember + trainerManager;
                        let divideTotalPercent = 100/total;
                        AI = AI * divideTotalPercent;
                        aflacMember = Math.round(aflacMember * divideTotalPercent);
                        trainerManager = Math.round(trainerManager * divideTotalPercent);
                        Object.assign(paramObj,{'AI':AI || 0});
                        Object.assign(paramObj,{'AF社員':aflacMember || 0});
                        Object.assign(paramObj,{'AS管理者':trainerManager || 0});
                        formatData.push(paramObj)
                    })
                    let dateRangeData  = getDateRangeData(params.groupByKey,from,to,formatData);
                    let timeRangeData  = getDateRangeData(params.groupByKey,from,to,timeFormatData);
                    setDigitalizationData(dateRangeData)
                    setTimeData(timeRangeData)
                }
                
            })
        }
    }
    useEffect(() => {
        if (digitalizationData && digitalizationData.length > 0) {
            let dataLength = digitalizationData.length
            let value = periodStatus === 'yearly' ? 2 : 1;
            switch (value) {
                case 1:
                    let widthMonthly = 0
                    for (let i = 0; i < dataLength; i++) {
                        if (i >= 12) {
                            if (dataLength < 15) {
                                widthMonthly += 100;
                            }
                            else  {
                                widthMonthly += 57;
                            }
                        }
                        else {
                            widthMonthly = 750;
                        }
                    }
                    widthMonthly > 0 && setPadding(0.7);
                    widthMonthly > 0 && setWidth(widthMonthly < 750 ? 750 : widthMonthly)
                    break;
                case 2:
                    setPadding(0.6);
                    setWidth(750)
                    break;

                default:
                    break;
            }
        }
        setProps({
            data:digitalizationData,
            keys: ['AI', 'AS管理者', 'AF社員'],
            height: 600,
            colors: ['#4876D2', '#E98300', '#FDBC15'],
            width: (ref.current.offsetWidth !== null && ref.current.offsetWidth >= width) ? ref.current.offsetWidth : width,
            padding: padding,
            type: "%",
            layout: "vertical"
        })

    }, [ref.current, isOpen,digitalizationData,width,periodStatus,padding])

    useEffect(() => {
        learningTheme.length > 0 && setSelectedLearningTheme(learningTheme[0]);
        learningTheme.length > 0 && setfilterTheme([learningTheme[0]]);
    }, [learningTheme])
   
    const handleTimePeriod = (period,textContent) => {
        setSelectedTime({'id' :period,'name':textContent});
        setSelectedTimePeriod(period);
        let defaultDuration = getLast12MonthsInterval(period); 
        switch (period) {
            case 1:
                setTimePeriodData(filterData.byMonth)
                setPeriodStatus(MONTHLY)
                setFrom({ "year": date[date.length - 1].year, "month": date[date.length - 1].month });
                setTo({ "year": date[0].year, "month": date[0].month });
                break;
            case 2:
                setTimePeriodData(filterData.byQuarter)
                setPeriodStatus(QUARTELY)
                setFrom({ "year": defaultDuration[1].year, "month": defaultDuration[1].month });
                setTo({ "year": defaultDuration[0].year, "month": defaultDuration[0].month });
                break;
            case 3:
                setTimePeriodData(filterData.bySemiAnnual)
                setPeriodStatus(HALF_YEARLY)
                setFrom({ "year": defaultDuration[1].year, "month": defaultDuration[1].month });
                setTo({ "year": defaultDuration[0].year, "month": defaultDuration[0].month });
                break;
            case 4:
                setTimePeriodData([])
                setPeriodStatus(ANNUALLY)
                setFrom({ "year": currentDate.getFullYear() });
                setTo({ "year": currentDate.getFullYear() });
                break;
        
            default:
                setTimePeriodData(filterData.byMonth)
                setPeriodStatus(MONTHLY)
                setFrom({"year":2021,"month":1});
                setTo({"year":2021,"month":12});
                break;
        }
    }
    const handleTheme = (themeId,textContent) => {
        setSelectedLearningTheme({id :themeId,name :textContent})
        if (themeId === 0) {
            setfilterTheme(learningTheme.slice(0,3));
        }else{
            setfilterTheme(learningTheme.filter(item=>item.id == themeId));
        }
    }
    return(
        <>
             <Sidebar />
             <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr digitalization" id="digitalization">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">
                            デジタル化
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id={autoId()}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="cmn-bg-box ">
                                <Row>
                                    <Col xl="11" className="order-xl-0 order-1">
                                        <Row>
                                            <Col lg="3" xl="3">
                                                <div className="cmn-bg-box-inr">
                                                    <label className="mb-2 font-weight-bold">表示形式​</label>
                                                    <GeneralDropdown
                                                        isStatic={true}
                                                        staticData={timePeriod}
                                                        onSelect={(el) => handleTimePeriod(el.value,el.textContent)}
                                                        selectedData={selectedTime && selectedTime.name}
                                                        placeholder="選択してください"
                                                        dropdown_id="time_period_dropdown" />
                                                </div>
                                            </Col>
                                            <Col lg="3" xl="3">
                                                <div className="cmn-bg-box-inr">
                                                    <label className="mb-2 font-weight-bold">抽出期間</label>
                                                        <TimePeriodDropdown 
                                                            data={timePeriodData}
                                                            selectedTimePeriod={selectedTimePeriod}
                                                            from={from}
                                                            to={to}
                                                            setFrom={setFrom}
                                                            setTo={setTo}
                                                            dropdown_id="time_picker_dropdown"
                                                        />
                                                </div>
                                            </Col>
                                            <Col lg="3" xl="3">
                                                <div className="cmn-bg-box-inr">
                                                    <label className="mb-2 font-weight-bold">AS コード</label>
                                                        <MultiselectDropdown
                                                            selectAll={true}
                                                            isStatic={true}
                                                            staticData={asCodeData}
                                                            selectAllText="全AS"
                                                            placeholder="全AS"
                                                            dropdown_id="digit_as_code_dropdown"
                                                            multiSelectedData={multiselectedASCode} setMultiSelectedData={setMultiSelectedASCode}
                                                            activeDropdown={activeDropdown} setActiveDropdown={setActiveDropdown} />
                                                </div>
                                            </Col>
                                            <Col lg="3" xl="3">
                                                <div className="cmn-bg-box-inr">
                                                    <label className="mb-2 font-weight-bold">学習テーマ</label>
                                                    <GeneralDropdown
                                                        isStatic={true}
                                                        staticData={learningTheme}
                                                        onSelect={(el) => handleTheme(el.value,el.textContent)}
                                                        selectedData={selectedLearningTheme && selectedLearningTheme.name}
                                                        placeholder="選択してください"
                                                        dropdown_id="learning_theme_dropdown" />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col  xl="1" className={`back-btn-box  text-right`}>
                                    <BackButton id={autoId()} className="back-btn mb-3 w-100 font-14" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>
                                <Row className={`py-4`}>
                                    <Col lg="12" >
                                        <div className="cmn-bg-box-inr">
                                            <label className="mb-2 font-weight-bold">{selectedLearningTheme.name} </label>
                                            <div className={`${styles.bar_height}`} ref={ref}>
                                                {props && <DigitalizationChart {...props} />}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <CSVExport 
                                    data={digitalizationData} 
                                    timeData={timeData}
                                    selectedLearningTheme={selectedLearningTheme} 
                                    selectedTime={selectedTime}
                                    selectedTimePeriod={selectedTimePeriod}
                                    from={from}
                                    to={to}
                                    timePeriodData={timePeriodData}
                                    multiselectedASCode={multiselectedASCode}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
             </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        }
    }
}

export default connect(stateToProps, dispatchToProps)(Digitalization);
