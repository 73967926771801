import React from 'react';
import { Row, Col } from 'reactstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeywordCard from '../../components/Cards/KeywordCard';
import EditKeyword from './EditKeyword';

const ControlledAccordions = ({ editState, keywords, setKeywords, expanded, setExpanded, status }) => {

  let lastId = 0;
  const autoId = (prefix) => {
    lastId++;
    prefix = prefix ? prefix : 'edit-keywords-'
    return `${prefix}${lastId}`;
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const text = ['10分以内にロープレが完了している', '15分以内にロープレが完了している'];
  // console.log('keyword ', keywords);
  return (
    <Row>
      <Col lg={9}>
        <div className={`cmn-scroll-bar scroll-bar-height-600 px-2`}>
          {keywords && keywords[0].sections.map((items, index) => {
            return (
              <div className={`edit-keywords-accordian mr-md-4 mr-2`} key={index} id={autoId('edit-keywords-')}>
                <h5 className="mb-3 font-14" id={`edit_keywords_title_${index}`} name={`edit_keywords_title_${index}`} key={index}>{items.name}</h5>
                {items.criteria.map((item, i) => {
                  return (
                    <>
                      {((item.keywords.length === 1 && item.keywords[0].name === '' && editState === false) || text.includes(item.name)) ?
                        <Accordion expanded={false} key={`${i}_${index}`} className={` mb-3`} onChange={handleChange('panel' + items.id + i)}>
                          <AccordionSummary
                            className="keywords-accor-summary-inr"
                            aria-controls="panel2bh-content"
                            id={`${autoId()} panel2bh-header`}
                          >
                            <Typography><label className={`m-0 MeiryoRegular font-14`} id={autoId('edit-keywords-')} key={`${i}_${index}`}>{item.name}</label></Typography>
                          </AccordionSummary>
                        </Accordion>
                        :
                        <Accordion expanded={expanded === 'panel' + items.id + i} key={`${i}_${index}`} className={` mb-3`} onChange={handleChange('panel' + items.id + i)}>
                          <AccordionSummary
                            className="keywords-accor-summary-inr"
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id={`${autoId()} panel2bh-header`}
                          >
                            <Typography><label className={`m-0 MeiryoRegular font-14`} id={autoId('edit-keywords-')}>{item.name}</label></Typography>
                          </AccordionSummary>

                          <AccordionDetails className="keywords-accor-summary-detail">
                            {!editState ?
                              item.keywords.map((keyword, key) => {
                                return (<>{!keyword.newAdd && <KeywordCard title={keyword.name} key={keywords.id} value={item.id} id={autoId('criteria-')} button_id={autoId('button-')} />}</>)
                              })
                              : <EditKeyword
                                items={item.keywords}
                                keywords={keywords}
                                setKeywords={setKeywords}
                                keyword_type={item.aiScoringFlag}
                                button={true}
                                section_id={items.id}
                                criteria_id={item.id}
                                key={keywords.id}
                                id={autoId('criteria-')}
                                button_id={autoId('button-')} />
                            }

                          </AccordionDetails>
                        </Accordion>
                      }
                    </>
                  );
                })}
              </div>
            );
          })}
        </div>
      </Col>
    </Row>

  );
}

export default ControlledAccordions;