import React, { useState, useEffect } from 'react';
import { Progress, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar';
import PieChart from './PieChart'
import History from './History';
import FeedbackSection from './Feedback/FeedbackSection'
import LogoutButton from '../../components/Buttons/LogoutButton'
import BackButton from "../../components/Buttons/BackButton";
import recordingicon from '../../assets/images/recordingicon.png'
import { getAiScoreResultList, getRolePlayDetail,getListenTalkChart } from '../../api/api'
import TalkListenSampleData from '../../staticData/TalkListen.json'
import { formatTime } from '../../utils/DateFormat'
import styles from './styles.module.css';
import { getASCodeCompany,isApigwEnv,changeMinutesFormat } from '../../utils/General';
import store from "../../store";
import SectionOne from './TalkListenSection/SectionOne';
import SectionTwo from './TalkListenSection/SectionTwo';
import TalkListenChart from './TalkListenSection/TalkListenChart';

const ResultDetail = (props) => {
    const { isOpen, agent_id, role, access_token, company, recruiter, recruiter_id } = props.vAgent
    const { rolePlayId, themeId, scenarioId } = props.startRolePlay
    const { recordingStatus } = props.startRolePlay
    const { totalPercent, totalPassCount, totalCount, totalDurTime, totalTalk, utterTime, listenTime, utterRate, averageSpeed,trainerAverageSpeed, evaluation, goodPoint, improvePoint, crossMatter, feedBack, interruptTimes, silenceTimes, lowerConversationTimes,guestFeedBackChat,traineeFeedBackChat,trainerFeedBackChat } = props.aiResultScore
    const { SET_AI_SCORE_RESULT, SET_FEEDBACK_GOOD_POINT, SET_FEEDBACK_IMPROVE_POINT, SET_FEEDBACK_CROSS_MATTER, SET_FEEDBACK_INIT, RESET_FEEDBACK_MICSTATUS, SET_FEEDBACK_ORIGINAL_GOOD_POINT, SET_FEEDBACK_ORIGINAL_IMPROVE_POINT, SET_FEEDBACK_ORIGINAL_CROSS_MATTER } = props
    const { t } = useTranslation();
    const history = useHistory();
    let lastId = 0;
    const refreshTime = 5000;
    const [reloadTimes, setReloadTimes] = React.useState(0);
    const [aiScoreResult, setAiScoreResult] = useState([]);
    const [locationKeys, setLocationKeys] = useState([])
    let [mountedStatus, setMountedStatus] = useState(true);
    let [talkListen, setTalkListen] = useState([]);

    useEffect(()=>{
        RESET_FEEDBACK_MICSTATUS();
    },[])

    useEffect(() => {
        window.scrollTo(0, 0)
        const getData = async () => {
            getRolePlayDetail(rolePlayId).then(res => {
                let rolePlayParam = {
                    totalTalk: res.data.totalTalk,
                    utterTime: res.data.utterTime,
                    utterRate: res.data.utterRate ? parseInt(res.data.utterRate * 100) : 0,
                    listenTime: res.data.listenTime,
                    evaluation: res.data.evaluation,
                    goodPoint: res.data.goodPoint,
                    improvePoint: res.data.improvePoint,
                    crossMatter: res.data.crossMatter,
                    feedBack: res.data.feedBack,
                    traineeFeedBackChat: res.data.traineeFeedBackChat,
                    trainerFeedBackChat: res.data.trainerFeedBackChat,
                    guestFeedBackChat: res.data.guestFeedBackChat,
                    interruptTimes: res.data.interruptTimes,
                    silenceTimes: res.data.silenceTimes,
                    lowerConversationTimes: res.data.lowerConversationTimes
                }
                SET_AI_SCORE_RESULT(rolePlayParam)
                
                const isRecordFeedBack = res.data.isRecordFeedBack
                const feedBackStatus = res.data.feedBackStatus

                const ifFeedBackRefresh = isRecordFeedBack ? feedBackStatus : true
                if(!store.getState().sttResult.micStatus){
                    SET_FEEDBACK_GOOD_POINT(res.data.goodPoint);
                    SET_FEEDBACK_IMPROVE_POINT(res.data.improvePoint);
                    SET_FEEDBACK_CROSS_MATTER(res.data.crossMatter);
                    SET_FEEDBACK_INIT();
                }

                getAiScoreResultList(rolePlayId, themeId, scenarioId).then(res => {
                    if (res.data && res.data.length > 0) {
                        setAiScoreResult(res.data)
                        let filterCheckItem = res.data.filter((item) => item.isChecked === 1 && item.sectionStatus !== 0);
                        let status = filterCheckItem.every((item) => item.sectionStatus === 3);
                        setMountedStatus(status && ifFeedBackRefresh)
                        let param = {
                            aiScoreResultList: res.data,
                            totalPercent: 0,
                            totalPassCount: 0,
                            totalCount: 0,
                            totalDurTime: 0,
                            averageSpeed: 0,
                            trainerAverageSpeed: 0,
                        }
                        let chatList = []
                        let trainerChatList = []
                        res.data.forEach(item => {
                            if (item.traineeChat) {
                                chatList = chatList.concat(JSON.parse(item.traineeChat))
                            }
                            if (item.trainerChat) {
                                trainerChatList = trainerChatList.concat(JSON.parse(item.trainerChat))
                            }
                            param.totalPassCount += item.passCount
                            param.totalCount += item.totalCount
                            param.totalDurTime += item.durTime
                        })
                        let chatStr = ''
                        let trainerChatStr = ''
                        if (chatList.length > 0) {
                            chatList.map(item => item.text).forEach(item => {
                                chatStr += item
                            })
                        }
                        if (trainerChatList.length > 0) {
                            trainerChatList.map(item => item.text).forEach(item => {
                                trainerChatStr += item
                            })
                        }
                        if (rolePlayParam.utterTime) {
                            param.averageSpeed = (chatStr.length / (rolePlayParam.utterTime / 1000 / 1000 / 1000)).toFixed(1)
                        }
                        if (rolePlayParam.listenTime) {
                            param.trainerAverageSpeed = (trainerChatStr.length / (rolePlayParam.listenTime / 1000 / 1000 / 1000)).toFixed(1)
                        }
                        param.totalPercent = Math.round(param.totalPassCount / param.totalCount * 100)
                        SET_AI_SCORE_RESULT(param)
                    }
                })
            })
            getListenTalkChart(rolePlayId).then(res => {
                let formattedData =[];
                const getFormatListenData = () => {
                    let finalizeArray = [];
                    let startMinute = 0;
                    finalizeArray.push({x:0,y:-0.2})
                    res.data.listen.map(item=>{
                        let minutesFormatArr = changeMinutesFormat(startMinute,item.interval,item.data.length);
                        item.data.map((subItem,index)=>{
                            finalizeArray.push({x:minutesFormatArr[index],y:-(subItem+0.2)})
                        })
                        startMinute +=2;
                    })
                    return finalizeArray;
                }
                const getFormatTalkData = () => {
                    let finalizeArray = [];
                    let startMinute = 0;
                    finalizeArray.push({x:0,y:0.2})
                    res.data.talk.map(item=>{
                        let minutesFormatArr = changeMinutesFormat(startMinute,item.interval,item.data.length);
                        item.data.map((subItem,index)=>{
                            finalizeArray.push({x:minutesFormatArr[index],y:(subItem+0.2)})
                        })
                        startMinute +=2;
                    })
                    return finalizeArray;
                }
                formattedData.push({
                    "id": "Listen",
                    "color": "#D77900",
                    "data": getFormatListenData()
                })
                formattedData.push({
                    "id": "Talk",
                    "color": "#00A5D9",
                    "data": getFormatTalkData()
                })
                setTalkListen(formattedData)
            })
        };
        if (mountedStatus === true) {
            getData();
        } else {
            const interval = setInterval(() => {
                getData();
            }, refreshTime);

            return () => clearInterval(interval);
        }
    }, [mountedStatus])
    const autoId = (prefix = 'ai_result-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
    }, []);
    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)

                } else {
                    setLocationKeys((keys) => [location.key, ...keys])

                    history.push('/RecruiterSummaryDetail')

                }
            }
        })
    }, [locationKeys,])
    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr" id="AI-scoring-result">
                    <Row>
                        <Col lg="12" className={`${styles.ai_header_section} row-fluid`}>
                            <h3 className="text-left d-inline align-middle">
                                {t('AI score result.title')}
                            </h3>
                            {
                                recordingStatus !== 0 ?
                                    <span className={`d-inline-block ml-3 ${styles.recording_state}`}><img className={styles.recording_icon} src={recordingicon}></img>記録中</span>
                                    : ""
                            }
                            <LogoutButton title={t('general.button.logout')} className={'font-14 float-right'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12">
                            <div className="cmn-bg-box">
                                <p className="mb-4 font-weight-bold">アソシエイツ名：{company && getASCodeCompany(company[0].name)[1]} <span className="mx-3 d-md-inline d-none">|</span>
募集人名：{recruiter}</p>
                                <Row>
                                    <Col xl="10" className="order-xl-0 order-1">
                                        <div className="cmn-bg-box-inr mb-32">
                                            <p className="mb-4 font-weight-bold font-16">{t('AI score result.score(overall)')}</p>
                                            <table id={autoId()} className="table table-bordered AI-scroe-result-table cmn-table mb-32">
                                                <thead>
                                                    <tr id={autoId()}>
                                                        <th id={autoId()} colSpan="2">{t('AI score result.achievement')}</th>
                                                        <th className="text-center" id={autoId()}>{t('AI score result.total hours')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr id={autoId()}>
                                                        <td id={autoId()} className="w-50 align-middle">
                                                            <Progress value={totalPercent} className="no-border-radius styles" />
                                                        </td>
                                                        <td id={autoId()} className="text-center align-middle">{totalPassCount}/{totalCount}({totalPercent}%)</td>
                                                        <td id={autoId()} className="text-center align-middle">{formatTime(totalDurTime)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <History aiScoreResult={aiScoreResult} role_id={role.id} totalDurTime={totalDurTime}/>
                                        </div>
                                        <Row className="large-padding-box">
                                            <Col lg="12" md="12" className=" mb-32">
                                                <div className="cmn-bg-box-inr">
                                                    <p id={autoId()} className="font-14 font-weight-bold mb-3">Talk/Listen率</p>
                                                    <div className={`mb-3 position-relative cmn-scroll-bar cmn-scroll-bar-blue ${styles.talklistchart_box}`}>
                                                        <span className={`position-absolute ${styles.talk_txt}`}>Talk</span>
                                                        <span className={`position-absolute ${styles.listen_txt}`}>Listen</span>
                                                        <TalkListenChart data={talkListen}/>
                                                    </div>
                                                    <Row  className="smallest-padding-box8">
                                                        <Col lg="6" className="mb-3 mb-lg-0">
                                                            <SectionOne 
                                                                totalTalk={totalTalk} 
                                                                utterTime={utterTime} 
                                                                listenTime={listenTime} 
                                                                utterRate={utterRate} 
                                                                averageSpeed={averageSpeed}
                                                                trainerAverageSpeed={trainerAverageSpeed}
                                                                interruptTimes={interruptTimes} 
                                                                silenceTimes={silenceTimes} 
                                                                lowerConversationTimes={lowerConversationTimes}
                                                            />
                                                        </Col>
                                                        <Col lg="6">
                                                            <SectionTwo rolePlayId={rolePlayId}/>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                        <FeedbackSection 
                                            goodPoint={goodPoint} 
                                            improvePoint={improvePoint} 
                                            crossMatter={crossMatter} 
                                            role={role} 
                                            recordingStatus={recordingStatus} 
                                            feedBack={feedBack} reloadTimes={reloadTimes} 
                                            setReloadTimes={setReloadTimes} 
                                            guestFeedBackChat={guestFeedBackChat}
                                            trainerFeedBackChat={trainerFeedBackChat}
                                            traineeFeedBackChat={traineeFeedBackChat}
                                        />
                                    </Col>
                                    <Col xl="2" className={styles.ai_btn_box}>
                                        <BackButton id={autoId()} className="back-btn font-14 mb-3 w-100" title='募集人別サマリーへ' onClick={() => { history.push('/RecruiterSummaryDetail') }} />
                                        <BackButton id={autoId()} className="back-btn font-14 mb-3" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        vAgent: state.vAgent,
        aiResultScore: state.aiResultScore,
        startRolePlay: state.startRolePlay,
        agent_id: state.vAgent.agent_id,
        role: state.vAgent.role,
        recruiter: state.vAgent.recruiter,
        company: state.vAgent.company
    }
}

const dispatchToProps = dispatch => ({
    SET_AI_SCORE_RESULT: (res) => dispatch({ type: 'SET_AI_SCORE_RESULT', paramData: res }),
    SET_FEEDBACK_GOOD_POINT: (res) => dispatch({ type: 'initTranscriptGoodPoint', transcriptGoodPoint: res }),
    SET_FEEDBACK_IMPROVE_POINT: (res) => dispatch({ type: 'initTranscriptImprovePoint', transcriptImprovePoint: res }),
    SET_FEEDBACK_CROSS_MATTER: (res) => dispatch({ type: 'initTranscriptCrossMatter', transcriptCrossMatter: res }),
    SET_FEEDBACK_INIT: (res) => dispatch({type: "updateMicStatus", micStatus: true}),
    RESET_FEEDBACK_MICSTATUS: (res) => dispatch({type: "resetMicStatus"})
})

export default connect(stateToProps, dispatchToProps)(ResultDetail);
