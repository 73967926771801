import { useHistory  } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import BackButton from "../../../components/Buttons/BackButton";
import {  Row, Col } from 'reactstrap';
import FeedbackCard from '../../../components/Cards/FeedbackCard'
import Sidebar from '../../../components/Sidebar';
import { connect } from 'react-redux';
import LogoutButton from '../../../components/Buttons/LogoutButton'
import  {useEffect} from 'react';
import {updateFeedback} from '../../../api/api'
import { isApigwEnv } from '../../../utils/General'

const Feedback = (props) => {
    const {isOpen,agent_id,access_token} = props.vAgent
    const {averageSpeed, evaluation, goodPoint, improvePoint, crossMatter} = props.aiResultScore
    const {rolePlayId} = props.startRolePlay
    const history = useHistory();
    const { t } = useTranslation();
    let lastId = 0;

    const autoId = (prefix='chat_history-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
    }, []);
    const handleUpdate = () => {
        let data = {
            evaluation,
            goodPoint,
            improvePoint,
            crossMatter
        }
        updateFeedback(rolePlayId, data).then(res => {
            history.goBack()
        })
    }
    return(
        <>
        <Sidebar/>
          <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between">{t('AI score result.feedback_edit')}</h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="10">
                            <div className="cmn-bg-box">
                                <BackButton id={autoId()} className="back-btn font-14 mb-4 mr-3" title={t('general.button.go_back')} onClick={history.goBack}/>
                                <BackButton id={autoId()} className="cmn-btn font-14 mb-4" title={t('general.button.done')} onClick={() => handleUpdate()} />
                                {/* <Row>
                                    <Col>
                                        <div className="form-group  mb-3">
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span id={autoId()} className="input-group-text font-weight-bold font-14 list-group-flush light_grey_2">{t('AI score result.average speed')}</span>
                                                </div>
                                                <div className="form-control text-right">
                                                    <span id={autoId()}>{averageSpeed} {t('AI score result.sec')}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row> */}
                                <Row className="medium-padding-box mt-2">
                                    <Col className="mb-3">
                                        <FeedbackCard  id={autoId()} editFlag={true} headerTitle={t('AI score result.good_point')} paramKey={"goodPoint"} content={goodPoint} limit={300}></FeedbackCard>
                                    </Col>
                                    <Col className="mb-3">
                                        <FeedbackCard  id={autoId()} editFlag={true} headerTitle={t('AI score result.improvement_point')} paramKey={"improvePoint"} content={improvePoint} limit={300}></FeedbackCard>
                                    </Col>
                                </Row>
                                <Row className="medium-padding-box mt-2">
                                    <Col className="mb-3">
                                        <FeedbackCard id={autoId()} editFlag={true} headerTitle={t('AI score result.correspondence_matter')} paramKey={"crossMatter"} content={crossMatter} limit={300}></FeedbackCard>
                                    </Col>
                                    <Col className="mb-3">
                                        <FeedbackCard  id={autoId()} editFlag={true} headerTitle={t('AI score result.evaluation')} paramKey={"evaluation"} content={evaluation} limit={300}></FeedbackCard>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
const stateToProps = state => {
    return {
        vAgent: state.vAgent,
        aiResultScore: state.aiResultScore,
        startRolePlay: state.startRolePlay,
    }
}

export default connect(stateToProps, null)(Feedback);