import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";
import Sidebar from './../../components/Sidebar';
import LogoutButton from './../../components/Buttons/LogoutButton';
import { setRecentPath } from '../../store/actions'
import Tab from '../../components/Tabs/ThemesTab';
import GeneralButton from './../../components/Buttons/GeneralButton';
import Accordian from './accordian';
import ConfirmDialog from '../../components/Dialog/ConfirmDialog'
import BackButton from './../../components/Buttons/BackButton';
import { getKeywords, getThemeList, updateKeywords } from '../../api/api';
import { split } from '../../utils/General'
import EventBus from '../../components/EventBus'
import { isTrainerManagerOrTrainee } from '../../configs/constants'
import { isApigwEnv } from '../../utils/General'

const EditKeywordPage = ({ access_token, isOpen, location, setRecentPath }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [visibleTab, setVisibleTab] = useState();
    const [selected, setSelected] = useState();
    const [editState, setEditState] = useState(false);
    const [allKeywords, setAllKeywords] = useState();
    const [keywords, setKeywords] = useState();
    const [learningThemes, setLearningThemes] = useState();
    const [scenarioByTheme, setScenarioByTheme] = useState();
    const [dataToUpdate, setDataToUpdate] = useState([])
    const [status, setStatus] = useState(false)
    const [refresh, setRefresh] = useState(true)
    const [reset, makeAllReset] = useState(false)

    useEffect(() => {
        // getKeywords().then(res => {
        //     if (res.data.length > 0) {
        //         let firstIndex = res.data[Object.keys(res.data)[0]]
        //         setScenarioByTheme(firstIndex.scenarios.map(item => { return { id: item.id, name: item.name } }))
        //         learningThemes && setVisibleTab(learningThemes[0].id)
        //     }
        // })

        // getThemeList().then(res => {
        //     setLearningThemes(res.data.map(item => { return { id: item.id, name: item.name, isActive: item.isActive } }))
        // })
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);
    }, []);

    useEffect(() => {
        getKeywords().then(res => {
            if (res.data.length > 0) {
                let firstIndex = res.data[Object.keys(res.data)[0]]
                setScenarioByTheme(firstIndex.scenarios.map(item => { return { id: item.id, name: item.name } }))
                learningThemes && setVisibleTab(learningThemes[0].id)
                setAllKeywords(res.data)
            }
        })
        getThemeList().then(res => {
            setLearningThemes(res.data.map(item => { return { id: item.id, name: item.name, isActive: item.isActive } }))
        })

    }, [refresh, status])

    useEffect(() => {
        if (allKeywords !== undefined) {
            let index = allKeywords.map(e => e.id).indexOf(visibleTab);
            let currentIndex = allKeywords[Object.keys(allKeywords)[index]]
            setScenarioByTheme(currentIndex.scenarios.map(item => { return { id: item.id, name: item.name } }))
        }
        setEditState(false)
    }, [visibleTab])

    useEffect(() => {
        if (selected !== undefined && visibleTab !== undefined && allKeywords !== undefined) {
            let currentSection = selected && visibleTab && allKeywords && allKeywords
                .filter(item => item.id === visibleTab)[0].scenarios
                .filter(value => value.id === selected.id)
            let editedKeywords = currentSection && currentSection.map(data => {
                return {
                    id: data.id,
                    name: data.name,
                    sections: data.sections.map(
                        items => {
                            return {
                                id: items.id,
                                name: items.name,
                                criteria: items.criteria.map(item => {
                                    let keywords = (item.aiScoringFlag) ? 'AIは採点しない' : split(item.keywords.keywords)
                                    if (keywords === 'AIは採点しない') {
                                        return {
                                            id: item.id,
                                            name: item.name,
                                            aiScoringFlag: item.aiScoringFlag,
                                            modifyFlag: false,
                                            keywords: [{
                                                id: item.id,
                                                name: keywords
                                            }]
                                        }

                                    } else {
                                        return {
                                            id: item.id,
                                            name: item.name,
                                            aiScoringFlag: item.aiScoringFlag,
                                            modifyFlag: false,
                                            keywords: keywords.map((data, index) => {
                                                return {
                                                    id: index + 1,
                                                    name: data,
                                                    delete: false,
                                                    action: ''
                                                }
                                            })
                                        }
                                    }
                                }
                                )
                            }
                        }
                    )
                }
            })
            setKeywords(editedKeywords)
        }
    }, [selected, status, allKeywords, refresh])

    const handleEditState = () => {
        setEditState(true);
    }

    useEffect(() => {
        let updatedData = [];
        confirmOpen && keywords && keywords.map(data => {
            data.sections.map(items => {
                items.criteria.map(item => {
                    !item.aiScoringFlag && item.keywords && item.keywords.map((keyword, index) => {
                        if (item.keywords.filter(val => val.action !== '').length > 0) {
                            let names = item.keywords.filter(val => !val.delete).map(val => { return val.name });
                            updatedData.push({
                                scenarioId: data.id,
                                sectionId: items.id,
                                checkCriteriaId: item.id,
                                valueToUpdate: names.toString()
                            })

                            // if (dataToUpdate.length === 0) {
                            //     setDataToUpdate([...dataToUpdate, {
                            //         scenarioId: data.id,
                            //         sectionId: items.id,
                            //         checkCriteriaId: item.id,
                            //         valueToUpdate: names.toString()
                            //     }])
                            // }
                            // else {
                            //     let curent = [{
                            //         scenarioId: data.id,
                            //         sectionId: items.id,
                            //         checkCriteriaId: item.id,
                            //         valueToUpdate: names.toString()
                            //     }];

                            //     const result = Object.values(
                            //         [].concat(dataToUpdate, curent)
                            //             .reduce((r, c) => (r[c.scenarioId && c.sectionId && c.checkCriteriaId] = Object.assign((r[c.scenarioId && c.sectionId && c.checkCriteriaId] || {}), c), r), {})
                            //     );
                            //     setDataToUpdate(result)
                            // }
                        }
                    })
                })
            })
        })

        const filteredArr = updatedData.reduce((acc, current) => {
            const x = acc.find(item => (item.scenarioId === current.scenarioId) && (item.sectionId === current.sectionId) && (item.checkCriteriaId === current.checkCriteriaId));
            if (!x) {
                return acc.concat([current]);
            } else {
                return acc;
            }
        }, []);

        filteredArr.length > 0 && setDataToUpdate(filteredArr)

    }, [confirmOpen])

    const openDialog = () => {
        let count = 0;
        keywords && keywords.map(data => {
            data.sections.map(items => {
                items.criteria.map(item => {
                    for (let i = 0; i < item.keywords.length; i++) {
                        if (item.keywords[i].action !== '' && !item.aiScoringFlag)
                            count++
                    }
                })
            })
        })
        if (count === 0) {
            setConfirmOpen(false);
            EventBus.dispatch('show_snack', { message: '更新データがありません', type: 'error' })
        }
        else {
            setConfirmOpen(true);
        }
    }

    const saveEditedKeyword = () => {
        setExpanded(false)
        setEditState(false);
        updateKeywords(dataToUpdate).then((res) => {
            setStatus(!status)
            EventBus.dispatch('show_snack', { message: "操作成功", type: 'success' })
        }).catch(err => {
            setStatus(!status)
            EventBus.dispatch('show_snack', { message: "失敗しました", type: 'error' })
        })
    }

    const onBack = () => {
        setRefresh(!refresh)
        setEditState(false);
    }

    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr edit_setting_page" id="edit_keyword">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="edit_keyword_title">
                                {t('edit keywords.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} reset={reset} id="logout_btn" />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12">
                            <div className="cmn-bg-box">
                                <Row>
                                    <Col xl="10" lg="12" className="order-xl-0 order-1">
                                        <div className="cmn-bg-box-inr p-32 mb-32">
                                            <Tab className="col-lg-12"
                                                visibleTab={visibleTab}
                                                setVisibleTab={setVisibleTab}
                                                selected={selected}
                                                setSelected={setSelected}
                                                learningThemes={learningThemes}
                                                scenarioByTheme={scenarioByTheme}
                                            />
                                        </div>
                                        <div className="cmn-bg-box-inr p-32 keyword-edit-sec">
                                            {
                                                editState ?
                                                    <>
                                                        <BackButton id={`back_btn`} className="back-btn mr-3" title={t('general.button.return')} onClick={onBack} />
                                                        <GeneralButton id={`apply_btn`} onClick={openDialog} className="cmn-btn font-weight-normal mb-32" title={t('edit keywords.apply')} />
                                                    </>
                                                    :
                                                    <GeneralButton id={`edit_btn`} onClick={handleEditState} className="cmn-btn font-weight-normal mb-32" title={t('edit keywords.edit')} />

                                            }
                                            {confirmOpen && <ConfirmDialog
                                                title={t('general.text.do_you_want_to_save')}
                                                open={confirmOpen}
                                                setOpen={setConfirmOpen}
                                                onConfirm={saveEditedKeyword}
                                            ></ConfirmDialog>}

                                            <Accordian editState={editState} keywords={keywords} setKeywords={setKeywords} expanded={expanded} setExpanded={setExpanded} status={status} />
                                        </div>
                                    </Col>
                                    <Col xl="2" className={`back-btn-box  text-right`}>
                                        <BackButton id={`go_back_btn`} className="back-btn mb-3" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(EditKeywordPage);