import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { GeneralDropdown } from './../../../components/Dropdowns/GeneralDropdown';
import GeneralButton02 from './../../../components/Buttons/LargeButton';
import { GeneralTable01 } from './../../../components/Table/GeneralTable01';
import TraineeTable from './../../../components/Table/TraineeTable';
import LogoutButton from './../../../components/Buttons/LogoutButton';
import Sidebar from './../../../components/Sidebar';
import './styles.css';
import { getThemeList, getTraineeDetailsDashboardByAgentId, getUserList, getLearningThemePattern, getAllTrainingPlan, patternsForCurrentUsersCompany, getTrainingPlan, getLearningThemeOrTraineeForMultiAgentCode, getTraineeFilterList } from '../../../api/api';
import { setRecentPath, setRecruiter, setRecruiterId, setSevenDigitsCode, setTenDigitsCode, setActiveCode } from '../../../store/actions';
import Tab from '../../../components/Tabs/AssociateTab'
import Toggle from '../../../components/Toggle'
import { longestString, isApigwEnv, getASCodeCompany, padLeadingZeros } from '../../../utils/General'
import LearningthemeTable from '../../../components/Table/LearningThemeTable'
import { isAflacMemberOrBranchManagerOrTrainerManager, isAflacMemberOrBranchManager, isTrainee, isTrainerManager, isSystemOwner } from '../../../configs/constants'
import { COMPANY_CODES_URL, COMPANY_WITH_BRANCHES_URL, LEARNING_THEMES_URL, ALL_TRAINEE, LEARNING_THTME_PATTERNS_BY_MULTI_AGENT_COMPANIES, TRAINER_LIST_BY_MULTI_AGENT_COMPANIES, LEARNING_THTMES_BY_MULTI_AGENT_COMPANIES } from "../../../configs/urlConstants";
import { MultiselectDropdown } from '../../../components/Dropdowns/MultiSelectDropdown';

const RecruiterSummaryPage = ({ isOpen, location, agent_company, role, recent_path, setRecentPath, setRecruiter, setRecruiterId, access_token, setSevenDigitsCode, setTenDigitsCode, company_code, branch_code, setActiveCode, active_code, syncStatus, employee_id }) => {

    const { t } = useTranslation();
    const history = useHistory();
    // const trainee_list_url = `/getTraineeList/${agent_company.id}`;
    const [traineeListUrl, setTraineeListUrl] = useState();
    let longestCompanyname = 0, longestBranchName = 0, threeCode = '';
    const [traineeByAgentId, setTraineeByAgentId] = useState([]);
    const [learningTheme, setLearningTheme] = useState();
    const [filterTrainee, setFilterTrainee] = useState();
    const [filterTheme, setFilterTheme] = useState();
    const [visibleTab, setVisibleTab] = useState();
    const [activeSlider, setActiveSlider] = useState();
    const [companyCode, setCompanyCode] = useState();
    const [branchCode, setBranchCode] = useState(undefined);
    const [uniqueCode, setUniqueCode] = useState([]);
    const [sevenFilterCode, setSevenFilterCode] = useState();
    const [threeFilterCode, setThreeFilterCode] = useState();
    const [longest, setLogest] = useState();
    const [trainingPlan, setTrainingPlan] = useState([]);
    const [asCodeData, setAsCodeData] = useState();
    const [traineeList, setTraineeList] = useState();
    const [filterCode, setFilterCode] = useState();
    const [status, setStatus] = useState();
    const [selectedTrainee, setSelectedTrainee] = useState();
    const [selectAllASCode, setSelectAllASCode] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState();
    const [selectedData, setSelectedData] = useState();
    const [allCompanies, setAllCompanies] = useState([]);
    const [traineeDetailIds, setTraineeDetailIds] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    let count = 0;
    const url = `/backend/getTraineeFilterList/${agent_company.id ? agent_company.id : 0}`;

    useEffect(() => {
        status !== undefined && setIsLoading(true);
        status !== undefined && getTraineeDetailsDashboardByAgentId().then(res => {
            setIsLoading(false)
            setTraineeByAgentId(res.data);
            let data = [];
            res.data.map(item => {
                data.push(item.id);
            })
            setTraineeDetailIds(data);
        })
        status !== undefined && getUserList(COMPANY_WITH_BRANCHES_URL).then(res => {
            setCompanyCode(res.data.map(item => { return { id: item.code, name: padLeadingZeros(item.code,7) + '/' + item.name, branches: item.branches } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
        })
    }, [status])

    useEffect(() => {
        if (employee_id !== '') {
            status === undefined && !active_code && setIsLoading(true)
            status === undefined && !active_code && getTraineeDetailsDashboardByAgentId().then(res => {
                setIsLoading(false)
                setTraineeByAgentId(res.data);
                let data = [];
                res.data.map(item => {
                    data.push(item.id);
                })
                setTraineeDetailIds(data);
            })

            !active_code && status === undefined && companyCode === undefined && getUserList(COMPANY_WITH_BRANCHES_URL).then(res => {
                setCompanyCode(res.data.map(item => { return { id: item.code, name: padLeadingZeros(item.code,7) + '/' + item.name, branches: item.branches } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            })

            if (active_code) {
                setActiveSlider(active_code)
                setThreeFilterCode([...branch_code])
                setStatus(!status)
            }
            window.scrollTo(0, 0)
            setRecentPath(location.pathname);

            if (isAflacMemberOrBranchManager() || isSystemOwner()) {
                getAllTrainingPlan().then(res => {
                    setTrainingPlan(res.data);
                })
            }
            if (isTrainerManager() || isTrainee()) {
                var code = agent_company.code;
                code && getTrainingPlan(code).then(res => {
                    setTrainingPlan(res.data)
                })
            }
            (isAflacMemberOrBranchManager() || isSystemOwner()) && getUserList(COMPANY_CODES_URL).then(res => {
                setUniqueCode(res.data.map(item => {
                    return {
                        id: item.id, name: item.name,
                        checked: company_code === '' ? true : company_code !== '' && company_code.some(data => data.id === item.id) ? true : false,
                        newlyAdded: false, found: true
                    }
                }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
                company_code === ''
                    ? setSelectAllASCode(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
                    : setSelectAllASCode(company_code)

                setAllCompanies(res.data.sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            })
        }
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
    }, [syncStatus, employee_id])

    useEffect(() => {
        if (employee_id !== '') {
            if (company_code !== undefined && company_code !== 0 && company_code !== "" && activeSlider === 1) {
                let params = new URLSearchParams();
                let array = company_code.map(function (a) { return a.id; });
                for (let i = 0; i < array.length; i++) {
                    params.append("agentCodeList", array[i])
                }
                getLearningThemeOrTraineeForMultiAgentCode(LEARNING_THTME_PATTERNS_BY_MULTI_AGENT_COMPANIES, params).then(res => {
                    setLearningTheme(res.data);
                })
                getLearningThemeOrTraineeForMultiAgentCode(LEARNING_THTMES_BY_MULTI_AGENT_COMPANIES, params).then(res => {
                    setAsCodeData(res.data)
                })
            }
            else if (branch_code !== undefined && branch_code !== '' && activeSlider === 2) {
                getLearningThemePattern(branch_code[0].company_code).then(res => {
                    var filterLearningThemePattern = res.data.patterns && res.data.patterns.filter((item) => item.id === res.data.selectedPatternId);
                    setLearningTheme(filterLearningThemePattern[0].themes);
                    setAsCodeData(filterLearningThemePattern[0].themes.filter(item => !item.deleteFlag))
                })
            }
            if (isTrainerManager() || isTrainee()) {
                patternsForCurrentUsersCompany().then(res => {
                    var filterLearningThemePattern = res.data.patterns &&res.data.patterns.filter((item) => item.id === res.data.selectedPatternId);
                    setLearningTheme(filterLearningThemePattern[0].themes);
                    setAsCodeData(filterLearningThemePattern[0].themes.filter(item => !item.deleteFlag))
                })
            }
            else if (branch_code === '' && activeSlider === 2) {
                getThemeList().then(res => {
                    setLearningTheme(res.data);
                    setAsCodeData(res.data)
                })
            }
        }
    }, [activeSlider, company_code, branch_code, employee_id])

    useEffect(() => {
        if (employee_id !== '') {
            if ((isAflacMemberOrBranchManager() || isSystemOwner()) && company_code !== "" && activeSlider && activeSlider === 1 && allCompanies.length > 0) {
                if (trainingPlan.length > 0 || traineeByAgentId.length > 0) {
                    let data = visibleTab === 1 ? [...trainingPlan] : [...traineeByAgentId];
                    let text = visibleTab === 1 ? 'agentCompanyCode' : 'companyCode';
                    let id = visibleTab === 1 ? 'userId' : 'id';
                    let name = visibleTab === 1 ? 'userName' : 'name';
                    if (company_code && company_code.length > 0) {
                        data = data && data.filter(function (array_el) {
                            return company_code.filter(function (anotherOne_el) {
                                return anotherOne_el.id === array_el[text];
                            }).length !== 0
                        });
                        let uniqueTrainee = [...new Set(data.map(item => { return { id: item[id], name: item[name] } }))];
                        setTraineeList(uniqueTrainee)
                    }
                }

                // let params = new URLSearchParams();
                // let array = company_code.map(function (a) { return a.id; });
                // for (let i = 0; i < array.length; i++) {
                //     params.append("agentCodeList", array[i])
                // }
                // getLearningThemeOrTraineeForMultiAgentCode(TRAINER_LIST_BY_MULTI_AGENT_COMPANIES, params).then(res => {
                //     setTraineeList(res.data)
                // })
            }
            else if ((isAflacMemberOrBranchManager() || isSystemOwner()) && branch_code !== "" && activeSlider && activeSlider === 2) {
                if (trainingPlan.length > 0 || traineeByAgentId.length > 0) {
                    let data = visibleTab === 1 ? [...trainingPlan] : [...traineeByAgentId];
                    let text = visibleTab === 1 ? 'agentCompanyCode' : 'companyCode';
                    let id = visibleTab === 1 ? 'userId' : 'id';
                    let name = visibleTab === 1 ? 'userName' : 'name';
                    if (branch_code && branch_code.length > 0) {
                        data = data && data.filter(function (array_el) {
                            return ((parseInt(branch_code[0].company_code) === parseInt(array_el[text])) && (parseInt(branch_code[1].branch_code) === parseInt(array_el.branchCode)))
                        });
                        let uniqueTrainee = [...new Set(data.map(item => { return { id: item[id], name: item[name] } }))];
                        setTraineeList(uniqueTrainee)
                    }
                }
                // let data = {
                //     roleId: role ? role.id : '',
                //     sevenFilterCode: branch_code ? branch_code[0].company_code : '',
                //     threeFilterCode: branch_code ? branch_code[1].branch_code : '',
                // }
                // getTraineeFilterList(url, data).then(res => {
                //     setTraineeList(res.data)
                // })
            }
            else if ((isAflacMemberOrBranchManager() || isSystemOwner()) && branch_code === "" && activeSlider === 2 && allCompanies.length > 0) {
                if (trainingPlan.length > 0 || traineeByAgentId.length > 0) {
                    let data = visibleTab === 1 ? [...trainingPlan] : [...traineeByAgentId];
                    let id = visibleTab === 1 ? 'userId' : 'id';
                    let name = visibleTab === 1 ? 'userName' : 'name';
                    let uniqueTrainee = [...new Set(data.map(item => { return { id: item[id], name: item[name] } }))];
                    setTraineeList(uniqueTrainee)
                }
                // let parameters = new URLSearchParams();
                // let companies = allCompanies.length > 0 && allCompanies.map(function (a) { return a.id; });
                // for (let i = 0; i < companies.length; i++) {
                //     parameters.append("agentCodeList", companies[i])
                // }
                // companies && allCompanies.length > 0 && getLearningThemeOrTraineeForMultiAgentCode(TRAINER_LIST_BY_MULTI_AGENT_COMPANIES, parameters).then(res => {
                //     setTraineeList(res.data)
                // })
            }
            else if (isTrainee() || isTrainerManager()) {
                if (trainingPlan.length > 0 || traineeByAgentId.length > 0) {
                    let data = visibleTab === 1 ? [...trainingPlan] : [...traineeByAgentId];
                    let id = visibleTab === 1 ? 'userId' : 'id';
                    let name = visibleTab === 1 ? 'userName' : 'name';
                    let uniqueTrainee = [...new Set(data.map(item => { return { id: item[id], name: item[name] } }))];
                    setTraineeList(uniqueTrainee)
                }
                // let data = {
                //     roleId: role ? role.id : '',
                //     sevenFilterCode: agent_company ? agent_company.code : '',
                //     threeFilterCode: agent_company ? agent_company.branch.code : '',
                // }
                // getTraineeFilterList(url, data).then(res => {
                //     setTraineeList(res.data)
                // })

            }
            count > 0 && setLogest(activeSlider === 1 ? count / 2 : count)
            setFilterCode()
            let code = '';
            if (activeSlider === 1) {
                code = company_code !== '' ? company_code.map(function (a) { return a.id; }) : false
            } else {
                code = branch_code !== '' ? [branch_code[0].company_code + '-' + branch_code[1].branch_code] : false
            }
            setFilterCode(code)
            setActiveCode(activeSlider)
        }
    }, [activeSlider, company_code, branch_code, allCompanies, traineeByAgentId, trainingPlan, visibleTab, employee_id])
    // }, [activeSlider, company_code, branch_code, allCompanies])

    useEffect(() => {
        longestCompanyname = longestString(companyCode && companyCode.map(item => { return { name: item.name } }))
        longestBranchName = longestString(companyCode && companyCode[0].branches.map(val => { return { name: val.id + '/' + val.name } }))
        longestCompanyname = longestCompanyname > longestBranchName ? longestCompanyname : longestBranchName;
        setLogest(longestCompanyname < 15 ? '6' : longestCompanyname >= 15 ? '7' : longestCompanyname >= 20 ? '8' : longestCompanyname >= 25 ? '8' : '9')
        count = longest
    }, [companyCode, branchCode])

    const onStartRoleplay = () => {
        setRecruiter('')
        setRecruiterId('')
        history.push("/StartOfNewRolePlay");
    }

    const onThemeChange = (el) => {
        setFilterTheme(el.textContent === t('general.text.theme select') ? undefined : el.textContent)
    }

    const onRecruiterChange = (el) => {
        setFilterTrainee(el.textContent === t('general.text.theme select') ? undefined : el.value)
        setSelectedTrainee(el.textContent === t('general.text.theme select') ? undefined : el.textContent)
    }

    useEffect(() => {
        setFilterTheme(undefined)
        setFilterTrainee(undefined)
        setSelectedTrainee(undefined)
        setActiveCode(activeSlider)
        // setStatus(!status)
        // setAgentCompanyCode(selectAllASCode)
        selectAllASCode.length > 0 && setSevenDigitsCode(selectAllASCode)
        // setTraineeListUrl(`/backend/trainees/forAgentCompanyCode/${el.getAttribute('value')}`)
    }, [selectAllASCode])

    const onSevenCodeChange = async (el) => {
        if (el.textContent === t('associate_summary.seven_code')) {
            setSevenFilterCode(undefined)
            setTenDigitsCode('')
            setStatus(!status)
            setActiveCode(activeSlider)
            setFilterCode()
            setThreeFilterCode(undefined)
            setBranchCode(undefined)
        } else {
            setSevenFilterCode({ id: el.getAttribute('value'), name: el.textContent })
            threeCode = await companyCode.filter(item => item.id === el.value)[0].branches.map(val => { return { id: val.code, name: val.code + '/' + val.name } });
            setBranchCode(threeCode && threeCode.sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            setThreeFilterCode(undefined)
        }
        setFilterTheme(undefined)
        setFilterTrainee(undefined)
        setSelectedTrainee(undefined)
    }

    const onThreeCodeChange = (el) => {
        setTenDigitsCode([{ company_code: sevenFilterCode.id, name: sevenFilterCode.name }, { branch_code: el.getAttribute('value'), name: el.textContent }])
        setThreeFilterCode({ id: el.getAttribute('value'), name: el.textContent })
        setActiveCode(activeSlider)
    }

    useEffect(() => {
        if (employee_id !== '') {
            setFilterTheme(undefined)
            setFilterTrainee(undefined)
            setActiveDropdown(undefined)
            isAflacMemberOrBranchManager() || isSystemOwner() && activeSlider !== undefined && allCompanies.length > 0 && getUserList(COMPANY_CODES_URL).then(res => {
                setUniqueCode(res.data.map(item => {
                    return {
                        id: item.id, name: item.name,
                        checked: company_code === '' ? true : company_code !== '' && company_code.some(data => data.id === item.id) ? true : false,
                        newlyAdded: company_code !== '' && company_code.some(data => data.id === item.id) ? false : true, found: true
                    }
                }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
                company_code === ''
                    ? setSelectAllASCode(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
                    : setSelectAllASCode(company_code)
            })
        }
    }, [activeSlider, employee_id])

    return (
        <>
            {employee_id !== '' ?
                <>
                    <Sidebar />
                    <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'} `} name='main_content'>
                        <div className="main-content-inr summary-page" id="recruiter-summary" name='recruiter_summary'>
                            <Row>
                                <Col lg="12" className="cmn-logout-btn-sec">
                                    <h3 className="text-left d-flex justify-content-between" id="summary_page" name='summary_page'>
                                        {t('siderbar.associates summary')}
                                    </h3>
                                    <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout_btn" name='logout_btn' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <div className="cmn-bg-box pb-4">
                                        {isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner() ? <GeneralButton02 title={t('general.button.start_new_role')} className="btn-large mb-4" id="start_role_play" onClick={onStartRoleplay} name="start_role_play" /> : ''}
                                        {(isAflacMemberOrBranchManager() || isSystemOwner()) && <Row className="mb-4">
                                            <Col lg={`${isOpen && activeSlider === 1 ? parseInt(longest) + 1 - 2 : isOpen && activeSlider === 2 ? parseInt(longest) + 1 : !isOpen && activeSlider === 1 ? longest - 2 : longest} `}
                                                className={`${activeSlider === 1 ? 'summary_multi_select_dropdown' : 'summary_single_select_dropdown'}`} >
                                                <div className="cmn-bg-box-inr  p-3">
                                                    <div className="AS_code_box">
                                                        <label className="font-weight-bold font-14 mb-0" name="AS_code">{t('associate_summary.AS_code')}</label>
                                                        <Toggle
                                                            activeSlider={activeSlider}
                                                            setActiveSlider={setActiveSlider}
                                                            className="mr-0"
                                                            AS_code={selectAllASCode}
                                                            message="ASコードが選択されていません"
                                                            selectedData={selectedData}
                                                        />
                                                    </div>

                                                    {activeSlider === 1 ?
                                                        <MultiselectDropdown
                                                            // url={COMPANY_CODES_URL}
                                                            isStatic={true}
                                                            staticData={uniqueCode}
                                                            selectAll={true}
                                                            selectAllText="全AS"
                                                            dropdown_id="AS_code_dropdown"
                                                            placeholder={t('general.text.theme select')}
                                                            multiSelectedData={selectAllASCode} setMultiSelectedData={setSelectAllASCode}
                                                            activeDropdown={activeDropdown} setActiveDropdown={setActiveDropdown}
                                                            setSelectedData={setSelectedData}
                                                            active={active_code} />

                                                        : <div className='d-flex'>
                                                            <GeneralDropdown
                                                                onSelect={(value) => onSevenCodeChange(value)}
                                                                className="font-10 recuiter-dropdown mr-2"
                                                                placeholder={t('associate_summary.seven_code')}
                                                                dropdown_id="seven_code_dropdown"
                                                                isStatic={true}
                                                                staticData={companyCode}
                                                                selectedData={branch_code !== '' && branch_code[0].name}
                                                                filter='true' />
                                                            <GeneralDropdown
                                                                onSelect={(value) => onThreeCodeChange(value)}
                                                                className="font-10 recuiter-dropdown"
                                                                placeholder={t('associate_summary.three_code')}
                                                                dropdown_id="three_code_dropdown"
                                                                isStatic={true}
                                                                staticData={branchCode}
                                                                selectedData={threeFilterCode !== undefined && branch_code !== '' && branch_code[1].name}
                                                            />
                                                        </div>}
                                                </div>
                                            </Col>
                                        </Row>}
                                        <Row className="mb-4">
                                            <Col lg="7" md="12" className="d-flex theme_section">
                                                {/* <div className="cmn-bg-box-inr">
                                            <h5 className="font-weight-bold font-14" name="study_theme">{t('associate_summary.study_theme')}</h5>
                                            <GeneralTable01 TableData={learningTheme} TableClassName="text-center study-theme-table" TableDivClassName="table-responsive" table_id="study_theme_table" />
                                        </div> */}

                                                <div className="cmn-scroll-bar-box study_theme_shadow">
                                                    <h5 className="font-weight-bold font-14" name="study_theme">{t('associate_summary.study_theme')}</h5>
                                                    <div className="cmn-scroll-bar-02">
                                                        {
                                                            activeSlider === 1 ? learningTheme && learningTheme.length > 0 && learningTheme.map(theme => {
                                                                var filterLearningThemePattern = theme.patterns && theme.patterns.filter((item) => item.id === theme.selectedPatternId);
                                                                var array = [...uniqueCode]
                                                                var agentCompanyName = theme && array.length > 0 && array.filter(item => item.id === theme.code)
                                                                return (
                                                                    <>
                                                                        {filterLearningThemePattern && <div className="cmn-scroll-inr" key={theme.selectedPatternId} >
                                                                            {agentCompanyName.length > 0 && <p className="font-14 mb-3" name="study_theme">アソシエイツ名：{agentCompanyName ? getASCodeCompany(agentCompanyName[0].name)[1] : ''}</p>}
                                                                            <GeneralTable01 TableData={filterLearningThemePattern[0].themes} TableClassName="text-center study-theme-table" TableDivClassName="table-responsive" table_id="study_theme_table" />
                                                                        </div>}
                                                                    </>
                                                                )
                                                            })
                                                                : <div className="cmn-scroll-inr">
                                                                    {branch_code !== ''
                                                                        ? <p className="font-14 mb-3" name="study_theme">アソシエイツ名：{branch_code ? getASCodeCompany(branch_code[0].name)[1] : ''}</p>
                                                                        : !isAflacMemberOrBranchManager() || isSystemOwner() && <p className="font-14 mb-3" name="study_theme">アソシエイツ名：{agent_company.name}</p>}
                                                                    <GeneralTable01 TableData={learningTheme} TableClassName="text-center study-theme-table" TableDivClassName="table-responsive" table_id="study_theme_table" />
                                                                </div>
                                                        }
                                                        {/* <div className="cmn-scroll-inr">
                                                    <p className="font-14 mb-3" name="study_theme">アソシエイツ名：株式会社アドバンテスト</p>
                                                    <GeneralTable01 TableData={learningTheme} TableClassName="text-center study-theme-table" TableDivClassName="table-responsive" table_id="study_theme_table" />
                                                </div>
                                                <div className="cmn-scroll-inr">
                                                    <p className="font-14 mb-3" name="study_theme">アソシエイツ名：株式会社アドバンテスト</p>
                                                    <GeneralTable01 TableData={learningTheme} TableClassName="text-center study-theme-table" TableDivClassName="table-responsive" table_id="study_theme_table" />
                                                </div> */}
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <div className="cmn_inner_box ">
                                                    {employee_id !== '' && <Tab
                                                        visibleTab={visibleTab}
                                                        setVisibleTab={setVisibleTab}
                                                        className="mx-0 mb-32" />}
                                                    <Row className="smallest-padding-box mx-12">
                                                        <Col lg="3" className="recruiter_name_dropdown">
                                                            <div className="cmn-bg-box-inr p-3">
                                                                <label className="font-weight-bold font-14" name="recruiter_names">{t('associate_summary.recruiter_name')}</label>
                                                                <GeneralDropdown
                                                                    onSelect={(value) => onRecruiterChange(value)}
                                                                    className="font-10 recuiter-dropdown"
                                                                    placeholder={t('general.text.recruiter select')}
                                                                    isStatic={true}
                                                                    staticData={traineeList}
                                                                    // url={traineeListUrl && traineeListUrl !== '' && traineeListUrl}
                                                                    dropdown_id="recruiter_name_dropdown"
                                                                    filter='true'
                                                                    selectedData={filterTrainee === undefined ? t('general.text.recruiter select') : selectedTrainee}
                                                                />
                                                            </div>
                                                        </Col>
                                                        {visibleTab === 2 &&
                                                            <Col lg="3" className="learning_theme_dropdown">
                                                                <div className="cmn-bg-box-inr p-3">
                                                                    <label className="font-weight-bold font-14" name="learning_themes">{t('associate_summary.learning_theme')}</label>
                                                                    <GeneralDropdown
                                                                        isStatic={true}
                                                                        staticData={asCodeData}
                                                                        onSelect={(value) => onThemeChange(value)}
                                                                        className="font-10 recuiter-dropdown"
                                                                        placeholder={t('general.text.theme select')}
                                                                        // url={LEARNING_THEMES_URL}
                                                                        dropdown_id="learning_theme_dropdown"
                                                                        filter='true'
                                                                        selectedData={filterTheme === undefined ? t('general.text.theme select') : filterTheme}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        }
                                                    </Row>
                                                    <Row className="mx-4 py-4">
                                                        {visibleTab === 1 ?
                                                            <LearningthemeTable
                                                                trainingPlan={trainingPlan}
                                                                active={activeSlider}
                                                                filterCode={filterCode}
                                                                filterTrainee={filterTrainee}
                                                                uniqueCode={uniqueCode}
                                                                companyCode={companyCode}
                                                                table_id="training_table"
                                                                traineeDetailIds={traineeDetailIds}
                                                            />
                                                            :
                                                            <TraineeTable
                                                                TableClassName=" text-center new-tainee-table cmn-scroll-bar"
                                                                table_id="working_theme_table"
                                                                traineeByAgentId={traineeByAgentId}
                                                                filterTrainee={filterTrainee}
                                                                filterTheme={filterTheme}
                                                                filterCode={filterCode}
                                                                active={activeSlider}
                                                                uniqueCode={uniqueCode}
                                                                companyCode={companyCode}
                                                                isLoading={isLoading} />
                                                        }
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div >
                </>
                : <p>このサイトは利用者が限定されており、閲覧することはできません。</p>
            }
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        agent_company: state.vAgent.agent_company,
        username: state.vAgent.username,
        access_token: state.vAgent.access_token,
        role: state.vAgent.role,
        company_code: state.vAgent.company_code,
        branch_code: state.vAgent.branch_code,
        active_code: state.vAgent.active_code,
        syncStatus: state.vAgent.syncStatus,
        employee_id: state.vAgent.employee_id,
    }
}
const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
        setRecruiter: (recruiter) => {
            dispatch(setRecruiter(recruiter));
        },
        setRecruiterId: (recruiter_id) => {
            dispatch(setRecruiterId(recruiter_id));
        },
        setSevenDigitsCode: (company_code) => {
            dispatch(setSevenDigitsCode(company_code));
        },
        setTenDigitsCode: (branch_code) => {
            dispatch(setTenDigitsCode(branch_code));
        },
        setActiveCode: (active_code) => {
            dispatch(setActiveCode(active_code));
        }
    }
}


export default connect(stateToProps, dispatchToProps)(RecruiterSummaryPage);
