import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { setRecruiter, setRecruiterId, setSelectedCompanyCode , setRecruiterForDetail, setSelectedCompanyForDetail} from '../../../store/actions';
import { joinDateFomat, monthDiff, padLeadingZeros, trainingPlanYearMonthFormat } from '../../../utils/General';
import { COMPANY_WITH_BRANCHES_URL } from "../../../configs/urlConstants"
import {getFormattedTrainingPlan} from '../../../utils/mapState'
import { getUserList } from "../../../api/api"
import styles from './styles.module.css';
import { isTrainerManagerOrTrainee } from '../../../configs/constants';

const LearningthemeTable = ({ traineeDetailIds, table_id, trainingPlan, active, filterCode, filterTrainee, setRecruiter, setRecruiterId, uniqueCode, companyCode, setSelectedCompanyCode, setRecruiterForDetail, setSelectedCompanyForDetail }) => {
    const { t } = useTranslation();
    const history = useHistory();
    let index = 0;
    
    const noData = <tr><td colSpan="100%">{t('general.text.no data available')}</td></tr>;
    var currentDate = new Date();
    var startingDate = new Date(2021, 2, 1);
    var diffMonth = monthDiff(startingDate,currentDate);
    var totalMonth = diffMonth + 36;
    var theMonths = Array(totalMonth).fill(0).map((e,i)=>padLeadingZeros(i+1,2))
    var twenveMonth = [];
    let data;
    const [code, setCode] = useState();
    const [branch, setBranch] = useState();
    useEffect(() => {
        var test = document.getElementById('test');
        var diffMonthCount = diffMonth-1;
        var totalMonthWidth = 0;
        for (let index = 1; index <= diffMonthCount; index++) {
            totalMonthWidth += document.getElementById('training_table_heading_month_'+index).getBoundingClientRect().width;
        }
        test.scrollLeft = totalMonthWidth;
    }, [])

    for (var i = 0; i < totalMonth; i++) {
        var future = new Date(startingDate.getFullYear(), (startingDate.getMonth()) + i, 1);
        var month = theMonths[future.getMonth()];
        var year = future.getFullYear();
        twenveMonth.push(year + '/' + month)
    }
    const allGrayOut = (joinDate) => {
        return <td colSpan={totalMonth} className="bg-gray"></td>;
    }
    const grayOutBeforeJoinDate = (joinDate) => {
        var index = twenveMonth.indexOf(joinDate);
        if (index >= 1) {
            return <td colSpan={index} className="bg-gray"></td>;
        }
    }
    const estimatedCompletionYearMonthFormat = (joinDate, estimatedCompletionYearMonth) => {
        estimatedCompletionYearMonth = trainingPlanYearMonthFormat(estimatedCompletionYearMonth);
        var firstIndex = twenveMonth.indexOf(joinDate);
        if (estimatedCompletionYearMonth !== null || firstIndex !== -1) {
            var secondIndex = twenveMonth.indexOf(estimatedCompletionYearMonth);
            firstIndex = firstIndex !== -1 ? firstIndex : 0;
            if (secondIndex !== -1) {
                return <td colSpan={(secondIndex - firstIndex) + 1}>初期教育</td>
            }
            
        } else {
            return <td colSpan={totalMonth - firstIndex} className="bg-gray"></td>;
        }

    }
    const expectedCompletionYearMonth = (firstDate, secondDate, themeName, themeIndex) => {
        firstDate = trainingPlanYearMonthFormat(firstDate)
        var firstIndex = twenveMonth.indexOf(firstDate);
        secondDate = trainingPlanYearMonthFormat(secondDate)
       if (firstIndex !== -1) {
        if (secondDate !== null) {
            var SIndex = twenveMonth.indexOf(secondDate);
            if (SIndex !== -1) {
                return <td colSpan={SIndex - firstIndex} key={themeIndex}>{themeName}</td>
            }
        } else {
            if (firstDate !== null) {
                return <td colSpan={totalMonth - firstIndex} className="bg-gray" key={themeIndex}></td>;
            }
        }
       }else{
            if (secondDate !== null) {
                var SIndex = twenveMonth.indexOf(secondDate);
                if (SIndex !== -1) {
                    return <td colSpan={SIndex - firstIndex} key={themeIndex}>{themeName}</td>
                }
            } else {
                if (firstDate !== null) {
                    return <td colSpan={totalMonth - firstIndex} className="bg-gray" key={themeIndex}></td>;
                }
            }
       }

    }
    const graduate = (expectedCompletionYearMonth) => {
        expectedCompletionYearMonth = trainingPlanYearMonthFormat(expectedCompletionYearMonth);
        if (expectedCompletionYearMonth !== null) {
            var index = twenveMonth.indexOf(expectedCompletionYearMonth);
            if (index !== -1 && totalMonth !== (index+1)) {
                return <td colSpan={totalMonth - index} className="bg-blue font-white">卒業</td>
            }
           
        }

    }
    if (filterCode) {
        // var string_include = filterCode.includes("-");
        // if (string_include) {
        if (active === 2 ) {
            trainingPlan = trainingPlan.filter((item) =>  filterCode.includes(item.agentCompanyCode+'-'+item.branchCode));
            // var split_filterCode = filterCode[0].split('-');
            // trainingPlan = trainingPlan.filter((item) => item.agentCompanyCode === parseInt(split_filterCode[0]));
            // trainingPlan = trainingPlan.filter((item) => item.branchCode === split_filterCode[1]);
        } else {
            // trainingPlan = trainingPlan.filter((item) => item.agentCompanyCode === parseInt(filterCode));
             trainingPlan = trainingPlan.filter((item) => filterCode.includes(item.agentCompanyCode));
        }
    }
    if (filterTrainee) {
        trainingPlan = trainingPlan.filter((item) => item.userId === filterTrainee)
    }
    const onRecruiter = (name, id, companyCode, branchCode) => {
        setRecruiter(name)
        setRecruiterId(id)
        setCode(companyCode)
        setBranch(branchCode)
        setRecruiterForDetail({ id: id, name: name })
    }
    useEffect(() => {
        let sevenCode = '', threeCode = '';
        code && getUserList(COMPANY_WITH_BRANCHES_URL).then(res => {
            data = res.data.map(item => { return { id: item.code, name: padLeadingZeros(item.code,7) + '/' + item.name, branches: item.branches } })
            sevenCode = data.filter(item => parseInt(item.id) === parseInt(code));
            threeCode = branch && sevenCode[0].branches.filter(value => parseInt(value.code) === parseInt(branch))
            if(branch) {
                setSelectedCompanyCode([...sevenCode, { branch: threeCode }])
                setSelectedCompanyForDetail([...sevenCode, { branch: threeCode }])
            }else {
                setSelectedCompanyCode(sevenCode)
                setSelectedCompanyForDetail(sevenCode)
            }
            history.push('/RecruiterSummaryDetail')
        })
    }, [code])
    trainingPlan = trainingPlan.sort((a, b) => a.agentCompanyCode > b.agentCompanyCode ? 1 : -1)
    trainingPlan.sort(function (a, b) {
        return new Date(a.joinDate) - new Date(b.joinDate)
    })
    trainingPlan.sort(function (a, b) {
        return (b.joinDate != null) - (a.joinDate != null) || a.joinDate - b.joinDate;
    })
    let formattedData = getFormattedTrainingPlan(traineeDetailIds,trainingPlan);
    return (
        <div className={`table-responsive cmn-table-scroll ${styles.tableFixHead}`} id="test">
            <table className={`table cmn-table text-center vertical-align-middle-table theme_table ${styles.theme_table}`} id={table_id}>
                <thead>
                    <tr>
                        <th className={`td-140 ${styles.sticky_top}`} id={`${table_id}_heading_AS_code`}>アソシエイツコード</th>
                        <th className={`td-90 ${styles.sticky_top}`} id={`${table_id}_heading_recruiter`}>氏名</th>
                        {
                            twenveMonth.map((item, key) => {
                                return (
                                    <th className="td-90" key={key} id={`${table_id}_heading_month_${(key+1)}`}>{item}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        formattedData.length === 0 ?
                            noData
                            : formattedData.map((item, index) => {
                                var joinDate = joinDateFomat(item.joinDate);
                                var lastTheme = item.themes[item.themes.length - 1];
                                return (
                                    <tr key={index} id={`${table_id}_body_${(index+1)}`}>
                                        <th>{padLeadingZeros(item.agentCompanyCode,7)}{(active === 2 || isTrainerManagerOrTrainee()) && '-' + item.branchCode}</th>
                                        <td className="text-left text-primary text_underline font-weight-bold text-nowrap" width="8%">
                                            <Link onClick={() => onRecruiter(item.userName, item.userId, item.agentCompanyCode, item.branchCode)} className="text-primary  text_underline" >{item.userName}</Link>
                                        </td>
                                        {joinDate === null && allGrayOut(item.joinDate)}
                                        {joinDate !== null && grayOutBeforeJoinDate(joinDate)}
                                        {joinDate !== null && estimatedCompletionYearMonthFormat(joinDate, item.estimatedCompletionYearMonth)}
                                        {
                                            joinDate !== null && item.themes.map((themeItem, themeIndex) => {
                                                var firstItem = item.themes.indexOf(item.themes[0]);
                                                var lastItem = item.themes.indexOf(item.themes[item.themes.length - 1]);
                                                return (
                                                    firstItem === themeIndex ?
                                                        expectedCompletionYearMonth(item.estimatedCompletionYearMonth, themeItem.expectedCompletionYearMonth, themeItem.name, themeIndex)
                                                        : lastItem === themeIndex ?
                                                            expectedCompletionYearMonth(item.themes[lastItem - 1].expectedCompletionYearMonth, item.themes[lastItem].expectedCompletionYearMonth, themeItem.name, themeIndex)
                                                            :
                                                            expectedCompletionYearMonth(item.themes[themeIndex - 1].expectedCompletionYearMonth, themeItem.expectedCompletionYearMonth, themeItem.name, themeIndex)
                                                )
                                            })
                                        }
                                        {joinDate !== null && lastTheme !== undefined && graduate(lastTheme.expectedCompletionYearMonth)}
                                    </tr>
                                )
                            })
                    }
                </tbody>


            </table>
        </div >
    );
};

const dispatchToProps = dispatch => {
    return {
        setRecruiter: (recruiter) => {
            dispatch(setRecruiter(recruiter));
        },
        setRecruiterId: (recruiter_id) => {
            dispatch(setRecruiterId(recruiter_id));
        },
        setSelectedCompanyCode: (company) => {
            dispatch(setSelectedCompanyCode(company));
        },
        setRecruiterForDetail: (recruiter_detail) => {
            dispatch(setRecruiterForDetail(recruiter_detail));
        },
        setSelectedCompanyForDetail: (company_at_recruiter_detail) => {
            dispatch(setSelectedCompanyForDetail(company_at_recruiter_detail));
        }
    }
}
export default connect(null, dispatchToProps)(LearningthemeTable);