import React, { useState, useEffect } from 'react';
import listStyle from './style.module.css';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import checkStyle from './style.module.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import GeneralButton from '../../components/Buttons/GeneralButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import criteria_option from '../../utils/mapState'
import { connect } from 'react-redux';
import './ListOfScenerio.less'
import { v4 as uuidv4 } from 'uuid';
import { updateHumanEval } from '../../api/api'

const ListOfSecnerio = ({ className, currentSection, ScenarioList, rolePlayId, themeId, scenarioId, title }) => {

    const [index, setIndex] = useState('')

    useEffect(() => {
    }, [index])
    const handleHumanEval = (e, criteria) => {
        criteria.humanEval = parseInt(e.target.value)
        let param = {
            rolePlayId: rolePlayId,
            themeId: themeId,
            scenarioId: scenarioId,
            sectionId: criteria.sectionId,
            criteriaId: criteria.id,
            humanEval: criteria.humanEval
        }
        updateHumanEval([param]).then(res => {
            console.log(ScenarioList)
            setIndex(uuidv4())
        })
    }

    return (
        <div className="list_of_scenario">
            {
                ScenarioList && ScenarioList.map((value, index) => (
                    <div className={`mb-2 ${listStyle.list_box_inner}`} key={index} id={`${value.id}_${index}`}>
                        <div className={listStyle.box}>
                            <Accordion className="section_collapse" disabled={value.sectionStatus !== 0}>
                                <AccordionSummary
                                    expandIcon={value.sectionStatus === 0 ? <ExpandMoreIcon /> : ''}
                                    aria-controls="panel1a-content"
                                    id={`list_of_scenario_header_${index}`}
                                >
                                    <span id={`list_of_scenario_section_${index}`}><Typography className={`${(value.sectionStatus !== 0) ? listStyle.waiting : listStyle.progess}`}>{value.section}</Typography></span>
                                    <span id={`list_of_scenario_sectionBtn_${index}`}>
                                        <div className={` ${(value.sectionStatus !== 0) ? listStyle.active : listStyle.inactive} `}>
                                            {
                                                value.sectionStatus === 1 ?
                                                    <Button
                                                        id="underscoring"
                                                        className={listStyle.mate_btn}
                                                        variant="contained"
                                                        color="primary"
                                                    >{"採点中"}</Button> :
                                                    value.sectionStatus === 2 ?
                                                        <Button
                                                            id="inprogress"
                                                            className={listStyle.mate_btn_primary}
                                                            variant="contained"
                                                            color="primary"
                                                        >{"実施中"}</Button> :
                                                        <Button
                                                            id="complete"
                                                            className={listStyle.mate_btn}
                                                            variant="contained"
                                                            color="primary"
                                                            startIcon={<DoneIcon />}
                                                        >{"完了"}</Button>
                                            }
                                        </div>
                                    </span>
                                </AccordionSummary>
                                {value.sectionStatus === 0 ?
                                    <AccordionDetails>
                                        <Typography>
                                            {
                                                value.criteria && value.criteria.map((value, index) => (
                                                    <div className={`mb-3 p-3 cmn-bg-box-inr ${checkStyle.criterial_box} ${checkStyle.border_radious_6} ${value.requiredFlag && checkStyle.required} ${(value.name === '10分以内にロープレが完了している' || value.name === '15分以内にロープレが完了している') ? checkStyle.height : ''} `} key={index} id={`${value.id}_${index}`}>
                                                        <div className={checkStyle.box}>
                                                            <div className="text-align-center font-12 mb-0" id={`section_list_${value.id}`}>{value.name}</div>
                                                            {(value.name !== '10分以内にロープレが完了している' && value.name !== '15分以内にロープレが完了している') &&
                                                                <div className="d-flex">
                                                                    <GeneralButton
                                                                        title={criteria_option[0].name}
                                                                        value={criteria_option[0].id}
                                                                        className={`mr-2 ${checkStyle.button} ${checkStyle.failed_btn} ${criteria_option[0].id === value.humanEval ? checkStyle.btn_checked : checkStyle.btn_not_checked}`}
                                                                        onClick={(e) => handleHumanEval(e, value)}
                                                                        id={`section_list_${value.id}_${criteria_option[0].id}`}
                                                                    />
                                                                    <GeneralButton
                                                                        title={criteria_option[1].name}
                                                                        value={criteria_option[1].id}
                                                                        className={` ${checkStyle.button} ${criteria_option[1].id === value.humanEval ? checkStyle.btn_checked : checkStyle.btn_not_checked}`}
                                                                        onClick={(e) => handleHumanEval(e, value)}
                                                                        id={`section_list_${value.id}_${criteria_option[1].id}`}
                                                                    />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </Typography>
                                    </AccordionDetails> : ''}
                            </Accordion>
                            {/* <p className={`text-align-center font-12 mb-0 ${(value.sectionStatus !== 0) ? listStyle.progess : listStyle.waiting} `}>{value.section}</p>
                            <div className={` ${(value.sectionStatus !== 0) ? listStyle.active : listStyle.inactive} `}>
                                {
                                    value.sectionStatus === 1 ?
                                    <Button
                                        id="underscoring"
                                        className={listStyle.mate_btn}
                                        variant="contained"
                                        color="primary"
                                    >{"採点中"}</Button> :
                                    value.sectionStatus === 2 ?
                                    <Button
                                        id="inprogress"
                                        className={listStyle.mate_btn_primary}
                                        variant="contained"
                                        color="primary"
                                    >{"実施中"}</Button> :
                                    <Button
                                        id="complete"
                                        className={listStyle.mate_btn}
                                        variant="contained"
                                        color="primary"
                                        startIcon={<DoneIcon />}
                                    >{"完了"}</Button>
                                }
                            </div> */}
                        </div>
                    </div>
                ))
            }
        </div>
    )

}

const mapStateToProps = state => {
    return {
        rolePlayId: state.startRolePlay.rolePlayId,
        themeId: state.startRolePlay.themeId,
        scenarioId: state.startRolePlay.scenarioId,
    }
}

export default connect(mapStateToProps, null)(ListOfSecnerio);