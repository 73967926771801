import React from 'react';


import styles from './styles.module.css';

const GeneralButton02 = ({ title, className, style, onClick,id, disabled}) => {

    return (
        <button className={`px-5 ${styles.cmn_btn02} ${className}`} style={style} onClick={onClick} id={id} disabled={disabled}>
            {title}
        </button>
    )
}

export default GeneralButton02;