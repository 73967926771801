const PTOPTable = ({ptpData,passFailEvil}) => {
    // delete ptpData.result;
    const data = [];
    Object.entries(ptpData).map(([key, val])=>{
        let item = new Object();
        if(key !== 'result'){
            item['name'] = key;
            item['value'] = val;
            data.push(item)
        }
    })
    return (
        data.length > 0?
        <span className={`description-text ptp-table cmn-description passfailevil`}>
            <table className="cmn-table">
              <tbody>
                    {
                        data.map((value,key) => {
                            return(
                                <tr key={`${key}`}>
                                    <th>{value.name}</th>
                                    <td className="text-center">{passFailEvil(value.value)}</td>
                                </tr>
                            )
                        })
                    }
              </tbody>
            </table>
        </span>
        :""
    )
}
export default PTOPTable;