import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { isAflacMemberOrBranchManagerOrTrainerManager, isSystemOwner, isTrainee } from '../../../configs/constants';
import styles from './styles.module.css'

const Tab = ({ className, visibleTab, setVisibleTab, tabList }) => {
    const { t } = useTranslation();
    const [isPermission, setIsPermission] = useState(true);
    const [categoryList, setCategoryList] = useState();

    const category = [
        {
            id: 1,
            name: "育成計画",
        },
        {
            id: 2,
            name: "実績",
        }
    ]
    useEffect(() => {
        tabList ? setCategoryList(tabList) : setCategoryList(category)
    }, [])

    let lastId = 0;
    const autoId = (prefix = 'tab_') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    useEffect(() => {
        if (isAflacMemberOrBranchManagerOrTrainerManager()|| isSystemOwner() || isTrainee()) {
            setIsPermission(true);
        } else {
            setIsPermission(false);
        }
        visibleTab === undefined && setVisibleTab(category[1].id)

    }, [])

    return (
        <div className={`${styles.tabs} ${className} `}>
            <ul className={`${styles.tabs_titles}`}>
                {categoryList && categoryList.map((item) => (
                    <li key={item.id}
                        onClick={isPermission ? () => setVisibleTab(item.id) : () => { }}
                        className={` ${styles.tab_title} ${visibleTab === item.id && styles.tab_title__active} }`}
                        id={autoId()}
                        name={`tab_${item.id}`}>
                        {item.name}
                    </li>
                ))
                }
            </ul>
        </div>
    )
}
export default Tab;
