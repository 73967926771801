import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Sidebar from './../../components/Sidebar';
import LogoutButton from './../../components/Buttons/LogoutButton';
import styles from './styles.module.css';
import { useHistory } from "react-router-dom";
import { setRecentPath } from '../../store/actions'
import Tab from '../../components/Tabs/ThemesTab'
import BackButton from '../../components/Buttons/BackButton';
import EditCriteria from './EditCriteria';
import AllCriteria from './AllCriteria';
import { GeneralDropdown } from './../../components/Dropdowns/GeneralDropdown';
import { getThemeList, getUserList, getCheckCriteriaList, updateCheckCriteriaList } from '../../api/api';
import { longestString, getLastString, split } from '../../utils/General'
import ConfirmDialog from '../../components/Dialog/ConfirmDialog'
import EventBus from '../../components/EventBus'
import { COMPANY_CODES_URL } from "../../configs/urlConstants";
import { isTrainerManagerOrTrainee } from '../../configs/constants'
import { isApigwEnv } from '../../utils/General'

const EditCheckCriteriaPage = ({ access_token, isOpen, location, setRecentPath }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [visibleTab, setVisibleTab] = useState();
    const [selected, setSelected] = useState();
    const [editState, setEditState] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [allKeywords, setAllKeywords] = useState();
    const [keywords, setKeywords] = useState();
    const [learningThemes, setLearningThemes] = useState();
    const [scenarioByTheme, setScenarioByTheme] = useState();
    const [dataToUpdate, setDataToUpdate] = useState([])
    const [status, setStatus] = useState(false)
    const [ASCode, setASCode] = useState();
    const [selectedASCode, setSelectedASCode] = useState();
    const [longest, setLogest] = useState();
    const [refresh, setRefresh] = useState(true)
    const [reset, makeAllReset] = useState(false)

     let newArray = [];
    
    useEffect(() => {
        getThemeList().then(res => {
            setLearningThemes(res.data.map(item => { return { id: item.id, name: item.name, isActive: item.isActive } }))
        })

        getUserList(COMPANY_CODES_URL).then(res => {
            setASCode(res.data.sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            let longestName = longestString(res.data.map(item => { return { name: item.name } }))
            setLogest(longestName < 15 ? 4 : longestName >= 15 ? 5 : longestName >= 20 ? 6 : longestName >= 25 ? 7 : 8)
        })
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);
    }, []);

    useEffect(() => {
        selectedASCode && getCheckCriteriaList(selectedASCode.id).then(res => {
            if (res.data.length > 0) {
                setAllKeywords(undefined)
                setAllKeywords(res.data)
                let firstIndex = res.data[Object.keys(res.data)[0]]
                setScenarioByTheme(firstIndex.scenario.map(item => { return { id: item.id, name: item.name } }))
                learningThemes && setVisibleTab(learningThemes[0].id)
            }
        })
        setEditState(false)
    }, [selectedASCode])

    useEffect(() => {
        selectedASCode && getCheckCriteriaList(selectedASCode.id).then(res => {
            if (res.data.length > 0) {
                setAllKeywords(undefined)
                setAllKeywords(res.data)
            }
        })
    }, [refresh, status])

    useEffect(() => {
        if (allKeywords !== undefined) {
            let index = allKeywords.map(e => e.id).indexOf(visibleTab);
            let currentIndex = allKeywords[Object.keys(allKeywords)[index]]
            setScenarioByTheme(currentIndex.scenario.map(item => { return { id: item.id, name: item.name } }))
        }
        // setEditState(false)
    }, [visibleTab, allKeywords])

    useEffect(() => {
        setEditState(false)
    }, [visibleTab])

    useEffect(() => {
        if (selected !== undefined && visibleTab !== undefined && allKeywords !== undefined) {
            let currentSection = selected && visibleTab && allKeywords && allKeywords
                .filter(item => item.id === visibleTab)[0].scenario
                .filter(value => value.id === selected.id)
            let editedKeywords = currentSection && currentSection.map(data => {
                return {
                    id: data.id,
                    name: data.name,
                    sections: data.sections.map(
                        items => {
                            return {
                                sectionId: items.sectionId,
                                sectionName: items.sectionName,
                                displayCategory: items.displayCategory,
                                criteria: items.criteria.filter(item => item.deletedFlag === false).sort((a, b) => parseInt(a.sortingOrder) > parseInt(b.sortingOrder) ? 1 : -1)
                                    .map(item => {
                                        return {
                                            id: item.id,
                                            name: item.name,
                                            deletedFlag: item.deletedFlag,
                                            checkedFlag: item.checkedFlag,
                                            requiredFlag: item.requiredFlag,
                                            newlyAddFlag: item.newlyAddFlag,
                                            sortingOrder: item.sortingOrder,
                                            newlyDeleteFlag: false,
                                            modifyFlag: false
                                        }
                                    })
                            }
                        }
                    )
                }
            })
            if (currentSection !== undefined || currentSection !== []) {
                currentSection && setKeywords(editedKeywords)
            }
        }
    }, [selected, allKeywords, status])

    const onBack = () => {
        setRefresh(!refresh)
        setEditState(false);
    }
    const onEditScenerio = () => {
        setEditState(true);
    }

    const onApply = () => {
        let count = 0;
        if (keywords) {
            for (let i = 0; i < keywords[0].sections.length; i++) {
                let checkedCount = 0
                for (let j = 0; j < keywords[0].sections[i].criteria.length; j++) {
                    if (keywords[0].sections[i].criteria[j].modifyFlag) {
                        count++
                    }
                    if (keywords[0].sections[i].criteria[j].checkedFlag) {
                        checkedCount++
                    }
                }
                if (checkedCount === 0) {
                    setConfirmOpen(false);
                    EventBus.dispatch('show_snack', { message: '最低1チェック基準を選択してください', type: 'error' })
                    return
                }
            }
        }
        // keywords && keywords.map(data => {
        //     data.sections.map(items => {
        //         let checkedCount = 0
        //         items.criteria.map(item => {
        //             if (item.modifyFlag) {
        //                 count++
        //             }
        //             if (item.checkedFlag) {
        //                 checkedCount++
        //             }
        //         })
        //     })
        // })
        if (count === 0) {
            setConfirmOpen(false);
            EventBus.dispatch('show_snack', { message: '更新データがありません', type: 'error' })
        }
        else {
            setConfirmOpen(true);
        }
    }

    useEffect(() => {
        confirmOpen && keywords && keywords.map(data => {
            data.sections.map(items => {
                items.criteria.filter(item => item.deletedFlag === false).map(item => {
                    newArray.push({
                        agentCompanyCode: selectedASCode.id,
                        themeId: visibleTab,
                        scenarioId: selected.id,
                        scenarioName: selected.name,
                        sectionId: items.sectionId,
                        sectionName: items.sectionName,
                        criteriaName: item.name,
                        displayCat: items.displayCategory,
                        deleteFlag: item.newlyDeleteFlag,
                        selectFlag: item.checkedFlag,
                        requiredFlag: item.requiredFlag,
                        newlyAddFlag: item.newlyAddFlag,
                        sortingOrder: item.sortingOrder
                    })
                })
            })
        })
        setDataToUpdate(newArray && newArray)
    }, [confirmOpen])

    const saveEditedCriteria = () => {
        if (dataToUpdate.length > 0) {
            updateCheckCriteriaList(dataToUpdate).then((res) => {
                setEditState(false);
                setStatus(!status)
                EventBus.dispatch('show_snack', { message: "操作成功", type: 'success' })
            }).catch(err => {
                setStatus(!status)
                EventBus.dispatch('show_snack', { message: "失敗しました", type: 'error' })
            })
        }

    }

    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr edit_setting_page" id="edit_check_criteria">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="edit_check_criteria_title">
                                {t('edit check criteria.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout_btn" reset={reset} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12">
                            <Row className="mb-4">
                            <Col lg={`${isOpen ? longest + 1 : longest} `} className={`${styles.AS_code_dropdown}`}>
                                    <div className="cmn-bg-box-inr p-3" id='AS_code_dropdown'>
                                        <label className="mb-2 font-weight-bold" id="AS_code">AS コード</label>
                                        <GeneralDropdown
                                            isStatic={true}
                                            staticData={ASCode}
                                            onSelect={(value) => setSelectedASCode({ id: value.value, name: value.textContent })}
                                            dropdown_id="edit_check_criteria_dropdown"
                                            placeholder={t('general.text.theme select')} />
                                    </div>
                                </Col>
                            </Row>
                            {selectedASCode && <div className="cmn-bg-box">
                                <p className="mb-4 font-weight-bold" id="selected_company">アソシエイツ名：{getLastString(selectedASCode.name)} </p>
                                <Row>
                                    <Col xl="10" lg="12" className="order-xl-0 order-1">
                                        <div className="cmn-bg-box-inr  py-4 mb-32">
                                            <Tab className="col-lg-12"
                                                visibleTab={visibleTab}
                                                setVisibleTab={setVisibleTab}
                                                selected={selected}
                                                setSelected={setSelected}
                                                learningThemes={learningThemes}
                                                scenarioByTheme={scenarioByTheme}
                                            />
                                        </div>
                                        {confirmOpen && <ConfirmDialog
                                            title={t('general.text.do_you_want_to_save')}
                                            open={confirmOpen}
                                            setOpen={setConfirmOpen}
                                            onConfirm={saveEditedCriteria}
                                        ></ConfirmDialog>}
                                        {
                                            !editState ? <AllCriteria onEditScenerio={onEditScenerio} keywords={keywords} setKeywords={setKeywords} /> : <EditCriteria onBack={onBack} keywords={keywords} setKeywords={setKeywords} onApply={onApply} />
                                        }
                                    </Col>
                                    <Col xl="2" className={`back-btn-box text-right`}>
                                        <BackButton id={`go_back_btn`} className="back-btn mb-3" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>
                            </div>}

                        </Col>
                    </Row>

                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(EditCheckCriteriaPage);