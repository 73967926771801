import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";
import Sidebar from './../../components/Sidebar';
import LogoutButton from './../../components/Buttons/LogoutButton';
import { setRecentPath } from '../../store/actions'
import Tab from '../../components/Tabs/ThemesTab';
import Accordian from './accordian';
import BackButton from './../../components/Buttons/BackButton';
import { getLearningThemesForAnalysis, getKeywordsForAnalysis } from '../../api/api';
import { isAflacMemberOrBranchManagerOrTrainerManagerOrSystemOwner } from '../../configs/constants'
import styles from './styles.module.css'
import { isApigwEnv, split } from '../../utils/General'

const KeywordAccuracyPage = ({ access_token, isOpen, location, setRecentPath }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [visibleTab, setVisibleTab] = useState();
    const [selected, setSelected] = useState();
    const [allKeywords, setAllKeywords] = useState();
    const [keywords, setKeywords] = useState();
    const [learningThemes, setLearningThemes] = useState();
    const [scenarioByTheme, setScenarioByTheme] = useState();
    const [reset, makeAllReset] = useState(false)

    useEffect(() => {
        getLearningThemesForAnalysis().then(res => {
            setLearningThemes(res.data.map(item => { return { id: item.id, name: item.name, isActive: item.isActive, avgTalkListenRatio: item.avgTalkListenRatio, avgSpeechSpeed: item.avgSpeechSpeed } }))
        })

        getKeywordsForAnalysis().then(res => {
            setAllKeywords(res.data)
        })
        window.scrollTo(0, 0)

        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (!isAflacMemberOrBranchManagerOrTrainerManagerOrSystemOwner()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);
    }, []);

    useEffect(() => {
        if (allKeywords !== undefined) {
            let index = allKeywords.map(e => e.id).indexOf(visibleTab);
            let currentIndex = allKeywords[Object.keys(allKeywords)[index]]
            setScenarioByTheme(currentIndex && currentIndex.scenarios.map(item => { return { id: item.id, name: item.name } }))
        }
    }, [visibleTab, allKeywords])

    useEffect(() => {
        if (selected !== undefined && visibleTab !== undefined && allKeywords !== undefined) {
            let currentSection = selected && visibleTab && allKeywords && allKeywords
                .filter(item => item.id === visibleTab)[0].scenarios
                .filter(value => value.id === selected.id)
            let editedKeywords = currentSection && currentSection.map(data => {
                return {
                    id: data.id,
                    name: data.name,
                    sections: data.sections.map(
                        items => {
                            return {
                                id: items.id,
                                name: items.name,
                                criteria: items.criteria.map(item => {
                                    let keywords = (item.aiScoringFlag) ? 'AIは採点しない' : split(item.keywords.keywords)
                                    if (keywords === 'AIは採点しない') {
                                        return {
                                            id: item.id,
                                            name: item.name,
                                            aiScoringFlag: item.aiScoringFlag,
                                            keywords: [{
                                                id: item.id,
                                                name: keywords,
                                                percentage: '-'
                                            }]
                                        }

                                    } else {
                                        return {
                                            id: item.id,
                                            name: item.name,
                                            aiScoringFlag: item.aiScoringFlag,
                                            keywords: keywords.map((data, index) => {
                                                let splitedKeywords = split(data, '-');
                                                return {
                                                    id: index + 1,
                                                    name: splitedKeywords && splitedKeywords[0],
                                                    percentage: splitedKeywords && splitedKeywords[1]
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        }
                    )
                }
            })
            setKeywords(editedKeywords)
        }
    }, [selected, allKeywords])

    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr edit_setting_page">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="keyword_accuracy_title">
                                {t('keyword used percentage.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout_btn" reset={reset} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12">
                            <div className="cmn-bg-box">
                                <Row>
                                    <Col xl="10" lg="12" className="order-xl-0 order-1" id="keyword_accuracy_themes">
                                        <div className="cmn-bg-box-inr p-32 mb-32" id="keyword_accuracy_section">
                                            <Tab className="col-lg-12"
                                                visibleTab={visibleTab}
                                                setVisibleTab={setVisibleTab}
                                                selected={selected}
                                                setSelected={setSelected}
                                                learningThemes={learningThemes}
                                                scenarioByTheme={scenarioByTheme}
                                            />
                                        </div>
                                        <div className="cmn-bg-box-inr p-32 keyword-accuracy-sec" id="keyword_accuracy_section">
                                            <Row className={`${styles.keyword_sec}`}>
                                                <Col lg={9}>
                                                    <div className={`keywords-accuracy pl-2 pr-4 ${styles.keyword_accuracy}`} id="keyword_accuracy_labels">
                                                        <label id="keyword_accuracy_listen_talk_label" className={`mb-0 col-3 label-box font-weight-bold ${styles.label}`} > {t('keyword used percentage.average talk/listen rate')} </label>
                                                        <label id="keyword_accuracy_listen_talk_rate" className={`mb-0 col-3 label-box border-right-0 ${styles.label_info}`}>  {learningThemes !== undefined && visibleTab !== undefined && learningThemes.filter(theme => theme.id === visibleTab)[0].avgTalkListenRatio} </label>
                                                        <label id="keyword_accuracy_characters_second_label" className={`mb-0 col-3 label-box font-weight-bold ${styles.label}`}>  {t('keyword used percentage.characters/second')} </label>
                                                        <label id="keyword_accuracy_characters_second_rate" className={`mb-0 col-3 label-box> ${styles.label_info}`}>  {learningThemes !== undefined && visibleTab !== undefined && learningThemes.filter(theme => theme.id === visibleTab)[0].avgSpeechSpeed} </label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Accordian keywords={keywords} />
                                        </div>
                                    </Col>
                                    <Col xl="2" className={`back-btn-box  text-right`} id="go_back_section">
                                        <BackButton id={`go_back_btn`} className="back-btn mb-3" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(KeywordAccuracyPage);