const initialState = {
  aiScoreResultList: [],
  totalPercent: 0,
  totalPassCount: 0,
  totalCount: 0,
  totalDurTime: 0,
  totalTalk: '',
  utterTime: '',
  utterRate: '',
  listenTime: '',
  averageSpeed: '',
  evaluation: '',
  goodPoint: '',
  improvePoint: '',
  crossMatter: '',
  feedBack: '',
  traineeFeedBackChat: '',
  trainerFeedBackChat: '',
  guestFeedbackChat: '',
  interruptTimes: '',
  silenceTimes: '',
  lowerConversationTimes: ''
}
const aiResultScore = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SET_AI_SCORE_RESULT":
      return { ...state, ...action.paramData }
    case "RESET_AI_SCORE_RESULT":
      return  initialState; 
    default:
      return state
  }
}

export default aiResultScore