import React from 'react';
import classes from './styles.module.css';

const FinishButton = ({ onClick, title, className, style, id}) => {

    return (
        <button className={`${classes.finish_button} ${className}`} style={style} onClick={onClick} id={id} >
            <p id={`${id}_text`}>{title}</p>
        </button>
    )
}

export default FinishButton;