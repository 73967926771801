import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import { useHistory } from "react-router-dom";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Sidebar from '../../components/Sidebar';
import ConfirmDialog from '../../components/Dialog/ConfirmDialog';
import GeneralButton from '../../components/Buttons/GeneralButton';
import LogoutButton from '../../components/Buttons/LogoutButton';
import Radio from '../../components/RadioButtons';
import BackButton from './../../components/Buttons/BackButton';
import { GeneralDropdown } from './../../components/Dropdowns/GeneralDropdown';
import EventBus from '../../components/EventBus';
import PatternSection from './PatternSection'
import Pattern from '../../staticData/Pattern.json';
import staticLearningThemeData from '../../staticData/EditLearningTheme.json';
import { setRecentPath, setSelectedCompanyCodeAtEditLearningTheme } from '../../store/actions';
import { getThemeList, getUserList, getLearningThemePattern, updatePatterns } from '../../api/api';
import { longestString, getASCodeCompany } from '../../utils/General'
import './styles.css'
import { COMPANY_CODES_URL } from "../../configs/urlConstants";
import { isTrainerManagerOrTrainee } from '../../configs/constants'
import { isApigwEnv } from '../../utils/General'

const EditLearningTheme = ({ access_token, isOpen, location, setRecentPath, setSelectedCompanyCodeAtEditLearningTheme }) => {
    const history = useHistory();
    const { t } = useTranslation();

    const [onASCode, setAsCode] = useState();
    const [asCodeData, setAsCodeData] = useState();
    const [selectedASCode, setSelectedASCode] = useState();
    const [longest, setLogest] = useState();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmOpenContinue, setConfirmOpenContinue] = useState(false);
    const [confirmRedirect, setConfirmRedirect] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [checkItem, setCheckItem] = useState(1);
    const [editState, setEditState] = useState(false);
    const [learningTheme, setLearningTheme] = useState([]);
    const [agentCompanyCode, setAgentCompanyCode] = useState();
    const [learningThemePattern, setLearningThemePattern] = useState({});
    const [currentPatternData, setCurrentPatternData] = useState([]);
    const [selectedThemeOrderIds, setSelectedThemeOrderIds] = useState([]);
    const noData = <tr className={styles.no_data}><td colSpan="100%">{t('general.text.no data available')}</td></tr>;
    const [reset, makeAllReset] = useState(false)

    let lastId = 0;
    const autoId = (prefix = 'elt-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        getThemeList().then(res => {
            setLearningTheme(res.data);
        })
    }, [])
    useEffect(() => {
        getUserList(COMPANY_CODES_URL).then(res => {
            setAsCodeData(res.data.sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            let longestName = longestString(res.data.map(item => { return { name: item.name } }))
            setLogest(longestName < 15 ? 4 : longestName >= 15 ? 5 : longestName >= 20 ? 6 : longestName >= 25 ? 7 : 8)
        })
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);
    }, []);
    useEffect(() => {
        if (agentCompanyCode !== undefined) {
            getLearningThemePattern(agentCompanyCode).then(res => {
                setLearningThemePattern(res.data);
                var filterLearningThemePattern = res.data.patterns.filter((item) => item.id === res.data.selectedPatternId);
                setCurrentPatternData([...filterLearningThemePattern[0].themes])
                setCheckItem(res.data.selectedPatternId)
                let data = [];
                filterLearningThemePattern[0].themes.map(item =>{
                    data.push({
                        deleteFlagOrg : item.deleteFlag,
                        descriptionOrg: item.description,
                        idOrg: item.id,
                        isActiveOrg: item.isActive,
                        nameOrg: item.name,
                        sortingOrderOrg: item.sortingOrder,
                        targetPeriodOrg: item.targetPeriod,
                        targetTimeOrg: item.targetTime,

                    })
                })
                setSelectedThemeOrderIds(data);
            })
        }
    }, [agentCompanyCode, refresh]);

    const openDialog = () => {
        let checkResult = [];
        var checkDuplicate = false;
        if (selectedThemeOrderIds.length === currentPatternData.length) {
            for (let index = 0; index < selectedThemeOrderIds.length; index++) {
                if(
                    selectedThemeOrderIds[index].deleteFlagOrg === currentPatternData[index].deleteFlag
                    && selectedThemeOrderIds[index].idOrg === currentPatternData[index].id
                    && selectedThemeOrderIds[index].nameOrg === currentPatternData[index].name
                    && selectedThemeOrderIds[index].sortingOrderOrg === currentPatternData[index].sortingOrder
                    && selectedThemeOrderIds[index].targetPeriodOrg === currentPatternData[index].targetPeriod
                    && selectedThemeOrderIds[index].targetTimeOrg === currentPatternData[index].targetTime
                ){
                    checkResult.push(true)
                }else{
                    checkResult.push(false)
                }
            }
            checkDuplicate = checkResult.every(item => item === true);
        }
        var filterSelectedPattern = learningThemePattern.patterns.filter((item) => item.id === checkItem);
        var checkDeleteFlag = filterSelectedPattern[0].themes.every(item => item.deleteFlag === true);
        if (checkDeleteFlag === true) {
            EventBus.dispatch('show_snack', { message: "最低1つの学習テーマを選択してください。", type: 'error' });
        } else if(checkDuplicate) {
            EventBus.dispatch('show_snack', { message: "更新データがありません", type: 'error' });
        }else{
            setConfirmOpen(true);
        }

    }

    const saveLearningTheme = () => {
        updatePatterns(learningThemePattern).then(res => {
            if (res.status === 200) {
                EventBus.dispatch('show_snack', { message: res.data.message, type: 'success' })
                setRefresh(!refresh)
                setEditState(false);
                setConfirmRedirect(true);
            }
        })
            .catch(err => {
                if (err.response.status === 400) {
                    EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' });
                }
                else {
                    EventBus.dispatch('show_snack', { message: '問題が発生しています', type: 'error' });
                }
            })

    }
    const handleEditState = () => {
        setConfirmOpenContinue(true);
    }
    const onBack = () => {
        setRefresh(!refresh)
        setEditState(false);
    }
    const handleChange = (event) => {
        var value = parseInt(event.target.value);
        setCheckItem(value)
        setLearningThemePattern({ ...learningThemePattern, selectedPatternId: value })
        var filterLearningThemePattern = learningThemePattern.patterns.filter((item) => item.id === value);
        setCurrentPatternData(filterLearningThemePattern[0].themes)
    }
    const handleCompanyName = (el) => {
        setAsCode(getASCodeCompany(el)[1])
        setAgentCompanyCode(getASCodeCompany(el)[0])        
    }
    const ContinueEdit = () => {
        setEditState(true);
    }
    const RedirectTo = () => {
        history.push("/TrainingPlan");
        setSelectedCompanyCodeAtEditLearningTheme({id: agentCompanyCode, name: agentCompanyCode +'/'+ onASCode})
    }
    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr training-plan" id="training-plan">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="edit_learning_theme_title">
                                {t('EditLearningTheme.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id={autoId()} reset={reset} />
                        </Col>
                    </Row>

                    <Row className="mb-4">
                        <Col lg={`${longest} `}>
                            <div className="cmn-bg-box-inr">
                                <label className="mb-2 font-weight-bold" id="as_code_label">AS コード</label>
                                <GeneralDropdown
                                    isStatic={true}
                                    staticData={asCodeData}
                                    onSelect={(el) => handleCompanyName(el.textContent)}
                                    url={COMPANY_CODES_URL}
                                    selectedData={selectedASCode && selectedASCode.name}
                                    placeholder="選択してください"
                                    dropdown_id="elt_ascode_dropdown" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="cmn-bg-box">
                                <Row className="align-items-center">
                                    <Col className="mb-4">
                                        <h5 className="font-14 mb-0" id="associate_code">{t('EditLearningTheme.Associates Name')} : {onASCode}</h5>
                                    </Col>
                                    <Col className="mb-4 text-right">
                                        <BackButton id={autoId()} className="back-btn" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col lg="12">
                                        <div className="cmn-bg-box-inr">
                                            <h5 className="font-weight-bold font-14" id="explanation_of_learning_theme">{t('EditLearningTheme.Explanation of learning Theme')}</h5>
                                            <div className="table-responsive">
                                                <table id={autoId()} className="table cmn-table">
                                                    <thead>
                                                        <tr id={autoId()} className="">
                                                            <th id={autoId()}>{t('EditLearningTheme.Learning theme')}</th>
                                                            <th id={autoId()}>{t('EditLearningTheme.Description')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            learningTheme.length === 0 ? noData :
                                                                learningTheme.map((theme, index) => {
                                                                    return (
                                                                        <tr id={autoId()} key={index}>
                                                                            <td>{theme.name}</td>
                                                                            <td>
                                                                                {theme.description}
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        {
                                            (agentCompanyCode !== undefined) ?
                                                <div className="cmn-bg-box-inr">
                                                    {
                                                        editState ?
                                                            <>
                                                                <BackButton id={autoId()} className="back-btn mr-3" title={t('general.button.return')} onClick={onBack} />
                                                                <GeneralButton id={autoId()} className="cmn-btn font-14 mb-32" title={t('general.button.reflect')} onClick={openDialog} />
                                                            </>
                                                            :
                                                            <GeneralButton id={autoId()} onClick={handleEditState} className="cmn-btn font-weight-normal mb-32" title={t('edit keywords.edit')} />

                                                    }
                                                    <ConfirmDialog
                                                        title={t('general.text.do_you_want_to_save')}
                                                        open={confirmOpen}
                                                        setOpen={setConfirmOpen}
                                                        onConfirm={saveLearningTheme}
                                                    ></ConfirmDialog>
                                                    <ConfirmDialog
                                                        title="（学習テーマの編集ボタン（反映ボタン）を押下すると）、育成計画に不整合が生じている可能性があります。学習テーマを変更しますか？"
                                                        open={confirmOpenContinue}
                                                        setOpen={setConfirmOpenContinue}
                                                        onConfirm={ContinueEdit}
                                                    ></ConfirmDialog>
                                                    <ConfirmDialog
                                                        title="育成計画の編集画面へ移動しますか？"
                                                        open={confirmRedirect}
                                                        setOpen={setConfirmRedirect}
                                                        onConfirm={RedirectTo}
                                                    ></ConfirmDialog>
                                                    <h5 className="font-weight-bold font-14 mb-4" id="learning_curriculum">学習カリキュラム</h5>
                                                    <Row lg="12" className="mb-4">
                                                        <label className={`font-weight-bold col-lg-2 col-md-3 col-sm-12 mb-0 pr-md-0 pt_label`}><span id="pattern_label">{t('EditLearningTheme.Pattern')}</span><span>:</span></label>
                                                        <Col lg="10" md="9" sm="12" className="pl-md-4">
                                                            {
                                                                Object.keys(learningThemePattern).length > 0 ?
                                                                    learningThemePattern.patterns.map((item, index) => {
                                                                        return (
                                                                            <FormControlLabel key={index} value={item.id} style={styles.root} disabled={editState || (checkItem === item.id) ? false : true}
                                                                                control={<Radio
                                                                                    checked={checkItem == item.id}
                                                                                    onChange={handleChange}
                                                                                    value={item.id}
                                                                                    name="radio-button"
                                                                                    color="default"
                                                                                    id={autoId()}
                                                                                />} label={<span style={styles.label} className={`MeiryoRegular ${checkItem == item.id ? 'font-weight-bold' : ''}`}>{item.title}</span>} />
                                                                        )
                                                                    })
                                                                    : ""
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <div>
                                                        <PatternSection currentPatternData={currentPatternData} setCurrentPatternData={setCurrentPatternData} editState={editState} />
                                                    </div>
                                                </div>
                                                : ''
                                        }

                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

const styles = {
    root: {
        minWidth: '110px',
        marginRight: '0',
        marginBottom: '8px',
        padding: '0',
        marginLeft: '0',
    },
    label: {
        marginBottom: '0',
        fontSize: '14px',
        fontWeight: 'normal',
        marginRight: '48px',
        marginLeft: '8px',
    }
};
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        agent_id: state.vAgent.agent_id,
        access_token: state.vAgent.access_token
    }
}
const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
        setSelectedCompanyCodeAtEditLearningTheme: (company_at_learning_theme) => {
            dispatch(setSelectedCompanyCodeAtEditLearningTheme(company_at_learning_theme));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(EditLearningTheme);