import React, { useState, useEffect } from 'react';
import { Progress, Row, Col } from 'reactstrap';
import { connect } from 'react-redux';
import { useHistory, NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { TimePicker } from 'antd';
import Sidebar from '../../components/Sidebar';
import History from './History';
import FeedbackSection from './Feedback/FeedbackSection'
import LogoutButton from '../../components/Buttons/LogoutButton'
import BackButton from "../../components/Buttons/BackButton";
import recordingicon from '../../assets/images/recordingicon.png'
import { getAiScoreResultList, getRolePlayDetail } from '../../api/api'
import { formatTime } from '../../utils/DateFormat'
import styles from './styles.module.css';
import { getASCodeCompany, padLeadingZeros } from '../../utils/General'
import { isApigwEnv } from '../../utils/General'

const ResultDetail = (props) => {
    const { isOpen, agent_id, role, access_token, company, recruiter, recruiter_id } = props.vAgent
    const { examId, themeId, scenarioId } = props.startExam
    const { totalPercent, totalPassCount, totalCount, goodPoint, improvePoint, crossMatter,totalTalk } = props.examResultScore
    const { SET_EXAM_SCORE_RESULT, SET_FEEDBACK_GOOD_POINT, SET_FEEDBACK_IMPROVE_POINT, SET_FEEDBACK_CROSS_MATTER } = props
    const { t } = useTranslation();
    const history = useHistory();
    let lastId = 0;
    const [reloadTimes, setReloadTimes] = React.useState(0);

    // const [examId, setExamId] = useState(1)
    // const [themeId, setThemeId] = useState(1)
    // const [scenarioId, setScenarioId] = useState(1)
    const [aiScoreResult, setAiScoreResult] = useState([]);
    const [locationKeys, setLocationKeys] = useState([]);
    var totalTalkResult = formatTime(totalTalk);

    useEffect(() => {
        window.scrollTo(0, 0)
        getRolePlayDetail(examId).then(res => {
            let rolePlayParam = {
                goodPoint: res.data.goodPoint,
                improvePoint: res.data.improvePoint,
                crossMatter: res.data.crossMatter,
                totalTalk: res.data.totalTalk,
            }
            SET_EXAM_SCORE_RESULT(rolePlayParam);
            SET_FEEDBACK_GOOD_POINT(res.data.goodPoint);
            SET_FEEDBACK_IMPROVE_POINT(res.data.improvePoint);
            SET_FEEDBACK_CROSS_MATTER(res.data.crossMatter);

            getAiScoreResultList(examId, themeId, scenarioId).then(res => {
                if (res.data && res.data.length > 0) {
                    setAiScoreResult(res.data)
                    let param = {
                        examScoreResultList: res.data,
                        totalPercent: 0,
                        totalPassCount: 0,
                        totalCount: 0
                    }
                    res.data.forEach(item => {
                        param.totalPassCount += item.passCount
                        param.totalCount += item.totalCount
                    })
                    param.totalPercent = Math.round(param.totalPassCount / param.totalCount * 100)
                    SET_EXAM_SCORE_RESULT(param)
                }
            })
        })
    }, [])
    const autoId = (prefix = 'ai_result-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
    }, []);
    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([location.key])
            }

            if (history.action === 'POP') {
                if (locationKeys[1] === location.key) {
                    setLocationKeys(([_, ...keys]) => keys)

                } else {
                    setLocationKeys((keys) => [location.key, ...keys])

                    history.push('/RecruiterSummaryDetail')

                }
            }
        })
    }, [locationKeys,])
    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr" id="AI-scoring-result">
                    <Row>
                        <Col lg="12" className={`${styles.ai_header_section} row-fluid`}>
                            <h3 className="text-left d-inline align-middle">
                                {t('exam results.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14 float-right'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12">
                            <div className="cmn-bg-box">
                                <Col lg="11">
                                    <Row className="mb-4">
                                        <Col md="7" sm="6">
                                            <p className="mb-4 font-weight-bold">
                                                アソシエイツ名：{company && getASCodeCompany(company[0].name)[1]}
                                                <span className="mx-3 d-md-inline d-none">|</span>募集人名：{recruiter}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Row>
                                    <Col xl="10" className="order-xl-0 order-1">
                                        <div className="cmn-bg-box-inr mb-32">
                                            <p className="mb-4 font-weight-bold font-16">{t('AI score result.score(overall)')}</p>
                                            <table id={autoId()} className="table table-bordered AI-scroe-result-table cmn-table mb-4">
                                                <thead>
                                                    <tr id={autoId()}>
                                                        <th id={autoId()} colSpan="2">{t('AI score result.achievement')}</th>
                                                        <th className="text-center" id={autoId()}>合計時間</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr id={autoId()}>
                                                        <td id={autoId()} className="w-50 align-middle">
                                                            <Progress value={totalPercent} className="no-border-radius styles" />
                                                        </td>
                                                        <td id={autoId()} className="text-center align-middle">{totalPassCount}/{totalCount}({totalPercent}%)</td>
                                                        <td id={autoId()} className="text-center align-middle">{totalTalkResult}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <p className="font-weight-bold">採点(項目別)</p>
                                            <History aiScoreResult={aiScoreResult} role_id={role.id} />
                                        </div>
                                        <FeedbackSection goodPoint={goodPoint} improvePoint={improvePoint} crossMatter={crossMatter} role={role} examId={examId} reloadTimes={reloadTimes} setReloadTimes={setReloadTimes}/>
                                    </Col>
                                    <Col xl="2" className={styles.ai_btn_box}>
                                        <BackButton id={autoId()} className="back-btn font-14 mb-3 w-100" title='募集人別サマリーへ' onClick={() => { history.push('/RecruiterSummaryDetail') }} />
                                        <BackButton id={autoId()} className="back-btn font-14 mb-3" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        vAgent: state.vAgent,
        examResultScore: state.examResultScore,
        startExam: state.startExam,
        agent_id: state.vAgent.agent_id,
        role: state.vAgent.role,
        company: state.vAgent.company,
    }
}

const dispatchToProps = dispatch => ({
    SET_EXAM_SCORE_RESULT: (res) => dispatch({ type: 'SET_EXAM_SCORE_RESULT', paramData: res }),
    SET_FEEDBACK_GOOD_POINT: (res) => dispatch({ type: 'initTranscriptGoodPoint', transcriptGoodPoint: res }),
    SET_FEEDBACK_IMPROVE_POINT: (res) => dispatch({ type: 'initTranscriptImprovePoint', transcriptImprovePoint: res }),
    SET_FEEDBACK_CROSS_MATTER: (res) => dispatch({ type: 'initTranscriptCrossMatter', transcriptCrossMatter: res }),
})

export default connect(stateToProps, dispatchToProps)(ResultDetail);
