import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import YesButton from '../../Buttons/YesButton';
import NoButton from '../../Buttons/NoButton';
import cancel from '../../../assets/images/cancel.png';
import { useTranslation } from 'react-i18next';
import './styles.css'
const ConfirmDialog = (props) => {
  const { open, setOpen, onConfirm, onCancel, className } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      PaperProps={{ className: "confirm_dialog_width" }}
    >
      <DialogTitle id="confirm-dialog" className="p-0 text-right">
        <img src={cancel} alt="Cancel" className="cancel" onClick={() => {
          setOpen(false);
        }} />
      </DialogTitle>
      <DialogContent className={`font-weight-bold font-14 ${className}`}>
        <div>AI採点結果画面に移ります。</div>
        <div>フィードバック内容の自動録音が できます。実行しますか？</div>
      </DialogContent>
      <DialogActions>
        <YesButton id="apply" className="yes_btn font-14 py-3 mb-2" title="自動録音を実行し、フィードバックを開始する" onClick={() => {
          setOpen(false);
          onConfirm();
        }} />
        <NoButton id="cancel" className="no_btn font-14 py-3" title="自動録音を実行せず、フィードバックを開始する" onClick={() => {
          setOpen(false);
          if(typeof onCancel === 'function'){
            onCancel();
          }
        }} />
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
