import React, { useEffect, useState } from 'react';
import StyledCheckbox from '../Checkbox'
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import GeneralTextbox from '../Textboxes/GeneralTextbox'
import { getLastString, minOrMax } from '../../utils/General'
import EventBus from '../../components/EventBus'

const ReorderList = ({ items, keywords, setKeywords, section_id , id}) => {

    const { t } = useTranslation();
    var position = 0, min = '', max = '';
    const [add, setAdd] = useState('');
    const [required, setRequired] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [list, setList] = useState(items);

    let lastId = 0;
    const autoId = (prefix = 'editing-criteria-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    useEffect(() => {
        const newArray = list && keywords && keywords.map(section => {
            section.sections.map(data => {
                if (data.sectionId === section_id) {
                    data.criteria = [...list]
                }
            })
            return section
        })
        newArray && setKeywords(newArray)

        let data = [...list]
        max = minOrMax(data)
        min = minOrMax(data, false)
    }, [list])


    function insertAfter(newElement, referenceElement) {
        referenceElement.parentNode.insertBefore(newElement, referenceElement.nextSibling);
    }

    const onSortUp = (event) => {
        var cCard = event.target.closest('.edit_criteria');
        var tCard = cCard.previousElementSibling;
        var parent = cCard.parentNode;
        var tIndex = getLastString(cCard.getAttribute('id'), '_')
        var cIndex = getLastString(tCard.getAttribute('id'), '_')
        var parent = tCard.parentNode;
        insertAfter(tCard, cCard);
        var id = getLastString(event.target.closest('.edit_criteria').id, '_')
        setList(
            list.map((item, index) => {
                if (parseInt(item.id) === parseInt(cIndex)) {
                    item.sortingOrder = parseInt(cCard.getAttribute('data-position'))
                }
                if (parseInt(item.id) === parseInt(tIndex)) {
                    item.sortingOrder = parseInt(tCard.getAttribute('data-position'))
                }
                item.modifyFlag = true
                return item;
            })
        )
    }
    const onSortDown = (event) => {
        var cCard = event.target.closest('.edit_criteria');
        var tCard = cCard.nextElementSibling;
        var tIndex = getLastString(tCard.id, '_')
        var cIndex = getLastString(cCard.id, '_')
        var parent = tCard.parentNode;
        insertAfter(cCard, tCard);
        var id = getLastString(event.target.closest('.edit_criteria').id, '_')
        setList(
            list.map((item, index) => {
                if (parseInt(item.id) === parseInt(tIndex)) {
                    item.sortingOrder = cCard.getAttribute('data-position')
                }
                if (parseInt(item.id) === parseInt(cIndex)) {
                    item.sortingOrder = tCard.getAttribute('data-position')
                }
                item.modifyFlag = true
                return item;
            })
        )
    }
    const onRequired = (event) => {
        setRequired(required)
        var id = getLastString(event.target.closest('.edit_criteria ').id, '_')
        setList(
            list.map((item, index) => {

                if (parseInt(item.id) === parseInt(id)) {
                    if (item.requiredFlag === '' || !item.requiredFlag) {
                        item.requiredFlag = true
                    }
                    else {
                        item.requiredFlag = false
                    }
                    item.modifyFlag = true
                }
                return item;
            })
        )
    }
    const onAdd = (event) => {
        setAddNew(true)
    }

    const onDone = (event) => {
        let value = add, count = 0;
        for (let i = 0; i < value.length; i++) {
            if (value[i] === ' ') {
                count++;
            }
        }

        if (count !== value.length) {
            let item = list.filter(item => item.name === add);
            if (item.length !== 0) {
                EventBus.dispatch('show_snack', { message: 'チェック基準が既に依存しています。', type: "error" })
            } else {
                EventBus.dispatch('close_snack')
                let data = [...list]
                max = minOrMax(data)
                let id = Math.floor(Math.random() * 10000);
                add.length > 0 && item.length === 0 && setList([
                    ...list,
                    {
                        id: id,
                        name: add.trim(),
                        deletedFlag: false,
                        newlyDeleteFlag: false,
                        checkedFlag: true,
                        requiredFlag: false,
                        newlyAddFlag: true,
                        sortingOrder: max + 1,
                        newId: id,
                        modifyFlag: true

                    }
                ])
            }
            setAdd('')
            setAddNew(false)
        }
        else if (count === value.length) {
            EventBus.dispatch('show_snack', { message: 'キーワードを入力の上、”追加”ボタンを押してください。', type: "error" })
            setAdd('')
            setAddNew(false)
        }
    }

    const onDelete = (event) => {
        var id = getLastString(event.target.closest('.edit_criteria').id, '_')
        const newList = list.map(data => {
            if (data.id == id) {
                if (data.newlyDeleteFlag === '' || !data.newlyDeleteFlag) {
                    data.newlyDeleteFlag = true
                }
                else {
                    data.newlyDeleteFlag = false
                }
                data.modifyFlag = true
            }
            return data;
        })
        setList(newList)
    }

    const onChange = (e) => {
        setList(
            list.map(data => {
                if (data.id == e.target.value) {
                    data.checkedFlag = e.target.checked;
                    data.modifyFlag = true
                }
                return data;
            })
        )
    };


    return (
        <section className={`${styles.section}`} id={`section_item_${section_id}`}>
            <ul id={`section_order_list_${section_id}`}>
                {list.map((item, index) => {
                    { position = position + 1 }
                    return (
                        <div className={`${styles.edit_criteria} mb-3 edit_criteria position_${item.id}`} id={`edit_criteria_${item.id}`} data-position={item.sortingOrder} key={index + '_' + item.id}>
                            <div className={`p-3  ${styles.edit_criteria_checkbox}`}>
                                <StyledCheckbox
                                    checked={item.checkedFlag}
                                    onChange={onChange}
                                    value={item.id} 
                                    id={`${autoId()}-${item.id}`}/>
                            </div>
                            <div className={`w-100 p-3  ${styles.edit_criteria_content} ${item.requiredFlag ? styles.required : !item.newlyDeleteFlag && item.newId ? styles.added : item.newlyDeleteFlag && styles.deleted}`} id={`edit_criteria_content${item.id}`} id={autoId()}>
                                <div className={`${styles.arrow}`} >
                                    <button className={`${styles.triangle} ${styles.up}`} onClick={onSortUp} id={`${autoId()}-${item.id}-up-btn`} ></button>
                                    <button className={`${styles.triangle} ${styles.down}`} onClick={onSortDown} id={`${autoId()}-${item.id}-down-btn`}></button>
                                </div>
                                <span className={`${styles.mandatory} mandatory mx-3 ${item.requiredFlag && styles.delete_btn}`} onClick={onRequired} id={`${autoId()}-${item.id}`}>{t('edit check criteria.mandatory')}</span>
                                {item.newlyAddFlag && <span className={`${styles.mandatory} mandatory mr-3 ${item.newlyDeleteFlag && styles.delete_btn}`} id={`criteria_delete_${position}`} onClick={onDelete} > {item.newlyDeleteFlag ? '取消' : '削除'}</span>}
                                <p className={`m-0 name`} id={`criteria_name_${section_id}_${index}`}>{item.name}</p>
                            </div>
                        </div>
                    )
                })
                }
                <div className={`${styles.add} add mb-4`} id={`edit_criteria_${position}`} data-position={position}>
                    <div className={`p-3  w-100 ${styles.edit_criteria_content}`} id={`${autoId()}-${section_id}`} >
                        {
                            !addNew ? <span className={`${styles.mandatory} mx-3 `} onClick={onAdd} id={`${autoId()}-${section_id}`}>{t('edit check criteria.add')}</span>
                                : <>
                                    <span className={`${styles.mandatory} mx-3 `} onClick={onDone} id={`${autoId()}-${section_id}`}>{t('edit check criteria.done')}</span>
                                    <GeneralTextbox className={styles.edit_criteria_textbox}
                                        onChange={(e) => setAdd(e.target.value)}
                                        autoFocus={true}
                                        id={`${autoId()}-${section_id}-input`}
                                    />
                                </>
                        }

                    </div>
                </div>
            </ul>
        </section>
    )
};

export default ReorderList;
