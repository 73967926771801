import React, { useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import StyledCheckbox from '../../../components/Checkbox';
import { CheckboxDropdown } from '../../../components/Dropdowns/CheckboxDropdown';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import GeneralButton from '../../Buttons/GeneralButton'
import cross_button from '../../../assets/images/cross_button.png'

const ORCard = ({ className, keywords, setKeywords, position, addNew, selectedKeywords, section_id, criteria_id, count, editState }) => {
  const { t } = useTranslation();
  const [checkboxes, setCheckboxes] = useState(selectedKeywords.keyword.filter(item => item.checked === '1'))  // json
  const [status, setStatus] = useState(false)

  useEffect(() => {
    setCheckboxes(selectedKeywords.keyword.filter(item => item.checked === '1'))
  }, [status, keywords])

  let lastId = 0;
  const autoId = (prefix = 'add-condition-') => {
    lastId++;
    return `section-${section_id}-${position}-${prefix}${lastId}`;
  }

  const onChange = (e) => {
    if (editState) {
      let newArray = keywords && keywords.map(section => {
        section.sections.map(data => {
          if (data.id === section_id) {
            data.criteria.map(criteria => {
              if (criteria.id === criteria_id) {
                if (criteria.keywordConditionsJSON && criteria.keywordConditionsJSON.length > 0) {
                  criteria.keywordConditionsJSON.map((item, index) => {
                    if (index === position) {
                      item.keyword.map((keyword, index) => {
                        criteria.modifyFlag = true;
                        if (keyword.name === e.target.value && !e.target.checked) {
                          item.keyword.splice(index, 1);
                          setCheckboxes(item.keyword);
                        }
                      })
                    }
                  })
                }
              }
            })
          }
        })
        return section
      })
      setKeywords(newArray);
    }
  };

  const onDelete = (e) => {
    e.preventDefault();
    const newArray = keywords && keywords.map(section => {
      section.sections.map(data => {
        if (data.id === section_id) {
          data.criteria.map(criteria => {
            if (criteria.id === criteria_id) {
              criteria.modifyFlag = true
              criteria.keywordConditionsJSON.splice(e.currentTarget.value, 1)
              setStatus(!status)
            }
          })
        }
      })
      return section
    })
    setKeywords(newArray);
  };

  const onDropdownSelect = (keywordsData, checkboxesData) => {
    setKeywords(keywordsData);
    setCheckboxes(checkboxesData)
  }

  return (
    <div className={`${styles.keyword_or_box} ${className} keyword-or-box`} id={`keyword_condition_box_${criteria_id}_${position}`}>
      <div className={`${styles.keyword_or_box_up}`}>
        <Row className="align-items-center">
          <Col sm="2"><span className="font-weight-bold">OR</span></Col>
          <Col sm={addNew || count > 1 ? '10' : '8'} className={addNew || count > 1 ? 'd-flex' : 'offset-2'}>
            <CheckboxDropdown
              isStatic={true}
              selectedKeywords={checkboxes}
              staticData={keywords}
              onSelect={onDropdownSelect}
              position={position}
              section_id={section_id}
              criteria_id={criteria_id}
              className={`font-10 recruiter ${styles.recruiter_select}`}
              placeholder={t('edit selection keyword.select a keyword')}
              dropdown_id={autoId()}
              editState={editState} />
            {count > 1 && editState && <GeneralButton id={autoId()} className={`cmn-btn w-auto ml-2 px-3 ${styles.keyword_selection_button}`} title={<img src={cross_button} />} value={position} onClick={onDelete} />}
          </Col>
        </Row>
      </div>

      <div className={`${styles.keyword_or_box_middle}`}>
        <ul className={`${styles.keyword_selection_check}`}>
          {
            checkboxes.length > 0 && checkboxes.map((keyword, index) => {
              return (
                <li className={`${keyword.condition ? 'd-block' : 'd-block'} `} key={index} id={`keyword_item_${position}_${index}`}>
                  <FormControlLabel style={inline_style.root} control={
                    <StyledCheckbox
                      style={inline_style.checkbox}
                      checked={keyword.checked === '1' ? true : false}
                      onChange={onChange}
                      value={keyword.name}
                      name="checkbox-button"
                      id={`checkbox-${position}-${index}`}
                    />
                  } label={<span style={inline_style.label} className={`MeiryoRegular text-center`} >{keyword.name}</span>} />
                </li>
              )
            }
            )}
        </ul>
      </div>
      <div className={`${styles.keyword_or_box_down}`}>
        <Row>
          <Col className="text-right">
            <span id={`total_keyword_item_${position}`}>
              {checkboxes.length + '/' + 10}
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
}
const inline_style = {
  root: {
    margin: 0,
    width: '100%'
  },
  checkbox: {
    padding: 6,
    boxShadow: '0px 4px 10px rgb(0 0 0 / 15%)',
    borderRadius: 0,
    marginRight: 4,
  },
  label: {
    marginButtom: 0,
    fontSize: '12px',
    paddingLeft: 0,
    paddingRight: '4px'
  },

};
export default ORCard;