import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import bookmark_orange from '../../assets/images/bookmark_orange.png'
import Sidebar from '../../components/Sidebar'
import { setRecentPath } from '../../store/actions';
import { isAflacMemberOrBranchManager,isSystemOwner,isTrainerManagerOrTrainee } from '../../configs/constants';
import { COMPANY_CODES_URL, TRAINEE_LIST_BY_AGENT_COMPANY, TRAINER_LIST_BY_AGENT_COMPANY } from '../../configs/urlConstants'
import { longestString } from '../../utils/General'
import LogoutButton from '../../components/Buttons/LogoutButton';
import BackButton from '../../components/Buttons/BackButton';
import EventBus from '../../components/EventBus'
import { getUserList, getRolePlayFavorite,checkFavoriteRolePlayExist,checkRolePlayExist } from '../../api/api';
import FavoriteTableList from './FavoriteTableList';
import { isApigwEnv } from '../../utils/General'
import { MultiselectDropdown } from '../../components/Dropdowns/MultiSelectDropdown';

import './styles.css'
const FavoriteList = ({ access_token, isOpen, location, evaluator_name, agent_company_info, setRecentPath, SET_NEW_ROLEPLAY, SET_NEW_EXAM }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [reset, makeAllReset] = useState(false);
    const [longest, setLogest] = useState();
    const [refresh, setRefresh] = useState(false);
    const [favoriteList, setFavoriteList] = useState([]);
    const [mainFavoriteList, setMainFavoriteList] = useState([]);

    const [multiselectedTrainee, setMultiSelectedTrainee] = useState([]);
    const [multiselectedTrainer, setMultiSelectedTrainer] = useState([]);
    const [selectAllASCode, setSelectAllASCode] = useState([]);
    const [asCodeData, setAsCodeData] = useState([]);
    const [traineeData, setTraineeData] = useState([]);
    const [trainerData, setTrainerData] = useState([]);
    const [activeDropdown, setActiveDropdown] = useState();
    const [flag, setFlag] = useState(false);

    let lastId = 0;
    const autoId = (prefix = 'fav-list-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        if (isAflacMemberOrBranchManager() || isSystemOwner()) {
            getUserList(COMPANY_CODES_URL).then(res => {
                setAsCodeData(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)))
                setSelectAllASCode(res.data.map(item => { return { id: item.id, name: item.name, checked: true, newlyAdded: false, found: true } }).sort((a, b) => parseInt(a.id) - parseInt(b.id)));
                let longestName = longestString(res.data.map(item => { return { name: item.name } }))
                setLogest(longestName < 15 ? '3' : longestName >= 15 ? '4' : longestName >= 20 ? '5' : longestName >= 25 ? '6' : '7')
            })
        }
        if(isTrainerManagerOrTrainee()) {
            setSelectAllASCode([{id:agent_company_info.code, name: agent_company_info.code+'/'+agent_company_info.name}])
        }
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        setRecentPath(location.pathname);
    }, []);
    useEffect(() => {
        getRolePlayFavorite().then(res => {
            setMainFavoriteList(res.data);
            setFlag(true)
            setFavoriteList(res.data);
        })
    }, [refresh]);
    const getAIScoreParam = (rolePlayId, themeId, scenarioId) => {
        let paramData = {
            rolePlayId: rolePlayId,
            themeId: themeId,
            scenarioId: scenarioId,
        }
        SET_NEW_ROLEPLAY(paramData);
        RolePlayExist(rolePlayId,"/ai_scoring_result");
    }
    const getExamScoreParam = (examId, themeId, scenarioId) => {
        let paramData = {
            examId: examId,
            themeId: themeId,
            scenarioId: scenarioId,
        }
        SET_NEW_EXAM(paramData);
        RolePlayExist(examId,"/EditExamScoringResult");
    }
    const FavoriteRolePlayExist = (rolePlayId,path)=>{
        checkFavoriteRolePlayExist(rolePlayId).then(res=>{
            if(res.status === 200){
                history.push(path);
            }
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }
    const RolePlayExist = (rolePlayId,path)=>{
        let rpId = rolePlayId;
        checkRolePlayExist(rolePlayId).then(res=>{
            if(res.status === 200){
                FavoriteRolePlayExist(rpId,path);
            }
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' })
            }
            else if (err.response.status === 404) {
                EventBus.dispatch('show_snack', { message: "選択したロープレが募集人別サマリー画面から削除されています。", type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }
    useEffect(() => {
        let data = mainFavoriteList;
        if (selectAllASCode && selectAllASCode.length > 0) {
            data = data && data.filter(function (array_el) {
                return selectAllASCode.filter(function (anotherOne_el) {
                    return anotherOne_el.id == array_el.AgentCompanyCode;
                }).length !== 0
            });
            let uniqueTrainee = [...new Set(data.map(item => item.Trainee_name))];
            let uniqueTrainer = [...new Set(data.map(item => item.Evaluator_name))];
            let trainees = uniqueTrainee && uniqueTrainee.map((item, index) => {
                return { id: index+1, name: item, checked:true, newlyAdded: false, found: true }
            })
            setTraineeData(trainees)
            setMultiSelectedTrainee(trainees)

            let trainers = uniqueTrainer && uniqueTrainer.map((item, index) => {
                return { id: index+1, name: item, checked: true, newlyAdded: false, found: true }
            })
            setMultiSelectedTrainer(trainers)
            setTrainerData(trainers)
            setFavoriteList(data);
        }
        
    }, [mainFavoriteList, selectAllASCode])
    useEffect(() => {
        let data = mainFavoriteList;
        if (multiselectedTrainer.length > 0) {
            data = data.filter(function (array_el) {
                return multiselectedTrainer.filter(function (anotherOne_el) {
                    return anotherOne_el.name == array_el.Evaluator_name;
                }).length !== 0
            });
            setFavoriteList(data);
        }
        if (multiselectedTrainee.length > 0) {
            data = data.filter(function (array_el) {
                return multiselectedTrainee.filter(function (anotherOne_el) {
                    return anotherOne_el.name == array_el.Trainee_name;
                }).length !== 0
            });
            setFavoriteList(data);
        }
        
    }, [multiselectedTrainer, multiselectedTrainee])
    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr favorite-list" id="favorite-list">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="favorite_list_title">
                                お気に入りロープレ一覧
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id={autoId()} reset={reset} />
                        </Col>
                    </Row>
                    {
                        (isAflacMemberOrBranchManager() || isSystemOwner()) &&
                        <Row className="mb-4">
                            <Col lg={`${longest} `}>
                                <div className="cmn-bg-box-inr p-3">
                                    <label className="mb-2 font-weight-bold" id="flist_lb_as_code">AS コード</label>
                                    <MultiselectDropdown
                                        url={COMPANY_CODES_URL}
                                        selectAll={true}
                                        isStatic={true}
                                        staticData={asCodeData}
                                        selectAllText="全AS"
                                        placeholder={t('general.text.theme select')}
                                        multiSelectedData={selectAllASCode} setMultiSelectedData={setSelectAllASCode}
                                        activeDropdown={activeDropdown} setActiveDropdown={setActiveDropdown}
                                        message="ASコードが選択されていません"
                                        dropdown_id="flist_as_code"
                                        />
                                </div>
                            </Col>
                        </Row>

                    }
                    <Row>
                        <Col lg="12">
                            <div className="cmn-bg-box ">
                                <Row className="smallest-padding-box pb-4">
                                    <Col xl="3" lg="4" className=" order-1">
                                        <div className="cmn-bg-box-inr p-3">
                                            <label className="font-weight-bold font-14" id="flist_lb_role_player">登録者</label>
                                            <MultiselectDropdown
                                                url={TRAINER_LIST_BY_AGENT_COMPANY}
                                                isStatic={true}
                                                staticData={trainerData}
                                                placeholder={t('general.text.theme select')}
                                                multiSelectedData={multiselectedTrainer} setMultiSelectedData={setMultiSelectedTrainer}
                                                activeDropdown={activeDropdown} setActiveDropdown={setActiveDropdown}
                                                selectAll={true}
                                                selectAllText="全登録者"
                                                message="全登録者が選択されていません"
                                                dropdown_id="role_player_dropdown"  />
                                        </div>
                                    </Col>
                                    <Col xl="3" lg="4" className=" order-2">
                                        <div className="cmn-bg-box-inr p-3">
                                            <label className="font-weight-bold font-14" id="flist_lb_trainee">募集人名</label>
                                            <MultiselectDropdown
                                                url={TRAINEE_LIST_BY_AGENT_COMPANY}
                                                isStatic={true}
                                                staticData={traineeData}
                                                dropdown_id="selection_recuiter_dropdown"
                                                placeholder={t('general.text.theme select')}
                                                multiSelectedData={multiselectedTrainee} setMultiSelectedData={setMultiSelectedTrainee}
                                                activeDropdown={activeDropdown} setActiveDropdown={setActiveDropdown}
                                                selectAll={true}
                                                selectAllText="全募集人"
                                                message="全募集人が選択されていません" />
                                        </div>
                                    </Col>
                                    <Col xl="6" lg="4" className="text-right order-0 order-lg-3">
                                        <BackButton id={autoId()} className="back-btn font-14" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>
                                <div>
                                    <FavoriteTableList 
                                    favoriteData={favoriteList} 
                                    refresh={refresh} 
                                    setRefresh={setRefresh} 
                                    getAIScoreParam={getAIScoreParam} 
                                    getExamScoreParam={getExamScoreParam} 
                                    evaluator_name={evaluator_name} 
                                    asCodeData={selectAllASCode}
                                    selectAllASCode={selectAllASCode}
                                    multiselectedTrainer={multiselectedTrainer}
                                    multiselectedTrainee={multiselectedTrainee}
                                    flag={flag}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        agent_id: state.vAgent.agent_id,
        access_token: state.vAgent.access_token,
        evaluator_name: state.vAgent.username,
        agent_company_info: state.vAgent.agent_company
    }
}
const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
        SET_NEW_ROLEPLAY: (res) => dispatch({ type: "SET_NEW_ROLEPLAY", paramData: res }),
        SET_NEW_EXAM: (res) => dispatch({ type: "SET_NEW_EXAM", paramData: res })
    }
}
export default connect(stateToProps, dispatchToProps)(FavoriteList);