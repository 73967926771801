import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ORCard from '../../components/Cards/ORCard';
import styles from './styles.module.css';
import GeneralButton from '../../components/Buttons/GeneralButton';
import { useTranslation } from 'react-i18next';
import EventBus from '../../components/EventBus/index';
import { max } from '../../utils/General'
import { getAIScoringConditions } from '../../api/api'

const EditAccordion = ({ items, item, expanded, setExpanded, keywords, setKeywords, newORKeyword, setNewORKeyword, index, selected, id, editState }) => {
    const { t } = useTranslation();
    const [list, setList] = useState();
    const [flag, setFlag] = useState();
    const [sectionId, setSectionId] = useState();
    const [criteriaId, setCriteriaId] = useState();
    const [alreadyCallAPI, setAlreadyCallAPI] = useState(false);

    let lastId = 0;
    const autoId = (prefix = id) => {
        lastId++;
        return `${prefix}-${lastId}`;
    }

    const handleChange = (panel, flag, section_id, criteria_id) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setFlag(flag)
        setSectionId(section_id)
        setCriteriaId(criteria_id)
    };

    useEffect(() => {
        setAlreadyCallAPI(false);
        setExpanded(false)
    }, [selected])

    useEffect(() => {
        expanded && !flag && !alreadyCallAPI && sectionId && criteriaId && getAIScoringConditions(keywords[0].id, sectionId, criteriaId).then(res => {
            setAlreadyCallAPI(true)
            if (res.data.length >= 1) {
                setList(res.data.sort((a, b) => a.sort - b.sort)
                    .map(item => {
                        return {
                            keyword: item.keyword.filter(keywordItem => { return keywordItem.checked === '1' }),
                            sort: item.sort,
                        }
                    }))
            }
            else {
                setList([{ keyword: [], sort: `1` }])
            }

        })

        expanded && setNewORKeyword(false)

    }, [selected, expanded, sectionId, criteriaId])

    useEffect(() => {
        const newArray = list && keywords && keywords.map(section => {
            section.sections.map(data => {
                if (data.id === sectionId) {
                    data.criteria.map(criteria => {
                        if (criteria.id === criteriaId && !flag) {
                            criteria.keywordConditionsJSON = list
                        }
                    })
                }
            })
            return section
        })
        newArray && setKeywords(newArray)
    }, [list])



    const onAddNew = (section_id, criteria_id) => {
        const newArray = keywords && keywords.map(section => {
            section.sections.map(data => {
                if (data.id === section_id) {
                    data.criteria.map(criteria => {
                        if (criteria.id === criteria_id) {
                            if (criteria.keywordConditionsJSON.length < 10) {
                                criteria.modifyFlag = true;
                                let maxNum = max(criteria.keywordConditionsJSON.map(item => { return { name: item.sort } })) + 1
                                setNewORKeyword(true)
                                EventBus.dispatch('close_snack')
                                criteria.keywordConditionsJSON.push({
                                    keyword: [],
                                    sort: `${maxNum}`,
                                })
                            } else {
                                EventBus.dispatch('show_snack', { message: 'OR条件の制限を超えました。', type: 'error' })
                            }
                        }
                    })
                }
            })
            return section
        })

        setKeywords(newArray)
    }

    return (
        <Accordion expanded={expanded === 'panel' + items.id + index} onChange={handleChange('panel' + items.id + index, item.aiScoringFlag, items.id, item.id)} className={`mb-3`} key={index} >
            <AccordionSummary
                className="keywords-accor-summary-inr"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id={`${autoId()} panel2bh-header`}
            >
                <Typography><label className={`m-0 MeiryoRegular font-14`} id={id}>{item.name}</label></Typography>
            </AccordionSummary>
            <AccordionDetails className={`keywords-accor-summary-detail ${styles.keywords_selection_accor_summary_detail}`}>

                {!item.aiScoringFlag && <GeneralButton id={`${autoId()}-add-btn`} className={`cmn-btn RobotoRegular font-weight-normal mb-32 ${styles.cmn_btn_large}`} title={t('edit selection keyword.add new OR')} onClick={() => onAddNew(items.id, item.id)} />}

                {item.aiScoringFlag ?
                    <div className={`cmn-bg-box-inr ${styles.keyword_action} ${styles.keyword_action_del}`} >
                        <span className="keyword_action_title" id={`AI`}>AIは採点しない</span>
                    </div>
                    :
                    <div className="row">
                        {
                            !item.aiScoringFlag && item.keywordConditionsJSON && item.keywordConditionsJSON.map((keyword, keywordIndex) => {
                                return (
                                    <>
                                        {
                                            <div className={`${styles.keyword_selection_box} col-lg-12 col-xl-6 pr-lg-0  ${keywordIndex % 2 == 1 && styles.px_lg_0}`} key={index + '_' + keywordIndex}>
                                                <ORCard className={`${styles.keyword_or_box_out} col-10 px-0`}
                                                    selectedKeywords={keyword}
                                                    addNew={newORKeyword}
                                                    section_id={items.id}
                                                    criteria_id={item.id}
                                                    position={keywordIndex}
                                                    count={item.keywordConditionsJSON.length}
                                                    keywords={keywords} setKeywords={setKeywords}
                                                    key={keywordIndex}
                                                    editState={editState} />
                                                <div className={`text-center col-2 px-0 ${styles.keyword_and_text} ${item.keywordConditionsJSON.length - 1 === keywordIndex || item.keywordConditionsJSON.length === 1 ? styles.none : styles.block}`}><span className="font-weight-bold">AND</span></div>
                                            </div>

                                        }
                                    </>
                                )
                            })
                        }
                    </div>
                }
            </AccordionDetails>
        </Accordion>
    )

}
export default EditAccordion;