import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Sidebar from './../../components/Sidebar';
import LogoutButton from './../../components/Buttons/LogoutButton';
import { useHistory } from "react-router-dom";
import { setRecentPath } from '../../store/actions'
import Tab from '../../components/Tabs/ThemesTab'
import GeneralButton from './../../components/Buttons/GeneralButton';
import EditKeywordSelection from './EditKeywordSelection'
import AllKeywordSelection from './AllKeywordSelection'
import ConfirmDialog from '../../components/Dialog/ConfirmDialog'
import BackButton from './../../components/Buttons/BackButton';
import { getKeywords, getThemeList, updateKeywordConditions } from '../../api/api';
import { split, isApigwEnv } from '../../utils/General'
import EventBus from '../../components/EventBus'
import { isTrainerManagerOrTrainee } from '../../configs/constants'

const EditKeywordSelectionPage = ({ access_token, isOpen, location, setRecentPath }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const [visibleTab, setVisibleTab] = useState();
    const [selected, setSelected] = useState();
    const [editState, setEditState] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [totalKeyword, setTotalKeyword] = useState();   // get  all keywords info
    const [keywords, setKeywords] = useState();           // get only keywords section
    const [learningThemes, setLearningThemes] = useState();
    const [scenarioByTheme, setScenarioByTheme] = useState();
    const [newORKeyword, setNewORKeyword] = useState(false)   // new OR card
    const [dataToUpdate, setDataToUpdate] = useState([])
    const [status, setStatus] = useState()
    const [reset, makeAllReset] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee()) {
            makeAllReset(!reset)
            return
        }

        setRecentPath(location.pathname);

        getKeywords().then(res => {
            if (res.data.length > 0) {
                setTotalKeyword(res.data)
                let firstIndex = res.data[Object.keys(res.data)[0]]
                setScenarioByTheme(firstIndex.scenarios.map(item => { return { id: item.id, name: item.name } }))
                learningThemes && setVisibleTab(learningThemes[0].id)
            }
        })

        getThemeList().then(res => {
            setLearningThemes(res.data.map(item => { return { id: item.id, name: item.name, isActive: item.isActive } }))
        })
    }, [status]);

    useEffect(() => {
        if (totalKeyword !== undefined) {
            let index = totalKeyword.map(e => e.id).indexOf(visibleTab);
            let currentIndex = totalKeyword[Object.keys(totalKeyword)[index]]
            setScenarioByTheme(currentIndex.scenarios.map(item => { return { id: item.id, name: item.name } }))
        }
        setEditState(false)
    }, [visibleTab])


    useEffect(() => {
        if (selected !== undefined && visibleTab !== undefined && totalKeyword !== undefined) {
            let currentSection = totalKeyword && totalKeyword
                .filter(item => item.id === visibleTab)[0].scenarios
                .filter(value => value.id === selected.id)

            let editedKeywords = currentSection && currentSection.map(data => {
                return {
                    id: data.id,
                    name: data.name,
                    sections: data.sections.map(
                        items => {
                            return {
                                id: items.id,
                                name: items.name,
                                criteria: items.criteria.map(item => {
                                    let keywords = (item.keywords.keywords) === "-" ? 'AIは採点しない' : split(item.keywords.keywords)
                                    if (keywords === 'AIは採点しない') {
                                        return {
                                            id: item.id,
                                            name: item.name,
                                            aiScoringFlag: item.aiScoringFlag,
                                            modifyFlag: false,
                                            keywordConditionsJSON: null,
                                        }

                                    } else {
                                        return {
                                            id: item.id,
                                            name: item.name,
                                            aiScoringFlag: item.aiScoringFlag,
                                            modifyFlag: false,
                                            keywordConditionsJSON: null,
                                            keywords: keywords.map((data, index) => {
                                                return {
                                                    id: index + 1,
                                                    name: data,
                                                }
                                            }),
                                        }
                                    }
                                })
                            }
                        })
                }
            })
            setKeywords(editedKeywords)
        }
    }, [selected, totalKeyword])

    let dataArr = [];
    useEffect(() => {
        keywords && keywords.map(data => {
            data.sections.map(items => {
                items.criteria.map(item => {
                    item.modifyFlag && item.keywordConditionsJSON && item.keywordConditionsJSON.map((keyword, index) => {
                        let current = {
                            scenarioId: data.id,
                            sectionId: items.id,
                            checkCriteriaId: item.id,
                            valueToUpdate: JSON.stringify(item.keywordConditionsJSON)
                        };
                        dataArr.push(current);
                    })
                })
            })
        })
        setDataToUpdate(dataArr)
    }, [confirmOpen])

    const handleEditState = () => {
        setEditState(true)
        setExpanded(false)
    }

    const openDialog = () => {
        let count = 0;
        keywords && keywords.map(data => {
            data.sections.map(items => {
                for (let i = 0; i < items.criteria.length; i++) {
                    if (items.criteria[i].modifyFlag === true)
                        count++
                }
                if (count === 0) {
                    setConfirmOpen(false);
                    EventBus.dispatch('show_snack', { message: '更新データがありません', type: 'error' })
                }
                else {
                    setConfirmOpen(true);
                }
            })
        })

        // setConfirmOpen(true);
    }

    const saveKeywordSelection = () => {
        setExpanded(false)
        setEditState(false);
        updateKeywordConditions(dataToUpdate).then((res) => {
            setStatus(true)
            EventBus.dispatch('show_snack', { message: "操作成功", type: 'success' })
        }).catch(err => {
            setStatus(false)
            EventBus.dispatch('show_snack', { message: "失敗しました", type: 'error' })
        })
    }

    const onBack = () => {
        setExpanded(false)
        setEditState(false);
        setNewORKeyword(false);
    }

    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className={`main-content-inr edit_setting_page`}>
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="edit_keyword_selection_title">
                                {t('edit selection keyword.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout_btn" reset={reset} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12">
                            <div className="cmn-bg-box">
                                <Row>
                                    <Col xl="11" lg="12" className="order-xl-0 order-1">
                                        <div className="cmn-bg-box-inr p-32 mb-32">
                                            <Tab className="col-lg-12"
                                                visibleTab={visibleTab}
                                                setVisibleTab={setVisibleTab}
                                                selected={selected}
                                                setSelected={setSelected}
                                                learningThemes={learningThemes}
                                                scenarioByTheme={scenarioByTheme}
                                            />
                                        </div>
                                        <div className="cmn-bg-box-inr p-32 keyword-edit-sec">
                                            {
                                                editState ?
                                                    <>
                                                        <BackButton id={`back_btn `} className="back-btn mr-3" title={t('general.button.return')} onClick={onBack} />
                                                        <GeneralButton id={`apply-btn`} onClick={openDialog} className="cmn-btn font-weight-normal mb-32" title={t('edit selection keyword.apply')} />
                                                    </>
                                                    :
                                                    <GeneralButton id={`edit-btn`} onClick={handleEditState} className="cmn-btn font-weight-normal mb-32" title={t('edit selection keyword.edit')} />

                                            }
                                            <ConfirmDialog
                                                title={t('general.text.do_you_want_to_save')}
                                                open={confirmOpen}
                                                setOpen={setConfirmOpen}
                                                onConfirm={saveKeywordSelection}
                                            ></ConfirmDialog>
                                            {
                                                !editState ?
                                                    <AllKeywordSelection
                                                        editState={editState}
                                                        keywords={keywords} setKeywords={setKeywords}
                                                        expanded={expanded} setExpanded={setExpanded}
                                                        selected={selected} />
                                                    : <EditKeywordSelection
                                                        editState={editState}
                                                        keywords={keywords} setKeywords={setKeywords}
                                                        newORKeyword={newORKeyword} setNewORKeyword={setNewORKeyword}
                                                        expanded={expanded} setExpanded={setExpanded}
                                                        selected={selected}
                                                    />
                                            }
                                        </div>

                                    </Col>
                                    <Col xl="1" className={`back-btn-box  text-right`}>
                                        <BackButton id="go_back" className="back-btn mb-3 w-100" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(EditKeywordSelectionPage);