import store from '../store'

const AFLAC_MEMBER = "AFLAC MEMBER";
const BRANCH_MANAGER = "BRANCH MANAGER";
const TRAINER_MANAGER = "TRAINER MANAGER";
const TRAINEE = "TRAINEE";
const SYSTEM_OWNER = "SYSTEM OWNER";


const getCurrentUserRole = () => store.getState().vAgent.role && store.getState().vAgent.role.name.toUpperCase();

const isAflacMember = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === AFLAC_MEMBER;
}

const isAflacMemberOrBranchManager = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === AFLAC_MEMBER || currentRole === BRANCH_MANAGER;
}

const isAflacMemberOrTrainerManager = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === AFLAC_MEMBER || currentRole === TRAINER_MANAGER;
}

const isAflacMemberOrBranchManagerOrTrainerManager = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === AFLAC_MEMBER || currentRole === BRANCH_MANAGER || currentRole === TRAINER_MANAGER;
}
const isAflacMemberOrBranchManagerOrTrainerManagerOrSystemOwner = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === AFLAC_MEMBER || currentRole === BRANCH_MANAGER || currentRole === TRAINER_MANAGER || currentRole === SYSTEM_OWNER;
}

const isBranchManagerOrTrainerManager = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === BRANCH_MANAGER || currentRole === TRAINER_MANAGER;
}

const isTrainerManagerOrTrainee = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === TRAINER_MANAGER || currentRole === TRAINEE;
}

const isTrainerManager = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === TRAINER_MANAGER;
}

const isTrainee = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === TRAINEE;
}

const isSystemOwner = () => {
    let currentRole = getCurrentUserRole();
    return currentRole === SYSTEM_OWNER;
}

export {
    isAflacMember,
    isAflacMemberOrBranchManager,
    isAflacMemberOrTrainerManager,
    isAflacMemberOrBranchManagerOrTrainerManager,
    isBranchManagerOrTrainerManager,
    isTrainerManagerOrTrainee,
    isTrainerManager,
    isTrainee,
    isSystemOwner,
    isAflacMemberOrBranchManagerOrTrainerManagerOrSystemOwner
}