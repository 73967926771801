import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { I18nextProvider } from 'react-i18next';
import i18n from './languages';
import App from './App';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './store';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import 'antd/dist/antd.css'

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <PersistGate persistor={persistor}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} TransitionComponent={Slide}>
          <App />
        </SnackbarProvider>
      </PersistGate>
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
