import React, { useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import GeneralButton from '../../../components/Buttons/GeneralButton'
const CSVExport = (props) => {
      const {data,timeData,selectedLearningTheme,selectedTime,selectedTimePeriod,from,to,timePeriodData,multiselectedASCode} = props;
      const [selectedText, setSelectedText] = useState({});
      useEffect(() => {
        if (selectedTimePeriod !== 4) {
            setSelectedText({from:`${from.year}/${timePeriodData[from.month-1]}`,to:to?`${to.year}/${timePeriodData[to.month-1]}`:""});
        }else{
            setSelectedText({from:`${from.year}`,to:`${to.year}`});
        }
    }, [from,to])
      const sumData = (fieldName) => {
        return data.reduce((a, b) => a + (b[fieldName] || 0), 0);
      }
      const sumTimeData = (fieldName) => {
        return timeData.reduce((a, b) => a + (b[fieldName] || 0), 0);
      }
      const ratioCalculation = () =>{
        let total=0,paramObj = {},
        AI = sumData("AI"),
        aflacMember = sumData("AF社員"),
        trainerManager = sumData("AS管理者");
        total = AI + aflacMember + trainerManager;
        let divideTotalPercent = 100/total;
        AI = AI * divideTotalPercent;
        aflacMember = aflacMember * divideTotalPercent;
        trainerManager = trainerManager * divideTotalPercent;
        Object.assign(paramObj,{'AI':AI || 0});
        Object.assign(paramObj,{'AF社員':aflacMember || 0});
        Object.assign(paramObj,{'AS管理者':trainerManager || 0});
        return paramObj;
      }
      const csvData = [];
      csvData.push(["Filter By "])
      const allAsCode = multiselectedASCode.map((x) => x.name).join(', ');
      csvData.push(["表示形式",selectedTime.name])
      csvData.push(["抽出期間",selectedText.from +"~"+ selectedText.to])
      csvData.push(["AS コード",allAsCode])
      csvData.push(["学習テーマ",selectedLearningTheme.name])
      csvData.push([])
      csvData.push(["","合計","AI","AS管理者","AF社員"])
      csvData.push(
            ["合計",
            sumTimeData("AI")+sumTimeData("AS管理者")+sumTimeData("AF社員"),
            sumTimeData("AI"),sumTimeData("AS管理者"),sumTimeData("AF社員")]
          )

        for (let i = 0; i < timeData.length; i += 1) {
            csvData.push([
                timeData[i].date,
                timeData[i].AS管理者+timeData[i].AF社員,
                timeData[i].AI,
                timeData[i].AS管理者,
                timeData[i].AF社員
            ]);
        }
        csvData.push([]);
        csvData.push([]);
        csvData.push(["","合計","AI","AS管理者","AF社員"])
        csvData.push(
            ["合計",
            ratioCalculation().AI+ratioCalculation().AS管理者+ratioCalculation().AF社員,
            ratioCalculation().AI,ratioCalculation().AS管理者,ratioCalculation().AF社員]
        )
        for (let i = 0; i < data.length; i += 1) {
            csvData.push([
                data[i].date,
                data[i].AS管理者+data[i].AF社員,
                data[i].AI,
                data[i].AS管理者,
                data[i].AF社員
            ]);
        }

      const exportCSV = {
            filename : "アクセス解析 - デジタル化.csv",
            data : csvData
        }
    return(
        <CSVLink {...exportCSV}>
            <GeneralButton id="export_csv" className="cmn-btn font-weight-normal" title="出力" />
        </CSVLink>
    )
}
export default CSVExport;