import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import classes from './styles.module.css';
import GeneralButton from '../../Buttons/GeneralButton';
import GeneralTextbox from '../../Textboxes/GeneralTextbox'
import down_arrow from '../../../assets/images/down_arrow.png';
import up_arrow from '../../../assets/images/up_arrow.png';
import { padLeadingZeros } from '../../../utils/General';

const TimePickerDropdown = ({placeholder, className,dropdown_id,activeDropdown, setActiveDropdown ,value,timeOnChange,index}) => {
    const { t } = useTranslation();
    const list = useRef();
    const elementRef = useRef(null);
    const wrapperRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [isOPen, setIsOpen] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [selectedText, setSelectedText] = useState(value);
    const [changeValue, setChangeValue] = useState('00:00:00');
    const [hours, setHours] = useState('00');
    const [minutes, setMinutes] = useState('00');
    const [seconds, setSeconds] = useState('00');
    useEffect(() => {
        let splitValue = value.split(':')
        setHours(splitValue[0])
        setMinutes(splitValue[1])
        setSeconds(splitValue[2])
        setChangeValue(value);
        setSelectedText(value);
    }, [selectedText,value])

    // useEffect(() => {
    //     let time = hours +":"+ minutes +":"+ seconds;
    //     console.log('time',time);
    //     setChangeValue(time);
    // }, [])
    const onOpen = () => {
        setOpen(true);
        list.current.focus();
    }
    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            const concernedElement = document.getElementById(`${dropdown_id}_multi___section`);
            if ( dropdown_id && concernedElement && concernedElement.contains(e.target)) {
                // console.log("clicked inside");
            } else {
                setOpen(false)
                // console.log('selectedText',selectedText);
                // console.log('changeValue',changeValue);
                // changeValue !==''? setChangeValue(''): setChangeValue(selectedText)
                setChangeValue(selectedText)
                
                // console.log("clicked outside");
            }
        });
    }, [wrapperRef,selectedText]);

    const onApply = (e) => {
        setSelectedText(changeValue)
        timeOnChange(index,changeValue)
        setOpen(false)
    }

    const getTimeInterval = (start,end)=> {
        let data = '';
        for (let index = start; index <= end; index++) {
            data +=`<option>${padLeadingZeros(index,2)  }</option>`
        }
        return data;
    }
    const onChangeHour = (e) => {
        console.log('selectedText',selectedText);
        console.log('hourchangevalue',changeValue);
        let selTime =  selectedText.split(':');
        let changeTime =  changeValue !== '' && changeValue.split(':');
        let hour = e.target.value;
        let minute = selTime[1] !== changeTime[1]?changeTime[1]:selTime[1]
        let second = selTime[2] !== changeTime[2]?changeTime[2]:selTime[2]
        let time = hour +":"+ minute +":"+ second;
        setChangeValue(time);
        setHours(e.target.value)
    }
    const onChangeMinute = (e) => {
        console.log('selectedText',selectedText);
        console.log('minutechangevalue',changeValue);
        let selTime =  selectedText.split(':');
        let changeTime =  changeValue !== '' && changeValue.split(':');
        let hour = selTime[0] !== changeTime[0]?changeTime[0]:selTime[0];
        let minute = e.target.value;
        let second =selTime[2] !== changeTime[2]?changeTime[2]:selTime[2]
        let time = hour +":"+ minute +":"+ second;
        setChangeValue(time);
        setMinutes(e.target.value)
    }
    const onChangeSecond = (e) => {
        console.log('selectedText',selectedText);
        console.log('secondchangevalue',changeValue);
        let selTime =  selectedText.split(':');
        let changeTime =  changeValue !== '' && changeValue.split(':');
        let hour = selTime[0] !== changeTime[0]?changeTime[0]:selTime[0];
        let minute =selTime[1] !== changeTime[1]?changeTime[1]:selTime[1];
        let second = e.target.value
        let time = hour +":"+ minute +":"+ second;
        setChangeValue(time);
        setSeconds(e.target.value)
    }
    return (
        <div className="position-relative" id={`${dropdown_id}_multi___section`}  ref={wrapperRef}>
            <div className={`${classes.select_box} ${className} ${open ? classes.select_box_radius : ''}`} id={`${dropdown_id}_main_div`}>
                <div onClick={onOpen} className={'d-flex align-items-center h-100'}>
                <span>{selectedText === '' ? placeholder : selectedText}</span>
                <img src={`${open ? up_arrow : down_arrow} `} className={`${classes.arrows} `} />
                </div>
            </div>
            <div tabIndex="0" ref={list} >
                <div className={`${open ? classes.showbox : classes.hidebox}  ${classes.list}`} ref={elementRef}>
                    <div>
                        <Row>
                            <Col className="pr-0">
                                <select size={7} className="w-100 text-center border border-none" onChange={onChangeHour} value={changeValue !== selectedText?changeValue.split(":")[0]:selectedText.split(":")[0]} dangerouslySetInnerHTML={{ __html: getTimeInterval(0,24) }}>
                                </select>
                            </Col>
                            <Col className="px-0">
                                <select size={7} className="w-100 text-center border border-none" onChange={onChangeMinute} value={changeValue !== selectedText?changeValue.split(":")[1]:selectedText.split(":")[1]} dangerouslySetInnerHTML={{ __html: getTimeInterval(0,59) }}>
                                </select>
                            </Col>
                            <Col className="pl-0">
                                <select size={7} className="w-100 text-center border border-none" onChange={onChangeSecond} value={changeValue !== selectedText?changeValue.split(":")[2]:selectedText.split(":")[2]} dangerouslySetInnerHTML={{ __html: getTimeInterval(0,59) }}>   
                                </select>
                            </Col>
                        </Row>
                    </div>
                    <div className={classes.footer}>
                        <GeneralButton className={`cmn-btn ${disabled && disabled === true ? classes.gray : ''}`} title="反映" onClick={onApply} disabled={disabled && disabled} id={`${dropdown_id}_apply_btn`} />
                    </div>
                </div>

            </div>
        </div>
    )
}
const styles = {
    root: {
        marginLeft: 0,
        marginBottom: 0
    },
    label: {
        marginButtom: 0,
        fontSize: '14px',
        paddingLeft: '8px',
        fontFamily: 'RobotoRegular'
    }
};
export { TimePickerDropdown };