import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import YesButton from '../../Buttons/YesButton';
import NoButton from '../../Buttons/NoButton';
import cancel from '../../../assets/images/cancel.png';
import { useTranslation } from 'react-i18next';
import './styles.css'
const ScenarioDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
      PaperProps={{ className: "confirm_dialog_width" }}
    >
      <DialogTitle id="confirm-dialog" className="p-0 text-right">
        <img src={cancel} alt="Cancel" className="cancel" onClick={() => {
            setOpen(false);
        }}/>
      </DialogTitle>
      <DialogContent className="font-weight-bold font-14">
        <h5 className="mb-2">{title}</h5>
        <br/>
        {children}
      </DialogContent>
      <DialogActions>
        <YesButton id="apply" className="yes_btn font-14 py-3 mb-2" title={t('general.button.next')} onClick={() => {
            setOpen(false);
            onConfirm();
        }}/>
      </DialogActions>
    </Dialog>
  );
};
export default ScenarioDialog;
