import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import EventBus from '../EventBus'

const Toggle = ({ className, activeSlider, setActiveSlider, selectedData, message ,isStatic,staticData}) => {
    const { t } = useTranslation();
    const category =isStatic?staticData: [
        {
            id: 1,
            name: "7桁",
        },
        {
            id: 2,
            name: "10桁",
        }
    ]
    let lastId = 0;
    const autoId = (prefix = 'slider-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        activeSlider === undefined && setActiveSlider(category[0].id)
    }, [])
    const onChangeSlider = (item) => {
        if (selectedData && selectedData.length !== 0 && selectedData.filter(data => data.checked).length === 0) {
            return EventBus.dispatch('show_snack', { message: message, type: "error" })
        } else {
            setActiveSlider(item.id)
        }
    }

    return (
        <div className={`${styles.slider} ${className} `}>
            <ul className={`${styles.slider_titles}`}>
                {category.map((item) => (
                    <li key={item.id}
                        onClick={() => onChangeSlider(item)}
                        className={` ${styles.slider_title} ${activeSlider === item.id && styles.tab_title__active} }`}
                        id={autoId()}
                        name={`slider_${item.id}`}>
                        {item.name}
                    </li>
                ))
                }
            </ul>
        </div>
    )
}
export default Toggle;
