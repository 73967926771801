import React, {useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { TimePicker } from 'antd';
import moment from 'moment'
import Checkbox from '../../../components/Checkbox';
import GeneralTextbox from '../../../components/Textboxes/GeneralTextbox';
import {GeneralDropdown} from '../../../components/Dropdowns/GeneralDropdown';
import {TimePickerDropdown} from '../../../components/Dropdowns/TimePickerDropdown'
import staticMonthData from '../../../staticData/MonthData';
import down_arrow from '../../../assets/images/down_arrow.png'
import './../styles.css'
const PatternSection = ({currentPatternData,setCurrentPatternData,editState}) =>{
    const { t } = useTranslation();
    const [activeDropdown, setActiveDropdown] = useState();
    let lastId = 0;
    const autoId = (prefix = 'p-sec-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    const handleCheckboxChange = (index)=>(event) => {
        const oldData = [...currentPatternData]
        oldData.forEach(function(item,key){
            if (index === key) {
                return item.deleteFlag = event.target.checked === true ?false:true;
            }else{
                return item;
            }
        });
        if (event.target.checked === true) {
            oldData.sort((a, b) => a.sortingOrder > b.sortingOrder ? 1 : -1)
        }else{
            oldData.sort((a, b) => a.deleteFlag - b.deleteFlag)
        }
        setCurrentPatternData(oldData)
      };
    const handleMonthChange = (index)=>(e) =>{
        var estimate_time = staticMonthData.find(o => o.id === e.value);
        const oldData = [...currentPatternData]
        oldData.forEach(function(item,key){
            if (index === key) {
                return item.targetTime = estimate_time.estimate_time;
            }else{
                return item;
            }
        }); 
        oldData.forEach(function(item,key){
            if (index === key) {
                return item.targetPeriod = estimate_time.id;
            }else{
                return item;
            }
        }); 
        setCurrentPatternData(oldData)
    }
    const onSortUp = (index) => {
        const oldData = [...currentPatternData] 
        
        var s1 = oldData[index].sortingOrder;
        var s2 = oldData[index-1].sortingOrder;
        oldData[index].sortingOrder = s2
        oldData[index-1].sortingOrder = s1
        var b = oldData[index];
        oldData[index] = oldData[index-1];
        oldData[index-1] = b;
        setCurrentPatternData(oldData);
    }
    const onSortDown = (index) => {
        const oldData = [...currentPatternData]

        var s1 = oldData[index].sortingOrder;
        var s2 = oldData[index+1].sortingOrder;
        oldData[index].sortingOrder = s2
        oldData[index+1].sortingOrder = s1
        var b = oldData[index];
        oldData[index] = oldData[index+1];
        oldData[index+1] = b;
        setCurrentPatternData(oldData);
    }

    const timeOnChange =(index,timeString)=> {
        const oldData = [...currentPatternData]
        oldData.forEach(function(item,key){
            if (index === key) {
                return item.targetTime = timeString;
            }else{
                return item;
            }
        });
        setCurrentPatternData(oldData);

    }
    const activePatternCount = currentPatternData.filter(item => item.deleteFlag === false);
    currentPatternData.sort((a, b) => a.deleteFlag - b.deleteFlag)
    return(
        <div className="edit-learning-theme-table">
            <table className="table cmn-table">
                <thead></thead>
                <tbody className="text-center">
                    {
                        currentPatternData.length>0 && currentPatternData.map((item,index) => {
                            return(
                                <tr key={index} className={item.deleteFlag === true ?'bg_grayout':''}>
                                    <td className="bg_gray align-middle" width="15%">
                                        {
                                            editState?
                                            <FormControlLabel style={styles.root} control={<Checkbox name="checkedStep1" id={autoId()} checked={item.deleteFlag === true ?false:true} onChange={handleCheckboxChange(index)}/>} label={<span style={styles.label}>{t(`step ${index+1}`)}</span>} />
                                            :
                                            <span style={styles.label}>{t(`step ${index+1}`)}</span>
                                        }
                                    
                                    </td>
                                    <td  width="26%">
                                        <div className={`panel_box p-3`} >
                                            {
                                                editState?
                                                <div className="arrow" >
                                                    <button className={`up triangle ${index === 0 || item.deleteFlag === true?"first_child_btn":""}`} disabled={index === 0|| item.deleteFlag === true?true:false} onClick={()=>onSortUp(index)}></button>
                                                    <button className={`down triangle ${index === activePatternCount.length -1 || item.deleteFlag === true?"last_child_btn":""}`} disabled={index === activePatternCount.length -1|| item.deleteFlag === true?true:false} onClick={()=>onSortDown(index)}></button>
                                                </div>
                                                :""
                                            }
                                            <p className={`my-0 mx-3`}>{item.name}</p>
                                        </div>
                                    </td>
                                    <td className="bg_gray align-middle font-weight-bold"  width="10%">目標時間</td>
                                    <td  width="20%">
                                        {item.deleteFlag === true || !editState?
                                            <div className="disable_div text-right">
                                                <div className="w-100 d-flex ">
                                                    <span className="float-left">{item.targetTime}</span>
                                                </div>
                                            </div>
                                            :
                                            <TimePickerDropdown
                                            activeDropdown={activeDropdown} 
                                            setActiveDropdown={setActiveDropdown}
                                            value={item.targetTime}
                                            dropdown_id={`time_picker_${index}`}
                                            timeOnChange={timeOnChange}
                                            index={index}
                                            className={'disable_div'}
                                            />
                                        }
                                    </td>
                                    <td className="bg_gray align-middle font-weight-bold"  width="10%">目標期間</td>
                                    <td className="align-middle" width="20%">
                                        {item.deleteFlag === true || !editState?
                                        <div className="disable_div text-right">
                                            <div className="w-100 d-flex ">
                                                <span className="float-left">{item.targetPeriod} ヶ月</span>
                                            </div>
                                        </div>
                                        :
                                        <GeneralDropdown isStatic={true} dropdown_id="m-picker" staticData={staticMonthData} selectedData={`${item.targetPeriod} ヶ月`} placeholder="選択してください" onSelect={handleMonthChange(index)} className="edit-theme-select-box" isShowPlaceholder={true}/>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

const styles = {
    root: {
        marginLeft: 0,
        marginBottom: 16
    },
    label: {
        marginBottom: 0,
        fontSize: '14px',
        paddingLeft: 8,
        fontWeight: 'bold'
    }
};
export default PatternSection;