import React, { useState, useRef } from 'react';
import './styles.css';
import moment from 'moment'

import Picker from 'react-month-picker'
import calendar from '../../../assets/images/calendar.png'
import {formatYearMonth,joinDateFormatYearMonth,padLeadingZeros} from '../../../utils/General'

const MonthBox = (props) => {
  const handleClick = (e) => {
    props.onClick && props.onClick(e);
  }
  return (
    <div className="box" onClick={handleClick}>
      <label className="mb-0 text-primary text_underline" id={props.id}>{props.value}</label>
    </div>
  );
}

const List = (props) => {
  const { status,selectedData,preDate,nextDate,setTrainingPlan,trainingPlan,userkey,themekey,editType,id } = props;
  var d = new Date()
  const [mvalue, setMvalue] = useState(selectedData);
  const pickAMonth = useRef("pickAMonth");
  const pickerLang = {
    months: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
  }
  const makeText = m => {
    const month = m && m.month ? m.month : 0;
    const year = m && m.year ? m.year : 0;
    return month !== 0 && year !== 0 ? `${year}/${month}月` : d.getFullYear() + "/○○";
  }
  var minDate = moment(preDate.year+"-"+preDate.month+"-01")
  var maxDate = moment(nextDate.year+"-"+nextDate.month+"-01")
  if (minDate > maxDate) {
      nextDate.year = d.getFullYear()+3;
      nextDate.month = 12;
  }
  const min= {year:preDate.year,month:preDate.month > 12 ?preDate.month-1 :preDate.month+1}
  const max= {year:nextDate.year !==0 ?nextDate.year:d.getFullYear()+2,month:nextDate.month !== 0?(nextDate.month-1 === 0?nextDate.month +1 : nextDate.month-1):d.getUTCMonth()}
    const range = {
    min: min,
    max: max
  };

  const handleClickMonthBox = (e) => {
    status && pickAMonth.current.show()
  }

  const handleAMonthChange =(userkey,themekey,editType)=> (value, text) => {
    pickAMonth.current.dismiss()
    setMvalue({ year: value, month: text });
    const oldData = [...trainingPlan]
        oldData.forEach(function(item,key){
            if (editType === "joinDate") {
              if (userkey === key) {
                  return item.joinDate =`${value}-${padLeadingZeros(text, 2)}-01T09:00:00Z`;
                  // return item.joinDate =`${value}-${padLeadingZeros(text, 2)}-01T09:00:00Z`;
              }else{
                  return item;
              }
            }else if(editType === "estimatedCompletionYearMonth"){
              if (userkey === key) {
                  return item.estimatedCompletionYearMonth = parseInt(value+''+padLeadingZeros(text, 2));
              }else{
                  return item;
              }
            }else{
              if (userkey === key) {
                  item.themes.map((themeItem,themeIndex) => {
                    if(themeIndex === themekey){
                      return themeItem.expectedCompletionYearMonth = parseInt(value+''+padLeadingZeros(text, 2));
                    }else{
                      return themeItem;
                    }
                  })
              }else{
                  return item;
              }
            }
        });
        setTrainingPlan(oldData)
  }

  const handleAMonthDissmis = (value, text) => {
    // setMvalue(value);
  }

  return (
    <ul className="list-unstyled m-0">
      <li>
        <div className="edit">
          <Picker
            ref={pickAMonth}
            years={range}
            value={mvalue}
            lang={pickerLang.months}
            onChange={handleAMonthChange(userkey,themekey,editType)}
            onDismiss={handleAMonthDissmis}
          >
            <MonthBox value={makeText(mvalue)} className="m-0" onClick={handleClickMonthBox} id={id}/>
          </Picker>
        </div>
      </li>

    </ul>
  )
}

const Main = ({ status = true,selectedData,setTrainingPlan,trainingPlan,userkey,themekey=null,editType,preDate,nextDate,id }) => {
  if (editType === "joinDate") {
    var preDate={ year: 2021, month: 2 };
    var changeFormat = joinDateFormatYearMonth(selectedData);
    var nextDate = formatYearMonth(nextDate);
    nextDate = {year:nextDate.year,month:nextDate.month+1}
  }else if(editType === "estimatedCompletionYearMonth"){
    var preDate=joinDateFormatYearMonth(preDate);
    preDate = {year:preDate.year,month:preDate.month-1}
    var changeFormat = formatYearMonth(selectedData);
    var nextDate = formatYearMonth(nextDate);
  }
  else{
    var preDate=formatYearMonth(preDate);
    var changeFormat = formatYearMonth(selectedData);
    var nextDate = formatYearMonth(nextDate);
  }
  return (
    <>
      {/* <img src={calendar} alt="Calendar Image" className="d-inline-block mr-2"></img> */}
      <List status={status} selectedData={changeFormat} preDate={preDate} nextDate={nextDate} setTrainingPlan={setTrainingPlan} trainingPlan={trainingPlan} userkey={userkey} themekey={themekey} editType={editType} id={id}/>
    </>
  )
}



export default Main;
