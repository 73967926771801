import React, {useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import {  Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import FeedbackCard from '../../../components/Cards/FeedbackCard';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import RecordActionButton from '../../../components/Buttons/RecordActionButton';
import BackButton from '../../../components/Buttons/BackButton';
import {updateFeedback} from '../../../api/api';
import './styles.css'
import ChatApi from "../../../api/chatApi";
const FeedbackSection = ({goodPoint,improvePoint,crossMatter,role,rolePlayId, SET_EXAM_SCORE_RESULT, examId, rolePlayIdF,reloadTimes, setReloadTimes}) => {
    const [editFlag, setEditFlag] = React.useState(false);
    const [gPoint, setgPoint] = React.useState(goodPoint);
    const [iPoint, setiPoint] = React.useState(improvePoint);
    const [cMatter, setcMatter] = React.useState(crossMatter);
    const { t } = useTranslation();
    const [noRepeat, setNoRepeat ] = React.useState(true);
    const [gperror, setGPError] = useState(false);
    const [iperror, setIPError] = useState(false);
    const [cmerror, setCMError] = useState(false);
    const [gpmiceOpen, setGPMiceOpen] = useState(false);
    const [ipmiceOpen, setIPMiceOpen] = useState(false);
    const [cmmiceOpen, setCMMiceOpen] = useState(false);


    setTimeout(()=>{
        if(reloadTimes == 1){
            setgPoint(goodPoint);
            setiPoint(improvePoint);
            setcMatter(crossMatter);
        }
        setReloadTimes(reloadTimes += 1)
    }, 1000)
    const dispatch = useDispatch();
    let lastId = 0;
    const autoId = (prefix='feedback_section-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    const handleEditFlag = () =>{
        setEditFlag(true);
    }
    const onBack = () =>{
        setgPoint(goodPoint);
        setiPoint(improvePoint);
        setcMatter(crossMatter);

        setEditFlag(false);

        setGPError(false);
        setIPError(false);
        setCMError(false);

        setGPMiceOpen(false);
        setIPMiceOpen(false);
        setCMMiceOpen(false);

        setNoRepeat(true);
        dispatch({ type: 'initTranscriptGoodPoint', transcriptGoodPoint: goodPoint });
        dispatch({ type: 'initTranscriptImprovePoint', transcriptImprovePoint: improvePoint });
        dispatch({ type: 'initTranscriptCrossMatter', transcriptCrossMatter: crossMatter });
        ChatApi.endConnection();
    }
    const handleUpdateFeedback = () => {
        ChatApi.endConnection();
        if((gPoint && gPoint.length > 600)){
            setGPError(true);
            return;
        }
        if(iPoint && iPoint.length > 600){
            setIPError(true);
            return;
        }
        if(cMatter && cMatter.length > 600){
            setCMError(true);
            return;
        }
        setGPMiceOpen(false);
        setIPMiceOpen(false);
        setCMMiceOpen(false);
        setNoRepeat(true);
        let param = {}
        param["goodPoint"] = gPoint;
        param["improvePoint"] = iPoint;
        param["crossMatter"] = cMatter;
        SET_EXAM_SCORE_RESULT(param)
        setEditFlag(false);
        let id = examId ? examId : rolePlayIdF;
        updateFeedback(examId, param).then(res => {
        })
    }


    return(
        <div className="cmn-bg-box-inr">
            <Row className="mb-3 align-items-center">
                <Col className="d-inline">
                    <span id={autoId()} className="text-left font-weight-bold mb-0">{t('AI score result.feedback')}</span>
                </Col>

                {role.id === 1 || role.id === 2 ?
                    editFlag?
                    <Col className="text-right">
                        <BackButton id={autoId()} className="back-btn mr-3" title={t('general.button.return')} onClick={onBack} />
                        <GeneralButton id={autoId()} onClick={handleUpdateFeedback} className="cmn-btn font-weight-normal button-shadow" title={t('edit keywords.apply')} />
                    </Col>
                    :
                    <Col className="text-right">
                        <GeneralButton className="cmn-btn font-14 button-shadow" onClick={handleEditFlag} title={t('general.button.edit')}></GeneralButton>
                    </Col>
                    : ""}
            </Row>
            <Row className="medium-padding-box">
                <Col className="mb-4" lg="6" md="6">
                    <FeedbackCard id={autoId()} headerTitle={t('AI score result.good_point')}  limit={601} editFlag={editFlag} paramKey={"goodPoint"} content={gPoint} setContent={setgPoint} noRepeat={noRepeat} setNoRepeat={setNoRepeat} error={gperror} setError={setGPError} miceOpen={gpmiceOpen} setMiceOpen={setGPMiceOpen}　className="good_point_height"></FeedbackCard>
                </Col>
                <Col className="mb-4" lg="6" md="6">
                    <FeedbackCard id={autoId()} headerTitle={t('AI score result.improvement_point')} limit={601} editFlag={editFlag} paramKey={"improvePoint"} content={iPoint} setContent={setiPoint} noRepeat={noRepeat} setNoRepeat={setNoRepeat} error={iperror} setError={setIPError} miceOpen={ipmiceOpen} setMiceOpen={setIPMiceOpen}　className="improvement_point_height"></FeedbackCard>
                </Col>
            </Row>
            <Row className="">
                <Col>
                    <FeedbackCard id={autoId()} headerTitle={t('AI score result.correspondence_matter')} limit={601} editFlag={editFlag} paramKey={"crossMatter"} content={cMatter} setContent={setcMatter} noRepeat={noRepeat} setNoRepeat={setNoRepeat} error={cmerror} setError={setCMError} miceOpen={cmmiceOpen} setMiceOpen={setCMMiceOpen}　className="cMatter_point_height"></FeedbackCard>
                </Col>
            </Row>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        rolePlayId: state.startRolePlay.rolePlayId,
    }
}
const mapDispatchToProps = dispatch => ({
    SET_EXAM_SCORE_RESULT: (res) => dispatch({type: 'SET_EXAM_SCORE_RESULT', paramData: res}),
})
export default connect(mapStateToProps, mapDispatchToProps)(FeedbackSection);
