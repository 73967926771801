import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import BackButton from '../../../../components/Buttons/BackButtonLarge';
import GeneralButton02 from './../../../../components/Buttons/LargeButton';
import { TraineeDetailTable } from './../../../../components/Table/TraineeDetailTable';
import { TraineeDetailByDateTable } from '../../../../components/Table/TraineeDetailByDateTable';
import LogoutButton from './../../../../components/Buttons/LogoutButton';
import Sidebar from './../../../../components/Sidebar';
import './styles.css';
import { getTraineeDetailsSummary, getTraineeDetailsByDate, updateExamStatus, checkRolePlayExist } from '../../../../api/api';
import EventBus from '../../../../components/EventBus'
import { setRecentPath, setRecruiter, setRecruiterId, setSelectedCompanyCode } from '../../../../store/actions';
import traineeId from '../../../../staticData/TraineeDetailById.json';
import traineeDate from '../../../../staticData/TraineeByDate.json';
import { isAflacMemberOrBranchManagerOrTrainerManager, isSystemOwner } from '../../../../configs/constants'
import { getASCodeCompany } from '../../../../utils/General';
import { isApigwEnv } from '../../../../utils/General'

const RecruiterSummaryDetailPage = ({ isOpen, location, access_token, role, evaluator_name, setRecentPath, recruiter, SET_NEW_ROLEPLAY, recruiter_id, company, SET_NEW_EXAM, recruiter_detail, setRecruiter, setRecruiterId, company_at_recruiter_detail, setSelectedCompanyCode }) => {
    const { t } = useTranslation();
    const history = useHistory();
    let located = useLocation();
    const [traineeById, setTraineeById] = useState([]);
    const [traineeByDate, setTraineeByDate] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [flag, setFlag] = useState(false);
    useEffect(() => {
        if(recruiter_detail && recruiter_detail !== ''){
            setRecruiterId(recruiter_detail.id)
            setRecruiter(recruiter_detail.name)
        }        
        if(company_at_recruiter_detail && company_at_recruiter_detail !== ''){
            setSelectedCompanyCode(company_at_recruiter_detail)
        }
    }, [])

    useEffect(() => {
        recruiter_id !== '' && getTraineeDetailsSummary(recruiter_id).then(res => {
            setTraineeById([])
            setTraineeById(res.data);
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' });
            }
            else {
                EventBus.dispatch('show_snack', { message: '問題が発生しています', type: 'error' });
            }
        })
        // setTraineeById(traineeId)
    }, [refresh, recruiter_id])
    useEffect(() => {
        recruiter_id !== '' && getTraineeDetailsByDate(recruiter_id).then(res => {
            setFlag(true)
            setTraineeByDate(res.data);
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' });
            }
            else {
                EventBus.dispatch('show_snack', { message: '問題が発生しています', type: 'error' });
            }
        })
        // setTraineeByDate(traineeDate)
    }, [recruiter_id])
    const updateExam = (examStatus, examId) => {
        updateExamStatus(
            { "examId": examId, "examStatus": examStatus }
        )
            .then(res => {
                if (res.status == 200) {
                    EventBus.dispatch('show_snack', { message: res.data.message, type: 'success' })
                    const oldData = [...traineeById]
                    oldData.forEach(function (item) {
                        if (item.examId === examId) {
                            return item.examStatus = examStatus;
                        } else {
                            return item;
                        }
                    });
                    setTraineeById(oldData)

                }

            }).catch(err => {
                setRefresh(!refresh)
                if (err.response.status === 400) {
                    EventBus.dispatch('show_snack', { message: "更新に失敗しました", type: 'error' })
                }
                else {
                    EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
                }
            })
    }
    let lastId = 0;

    const autoId = (prefix = 'default') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    const onBack = () => {
        history.push('RecruiterSummary')
    }

    const onStartRoleplay = () => {
        history.push({
            pathname: "/StartOfNewRolePlay",
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        setRecentPath(location.pathname);
    }, []);

    const getAIScoreParam = (rolePlayId, themeId, scenarioId) => e => {
        let paramData = {
            rolePlayId: rolePlayId,
            themeId: themeId,
            scenarioId: scenarioId,
        }
        SET_NEW_ROLEPLAY(paramData);
        RolePlayExist(rolePlayId,"/ai_scoring_result");
    }
    const getExamScoreParam = (examId, themeId, scenarioId) => e => {
        let paramData = {
            examId: examId,
            themeId: themeId,
            scenarioId: scenarioId,
        }
        SET_NEW_EXAM(paramData);
        RolePlayExist(examId,"/EditExamScoringResult");
    }
    const RolePlayExist = (rolePlayId,path)=>{
        checkRolePlayExist(rolePlayId).then(res=>{
            if(res.status === 200){
                history.push(path);
            }
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' })
            }
            else if (err.response.status === 404) {
                EventBus.dispatch('show_snack', { message: "選択したロープレが募集人別サマリー画面から削除されています。", type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }
    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr summary-page" id="recruiter-summary-detail">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec" id={autoId()}>
                            <h3 className="text-left d-flex justify-content-between">
                                {t('associate_summary.summary_by_trainee')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12">
                            <div className="cmn-bg-box" id={autoId()}>
                                <BackButton className="back-btn font-14 mr-3" title={t('general.button.return')} onClick={onBack} id="go_back" />
                                {isAflacMemberOrBranchManagerOrTrainerManager() || isSystemOwner() ? <GeneralButton02 title={t('general.button.start_new_role')} className="mb-4" onClick={onStartRoleplay} id="start_role_play" /> : ''}
                                <h5 className="font-14 mb-3">アソシエイツ名： {getASCodeCompany(company[0].name)[1]} <span className="mx-3">|</span>{t('associate_summary.recruiter_name')} : {recruiter}</h5>
                                <Row className="mb-4">
                                    <Col lg="12">
                                        <div className="cmn-bg-box-inr">
                                        <TraineeDetailTable role_id={role.id} user_id={recruiter_id} TableClassName="step-table text-center" table_id="step_table" traineeById={traineeById} updateExam={updateExam}  SET_NEW_EXAM={SET_NEW_EXAM} refresh={refresh} setRefresh={setRefresh} />
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col lg="12">
                                        <div className="cmn-bg-box-inr">
                                            <TraineeDetailByDateTable getAIScoreParam={getAIScoreParam} getExamScoreParam={getExamScoreParam} TableClassName="result-detail-table" TableDivClassName=" no-overflow-table text-center" table_id="result_detail_table" traineeByDate={traineeByDate} setTraineeByDate={setTraineeByDate} evaluator_name={evaluator_name} flag={flag} setFlag={setFlag} />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
        role: state.vAgent.role,
        user_id: state.vAgent.user_id,
        recruiter: state.vAgent.recruiter,
        recruiter_id: state.vAgent.recruiter_id,
        company: state.vAgent.company,
        evaluator_name: state.vAgent.username,
        recruiter_detail: state.vAgent.recruiter_detail,
        company_at_recruiter_detail: state.vAgent.company_at_recruiter_detail,
    }
}
const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
        SET_NEW_ROLEPLAY: (res) => dispatch({ type: "SET_NEW_ROLEPLAY", paramData: res }),
        SET_NEW_EXAM: (res) => dispatch({ type: "SET_NEW_EXAM", paramData: res }),
        setRecruiter: (recruiter) => {
            dispatch(setRecruiter(recruiter));
        },
        setRecruiterId: (recruiter_id) => {
            dispatch(setRecruiterId(recruiter_id));
        },
        setSelectedCompanyCode: (company) => {
            dispatch(setSelectedCompanyCode(company));
        },
    }
}
export default connect(stateToProps, dispatchToProps)(RecruiterSummaryDetailPage);
