import React from 'react';
import styles from  './styles.module.css';

const Step = ({ className, style, text }) => {
    return (
        <div className={`${styles.circle} ${className}`} style={style}>
            <span>{text}</span> 
        </div>
    )
};
export const Line = ({ className, style, text, active }) => {
    return (
        <div className={`${styles.line} ${className} ${active? styles.active : styles.inactive}`} style={style}>
            <span>{text}</span> 
        </div>
    )
};

export default Step;