import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '../../../components/RadioButtons';
import { isAflacMemberOrBranchManager, isSystemOwner, isTrainee, isTrainerManager } from '../../../configs/constants';
import { getThemeList, patternsForAgentCompanyCode, patternsForCurrentUsersCompany } from '../../../api/api'
import { connect } from 'react-redux'

const useStyles = makeStyles({
    root: {
        minWidth: 130,
        marginBottom: 0,
        marginRight: 47,
    },
});

const FilterCard = ({ policy, setPolicy, setInsurance, className, id, SET_NEW_ROLEPLAY, setIsClicked, disabled = false, role, sevenFilterCode, threeFilterCode, uniqueFilterCode, uniqueCodePolicy, setUniqueCodePolicy, sevenCodePolicy, setSevenCodePolicy }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [policyList, setPolicyList] = useState([]);
    useEffect(() => {
        if (((sevenFilterCode !== undefined && threeFilterCode !== undefined) || (uniqueFilterCode !== undefined)) && (isAflacMemberOrBranchManager() || isSystemOwner())) {
            let id = threeFilterCode !== undefined ? sevenFilterCode.id : uniqueFilterCode.id
            patternsForAgentCompanyCode(id).then(res => {
                let tempList = res.data.patterns.filter(item => item.id === res.data.selectedPatternId)[0].themes.map(item => { return { id: item.id, name: item.name, active: item.isActive, deleteFlag: item.deleteFlag } })
                setPolicyList(tempList.filter(data => data.active === true && data.deleteFlag === false))
                let themeId = tempList.filter(data => data.active === true && data.deleteFlag === false)[0].id;
                // setPolicy(themeId);
                uniqueCodePolicy === '' &&  setUniqueCodePolicy(themeId)
                sevenCodePolicy === '' && setSevenCodePolicy(themeId)
                
                // ((uniqueFilterCode !== undefined && threeFilterCode === undefined) && uniqueCodePolicy === '') && setUniqueCodePolicy(themeId);
                // ((sevenFilterCode !== undefined && threeFilterCode !== undefined) && sevenCodePolicy === '') && setSevenCodePolicy(themeId)
            })
        }
        if (isTrainerManager() || isTrainee()) {
            patternsForCurrentUsersCompany().then(res => {
                let tempList = res.data.patterns.filter(item => item.id === res.data.selectedPatternId)[0].themes.map(item => { return { id: item.id, name: item.name, active: item.isActive, deleteFlag: item.deleteFlag } })
                setPolicyList(tempList.filter(data => data.active === true && data.deleteFlag === false))
                let themeId = policy && !disabled ? policy : tempList.filter(data => data.active === true && data.deleteFlag === false)[0].id
                setPolicy(themeId)
            })
        } else if ((sevenFilterCode === undefined && threeFilterCode === undefined) && (uniqueFilterCode === undefined)) {
            getThemeList().then(res => {
                let tempList = res.data.map(item => { return { id: item.id, name: item.name, active: item.isActive, deleteFlag: item.deleteFlag } })
                setPolicyList(tempList.filter(data => data.active === true))
                let themeId = policy && !disabled ? policy : tempList[0].id
                setPolicy(themeId)
                // SET_NEW_ROLEPLAY(themeId)
            })
        }
    }, [sevenFilterCode, threeFilterCode, uniqueFilterCode])
    const handleChange = (event) => {
        (uniqueFilterCode !== undefined) && setUniqueCodePolicy(parseInt(event.target.value));
        (sevenFilterCode !== undefined && threeFilterCode !== undefined) && setSevenCodePolicy(parseInt(event.target.value));
        setPolicy(parseInt(event.target.value))
        setIsClicked('')
        // SET_NEW_ROLEPLAY(event.target.value)
    };
    // deleteflag = true || isAcitive = false (hide)
    return (
        <div style={styles.container}>
            {
                policyList.map((item, index) => (
                    <FormControlLabel className={classes.root, className, item.deleteFlag === true || item.active === false ? 'd-none' : ''} key={index} style={styles.root} id={`learning_theme_item_${item.id}`} disabled={disabled} control={
                        <Radio
                            checked={policy == item.id}
                            onChange={handleChange}
                            value={item.id}
                            name="radio-button"
                            color="default"
                            id={`${id}${index}`}
                            disabled={disabled}
                        />
                    }
                        label={<span style={styles.label} className={`MeiryoRegular ${policy == item.id ? 'font-weight-bold' : ''}`}>{item.name}</span>} />
                ))
            }
        </div>
    )
}


const styles = {
    root: {
        minWidth: '110px',
        marginRight: '0',
        marginBottom: '8px',
        padding: '0',
        marginLeft: '0',
    },
    label: {
        marginButtom: '0',
        fontSize: '16px',
        fontWeight: 'normal',
        marginRight: '48px',
        marginLeft: '8px',
    }
};

const mapDispatchToProps = dispatch => ({
    SET_NEW_ROLEPLAY: (res) => dispatch({ type: 'SET_NEW_ROLEPLAY', paramData: { themeId: res } })
})

export default connect(null, mapDispatchToProps)(FilterCard);
