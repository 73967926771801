import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Row, Col } from 'reactstrap';
import GeneralButton from '../../../components/Buttons/GeneralButton'
import Chip from '@material-ui/core/Chip';
import {NavLink } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import StarSelect from '../../../assets/images/star_select.svg';
import StarPending from '../../../assets/images/star_pending.svg';
import StarNoSelect from '../../../assets/images/star_no_select.svg';
import {personEval, aiEval, passStar} from '../../../utils/mapState'
import {formatTime} from '../../../utils/DateFormat'
import classes from './styles.module.css'
import {stringFormat} from '../../../utils/General'
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
  

    const History = ({aiScoreResult,role_id}) => {

    const history = useHistory();

    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const [confirmOpen,setConfirmOpen] = React.useState(false);
    let lastId = 0;

    const autoId = (prefix='chat_history-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const editScoreResult = (section) => {
        history.push({pathname: `/exam_scoring_result/${section.sectionId}`, query: section})
    }
    const handleClickOpen = () => {
        setConfirmOpen(true);
    }
    const handleClose = () => {
        setConfirmOpen(false);
    };  
    return(
        <div className={`edit-keywords-accordian ${classes.root}`}>
            {aiScoreResult.map((item, index) => {
            return (
                <Accordion id={autoId()} key={index}  expanded={expanded === 'panel'+index} onChange={handleChange('panel'+index)} className={`history ${classes.accordianHead} MeiryoRegular`}>
                    <AccordionSummary className={ `${classes.accordiansummaryHead}` }
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}bh-content`}
                        id={`panel${index}bh-header`}
                    >
                        <span id={autoId()} className={`${classes.heading} font-weight-bold`}> {item.sectionName} </span>
                        <span id={autoId()} className={`${classes.secondaryHeading} d-flex align-items-center`}>
                            <Row className="MeiryoRegular w-100">
                                <Col lg={4} className="font-14">{item.passCount}/{item.passCount + item.unPassCount}({item.passPercent})</Col>
                                <Col lg={7} className="text-left offset-1">
                                    {passStar(item.passCount, item.unPassCount)}
                                </Col>
                            </Row>
                        </span>
                    </AccordionSummary>
                    <AccordionDetails className="d-block">
                        <Row className="pt-3 pb-2">
                            <Col lg="6">
                                <div className="">
                                    <table id={autoId()} className="cmn-table table MeiryoRegular text-center font-14">
                                        <tbody>
                                            <tr id={autoId()} className="cmn-bg-box">
                                                <th id={autoId()} style={{width : "230px"}} className="text-left">{t('AI score result.check_criteria')}</th>
                                                <th id={autoId()} style={{width : "50px"}}>{t('AI score result.evaluation')}</th>
                                                <th id={autoId()} >{t('AI score result.person_scoring')}</th>
                                            </tr>
                                            {item.criteriaResult.map((criteriaItem, criteriaIndex) => {
                                                return (
                                                    <tr key={criteriaIndex}>
                                                        <td id={autoId()} className="text-left">{stringFormat(criteriaItem.criteriaName)}</td>
                                                        <td id={autoId()}>
                                                            <img src={criteriaItem.evaluation === "true" ? StarSelect : StarNoSelect} alt="Star Select"/>
                                                        </td>
                                                        <td id={autoId()}>{personEval(criteriaItem.humanEval)}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                            <Col lg="6" className="text-right">
                                <GeneralButton id={autoId()} className="cmn-btn font-14 button-shadow" onClick={() => editScoreResult(item)} title={t('general.button.edit')}></GeneralButton>
                            </Col>
                        </Row>
                    </AccordionDetails>
                </Accordion>
            );
            })}
        </div>
    )
}


export default History;