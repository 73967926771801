import React, { useState, useEffect } from 'react';
import GeneralButton from '../../components/Buttons/GeneralButton';
import checkStyle from './style.module.css';
import { Dropdown } from '../../components/Dropdowns/HumanChoiceDropdown';
import { personEval } from '../../utils/mapState'
import criteria_option from '../../utils/mapState'
const CheckCriteria = ({ className, currentCriteria, setCurrentCriteria, selectedScenario, setSelectedScenario }) => {
    const [selected, setSelected] = useState([]);
    useEffect(() => {
        setSelected(
            currentCriteria.map((data,) => {
                return data.humanEval;
            }))
    }, [currentCriteria])

    const handleHumanEval = (e) => {
        let criteria = currentCriteria
        let paramArr = e.target.id.split('_')
        let criteriaIndex = paramArr[0]
        let criteriaVal = parseInt(paramArr[1])
        criteria[criteriaIndex].humanEval = criteriaVal
        setCurrentCriteria(criteria)
        setSelected(
            currentCriteria.map((data,) => {
                return data.humanEval;
            })
        )

    }

    return (
        <>
            {
                currentCriteria && currentCriteria.map((value, index) => (
                    <div className={`mb-3 p-3 cmn-bg-box-inr ${checkStyle.criterial_box} ${checkStyle.border_radious_6} ${value.requiredFlag && checkStyle.required} ${(value.name === '10分以内にロープレが完了している' || value.name === '15分以内にロープレが完了している') ? checkStyle.height : ''} `} key={index} id={`${value.id}_${index}`}>
                        <div className={checkStyle.box}>
                            <p className="text-align-center font-12 mb-0" id={`ongoing_criteria_${index}`}>{value.name}</p>
                            {(value.name !== '10分以内にロープレが完了している' && value.name !== '15分以内にロープレが完了している') &&
                                <div className="d-flex">
                                    <GeneralButton
                                        title={criteria_option[0].name}
                                        className={`mr-2 ${checkStyle.button} ${checkStyle.failed_btn} RobotoRegular ${criteria_option[0].id === selected[index] ? checkStyle.btn_checked : checkStyle.btn_not_checked}`}
                                        onClick={(e) => handleHumanEval(e)} id={index + '_' + criteria_option[0].id}
                                    />
                                    <GeneralButton
                                        title={criteria_option[1].name}
                                        className={` ${checkStyle.button} RobotoRegular ${criteria_option[1].id === selected[index] ? checkStyle.btn_checked : checkStyle.btn_not_checked}`}
                                        onClick={(e) => handleHumanEval(e)} id={index + '_' + criteria_option[1].id} />
                                    {/* <Dropdown onSelect={(e) => handleHumanEval(e)} selectedData={personEval(value.humanEval)} className={`${checkStyle.button} ${checkStyle.button} ${checkStyle.dropdown_box}`} items={criteria_option} dropdown_id={index} /> */}
                                </div>
                            }
                        </div>
                    </div>
                ))
            }
        </>

    )
}

export default CheckCriteria;