import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import './keywordPop.less';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const  AlertDialogSlide = (props) => {
  const { open, handleClose, keywords, keywordsJSON, matchedKeywords } = props;
  let lastId = 0;

  const calculateScore = (keywordObj) => {
    let keywordsArr = keywordObj.map(item => item.name)
    for (let i = 0; i < matchedKeywords.length; i++) {
        if (keywordsArr.includes(matchedKeywords[i])) {
            return true
        }
    }
    return false
  }
  const autoId = (prefix='ai_result-') => {
    lastId++;
    return `${prefix}${lastId}`;
    }

  return (
    <div>
      <Dialog
        className="ai-dialog"
        id={autoId()}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
                <div className="ai_or_popup_box" id={autoId()}>
                    {keywordsJSON && keywordsJSON.filter(item => item.keyword.length > 0).length === 0 ? <div className="alert_font">採点基準が設定していません。</div> :
                        keywordsJSON && keywordsJSON.filter(item => item.keyword.length > 0).map((keywordsItem, keywordsIndex) => {
                            return (
                                <div key={keywordsIndex}>
                                    {
                                        keywordsIndex !== 0 ? 
                                        <div className="ai_and_text">
                                            <span id={autoId()}>AND</span>
                                        </div> : ''
                                    }
                                    {
                                        keywordsItem.keyword.length > 0 ?
                                        <div className="d-flex ai_or_box" id={autoId()}>
                                        {
                                        calculateScore(keywordsItem.keyword) ?
                                        <div className="pass">
                                            <span id={autoId()}>〇</span>
                                        </div> :
                                        <div className="unpass">
                                            <span id={autoId()}>X</span>
                                        </div>
                                        }
                                        <div className="keyword_body">
                                            <p id={autoId()} className="font-weight-bold mb-1">OR</p>
                                            <div>
                                                {
                                                    keywordsItem.keyword.map((item, index) => {
                                                        return (
                                                            <span key={index} id={autoId()} className={matchedKeywords.includes(item.name) ? `ai_or_label match_item` : `ai_or_label`}>
                                                                {item.name}
                                                            </span>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div> : ''
                                    }
                                </div>
                            )
                        })
                    }
                </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default AlertDialogSlide;
