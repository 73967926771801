import React from 'react';

import classes from './styles.module.css';

const PageNotFound = () => {
    return (
        <>
            <div id="main-content" className="vh-100 w-100">
                <div className={classes.page_not_found}>
                    <h1>Page Not Found</h1>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;