import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Sidebar from '../../components/Sidebar';
import LogoutButton from '../../components/Buttons/LogoutButton';
import { useHistory } from "react-router-dom";
import { setRecentPath } from '../../store/actions'
import RadioButton from '../../components/RadioButtons/RadioButton02';
import FinishButton from '../../components/Buttons/FinishButton';
import classes from './styles.module.css';
import './StartExamPage.less'
import criteria_option from '../../utils/mapState'
import ConfirmDialog from '../../components/Dialog/ConfirmDialog'
import { getExamSectionCriteria, submitExam } from '../../api/api'
import moment from 'moment'
import { formatTime } from '../../utils/DateFormat'
import { TimePicker } from 'antd';
import EventBus from '../../components/EventBus'
import { getASCodeCompany } from '../../utils/General';
import { isTrainee } from '../../configs/constants'
import { isApigwEnv } from '../../utils/General'

const StartExamPage = ({ onBack, access_token, agent_company, user_id, recruiter_id, isOpen, location, setRecentPath, themeId, scenarioId, examStartTime, examEndTime, examStatus, SET_NEW_EXAM, company, recruiter }) => {
    const history = useHistory();
    const { t } = useTranslation();

    let lastId = 0
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [reset, makeAllReset] = useState(false)

    const autoId = (prefix = 'exam-in-progress') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    const timeOnClick = () => {
        document.getElementsByClassName("ant-btn-primary")[0].childNodes[0].textContent = '反映'
    }

    const timeOnChange = (time) => {
        if (time) {
            let durTime = new Date(moment(time, 'HH:mm:ss')) - new Date(moment("00:00:00", 'HH:mm:ss'))
            setSelectDurTime(durTime)
            setTimeValue(moment(time, 'HH:mm:ss'))
        } else {
            setSelectDurTime(0)
            setTimeValue(moment('00:00:00', 'HH:mm:ss'))
        }

    }

    const [timeValue, setTimeValue] = useState(moment('00:00:00', 'HH:mm:ss'));

    const [initFlag, setInitFlag] = useState(true);
    const [selectDurTime, setSelectDurTime] = useState(0)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainee()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);
    }, []);

    const [sectionCriteria, setSectionCriteria] = useState([]);
    const [currentTime, setCurrentTime] = useState('00:00:00')
    const [counter, setCounter] = useState(0)

    useEffect(() => {
        const timer = setTimeout(() => {
            let sum = counter + 1;
            setCounter(sum)
        }, 1000);
        setCurrentTime(formatTime(counter * 1000 * 1000 * 1000))
        return () => clearTimeout(timer)
    }, [counter])

    useEffect(() => {
        getExamSectionCriteria(recruiter_id, themeId, scenarioId).then(res => {
            let newArray = res.data.map((items, key) => (
                {
                    sectionId: items.sectionId,
                    sectionName: items.sectionName,
                    criteria: items.criteria.map((item, i) => (
                        {
                            id: item.id,
                            sectionId: items.sectionId,
                            name: item.name,
                            condition: '',
                            requiredFlag: item.requiredFlag
                        })
                    ),
                })
            )
            setSectionCriteria(newArray)
            let examStartTime = moment().utc().format("YYYY-MM-DD HH:mm:ss")
            SET_NEW_EXAM({ examStartTime })
        })
    }, [])




    const handleHumanEval = (e) => {
        let criteriaId = e.target.name.split('_')[1]
        let paramArr = e.target.id.split('_')
        let sectionId = paramArr[0]
        let criteriaVal = parseInt(paramArr[1])
        setSectionCriteria(
            sectionCriteria.map((data, index) => {
                if (parseInt(data.sectionId) === parseInt(sectionId)) {
                    data.criteria.map((item, key) => {
                        if (parseInt(item.id) === parseInt(criteriaId)) {
                            item.condition = criteriaVal
                        }

                    })
                }
                return data;
            }))
    }

    const handleFinish = (e) => {
        setInitFlag(false)
        const result = sectionCriteria
            .map(item => ({
                ...item,
                criteria: item.criteria.filter(child => child.condition === '')
            }))
            .filter(item => item.criteria.length > 0);
        if (result.length > 0) {
            EventBus.dispatch('show_snack', { message: "未入力の項目があります。ご確認の上、”確定”ボタンを押してください", type: "error" })
            return
        }
        let durTime = new Date(moment(timeValue, 'HH:mm:ss')) - new Date(moment("00:00:00", 'HH:mm:ss'))
        if (durTime === 0) {
            EventBus.dispatch('show_snack', { message: "未入力の項目があります。ご確認の上、”確定”ボタンを押してください", type: "error" })
            return
        }
        setConfirmOpen(true)
    }

    const confirmFinished = () => {
        let examEndTime = moment().utc().format("YYYY-MM-DD HH:mm:ss")
        SET_NEW_EXAM({ examEndTime })
        // let durTime = new Date(examEndTime) - new Date(examStartTime)
        let durTime = (new Date(timeValue) - new Date(moment("00:00:00", 'HH:mm:ss'))) * 1000 * 1000

        let data = {
            user_id,
            recruiter_id,
            themeId, // RPD_THEME_ID
            scenarioId,
            sectionCriteria,
            examStartTime, // RPD_START_TIME
            examEndTime, // RPD_END_TIME
            examStatus,
            durTime // RPD_TIME_DUR
        }

        submitExam(data).then(res => {
            SET_NEW_EXAM({ examId: res.data.examId })
            history.push({ pathname: `/EditExamScoringResult` })
        })
    }
    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="exam_page" name="exam_page">
                                {t('start new exam.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout_btn" reset={reset} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="10" md="12">
                            <Row>
                                <Col>
                                    <FinishButton title={t('general.button.return')} id="back_btn" className="mr-3" onClick={() => { history.push('/RecruiterSummaryDetail') }} />
                                    <FinishButton title={t('general.button.confirm')} id="confirm_btn" className={`font-weight-bold ${classes.finish_button}`} onClick={handleFinish} />
                                </Col>
                            </Row>
                            <Row className="mb-4 align-items-end">
                                <Col md="7" sm="6">
                                    <p className="m-md-0 mb-sm-0 mt-4 mb-2 font-weight-bold" id="selected_company_and_recruiter">
                                        <span className="d-block d-md-inline"  id="selected_company">アソシエイツ名：{getASCodeCompany(company[0].name)[1]}</span>
                                        <span className="mx-3 d-md-inline d-none">|</span>
                                        <span className="d-block d-md-inline"  id="selected__recruiter">募集人名：{recruiter}</span>
                                    </p>
                                </Col>
                                <Col md="5" sm="6" className="d-flex justify-content-sm-end">
                                    <div className={`form-group mb-0 ${classes.exam_in_progress_time_box}`}>
                                        <div className={`${classes.input_group} ${!initFlag && !selectDurTime && classes.validate}`}>
                                            <div className="input-group-prepend">
                                                <span className="input-group-text font-weight-bold font-14 list-group-flush light_grey_2" id="total_time_label">合計時間</span>
                                            </div>
                                            <TimePicker value={timeValue} onClick={timeOnClick} onChange={timeOnChange} size="large"  id="total_time_picker"/>
                                            {/* <div className={`rounded-0 ${classes.exam_in_progress_time}`}>{currentTime}</div> */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            {alertOpen && <ConfirmDialog
                                title={t('start new exam.alert message')}
                                open={alertOpen}
                                setOpen={setAlertOpen}
                                alert={true}
                            ></ConfirmDialog>}
                            {confirmOpen && <ConfirmDialog
                                title={t('start new exam.confirm message')}
                                open={confirmOpen}
                                setOpen={setConfirmOpen}
                                onConfirm={confirmFinished}
                            ></ConfirmDialog>}
                            <div className={`cmn-bg-box cmn-scroll-bar scroll-bar-height-600 ${classes.exam_in_progress}`}>
                                {
                                    sectionCriteria.map((item, index) => {
                                        return (
                                            <div className={`${classes.exam_in_progress_row}`} key={index}>
                                                <p id={`exam_section_name_${index}`} className="mb-3 font-weight-bold">{item.sectionName}</p>
                                                <Row id={autoId()} className="smallest-padding-box">
                                                    {item.criteria.map((value, key) => {
                                                        return (
                                                            <Col xl="4" lg="6" md="12" id={autoId()} className={`d-flex `} key={key}>
                                                                <div className={`${classes.progress_box} ${value.requiredFlag && classes.required} ${![0, 1].includes(value.condition) && !initFlag && classes.validate}`}>
                                                                    <label id={`exam_section_item_label_${item.sectionId}_${key}`}>{value.name}</label>
                                                                    <div className="d-flex" id={`exam_section_item_${item.sectionId}_${key}`}>
                                                                        <RadioButton
                                                                         title={criteria_option[0].name} 
                                                                         name={`${value.sectionId}_${value.id}`} 
                                                                         isChecked={value.condition === 0} 
                                                                         className={`${classes.exam} ${classes.failed_btn} mr-2`}
                                                                         onClick={(e) => handleHumanEval(e)} 
                                                                         id={item.sectionId + '_' + criteria_option[0].id} />
                                                                        <RadioButton 
                                                                        title={criteria_option[1].name} 
                                                                        name={`${value.sectionId}_${value.id}`} 
                                                                        isChecked={value.condition === 1} 
                                                                        className={`${classes.exam}`} 
                                                                        onClick={(e) => handleHumanEval(e)} 
                                                                        id={item.sectionId + '_' + criteria_option[1].id} />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        )
                                                    })
                                                    }
                                                </Row>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </Col>
                    </Row>
                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
        agent_company: state.vAgent.agent_company,
        user_id: state.vAgent.user_id,
        recruiter_id: state.vAgent.recruiter_id,
        recruiter: state.vAgent.recruiter,
        examId: state.startExam.examId,
        themeId: state.startExam.themeId,
        scenarioId: state.startExam.scenarioId,
        examStartTime: state.startExam.examStartTime,
        examEndTime: state.startExam.examEndTime,
        examStatus: state.startExam.examStatus,
        company: state.vAgent.company
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
        SET_NEW_EXAM: (res) => dispatch({ type: "SET_NEW_EXAM", paramData: res })
    }
}
export default connect(stateToProps, dispatchToProps)(StartExamPage);