import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import FilterCard from './../../../components/Cards/FilterLearningThemeCard';
import SingleCard from './../../../components/Cards/FilterLearningThemeCard/SingleCard';
import CustomerCard from './../../../components/Cards/CustomerCard';
import ResponsiveDialog from './../../../components/Popup/ImagePopup';
import GeneralButton from './../../../components/Buttons/GeneralButton';
import BackButton from './../../../components/Buttons/BackButton';
import { GeneralDropdown } from './../../../components/Dropdowns/GeneralDropdown';
import styles from './styles.module.css';
import CustomerProfileForTainer from '../../../assets/images/customer_profile_for_trainer.png';
import CustomerProfileForTainee from '../../../assets/images/customer_profile_for_trainee.png';
import CustomerListPdfForTrainer from '../../../assets/pdf/trainer/顧客属性一覧.pdf';
import CustomerListPdfForTrainee from '../../../assets/pdf/trainee/顧客属性一覧.pdf';
import { isTrainerManager, isTrainee } from '../../../configs/constants';

const Step1 = (props) => {
    let { text, onBack, onNext, policy, setPolicy, insurance, setInsurance, isClicked, setIsClicked, agent_id, recruiter, disabled, selectedCompanyName, uniqueFilterCode, sevenFilterCode, threeFilterCode, role, activeSlider, url, setUrl, isBack, uniqueCodePolicy, setUniqueCodePolicy, sevenCodePolicy, setSevenCodePolicy, setBranchCode, onRecruiter, trainerEmail, traineeEmail } = props;
    const { t } = useTranslation();
    let lastId = 0;

    const autoId = (prefix = 'rp1-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    // const [uniqueFilterId, setUniqueFilterId] = useState()
    // const [sevenFilterId, setSevenFilterId] = useState()
    // const [threeFilterId, setThreeFilterId] = useState()

    // useEffect(() => {
    //     setUniqueFilterId(uniqueFilterCode && !threeFilterCode &&  uniqueFilterCode.id)
    //     setSevenFilterId(sevenFilterCode && threeFilterCode && sevenFilterCode.id)
    //     setThreeFilterId(threeFilterCode && threeFilterCode.id)

    // }, [uniqueFilterCode, sevenFilterCode, threeFilterCode])


    useEffect(() => {
        if ((threeFilterCode && sevenFilterCode) && activeSlider && activeSlider === 2) {
            // !isBack && onRecruiter({ value: '', textContent: '' })
            // setThreeFilterId(threeFilterCode.id)
            setUrl(`/backend/getTraineeFilterList/${agent_id ? agent_id : 0}`)
        }
        else if ((uniqueFilterCode) && activeSlider && activeSlider === 1) {
            // !isBack && onRecruiter({ value: '', textContent: '' })
            // setUniqueFilterId(uniqueFilterCode.id)
            setUrl(`/backend/trainees/forAgentCompanyCode/${uniqueFilterCode.id}`)
        }

    }, [isBack, activeSlider, uniqueFilterCode, sevenFilterCode, threeFilterCode])

    const selectionRecruiter = (value) => {
        onRecruiter(value)
    }    

    return (
        <div className="cmn-bg-box" id="step-1">
            {!disabled && <p className="mb-4 font-weight-bold" id="selected_company" name="selected_company">アソシエイツ名： {selectedCompanyName[0].companyName} </p>}
            <Row className="mb-32">
                <Col>
                    <BackButton id="back_btn" className="back-btn font-14 mr-3" title={t('general.button.return')} onClick={onBack} />
                    {(disabled || traineeEmail === false || trainerEmail === false) ? <GeneralButton id="next_btn" className={`cmn-btn font-14 ${styles.grey}`} title={t('general.button.next')} onClick={onNext} disabled={disabled || traineeEmail === false || trainerEmail === false} />
                        : <GeneralButton id="next_btn" className="cmn-btn font-14" title={t('general.button.next')} onClick={onNext} />}
                </Col>
            </Row>
            <div className="cmn-bg-box-inr">
                <Row className="mb-4">
                    <Col lg="4">
                        <h6 className="mb-3" id="recruiter_selection" name="recruiter_selection"> {t('roleplay.step 1.card title')} </h6>
                        {!isTrainerManager() ? <GeneralDropdown
                            url={url && url}
                            role={role}
                            uniqueFilterCode={activeSlider === 1 ? uniqueFilterCode : undefined}
                            sevenFilterCode={activeSlider === 2 ? sevenFilterCode : undefined}
                            threeFilterCode={activeSlider === 2 ? threeFilterCode : undefined}
                            onSelect={selectionRecruiter}
                            className={`font-10 recruiter ${disabled && styles.grey_selected}`}
                            placeholder={t('roleplay.step 1.select-placeholder')}
                            selectedData={recruiter}
                            disabled={trainerEmail === false || disabled}
                            dropdown_id="recruiter_dropdown"
                            setBranchCode={setBranchCode}
                            agent_id={agent_id ? agent_id : 0}
                        />
                            : <GeneralDropdown
                                url={`/backend/getTraineeFilterList/${agent_id ? agent_id : 0}`}
                                role={role}
                                sevenFilterCode={sevenFilterCode}
                                threeFilterCode={threeFilterCode}
                                onSelect={selectionRecruiter}
                                className={`font-10 recruiter `}
                                placeholder={t('roleplay.step 1.select-placeholder')}
                                selectedData={recruiter}
                                dropdown_id="recruiter_dropdown"
                                agent_id={agent_id ? agent_id : 0}
                                disabled={trainerEmail === false}
                            />
                        }
                    </Col>
                </Row>
                <Row lg="12" className="mb-4">
                    <label className={`font-weight-bold col-lg-2 col-md-3 col-sm-12 mb-0 pr-md-0  ${styles.header} ${styles.header_1}`} id="learning_theme" name="learning_theme"><span>{t('roleplay.step 1.learning theme')}</span><span>:</span></label>
                    <Col lg="10" md="9" sm="12" className="pl-md-4">
                        <FilterCard id={autoId()} policy={policy} setPolicy={setPolicy} setInsurance={setInsurance} setIsClicked={setIsClicked} disabled={disabled}
                            role={role}
                            uniqueFilterCode={activeSlider === 1 ? uniqueFilterCode : undefined}
                            sevenFilterCode={activeSlider === 2 ? sevenFilterCode : undefined}
                            threeFilterCode={activeSlider === 2 ? threeFilterCode : undefined}
                            setUniqueCodePolicy={setUniqueCodePolicy} uniqueCodePolicy={uniqueCodePolicy}
                            setSevenCodePolicy={setSevenCodePolicy} sevenCodePolicy={sevenCodePolicy}
                        />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <label className={`font-weight-bold col-lg-2 col-md-3 col-sm-12 mb-0 pr-md-0 ${styles.header} ${styles.header_2} `} id="scenario" name="scenario">{t('roleplay.step 1.scenario')}<span className="text-align-right">:</span></label>
                    <Col lg="10" md="9" sm="12" className="pl-md-4">
                        <SingleCard id={autoId()} policy={policy} insurance={insurance} setInsurance={setInsurance} setIsClicked={setIsClicked} disabled={disabled} />
                    </Col>
                </Row>

                <Row className="mb-4">
                    <label className={`font-weight-bold col-lg-2 col-md-3 col-sm-12 mb-0 pr-md-0 ${styles.header} ${styles.header_2} `} id="customer_attribute_selection">{t('roleplay.step 1.select customer attribute')}<span className="text-align-right">:</span></label>
                    <Col lg="10" md="9" sm="12" className="pl-md-4">
                        <ResponsiveDialog url={isTrainee() ? CustomerProfileForTainee : CustomerProfileForTainer} text={t('roleplay.step 1.Customer Attributes Menu')} className={`font-weight-bold font-16 ${styles.active_text}  ${disabled && styles.grey_text}`} download={isTrainee() ? CustomerListPdfForTrainee : CustomerListPdfForTrainer} disabled={disabled} id="customer_attribute_selection_popup" />
                    </Col>
                </Row>
                <Row>
                    <CustomerCard id={autoId()} className={`${styles.grey_selected}`} text={t('roleplay.step 1.display')} isClicked={isClicked} setIsClicked={setIsClicked} policy={policy} insurance={insurance} disabled={disabled} />
                </Row>
            </div>
        </div>
    )
};
export default Step1;
