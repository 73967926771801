import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import GeneralButton from '../../components/Buttons/GeneralButton';
import BackButton from '../../components/Buttons/BackButton';
import ReorderList from '../../components/ReorderList'

const EditCriteria = ({ className, style, onBack, keywords, setKeywords, onApply }) => {

    const { t } = useTranslation();
    let lastId = 0;
    const autoId = (prefix = 'edit-criteria-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    return (
        <>
            <div className="cmn-bg-box-inr py-32" >
                <Row className="mb-32" id={autoId()}>
                    <Col>
                        <BackButton id={`back-btn`} className="back-btn mr-3" title={t('general.button.return')} onClick={onBack} />
                        <GeneralButton id={`apply-btn`} className="cmn-btn" title={t('general.button.apply')} onClick={onApply} />
                    </Col>
                </Row>
                <Row id={autoId()}>
                    <Col lg="9">
                        <div className={`cmn-scroll-bar px-2`}>
                            {keywords && keywords[0].sections.map((section, index) => {
                                return (
                                    <>
                                        <h5 className={`font-14 mb-3`} key={index} id={`section_title_${index}`}> {section.sectionName} </h5>
                                        <div className="mr-md-4 mr-2" id={`section_list_${index}`}>
                                            <ReorderList items={section.criteria} key={section.id} keywords={keywords} setKeywords={setKeywords} section_id={section.sectionId} id={autoId()} />
                                        </div>
                                    </>
                                )
                            })
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
};

export default EditCriteria;