import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import './styles.css';
import { getLastString, split } from '../../../utils/General';
import axios from 'axios';
const useStyles = makeStyles({

});

export default function ResponsiveDialog({ className, url, text, style, customerDetail, id, download, disabled = false }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();
  const handleClickOpen = () => {
    !disabled && setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = async event => {
    axios({
      url: event.target.value,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const filename = getLastString(event.target.value.split('?')[0]).split('.')
      link.href =  url;
      link.setAttribute('download', decodeURIComponent(filename.length === 3 ? filename[0] + '.' + filename[2] : getLastString(event.target.value.split('?')[0])));
      document.body.appendChild(link);
      link.click();
      window.open(event.target.value, '_blank');
    });
  };

  return (
    <div className={classes.root, className} style={style}>
      <a variant="outlined" color="primary" onClick={handleClickOpen} id={`${id}_link`}>
        {text}
      </a>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog"
        className={`img-popup ${classes.root} `}
        id={`${id}_dialog_close`}
      >
        <DialogContent id={`${id}_dialog_content`} className={`popup_content`}>
          {split(url).map((img, index) => (
            <img key={index} id={`${id}_dialog_image`} src={img} className="img-fluid" />
          ))}
          <button onClick={e => handleDownload(e)} value={download} id={`${id}_download`}>{t('general.button.download pdf')}</button>
        </DialogContent>
      </Dialog>
    </div>
  );
}