import StarSelect from '../assets/images/star_select.svg';
import StarNoSelect from '../assets/images/star_no_select.svg';
import { ANNUALLY, MONTHLY, QUARTELY } from '../configs/urlConstants';
const quartelyLabel = ["1-3月（","4-6月 (","7-9月 (","10-12月 ("]
const quartelyLabel2 = [" 1Ｑ)"," 2Ｑ)"," 3Ｑ)"," 4Ｑ)"]
const halfYearlyLabel = ["1-6月（","7-12月（"]
const halfYearlyLabel2 = ["上期）","下期)"]

export function personEval(humanEval) {
  switch (humanEval) {
      case 0:
          return 'x'
      case 1:
          return 'o'
      case 2:
          return '-'
      default:
          return ''
  }
}

export function aiEval(aiEval, aiScoreFlag) {
    if (aiScoreFlag === 0) {
        return '対象外'
    }
    switch (aiEval) {
      case 0:
          return 'x'
      case 1:
          return 'o'
      default:
          return '-'
    }
}

export function passStar(passCount, unPassCount) {
    let i = 0
    let j = 0
    let index = 0
    let elements = []
        while (i < passCount) {
            elements.push(<img className="star_img_sum" src={StarSelect} key={index} alt="Star Select"/>)
            i++
            index++
        }
        while (j < unPassCount) {
            elements.push(<img className="star_img_sum" src={StarNoSelect} key={index} alt="Star No Select"/>)
            j++
            index++
        }
    return elements
}

export function checkAverageLearningTheme(data,filterByASCode){
    const averageLearningTheme = [{name: "単品編"},{name: "併売編"},{name: "総合保障編"}]
    let checkResult =  filterByASCode.map(item =>{
       return averageLearningTheme.every(c => item.learningThemes.find(a=> a.themeName === c.name)) === true?true:false
    } );
    let checkAverageLearningThemeInclude = checkResult.every(status => status === true);
    if (checkAverageLearningThemeInclude) {
        let notAvgLearningTheme = data.filter(item => {
            return averageLearningTheme.filter(subItem =>{
                return item.name == subItem.name
            }).length == 0
        });
        let avgLearningTheme = data.filter(item => {
            return averageLearningTheme.filter(subItem =>{
                return item.name == subItem.name
            }).length != 0
        });
        let formatData = [];
        avgLearningTheme.sort((a,b)=> (a.id > b.id ? 1 : -1))
        avgLearningTheme.map(item=> formatData.push({id:item.id,name :item.name}))
        formatData.push({id : 0,name :"3編平均"})
        notAvgLearningTheme.map(item=> formatData.push({id:item.id,name :item.name}))
        return formatData;
    }else{
        data.sort((a,b)=> (a.id > b.id ? 1 : -1))
        return data.map(item => { return { id: item.id, name: item.name } });
    }
}

export function getLearningThemeBasedOnASCode(filterByASCode){
    let known = [];
    let result = filterByASCode.reduce(function (r, k) {
        const a = new Set(k.learningThemes.map(x => x.themeName))
        known = [...known,...k.learningThemes.filter(d => a.has(d.themeName))];
        return known;
    }, []);
    const ids = result.map(o => o.themeName)
    const filtered = result.filter(({themeName}, index) => !ids.includes(themeName, index + 1))
    return filtered;
}


export function getDateRangeData(status,from,to,formatData){
    let rangeValue =[];
    for (let year = from.year; year <= to.year; year++) {
        if(status !== ANNUALLY){
            let startingMonth,endMonth;
            if (status === MONTHLY) {
                startingMonth = year === from.year?from.month : 1;
                endMonth = year === to.year?to.month : 12;
            }else if(status === QUARTELY){
                startingMonth = year === from.year?from.month : 1;
                endMonth = year === to.year?to.month : 4;
            }else{
                startingMonth = year === from.year?from.month : 1;
                endMonth = year === to.year?to.month : 2;
            }
            for (let months = startingMonth; months <= endMonth; months++) {
                if (status === MONTHLY) {
                    rangeValue.push(year+"年"+months+"月");
                }else if(status === QUARTELY){
                    rangeValue.push(quartelyLabel[months-1]+year+quartelyLabel2[months-1]);
                }else{
                    rangeValue.push(halfYearlyLabel[months-1]+year+halfYearlyLabel2[months-1]);
                }
            }
        }else{
            rangeValue.push(year+'年')
        }
    }
    let data =[];
    for (let index = 0; index < rangeValue.length; index++) {
        let checkExistOrNot = formatData.filter(x => x.date === rangeValue[index])[0];
        if (checkExistOrNot) {
            data.push({
                date :rangeValue[index],
                AF社員: checkExistOrNot.AF社員,
                AI: checkExistOrNot.AI,
                AS管理者: checkExistOrNot.AS管理者
            })
        }else{
            data.push({
                date :rangeValue[index],
                AF社員: 0,
                AI: 0,
                AS管理者: 0
            })
        }
    }
    return data;
}

export function getFormattedTrainingPlan(traineeDetailIds,trainingPlan){
    let notIncludesTraineeDetail = trainingPlan.filter(item => {
        return traineeDetailIds.filter(subItem =>{
            return item.userId == subItem
        }).length == 0
    });
    let includesTraineeDetail = trainingPlan.filter(item => {
        return traineeDetailIds.filter(subItem =>{
            return item.userId == subItem
        }).length != 0
    });
    let orderByTraineeIds = [];
    traineeDetailIds.map(item => orderByTraineeIds.push(includesTraineeDetail.filter(subItem => subItem.userId === item)[0]));
    let mergeItem = [...orderByTraineeIds,...notIncludesTraineeDetail];
    mergeItem = mergeItem.filter(function( element ) {
        return element !== undefined;
     });
    return mergeItem
}

export function getEmptyAsData(data) {
    data.map(item => {
        return item.hasOwnProperty("AS管理者") ?item:
         Object.assign(item,{'AS管理者':0});
    })
    data.map(item => {
        return item.hasOwnProperty("AF社員") ?item:
         Object.assign(item,{'AF社員':0});
    })

    return data;
}

export function getRemoveUnselectedTraines(countData,trainees){
    let filterNotTrainee = countData.filter(item => {
        return trainees.filter(subItem =>{
            return item.date == subItem.name
        }).length == 0
    });
    return filterNotTrainee;
}

const criteria_option = [{ id: 0, name: "×" }, { id: 1, name: "〇" }];
export default criteria_option