import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import GeneralButton from './../../../components/Buttons/GeneralButton';
import BackButton from './../../../components/Buttons/BackButton';
import SelectScenario from './ScenarioBasedRoleplay';
import Scenario from '../../../staticData/RolepayScenario.json';
import step2Class from './styles.module.css';
import { setScenario } from '../../../store/actions';

const Step2 = ({ className, style, onBack, onNext, policy, insurance, sectionCriteria, setSectionCriteria, setScenario, trainerId, traineeId, selectedCompanyName, recruiter, agent_company, role, sevenFilterCode, threeFilterCode, uniqueFilterCode, activeSlider}) => {
    const { t, i18n } = useTranslation();
    let lastId = 0;

    const autoId = (prefix = 'rp2-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }

    const onNextStep = () => {
        setScenario(sectionCriteria);
        onNext();
    }

    return (
        <div className={`cmn-bg-box ${step2Class.select_scenario}`} >
            <p className="mb-4 font-weight-bold" id='selected_company_and_recruiter'>アソシエイツ名：{selectedCompanyName[0].companyName} <span className="mx-3 d-md-inline d-none">|</span>
                                       募集人名：{recruiter}</p>
            <Row className="mb-32" id={autoId()}>
                <Col>
                    <BackButton id='back_btn' className="back-btn mr-3" title={t('general.button.return')} onClick={onBack} />
                    <GeneralButton id='next_btn' className="cmn-btn" title={t('general.button.next')} onClick={onNextStep} />
                </Col>
            </Row>
            <div className="star-new-role-step2" id={autoId()}>
                <Row>
                    <Col lg="12" id={autoId()}>
                        <h6 id="section_selection" name="section_selection" className="font-weight-bold  d-flex mb-3"> {t('roleplay.step 2.title')}</h6>
                        <SelectScenario id={autoId()} policy={policy} insurance={insurance} sectionCriteria={sectionCriteria} setSectionCriteria={setSectionCriteria} trainerId={trainerId} traineeId={traineeId} agent_company={agent_company} role={role} sevenFilterCode={sevenFilterCode} threeFilterCode={threeFilterCode} uniqueFilterCode={uniqueFilterCode} activeSlider={activeSlider} />
                    </Col>
                </Row>
            </div>
        </div>
    )
}
const stateToProps = state => {
    return {
        scenario: state.scenario,
    }
}

const dispatchToProps = dispatch => {
    return {
        setScenario: (sectionCriteria) => {
            dispatch(setScenario(sectionCriteria));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(Step2);

