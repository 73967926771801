export const USERNAME = 'USERNAME';
export const ACTIVE = 'ACTIVE';
export const OPEN = 'OPEN';
export const SCENARIO = 'SCENARIO';
export const AGENT_COMPANY = 'AGENT_COMPANY';
export const EMPLOYEE_ID = 'EMPLOYEE_ID';
export const ROLE = 'ROLE';
export const USER_ID = 'USER_ID';
export const RECENT_PATH = 'RECENT_PATH';
export const RECRUITER = 'RECRUITER';
export const RECRUITER_ID = 'RECRUITER_ID';
export const RESET_STORE_VAGENT = 'RESET_STORE_VAGENT';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const COMPANY_CODE = 'COMPANY_CODE';
export const BRANCH_CODE = 'BRANCH_CODE';
export const ACTIVE_CODE = 'ACTIVE_CODE';
export const COMPANY = 'COMPANY';
export const SELECTED_COMPANY_AT_SELECTION_OF_RECRUITER = 'SELECTED_COMPANY_AT_SELECTION_OF_RECRUITER';
export const LABEL_AT_ANALYSIS = 'LABEL_AT_ANALYSIS';
export const REQUEST_HEADER_GROUP_ID = 'REQUEST_HEADER_GROUP_ID';
export const REQUEST_HEADER_USER_ID = 'REQUEST_HEADER_USER_ID';
export const SYNC_STATUS = 'SYNC_STATUS';
export const RECRUITER_DETAIL = 'RECRUITER_DETAIL';
export const COMPANY_AT_RECRUITER_DETAIL= "COMPANY_AT_RECRUITER_DETAIL";
export const SELECTED_COMPANY_AT_LEARNING_THEME = 'SELECTED_COMPANY_AT_LEARNING_THEME';
