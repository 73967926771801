import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '../../../../components/RadioButtons';
import { getScenarioByThemeId } from '../../../../api/api'
import { connect } from 'react-redux'
const useStyles = makeStyles({
    root: {
        marginBottom: 0,
        marginRight: 0,
    },
});

const SingleCard = ({ policy, insurance, setInsurance, id, SET_NEW_ROLEPLAY, setIsClicked, disabled = false }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const [insuranceList, setInsuranceList] = useState([])
    useEffect(() => {
        if (policy) {
            getScenarioByThemeId(policy).then(res => {
                let resultList = res.data
                setInsuranceList(resultList)
                let scenarioId = resultList.length > 0 ? (insurance && resultList.map(item => item.scId).includes(parseInt(insurance)) ? insurance : resultList[0].scId) : ''
                setInsurance(scenarioId);
                // SET_NEW_ROLEPLAY(scenarioId)
            })
        }
    }, [policy])

    const handleChange = (event) => {
        setInsurance(parseInt(event.target.value));
        setIsClicked('')
        // SET_NEW_ROLEPLAY(event.target.value)
    };

    return (
        <div style={styles.container}>
            {
                insuranceList.map((item, index) => (
                    <FormControlLabel className={classes.root} style={styles.root} key={index} id={`scenario_${item.scId}`} disabled={disabled} control={
                        <Radio
                            checked={insurance == item.scId}
                            onChange={handleChange}
                            value={item.scId}
                            name="radio-button"
                            color="default"
                            id={`scenario_${item.scId}_${index}`}
                        />
                    } label={<span id={`scenario_${item.scId}_${index}_${index}`} name={`scenario_${item.scId}_${index}_${index}`} style={styles.label} className={`MeiryoRegular ${insurance == item.scId ? 'font-weight-bold' : ''}`}>{item.scName}</span>} />
                ))
            }

        </div>
    )
}

const styles = {
    root: {
        minWidth: '110px',
        marginRight: '0',
        marginBottom: '8px',
        padding: '0',
        marginLeft: '0',
    },
    label: {
        marginButtom: '0',
        fontSize: '14px',
        fontWeight: 'normal',
        marginRight: '48px',
        marginLeft: '8px',
    }
};

const mapDispatchToProps = dispatch => ({
    SET_NEW_ROLEPLAY: (res) => dispatch({ type: 'SET_NEW_ROLEPLAY', paramData: { scenarioId: res } })
})

export default connect(null, mapDispatchToProps)(SingleCard);



