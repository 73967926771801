import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Row, Col } from 'reactstrap';
import moment from 'moment'
import Sidebar from '../../components/Sidebar';
import LogoutButton from '../../components/Buttons/LogoutButton';
import GeneralButton from '../../components/Buttons/GeneralButton';
import MonthPicker from '../../components/Calendar/MonthPicker';
import ConfirmDialog from '../../components/Dialog/ConfirmDialog';
import BackButton from './../../components/Buttons/BackButton';
import { GeneralDropdown } from './../../components/Dropdowns/GeneralDropdown';
import EventBus from '../../components/EventBus'
import { setRecentPath, setSelectedCompanyCodeAtSelectionOfRecruiter } from '../../store/actions';
import { getTrainingPlan, getUserList, updateTrainingPlan } from '../../api/api';
import { longestString, getASCodeCompany,isApigwEnv } from '../../utils/General'
import classes from './styles.module.css';
import { COMPANY_CODES_URL } from "../../configs/urlConstants";
import { isTrainerManagerOrTrainee } from '../../configs/constants';
import trainingPlanData from '../../staticData/TrainingPlan.json'

const TrainingPlan = ({ access_token, isOpen, location, setRecentPath, company_at_selection_of_recruiter,syncStatus , company_at_learning_theme}) => {
    const history = useHistory();
    const { t } = useTranslation();


    const [confirmOpen, setConfirmOpen] = useState(false);
    const [onASCode, setAsCode] = useState();
    const [asCodeData, setAsCodeData] = useState();
    const [selectedASCode, setSelectedASCode] = useState();
    const [longest, setLogest] = useState();
    const [agentCompanyCode, setAgentCompanyCode] = useState();
    const [trainingPlan, setTrainingPlan] = useState([]);
    const [trainingPlanOrgData, setTrainingPlanOrgData] = useState([]);
    const [editState, setEditState] = useState(false);
    const [refresh, setRefresh] = useState(true);
    const [patternCount, setPatternCount] = useState([]);
    const noData = <tr><td colSpan="100%">{t('general.text.no data available')}</td></tr>;
    const [reset, makeAllReset] = useState(false)
    const [activeusers, setActiveUsers] = useState()

    var d = new Date();
    var lastDate =( d.getFullYear()+3)+''+(d.getUTCMonth()+1);

    let lastId = 0;
    const autoId = (prefix = 'tp-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        getUserList(COMPANY_CODES_URL).then(res => {
            setAsCodeData(res.data.sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            let longestName = longestString(res.data.map(item => { return { name: item.name } }))
            setLogest(longestName < 15 ? 4 : longestName >= 15 ? 5 : longestName >= 20 ? 6 : longestName >= 25 ? 7 : 8)
        })
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainerManagerOrTrainee()) {
            makeAllReset(!reset)
            return
        }
    if (company_at_selection_of_recruiter !== '') {   
        setSelectedASCode(company_at_selection_of_recruiter.AS_code)
        setAsCode(getASCodeCompany(company_at_selection_of_recruiter.AS_code.name)[1]);
        setAgentCompanyCode(getASCodeCompany(company_at_selection_of_recruiter.AS_code.name)[0]);
        setActiveUsers(company_at_selection_of_recruiter.active_users)
    }
    if (company_at_learning_theme !== '') {
        setSelectedASCode(company_at_learning_theme)
        setAsCode(getASCodeCompany(company_at_learning_theme.name)[1]);
        setAgentCompanyCode(getASCodeCompany(company_at_learning_theme.name)[0]);
    }
    setRecentPath(location.pathname);
}, []);
useEffect(() => {
    if (agentCompanyCode !== undefined) {
        getTrainingPlan(agentCompanyCode).then(res => {
            if (res.status === 200) {
                setTrainingPlan([])
                var newArray = [];
                var orgArray = [];
                res.data.length>0 && res.data.map((item, key) => {
                // trainingPlanData.length>0 && trainingPlanData.map((item, key) => {
                    newArray.push({
                        "userId": item.userId,
                        "userName": item.userName,
                        "joinDate": item.joinDate === null ? null : item.joinDate + "T09:00:00Z",
                        "estimatedCompletionYearMonth": item.estimatedCompletionYearMonth,
                        "themes": item.themes,
                    })
                    var themes = [];
                    item.themes.map(theme => {
                        themes.push(
                            {
                                expectedCompletionYearMonthOrg: theme.expectedCompletionYearMonth,
                                id: theme.id,
                                name: theme.name,
                                sortingOrder: theme.sortingOrder
                            }
                        )
                    })
                    orgArray.push({
                        "userIdOrg": item.userId,
                        "userNameOrg": item.userName,
                        "joinDateOrg": item.joinDate === null ? null : item.joinDate + "T09:00:00Z",
                        "estimatedCompletionYearMonthOrg": item.estimatedCompletionYearMonth,
                        "themesOrg": themes,
                    })
                })
                setTrainingPlan([...newArray]);
                setTrainingPlanOrgData(orgArray);
                res.data.length>0 && setPatternCount(res.data[0].themes)
                // trainingPlanData.length>0 && setPatternCount(trainingPlanData[0].themes)
            }
        })
            .catch(err => {
                EventBus.dispatch('show_snack', { message: '問題が発生しています', type: 'error' });
            });
    }
}, [refresh, agentCompanyCode,syncStatus]);
const openDialog = () => {
    let checkResult = [];
    for (let index = 0; index < trainingPlanOrgData.length; index++) {
        if(
            trainingPlanOrgData[index].joinDateOrg === trainingPlan[index].joinDate
            && trainingPlanOrgData[index].estimatedCompletionYearMonthOrg === trainingPlan[index].estimatedCompletionYearMonth && checkAllTheme(trainingPlanOrgData[index].themesOrg,trainingPlan[index].themes)
        ){
            checkResult.push(true)
        }else{
            checkResult.push(false)
        }
    }
    var checkDuplicate = checkResult.every(item => item === true);
    if (checkDuplicate) {
        EventBus.dispatch('show_snack', { message: "更新データがありません", type: 'error' });
    } else {
        setConfirmOpen(true);
    }
}
const checkAllTheme = (orgTheme,updateTheme) => {
    let checkResult = [];
    for (let index = 0; index < orgTheme.length; index++) {
        if(orgTheme[index].expectedCompletionYearMonthOrg === updateTheme[index].expectedCompletionYearMonth){
            checkResult.push(true);
        }else{
            checkResult.push(false);
        }
    }
    return checkResult.every(item => item === true);
}
const savePlan = () => {
    updateTrainingPlan(trainingPlan).then(res => {
        if (res.status === 200) {
            EventBus.dispatch('show_snack', { message: res.data.message, type: 'success' })
            setEditState(false);
            setRefresh(!refresh)
        }
    })
        .catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: err.response.data.message, type: 'error' });
            }
            else {
                EventBus.dispatch('show_snack', { message: '問題が発生しています', type: 'error' });
            }
        })
}

const onEdit = () => {
    setEditState(true)
}
const onBack = () => {
    setRefresh(!refresh)
    setEditState(false)
}
const onRedirectSelectionRecruiter = () => {
    company_at_selection_of_recruiter !== '' && history.replace('/SelectionOfRecruiter')
}

const handleCompanyName = (el) => {
    setAsCode(getASCodeCompany(el)[1]);
    setAgentCompanyCode(getASCodeCompany(el)[0]);
}
const checkMonth = (preDate,currentThemeDate) =>{
    preDate = preDate.toString();
    currentThemeDate = currentThemeDate.toString();
    if (preDate.length === 6 && currentThemeDate.length === 6) {
        let preYear = preDate.slice(0,4)
        let preMonth = preDate.slice(4)
        let nextYear = currentThemeDate.slice(0,4)
        let nextMonth = currentThemeDate.slice(4)
        var fullPreDate = moment(preYear+"-"+preMonth+"-01")
        var fullcurrentThemeDate = moment(nextYear+"-"+nextMonth+"-01")
        if (fullPreDate > fullcurrentThemeDate) {
            return true;
        }
    }else if(preDate.length === 1 && currentThemeDate.length === 6){
        return true;
    }
}
return (
    <>
        <Sidebar />
        <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
            <div className="main-content-inr training-plan" id="training-plan">
                <Row>
                    <Col lg="12" className="cmn-logout-btn-sec">
                        <h3 className="text-left d-flex justify-content-between" id="training_plan_title">
                            {t('TrainingPlan.title')}
                        </h3>
                        <LogoutButton title={t('general.button.logout')} className={'font-14'} id={autoId()} reset={reset} />
                    </Col>
                </Row>
                <Row>
                    <Col lg="12">
                        <Row className="mb-4">
                            <Col lg={`${longest} `}>
                                <div className="cmn-bg-box-inr">
                                    <label className="mb-2 font-weight-bold" id="tplan_lb_as_code">AS コード</label>
                                    <GeneralDropdown
                                        isStatic={true}
                                        staticData={asCodeData}
                                        onSelect={(el) => handleCompanyName(el.textContent)}
                                        url={COMPANY_CODES_URL}
                                        selectedData={selectedASCode && selectedASCode.name}
                                        placeholder="選択してください"
                                        dropdown_id="training_plan_dropdown" />
                                </div>
                            </Col>
                        </Row>
                        {
                            (agentCompanyCode !== undefined) ?
                                <div className={`cmn-bg-box ${classes.mb120}`}>
                                    <Row className="mb-4">
                                        <Col>
                                            {!editState ?
                                                <GeneralButton id={autoId()} className="cmn-btn font-14" title={t('general.button.edit')} onClick={onEdit} />
                                                : <>
                                                    <BackButton id={autoId()} className="back-btn font-14  mr-3" title={t('general.button.return')} onClick={onBack} />
                                                    <GeneralButton id={autoId()} className="cmn-btn font-14" title={t('general.button.reflect')} onClick={openDialog} />
                                                </>
                                            }
                                            <ConfirmDialog
                                                title={t('general.text.do_you_want_to_save')}
                                                open={confirmOpen}
                                                setOpen={setConfirmOpen}
                                                onConfirm={savePlan}
                                                onCancel={onRedirectSelectionRecruiter}
                                            ></ConfirmDialog>
                                        </Col>
                                        <Col className="text-right">
                                            <BackButton id={autoId()} className="back-btn" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                        </Col>
                                    </Row>
                                    <p className="mb-3 font-weight-bold" id="tplan_as_name">アソシエイツ名：{onASCode} </p>
                                    <div className="cmn-bg-box-inr">
                                        <h5 className="font-weight-bold font-14" id="tplan_list">{t('TrainingPlan.title')}</h5>
                                        <div className={`${classes.trainingplan_table}`}>
                                            <table id={autoId()} className="table cmn-table">
                                                <thead>
                                                    <tr id={autoId()} className="text-center">
                                                        <th id={autoId()} rowSpan="2" className="text-left align-middle">{t('TrainingPlan.Achieve Steps')}</th>
                                                        <th id={autoId()} colSpan="2">{t('TrainingPlan.Early education')}</th>
                                                        {
                                                            patternCount && patternCount.map((themeItem, themeIndex) => {
                                                                return (
                                                                    <th id={autoId()} key={themeIndex}>{t('step ' + (themeIndex + 1))}</th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                    <tr id={autoId()} className="text-center">
                                                        <th id={autoId()}>{t('TrainingPlan.Starting Month')}</th>
                                                        <th id={autoId()}>{t('TrainingPlan.Initial Complete Month')}</th>
                                                        {
                                                            patternCount && patternCount.map((themeItem, themeIndex) => {
                                                                return (
                                                                    <th id={autoId()} key={themeIndex}>{themeItem.name}</th>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        trainingPlan.length === 0 ? noData :
                                                            trainingPlan.map((item, key) => {
                                                                return (
                                                                    <tr id={autoId()} key={key}>
                                                                        <td className={`text-left font-weight-bold td-120 ${activeusers && activeusers.some(el => el.userName === item.userName) ? classes.active_user : ''}`}>{item.userName}</td>
                                                                        <td className={`text-center ${activeusers && activeusers.some(el => el.userName === item.userName) ? classes.active_picker : ''}` }><MonthPicker userkey={key} setTrainingPlan={setTrainingPlan} trainingPlan={trainingPlan} status={editState} editType="joinDate" selectedData={item.joinDate} nextDate={item.estimatedCompletionYearMonth} /></td>
                                                                        <td className={`text-center ${activeusers && activeusers.some(el => el.userName === item.userName) ? classes.active_picker : ''}` }><MonthPicker userkey={key} setTrainingPlan={setTrainingPlan} trainingPlan={trainingPlan} status={editState} editType="estimatedCompletionYearMonth" selectedData={item.estimatedCompletionYearMonth} preDate={item.joinDate} nextDate={item.themes[0].expectedCompletionYearMonth} /></td>
                                                                        {
                                                                            item.themes.map((themeItem, themeIndex) => {
                                                                                var firstItem = item.themes.indexOf(item.themes[0]);
                                                                                var lastItem = item.themes.indexOf(item.themes[item.themes.length - 1]);
                                                                                return (
                                                                                    firstItem === themeIndex?
                                                                                        <td className={`text-center ${activeusers && activeusers.some(el => el.userName === item.userName) ? classes.active_picker : ''}`} key={themeIndex}>
                                                                                            <MonthPicker userkey={key} themekey={themeIndex} setTrainingPlan={setTrainingPlan} trainingPlan={trainingPlan} status={editState} editType="expectedCompletionYearMonth" selectedData={themeItem.expectedCompletionYearMonth} preDate={item.estimatedCompletionYearMonth} nextDate={item.themes.length === 1?null:item.themes[firstItem + 1].expectedCompletionYearMonth} />
                                                                                        </td>
                                                                                        : lastItem === themeIndex ?
                                                                                            <td className={`text-center ${checkMonth(item.themes[lastItem - 1].expectedCompletionYearMonth,item.themes[lastItem].expectedCompletionYearMonth)?classes.warning_month:''} ${activeusers && activeusers.some(el => el.userName === item.userName) ? classes.active_picker : ''}`} key={themeIndex}>
                                                                                                <MonthPicker userkey={key} themekey={themeIndex} setTrainingPlan={setTrainingPlan} trainingPlan={trainingPlan} status={editState} editType="expectedCompletionYearMonth" selectedData={item.themes[lastItem].expectedCompletionYearMonth} preDate={item.themes[lastItem - 1].expectedCompletionYearMonth} nextDate={lastDate} />
                                                                                            </td>
                                                                                            :
                                                                                            <td className={`text-center ${checkMonth(item.themes[themeIndex - 1].expectedCompletionYearMonth,themeItem.expectedCompletionYearMonth)?classes.warning_month:''} ${activeusers && activeusers.some(el => el.userName === item.userName) ? classes.active_picker : ''}`} key={themeIndex}>
                                                                                                <MonthPicker userkey={key} themekey={themeIndex} setTrainingPlan={setTrainingPlan} trainingPlan={trainingPlan} status={editState} editType="expectedCompletionYearMonth" selectedData={themeItem.expectedCompletionYearMonth} preDate={item.themes[themeIndex - 1].expectedCompletionYearMonth} nextDate={item.themes[themeIndex + 1].expectedCompletionYearMonth} />
                                                                                            </td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                )
                                                            })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                : ""
                        }

                    </Col>
                </Row>
            </div>
        </div>
    </>
)
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        agent_id: state.vAgent.agent_id,
        access_token: state.vAgent.access_token,
        recent_path: state.vAgent.recent_path,
        company_at_selection_of_recruiter: state.vAgent.company_at_selection_of_recruiter,
        syncStatus: state.vAgent.syncStatus,
        company_at_learning_theme:state.vAgent.company_at_learning_theme
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
        setSelectedCompanyCodeAtSelectionOfRecruiter: (company_at_selection_of_recruiter) => {
            dispatch(setSelectedCompanyCodeAtSelectionOfRecruiter(company_at_selection_of_recruiter));
        }
    }
}

export default connect(stateToProps, dispatchToProps)(TrainingPlan);