import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import KeywordCard from '../../components/Cards/KeywordCard';
import EventBus from '../../components/EventBus'

const EditKeyword = ({ className, style, items, keywords, setKeywords, keyword_type, section_id, criteria_id, status, id, button_id }) => {
    const { t } = useTranslation();
    const [newKeyword, setNewKeyword] = useState(false);
    const [list, setList] = useState((items.length === 1 && items[0].name === '') ? [] : items);
    const [newAdd, setNewAdd] = useState('');

    let lastId = 0;
    const autoId = () => {
        return '-' + lastId++;
    }

    const onAddHandle = () => {
        setNewKeyword(true)
    }

    const onDone = (event) => {
        let value = newAdd, count = 0;
        for (let i = 0; i < value.length; i++) {
            if (value[i] === ' ') {
                count++;
            }
        }
        if (count !== value.length) {
            let item = list.filter(item => item.name === newAdd);
            if (item.length !== 0) {
                EventBus.dispatch('show_snack', { message: 'キーワードが既に依存しています。', type: "error" })
            }
            else {
                EventBus.dispatch('close_snack')
                newAdd.length > 0 && item.length === 0 && setList(
                    [
                        ...list,
                        {
                            id: list.length === 0 ? 1 : parseInt(list[list.length - 1].id) + 1,
                            name: newAdd,
                            delete: false,
                            action: 'add',
                            newAdd: true
                        }
                    ])
                setNewAdd('')
                setNewKeyword(false)
            }
        } else if (count === value.length) {
            EventBus.dispatch('show_snack', { message: 'キーワードを入力の上、”追加”ボタンを押してください。', type: "error" })
            setNewAdd('')
            setNewKeyword(false)
        }
    }

    useEffect(() => {
        const newArray = list && keywords && keywords.map(section => {
            section.sections.map(data => {
                if (data.id === section_id) {
                    data.criteria.map(criteria => {
                        if (criteria.id === criteria_id) {
                            criteria.keywords = list
                            criteria.modifyFlag = true
                        }
                    })
                }
            })
            return section
        })
        newArray && setKeywords(newArray)
    }, [list])

    const onAddNew = (event) => {
        setNewAdd(event.target.value.trim())
    }

    const onDelete = (event) => {
        let condition = event.target.textContent
        let id = event.target.value
        setList(list.map(item => {
            if (parseInt(item.id) === parseInt(id) && !item.delete && condition === t('edit keywords.delete')) {      // delete button
                item.delete = true;
                item.action = 'delete'
            } else if (parseInt(item.id) === parseInt(id) && item.delete && condition === t('edit keywords.cancel')) {    // cancel button
                item.delete = false;
                item.action = 'cancel'
            }
            return item;
        }))
    }

    return (
        <>
            {
                list.map((item, index) => {
                    return (
                        <>
                            {keyword_type === true ?
                                <KeywordCard
                                    keywords={keywords}
                                    setKeywords={setKeywords}
                                    title={item.name}
                                    key={index}
                                    value={item.id}
                                    id={`${id}${autoId()}`}
                                    button_id={`${button_id}${autoId()}`} />
                                :
                                <KeywordCard
                                    keywords={keywords}
                                    setKeywords={setKeywords}
                                    title={item.name}
                                    key={index}
                                    button_label={item.action === 'delete' ? t('edit keywords.cancel') : t('edit keywords.delete')}
                                    value={item.id}
                                    onClick={onDelete}
                                    status={(!item.delete && item.action === '') ? t('edit keywords.done')
                                        : (!item.delete && item.action === 'add' || !item.delete && item.action === 'cancel' && item.newAdd) ? t('edit keywords.add')
                                            : (item.delete && item.action === 'delete') && t('edit keywords.delete')}
                                    id={`${id}${autoId()}`}
                                    button_id={`${button_id}${autoId()}`} />
                            }
                        </>

                    )
                })
            }

            {newKeyword ?
                <KeywordCard button_label={t('edit keywords.done')} onClick={onDone} onChange={onAddNew} id={`${id}${autoId()}`} button_id={`${button_id}${autoId()}`} />
                :
                keyword_type !== true && <KeywordCard button_label={t('edit keywords.add')} onClick={onAddHandle} id={`${id}${autoId()}`} button_id={`${button_id}${autoId()}`} />}
        </>
    )
}
export default EditKeyword;