import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FinishButton from '../../components/Buttons/FinishButton';
import GeneralButton02 from '../../components/Buttons/LargeButton';
import { Row, Col } from 'reactstrap';
import styles from './style.module.css';
import up_arrow from '../../assets/images/training_up_arrow.png';
import down_arrow from '../../assets/images/training_down_arrrow.png';
import Sidebar from './../../components/Sidebar';
import OngoingScenerio from './OngoingScenerio';
import ListOfScenerio from './ListOfScenerio';
import CheckCriteria from './CheckCriteria';
import { useHistory } from "react-router-dom";
import { getStartRolePlayList, startNextSection, finishRolePlay, updateHumanEval, updateRolePlayEndTime, updateSectionStatus, updateRecordingStatus, addBookmarkToSection } from '../../api/api'
import EventBus from '../../components/EventBus'
import ConfirmRecord from '../../components/Dialog/ConfirmRecord';
import { isTrainee } from '../../configs/constants';
import { isApigwEnv } from '../../utils/General'

const MicrosoftTeamTraningPage = ({ isOpen, location, access_token, rolePlayId, callId, themeId, scenarioId, currentSectionIndex, urls, SET_NEW_ROLEPLAY }) => {
    const history = useHistory();
    const { t } = useTranslation();
    let prevIndex = '';

    // SET_NEW_ROLEPLAY({currentSectionIndex: 0})
    const [isGetListOpen, setGetListOpen] = React.useState(false);

    const [disableBtn, setDisableBtn] = React.useState(false)
    const [selectedScenario, setSelectedScenario] = React.useState([]);
    const [currentSection, setCurrentSection] = React.useState("");
    const [currentCriteria, setCurrentCriteria] = React.useState([])

    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [bookmark, setBookmark] = React.useState(false);
    const [bookmarkTime, setBookmarkTime] = React.useState()

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        if (isTrainee()) {
            history.push("/Logout");
            return
        }
    }, []);

    useEffect(() => {
        getStartRolePlayList(rolePlayId, themeId, scenarioId).then(res => {
            let selectedData = res.data.map((val, index) => {
                return {
                    id: val.sectionId,
                    section: val.sectionName,
                    sectionStatus: val.sectionStatus,
                    criteria: val.criteriaResult.map((value, key) => {
                        return {
                            sectionId: value.sectionId,
                            id: value.criteriaId,
                            name: value.criteriaName,
                            condition: "",
                            humanEval: value.humanEval,
                            aiEval: value.aiEval,
                            requiredFlag: value.requiredFlag
                        }
                    })
                }
            })
            setSelectedScenario(selectedData);
            setCurrentSection(selectedData[currentSectionIndex])
            setCurrentCriteria(selectedData[currentSectionIndex].criteria)
        })
    }, [currentSectionIndex])

    const handleFinish = () => {
        setConfirmOpen(true)
    }

    const confirmFinished = () => {
        updateRecordingStatus(rolePlayId).then(res => {
            SET_NEW_ROLEPLAY({ recordingStatus: 1 })
            handleNext(true).then(res2 => {
                history.push("/ai_scoring_result")
            })
            startNextSection(urls.divided, 0)
        })

    }

    const cancel = () => {
        handleNext(true).then(res => {
            updateRolePlayEndTime(rolePlayId).then(res => {
                history.push("/ai_scoring_result")
            })
        })
        finishRolePlay(urls.finished)
    }

    const handleNext = (finishFlag = false) => {
        return new Promise((resolve, reject) => {
            setDisableBtn(true)
            let index = currentSectionIndex
            let paramList = []
            currentCriteria.forEach(item => {
                let param = {
                    rolePlayId: rolePlayId,
                    themeId: themeId,
                    scenarioId: scenarioId,
                    sectionId: item.sectionId,
                    criteriaId: item.id,
                    humanEval: item.humanEval
                }
                paramList.push(param)
            })
            updateHumanEval(paramList).then(res => {
                let sectionParam = {
                    rolePlayId,
                    themeId,
                    scenarioId,
                    currentSectionId: selectedScenario[index].id,
                    nextSectionId: finishFlag ? "" : (index < selectedScenario.length - 1) ? selectedScenario[index + 1].id : ""
                }
                updateSectionStatus(sectionParam).then(res2 => {
                    if (finishFlag) {
                        resolve(true)
                        setDisableBtn(false)
                        return
                    }
                    if (currentSectionIndex < selectedScenario.length - 1) {
                        index += 1
                        SET_NEW_ROLEPLAY({ currentSectionIndex: index })
                        setCurrentSection(selectedScenario[index])
                        setCurrentCriteria(selectedScenario[index].criteria)
                        startNextSection(urls.divided, selectedScenario[index].id) // pathParam: podAddress
                        setDisableBtn(false)
                    } else {
                        resolve(true)
                        setDisableBtn(false)
                        // EventBus.dispatch('show_snack', { message: 'シナリオが完了しました。終了ボタンを押してください。', type: 'error' })
                        // alert("シナリオが完了しました。終了ボタンを押してください。")
                    }
                })
                setBookmark(false)
                setBookmarkTime()
            })
            bookmarkTime !== undefined && addBookmarkToSection(rolePlayId, selectedScenario[index].id, bookmarkTime).then(res => {
                console.log('updated !');
            }).catch(err => {
                EventBus.dispatch('show_snack', { message: "更新に失敗しました", type: 'error' })
            })
        })
    }

    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className={`main-content-inr`} id="microsoft-team-training" >
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="microsoft_team_training" name="microsoft_team_training">
                                {t('microsoft team traning.title')}
                            </h3>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <div>
                                <FinishButton title={t('general.button.finish')} onClick={() => handleFinish()} className="font-18 mb-32 mr-3" id="finish_btn" />
                                {currentSectionIndex < selectedScenario.length - 1 ?
                                    <GeneralButton02 title={t('general.button.Next Scenario')} onClick={() => handleNext()} className={`btn-large mb-32 ${currentSectionIndex < selectedScenario.length - 1 ? '' : styles.grey}`} disabled={disableBtn ? 'disabled' : ''} id="next_scenario_btn" />
                                    : <GeneralButton02 title={t('general.button.Next Scenario')} onClick={() => handleNext()} className={`btn-large mb-32 ${currentSectionIndex < selectedScenario.length - 1 ? '' : styles.grey}`} disabled='disabled' id="next_scenario_btn" />}

                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={7} md="12">
                            <div className={`cmn-bg-box mb-4 ${styles.border_radious_4}`}>
                                <div className={` ${styles.active} ${styles.active} `}>
                                    <Row>
                                        <Col lg="12">
                                            <h5 className="font-weight-bold font-14 d-flex" id="current_scenario" name="current_scenario"> {t('microsoft team traning.Ongoing scenario')} </h5>
                                            <OngoingScenerio
                                                text={currentSection.section}
                                                title={t('general.button.in progress')}
                                                className={`font-14 ${styles.in_progress_button}`}
                                                bookmark={bookmark} setBookmark={setBookmark}
                                                bookmarkTime={bookmarkTime} setBookmarkTime={setBookmarkTime} />
                                        </Col>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col lg="12">
                                            <h5 className="font-14" id="ongoing_check_criteria"> {t('microsoft team traning.Check Criteria')} </h5>
                                            <CheckCriteria currentCriteria={currentCriteria} setCurrentCriteria={setCurrentCriteria} selectedScenario={selectedScenario} setSelectedScenario={setSelectedScenario} />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className={`cmn-bg-box mt-3  ${styles.border_radious_4}  ${styles.check_cretria_box}`}>
                                <Row>
                                    <Col lg="12">
                                        <h5 className="font-14" id="list_of_scenarios" name="list_of_scenarios"> {t('microsoft team traning.list of scenarios')}</h5>
                                        <div className={`${styles.list_box} `}>
                                            <button className={`${styles.get_url_button}`} onClick={() => setGetListOpen(!isGetListOpen)}>
                                                <span className="font-14" id="list_of_scenario_item">{t('microsoft team traning.indicate')}</span>
                                                <img className={`${styles.icon} `} src={` ${isGetListOpen ? up_arrow : down_arrow}`} alt="" id="list_of_scenario_arrowBtn"/>
                                            </button>
                                            <div className={`${styles.get_list_content}  ${isGetListOpen ? styles.get_list_content_show : styles.get_list_content} `}>
                                                <Row>
                                                    <Col lg="12">
                                                        <ListOfScenerio ScenarioList={selectedScenario} currentSection={currentSection} title={t('general.button.underscoring')} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <ConfirmRecord
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={confirmFinished}
                onCancel={cancel}
            ></ConfirmRecord>
        </>
    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
        rolePlayId: state.startRolePlay.rolePlayId,
        callId: state.startRolePlay.callId,
        themeId: state.startRolePlay.themeId,
        scenarioId: state.startRolePlay.scenarioId,
        currentSectionIndex: state.startRolePlay.currentSectionIndex,
        urls: state.startRolePlay.urls
    }
}

const mapDispatchToProps = dispatch => ({
    SET_NEW_ROLEPLAY: (res) => dispatch({ type: 'SET_NEW_ROLEPLAY', paramData: res })
})


export default connect(stateToProps, mapDispatchToProps)(MicrosoftTeamTraningPage);