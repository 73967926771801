import React, { useRef, useState, useEffect } from 'react';
import up_arrow from '../../../assets/images/up_arrow.png';
import down_arrow from '../../../assets/images/down_arrow.png';
import StyledCheckbox from '../../Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classes from './styles.module.css';
import EventBus from '../../EventBus';

const CheckboxDropdown = ({ placeholder, onSelect, className, selectedKeywords, dropdown_id, staticData, position, section_id, criteria_id, editState }) => {
    const list = useRef();
    const [userList, setUserList] = useState([]);
    const [open, setOpen] = useState(false);
    useEffect(() => {
        const newArray = staticData && staticData[0].sections.filter(data => data.id === section_id)[0].criteria
            .filter(criteria => criteria.id === criteria_id)
        setUserList(newArray)

    }, [section_id, criteria_id, staticData, open])

    const onOpen = () => {
        editState &&  setOpen(!open);
        editState &&   list.current.focus();
    }

    const close = () => {
        setOpen(false);
    }

    useEffect(() => {
        !open && setUserList(staticData && staticData[0].sections.filter(data => data.id === section_id)[0].criteria
            .filter(criteria => criteria.id === criteria_id))
    }, [open])

    const itemClick = (e) => {
        let tempArray = [...selectedKeywords];
        if (e.target.checked) {
            if (selectedKeywords.length < 10) {
                tempArray.push({ checked: '1', name: e.target.value, newlyAdded: true });
            }
            else {
                e.target.checked = false;
                EventBus.dispatch('show_snack', { message: '最大キーワードの追加制限は10です。', type: 'error' })
            }
        }
        else {
            tempArray = tempArray.filter(item => item.name !== e.target.value);
        }

        const newArray = staticData && staticData.map(section => {
            section.sections.map(data => {
                if (data.id === section_id) {
                    data.criteria.map(criteria => {
                        if (criteria.id === criteria_id) {
                            if (criteria.keywordConditionsJSON.length < 10) {
                                criteria.keywordConditionsJSON.map((item, index) => {
                                    if (index === position) {
                                        item.keyword = tempArray;
                                        criteria.modifyFlag = true
                                    }
                                })
                            }
                        }
                    })
                }
            })
            return section
        });

        onSelect(newArray, tempArray);
    }

    const isEqualCheckbox = (name) => {
        let result = false;
        selectedKeywords.map(checkboxItem => {
            if (checkboxItem.name === name) {
                result = true;
            }
        });
        return result;
    }

    return (
        <div className={`${classes.select_box} ${className} ${open ? classes.select_box_radius : ''}`} ref={list} onMouseLeave={close} id={dropdown_id}>
            <div onClick={onOpen} className={'d-flex justify-content-between  align-items-center'} >
                <span>{placeholder}</span>
                <img src={`${open ? up_arrow : down_arrow} `} className={`${classes.arrows} `} />
            </div>
            <div tabIndex="0">
                <ul className={`${open ? classes.showbox : classes.hidebox} ${classes.min_height} `} >
                    {
                        userList && userList[0] && userList[0].keywords && userList[0].keywords.map((item, index) => (
                            <>
                                {item.name !== '' && <li key={index} value={item.id} id={`${dropdown_id}-${item.id}`} >
                                    <FormControlLabel
                                        style={styles.root}
                                        control={<StyledCheckbox
                                            value={item.name}
                                            checked={isEqualCheckbox(item.name)}
                                            id={item.id + '_' + item.name}
                                            onClick={itemClick}
                                        />}
                                        label={item.name}
                                    />
                                </li>
                                }
                            </>
                        )
                        )
                    }
                </ul>
            </div>
        </div>
    )
}
const styles = {
    root: {
        marginLeft: 0,
        marginBottom: 0
    },
    label: {
        marginButtom: 0,
        fontSize: '16px',
        paddingLeft: '8px'
    }
};
export { CheckboxDropdown };