import { getEmployeeInfo, getAllTraineesForAgentCompany } from '../api/mstDBApi'
import { isEmpty, padLeadingZeros } from './General'
import { COMPANY_WITH_BRANCHES_URL } from '../configs/urlConstants'
import { traineeInfoSyncToSystem, traineeListInfoSyncToSystem, getUserList } from '../api/api'
import store from '../store'

export function syncForTrainerOrTrainee(value, url = "") {
    let employeeInfoParams = { salsmanCde: value.empId }
    let employeeInfoData = null, traineeInfoList = null;
    getEmployeeFromMstDb(employeeInfoParams).then(res => {
        employeeInfoData = res
        let syncParams = isEmpty(employeeInfoData) || (!isEmpty(employeeInfoData) && employeeInfoData.solicitorInf && employeeInfoData.solicitorInf.length === 0) || employeeInfoData.hasOwnProperty('error')
            ? { username: value.name ? value.name : '' }
            : { username: employeeInfoData.salsmanSeiKj + " " + employeeInfoData.salsmanMeiKj }
        SyncTrainee(syncParams).then(response => {
            storeData(response, url)
            let real_params =
                isEmpty(employeeInfoData) || (!isEmpty(employeeInfoData) && employeeInfoData.solicitorInf && employeeInfoData.solicitorInf.length === 0) || employeeInfoData.hasOwnProperty('error')
                    ? {
                        agntCde: padLeadingZeros(response.agentCompany.code, 7),
                        agstCde: response.agentCompany.branch.code
                    }
                    : {
                        agntCde: padLeadingZeros(employeeInfoData.agtCde, 7),
                        agstCde: employeeInfoData.agtBrnCde
                    };
            getTraineeListFromMstDb(real_params).then(trainee => {
                traineeInfoList = trainee
                let synctraineeParams = [];
                traineeInfoList && !isEmpty(traineeInfoList) && (employeeInfoData.solicitorInf && traineeInfoList.solicitorInf.length > 0) && !employeeInfoData.hasOwnProperty('error') && traineeInfoList.solicitorInf.map(item => {
                    synctraineeParams.push({
                        empId: item.salsmanCde,
                        username: item.salsmanSeiKj + " " + item.salsmanMeiKj
                    })
                })
                if (synctraineeParams.length > 0) {
                    (syncTraineeList(
                        isEmpty(employeeInfoData) || (!isEmpty(employeeInfoData) && employeeInfoData.solicitorInf && employeeInfoData.solicitorInf.length === 0) || employeeInfoData.hasOwnProperty('error') ? response.agentCompany.code : employeeInfoData.agtCde,
                        isEmpty(employeeInfoData) || (!isEmpty(employeeInfoData) && employeeInfoData.solicitorInf && employeeInfoData.solicitorInf.length === 0) || employeeInfoData.hasOwnProperty('error') ? response.agentCompany.branch.code : employeeInfoData.agtBrnCde, synctraineeParams))
                        && store.dispatch({ type: 'SYNC_STATUS', syncStatus: true });
                }
            });
        }).catch(error => {
            syncForAflacMember()
        })

    }).catch(error => {
        let syncParams = { username: value.name ? value.name : '' }
        SyncTrainee(syncParams).then(response => {
            storeData(response, url)
            let real_params = {
                agntCde: padLeadingZeros(response.agentCompany.code, 7),
                agstCde: response.agentCompany.branch.code
            }
            getTraineeListFromMstDb(real_params).then(trainee => {
                traineeInfoList = trainee
                let synctraineeParams = [];
                traineeInfoList && !isEmpty(traineeInfoList) && (traineeInfoList.solicitorInf && traineeInfoList.solicitorInf.length > 0) && !employeeInfoData.hasOwnProperty('error') && traineeInfoList.solicitorInf.map(item => {
                    synctraineeParams.push({
                        empId: item.salsmanCde,
                        username: item.salsmanSeiKj + " " + item.salsmanMeiKj
                    })
                })
                if (synctraineeParams.length > 0) {
                    (syncTraineeList(response.agentCompany.code, response.agentCompany.branch.code, synctraineeParams)) && store.dispatch({ type: 'SYNC_STATUS', syncStatus: true });
                }
            });
        }).catch(error => {
            syncForAflacMember()
        })
    })
}

export function syncForAflacMember() {
    let traineeInfoList = '', count = 1;
    getUserList(COMPANY_WITH_BRANCHES_URL).then(agentListRes => {
        // console.log({ agentListRes })
        agentListRes.data.length > 0 &&
            agentListRes.data.map((item, index) => {
                item.branches.map(branch => {
                    let real_params = {
                        agntCde: padLeadingZeros(item.code, 7),
                        agstCde: branch.code
                    };
                    getTraineeListFromMstDb(real_params).then(res => {
                        // console.log({ real_params })
                        // console.log({ res })
                        traineeInfoList = res;
                        let synctraineeParams = [];
                        traineeInfoList && !isEmpty(traineeInfoList) && (traineeInfoList.solicitorInf && traineeInfoList.solicitorInf.length > 0) && !traineeInfoList.hasOwnProperty('error') && traineeInfoList.solicitorInf.map(traineeItem => {
                            synctraineeParams.push({
                                empId: traineeItem.salsmanCde,
                                username: traineeItem.salsmanSeiKj + " " + traineeItem.salsmanMeiKj
                            })
                        })
                        if (synctraineeParams.length > 0) {
                            count = (syncTraineeList(item.code, branch.code, synctraineeParams)) ? (count + 1) : count;
                            count === agentListRes.data.length && store.dispatch({ type: 'SYNC_STATUS', syncStatus: true });
                        }

                    });
                })
            })
        // console.log({ count });
        // console.log(agentListRes.data.length);

    })
}
//get data from mstdb 
const getEmployeeFromMstDb = (userInfoParams) => {
    return getEmployeeInfo(userInfoParams).then(response => {
        return response
    })

}
const getTraineeListFromMstDb = (userInfoParams) => {
    return getAllTraineesForAgentCompany(userInfoParams).then(response => {
        return response
    })

}
//sync data to our system
const SyncTrainee = (params) => {
    return traineeInfoSyncToSystem(params).then(res => {
        return res.data;
    }).catch(error => {
        // console.log({ error })
    })
}

const syncTraineeList = (companyCode, branchCode, params) => {
    return traineeListInfoSyncToSystem(companyCode, branchCode, params).then(res => {
        // console.log({ res })
        return true;
    }).catch(error => {
        // console.log({ error })
        return false;
    })
}

const storeData = (response, url = '') => {
    store.dispatch({ type: 'USER_ID', user_id: response.id });
    store.dispatch({ type: 'USERNAME', username: response.name });
    store.dispatch({ type: 'EMPLOYEE_ID', employee_id: response.empId });
    store.dispatch({ type: 'ROLE', role: response.role });
    store.dispatch({ type: 'AGENT_COMPANY', agent_company: response.agentCompany });
    url !== '' && store.dispatch({ type: 'ACCESS_TOKEN', access_token: "sample token" });
}

