import React from 'react';
import styles from './styles.module.css';
import GeneralTextbox from '../../Textboxes/GeneralTextbox/index'
import { useTranslation } from 'react-i18next';

const KeywordCard = ({ title, button_label, onClick, onChange, value, status, id, button_id }) => {
  const { t } = useTranslation();

  return (
    <div className={`cmn-bg-box-inr ${styles.keyword_action} ${styles.keyword_action_del} 
      ${status === t('edit keywords.done') || status === t('edit keywords.cancel') ? styles.keyword_action_current_done
        : (status === t('edit keywords.delete')) ? styles.keyword_action_current_cancel
          : (status === t('edit keywords.add')) && styles.keyword_action_current_add}`}
      key={title} id={id}>

      {
        button_label ?
          <span className={`${styles.keyword_action_button}`}>
            {(button_label === t('edit keywords.done') || button_label === t('edit keywords.add')) ? <button className={`${styles.done_button} `} onClick={onClick} value={value} id={button_id} >{button_label}</button>
              :
              <button className={`${styles.del_inst}`} onClick={onClick} value={value} id={button_id}>{button_label}</button>}
          </span>
          : ''
      }

      {button_label === t('edit keywords.done') ?
        <GeneralTextbox className={styles.keyword_textbox} autoFocus={true} onChange={onChange} id={`input-${button_id}`} />
        :
        <span className="keyword_action_title"  >{title}</span>
      }
    </div>
  );
}

export default KeywordCard;
