import { CSVLink } from "react-csv";
import GeneralButton from '../../../components/Buttons/GeneralButton'
const csvExport = ({favoriteData,agentCompany,trainees,trainers}) => {
    const passFailEvil = (passFailEvil) => {
        switch (passFailEvil) {
            case 0:
                return 'x'
            case 1:
                return '〇'
            case 2:
                return '-'
            default:
                return ''
        }
      }
      const csvData = [];
      csvData.push(["Filter By "])
      const allAgent = agentCompany.map((x) => x.name).join(', ');
      const allTrainee = trainees.map((x) => x.name).join(', ');
      const allTrainer = trainers.map((x) => x.name).join(', ');
      csvData.push(["AS コード",allAgent])
      csvData.push(["募集人名",allTrainee])
      csvData.push(["学習テーマ",allTrainer])
      csvData.push([])
      csvData.push(['氏名','実施日','学習テーマ','プレイ時間','達成率','FF','FA','P','C','評価者','登録者'])

        for (let i = 0; i < favoriteData.length; i += 1) {
            csvData.push([
                favoriteData[i].Trainee_name,
                favoriteData[i].ImplementationDate, 
                favoriteData[i].Theme_name,
                favoriteData[i].duration,
                favoriteData[i].achivement_rate,
                passFailEvil(favoriteData[i].FA.result),
                passFailEvil(favoriteData[i].FF.result),
                passFailEvil(favoriteData[i].P.result),
                passFailEvil(favoriteData[i].C.result),
                favoriteData[i].Evaluator_name,
                favoriteData[i].Roleplayer_name
            ]);
        }

      const exportCSV = {
            filename : "お気に入りロープレ一覧.csv",
            data : csvData
        }
    return(
        <CSVLink {...exportCSV}>
            <GeneralButton id="export_csv" className="cmn-btn font-weight-normal" title="出力" />
        </CSVLink>
    )
}
export default csvExport;