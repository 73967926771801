import React, {useCallback, useEffect, useRef, useState} from 'react';
import { connect, useDispatch } from "react-redux";

import styles from './styles.module.css'
import ErrorMessage from '../../ErrorMessage';
import { useTranslation } from 'react-i18next';
import pause from '../../../assets/images/pause.png';
import mice from '../../../assets/images/mice.png';
import micedisabled from '../../../assets/images/micedisabled.png';
import ChatApi from "../../../api/chatApi";
import store from "../../../store";

const SAMPLING_RATE = 4096;

const FeedbackCard = ({headerTitle, paramKey, content,setContent,noRepeat, setNoRepeat, className,style,id, editFlag=false, SET_AI_SCORE_RESULT ,limit,recordingStatus=0, error, setError, miceOpen, setMiceOpen}) => {

    // const [miceOpen, setMiceOpen] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const change = (e) => {
        e.target.value.length >= limit?setError(true):setError(false);
        let param = {}
        // setContent(e.target.value);
        switch(paramKey){
            case "goodPoint":
                dispatch({ type: 'initTranscriptGoodPoint', transcriptGoodPoint: e.target.value });
                setContent(store.getState().sttResult.innerTranscriptGP)
                break;
            case "improvePoint":
                dispatch({ type: 'initTranscriptImprovePoint', transcriptImprovePoint: e.target.value });
                setContent(store.getState().sttResult.innerTranscriptIP)
                break;
            case "crossMatter":
                dispatch({ type: 'initTranscriptCrossMatter', transcriptCrossMatter: e.target.value });
                setContent(store.getState().sttResult.innerTranscriptCM)
                break;
            default:
                dispatch({ type: 'initTranscriptGoodPoint', transcriptGoodPoint: e.target.value });
                setContent(store.getState().sttResult.innerTranscriptGP)
                break;
        }

        // param[e.target.name] = e.target.value
        // SET_AI_SCORE_RESULT(param)
    }
    const audioContextRef = useRef(AudioContext);
    const streamRef = useRef(MediaStream);
    const streamSourceRef = useRef(MediaStreamAudioSourceNode);
    const processorRef = useRef(ScriptProcessorNode);
    const processAudio = useCallback((event) => {
        const buffer = event.inputBuffer.getChannelData(0) || new Float32Array(SAMPLING_RATE);
        const toSend = new Int16Array(buffer.length);
        for (let index = buffer.length; index >= 0;) {
            toSend[index] = 32767 * Math.min(1, buffer[index]);
            index -= 1;
        }
        ChatApi.sendData(toSend.buffer);
    }, []);

    const processTranscription = useCallback(
        (transcription) => {
            if (transcription.isFinal) {
                switch(paramKey){
                    case "goodPoint":
                        dispatch({ type: 'addTranscriptGoodPoint', transcriptGoodPoint: transcription.transcription });
                        setContent(store.getState().sttResult.innerTranscriptGP)
                        if(store.getState().sttResult.innerTranscriptGP.length > 600){
                            setError(true)
                            return
                        }
                        break;
                    case "improvePoint":
                        dispatch({ type: 'addTranscriptImprovePoint', transcriptImprovePoint: transcription.transcription });
                        setContent(store.getState().sttResult.innerTranscriptIP)
                        if(store.getState().sttResult.innerTranscriptIP.length > 600){
                            setError(true)
                            return
                        }
                        break;
                    case "crossMatter":
                        dispatch({ type: 'addTranscriptCrossMatter', transcriptCrossMatter: transcription.transcription });
                        setContent(store.getState().sttResult.innerTranscriptCM)
                        if(store.getState().sttResult.innerTranscriptCM.length > 600){
                            setError(true)
                            return
                        }
                        break;
                    default:
                        dispatch({ type: 'addTranscriptGoodPoint', transcriptGoodPoint: transcription.transcription });
                        setContent(store.getState().sttResult.innerTranscriptGP)
                        if(store.getState().sttResult.innerTranscriptGP.length > 600){
                            setError(true)
                            return
                        }
                        break;
                }

                // when translation finished, close connection
                // closeGoogleConnection();
            } else {

            }
        },
        [dispatch]
    );

    function startGoogleConnection(){
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        if (!AudioContext) {
            console.log("This browser does not support mic recording");
        }
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                streamRef.current = stream;
                audioContextRef.current = new AudioContext();
                streamSourceRef.current = audioContextRef.current.createMediaStreamSource(stream);
                processorRef.current = audioContextRef.current.createScriptProcessor(SAMPLING_RATE, 1, 1);
                processorRef.current.onaudioprocess = processAudio;

                streamSourceRef.current.connect(processorRef.current);
                processorRef.current.connect(audioContextRef.current.destination);
                ChatApi.startConnection(audioContextRef.current.sampleRate);
                ChatApi.setupTranscriptionCallback(processTranscription);
            })
            .catch((error) => {
                audioContextRef.current?.close().then(() => {
                    audioContextRef.current = undefined;
                });
                console.log(error.message);
            });
    }

    function closeGoogleConnection() {
        ChatApi.endConnection();
        processorRef.current?.disconnect();
        streamSourceRef.current?.disconnect();

        streamRef.current?.getTracks().forEach((track) => {
            track.stop();
        });
        streamRef.current = undefined;

        audioContextRef.current?.close().then(() => {
            audioContextRef.current = undefined;
            streamSourceRef.current = undefined;
            processorRef.current = undefined;
        });
    }

    const handleIconShow = (flag) => {
        if(!noRepeat){
            return;
        }
        // 三个录音不能同时开启
        setNoRepeat(false);
        setMiceOpen(flag);
        // start
        startGoogleConnection();
    }
    const handleIconHide = (flag)=>{
        //end
        setMiceOpen(flag);
        setNoRepeat(true);
        // confirm to stop translation
        // ChatApi.sendBackTranscription();
        closeGoogleConnection();
    }
    return (
        <div>
            <div id={id} className={`card no-border-radius`}>
                <div id={`${id}_header`} className="card-header no-border-radius py-3 px-3 d-inline">
                    <b>{headerTitle}</b>
                    {
                        editFlag?
                            recordingStatus !== 0?
                                <img src={micedisabled} className={`float-right ${styles.record_icon_size}`}></img>
                                :
                                miceOpen?
                                    <img src={pause} onClick={()=>handleIconHide(false)} className={`float-right ${styles.record_icon_size}`}></img>
                                    :
                                    <img src={mice} onClick={()=>handleIconShow(true)}  className={`float-right ${styles.record_icon_size}`}></img>
                            :""
                    }
                </div>
                {editFlag ?
                    <div className={`${error?styles.show_error:styles.no_border_radius}`}>
                        <textarea name={paramKey} value={content} onChange={(e) => change(e)} className={`border-0 w-100 p-3 ${className}`} maxLength={limit} disabled={miceOpen?true:false}/>
                    </div>
                    : <div  id={`${id}_body`} className={`no-border-radius p-3 ${className}`}>
                        <p style={{whiteSpace: 'pre-wrap'}}>{content}</p>
                    </div>
                }
            </div>
            { editFlag?error? <ErrorMessage className="text-right" message={t('AI score result.limit_character')} />:"":""}
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    SET_AI_SCORE_RESULT: (res) => dispatch({type: 'SET_AI_SCORE_RESULT', paramData: res}),
})

export default connect(null, mapDispatchToProps)(FeedbackCard);
