import React, { useState,useEffect } from 'react';
import styles from './styles.module.css'
import chat_icon from '../../../../assets/images/chat_icon.png'
import bot_person from '../../../../assets/images/bot_person.png'
import moment from "moment";

const ChatLog = ({traineeChat,trainerChat,allmatchedKeywords,bookMarkedAt,isBookMarked,trainerName,traineeName}) =>{
    const [minIndex, setminIndex] = useState();
    const traineeChatjsonToArray = traineeChat && JSON.parse(traineeChat).length > 0 ? JSON.parse(traineeChat) : null;
    const addTypeToTrainee = traineeChatjsonToArray?traineeChatjsonToArray.filter(item => item.text).map((item) => {
        var o = Object.assign({},item)
        o.type = "trainee"
        return o
    }):[];
    const trainerChatjsonToArray = trainerChat && JSON.parse(trainerChat).length > 0 ? JSON.parse(trainerChat) : null;
    const addTypeToTrainer = trainerChatjsonToArray?trainerChatjsonToArray.filter(item => item.text).map((item) => {
        var o = Object.assign({},item)
        o.type = "trainer"
        return o
    }):[];
    const mergeChat = [...addTypeToTrainee, ...addTypeToTrainer]
    const chatResults = mergeChat.sort((a, b) =>  a.time > b.time ? 1: -1)
    const  keywordHighlight = (text) =>{
        allmatchedKeywords.forEach(item => {
            if(text.indexOf(item) > -1) {
                text = text.replace(item,`<span class='${styles.keyword_highlight}'>${item}</span>`)
            }
        })
        return text;
    }
    useEffect(() => {
        
        if (isBookMarked) {
            let bookmarkData= [];
            chatResults && chatResults.forEach((item,index) => {
                if(item.type === "trainee"){
                    let isafter = Math.abs(moment(bookMarkedAt).diff(item.time));
                    bookmarkData.push({
                        time : item.time,
                        timeDiff : isafter
                    })
                }
            });
            bookmarkData.sort(function (a, b) {
                return a.timeDiff - b.timeDiff
            })
            bookmarkData.length > 0 &&  setminIndex(bookmarkData[0]);
           
        }
    }, [chatResults])
    return(
        <div className={`${styles.main_div}`}>
            <div className={`${styles.title_div}`}>
                <img src={chat_icon} className={styles.chat_icon} alt="" />
                <span className="ml-3"><b>チャット履歴</b></span>
            </div>
            <div className={`${styles.body_div} ${styles.msger}`} >
                <div className={`${styles.msger_chat} msger_chat`} id={`msger_chat_content_${bookMarkedAt}`}>
                    {
                        chatResults.map((item,index) => {
                            return (
                                <div key={index}>
                                    {item.type === "trainer"?
                                        <div className={`${styles.msg} ${styles.left_msg} my-1`}>
                                            <div className={`${styles.msg_img} d-flex flex-wrap align-items-center`}>
                                                <img src={bot_person} className={`${styles.bot_person} d-block mx-auto`} alt=""></img>
                                            </div>

                                            <div className={styles.msg_bubble}>
                                                <div className={styles.msg_info}>
                                                <div className={styles.msg_info_name}>{trainerName}</div>
                                                </div>

                                                <div className={styles.msg_text}>
                                                   {item.text}
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        <div className={`${styles.msg} ${styles.right_msg} my-1`}>
                                            <div className={isBookMarked && minIndex&& item.time === minIndex.time ?styles.msg_bubble_orange:styles.msg_bubble} id={isBookMarked && minIndex&& item.time === minIndex.time ?`bookmarkPosition_${bookMarkedAt}` : ''}>
                                                <div className={styles.msg_info}>
                                                    <div className={styles.msg_info_name_right}>{traineeName}</div>
                                                </div>
                                                <div className={styles.msg_text}>
                                                    <div dangerouslySetInnerHTML={{__html:keywordHighlight(item.text)}}></div>
                                                    {/* {item.text} */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ChatLog;