import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import CustomChip from '../../../components/Chip/CustomChip';
import MouthHabitChip from '../../../components/Chip/MouthHabitChip';
import { getWordCountDetails } from '../../../api/api';
import words from '../../../staticData/Words.json';
import wordCountSample from '../../../staticData/WordCountSample.json';
import  './styles.css'

const SectionTwo = (props) => {
    let {rolePlayId} = props;
    const { t } = useTranslation();
    const [wordCountData, setwordCountData] = useState([])
    const [similarWord1, setsimilarWord1] = useState(["あのー","そのー"])
    const [similarWord2, setsimilarWord2] = useState(["えー","えーと","えっと","でー"])
    let lastId = 0;
    const autoId = (prefix = 'talklisten-section-two-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        getWordCountDetails(rolePlayId).then(res => {
            setwordCountData(res.data);
        })
    }, [])
    const findWordCount = (typeWord) =>{
        let filterResult = wordCountData.filter(o => o.type === typeWord);
        return filterResult;
    }
    const findSimilarWord = (typeWord,similarWord) =>{
        let typeWordCount = findWordCount(typeWord);
        let filterResult =[];
        if (similarWord === "あのー") {
            for (let index = 0; index < similarWord1.length; index++) {
               filterResult = filterResult.concat(typeWordCount.filter(o => o.wordName === similarWord1[index]))
            }
        }else if(similarWord === "えー"){
            for (let index = 0; index < similarWord2.length; index++) {
                filterResult = filterResult.concat(typeWordCount.filter(o => o.wordName === similarWord2[index]))
             }
        }
        else {
            filterResult = typeWordCount.filter(o => o.wordName === similarWord);
        }
        return filterResult;
    }
    const sumData = (data) => {
        return data.reduce((a, b) => a + (b['wordCount'] || 0), 0);
    }
    return(
        <div className="table-responsive">
            <table id={autoId()} className="table cmn-table wods-table text-center">
                <thead>
                    <tr id={autoId()}>
                        <th id={autoId()} width="33.3%">質問回数</th>
                        <th id={autoId()} width="33.3%">共感回数</th>
                        <th id={autoId()}>クッション言葉</th>
                    </tr>
                </thead>
                <tbody>
                    <tr id={autoId()} >
                        <td id={autoId()}>{sumData(findWordCount(1))}回</td>
                        <td id={autoId()}>{sumData(findWordCount(3))}回</td>
                        <td id={autoId()}>{sumData(findWordCount(2)) === 0?<span>NG</span>:<span>OK</span>}</td>
                    </tr>
                    <tr>
                    <td colSpan={3} className="p-0">
                        <table className="table cmn-table text-center double-tb-inner">
                            <tr id={autoId()} className="ng_word_header_height">
                                <th id={autoId()} width="50%">口グセ</th>
                                <th id={autoId()} width="50%">NGワード</th>
                            </tr>
                            <tr id={autoId()} className="ng_word_body_height text-left">
                                <td id={autoId()} width="50%">
                                    {
                                        words.words.map((item,index) => {
                                            return(
                                                <span key={index}>
                                                    <MouthHabitChip text={item.name} count={sumData(findSimilarWord(4,item.name))+"回"}  className="mb-2 custom_habitchip_pd p-0"/>
                                                </span>
                                            )
                                        })
                                    }
                                    
                                </td>
                                <td id={autoId()} width="50%" >
                                    <div className="ngWordScroll">
                                        {
                                            words.ng_words.map((item,index) => {
                                                return(
                                                    <span key={index}>
                                                        <CustomChip text={item.name} className={`custom_bg_chit mb-2 ${sumData(findSimilarWord(5,item.name))>0?'is_custom_bg':''}`}/>
                                                    </span>
                                                )
                                            })
                                        }
                                    </div>
                                   
                                </td>
                            </tr>
                        </table>
                    </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}
export default SectionTwo;