import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col } from 'reactstrap';
import Sidebar from '../../components/Sidebar';
import LogoutButton from '../../components/Buttons/LogoutButton';
import { setRecentPath, setSelectedCompanyCodeAtSelectionOfRecruiter } from '../../store/actions'
import { GeneralDropdown } from '../../components/Dropdowns/GeneralDropdown';
import BackButton from '../../components/Buttons/BackButton';
import GeneralButton from '../../components/Buttons/GeneralButton';
import { getUserList, getRecruiterForTraining, updateRecruiterForTraining } from '../../api/api';
import { COMPANY_CODES_URL } from "../../configs/urlConstants";
import { longestString, getLastString } from '../../utils/General'
import EditSelectionOfRecruiter from './EditSelectionOfRecruiter'
import ConfirmDialog from '../../components/Dialog/ConfirmDialog';
import { isTrainerManagerOrTrainee } from '../../configs/constants';
import styles from './styles.module.css';
import EventBus from '../../components/EventBus'
import { isApigwEnv } from '../../utils/General'

const SelectionOfRecruitersForTrainingPage = ({ access_token, isOpen, location, recent_path, setRecentPath, company_at_selection_of_recruiter, setSelectedCompanyCodeAtSelectionOfRecruiter, syncStatus }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [ASCode, setASCode] = useState();
    const [selectedASCode, setSelectedASCode] = useState();
    const [editState, setEditState] = useState(false);
    const [reset, makeAllReset] = useState(false)
    const [longest, setLongest] = useState();
    const [allUsers, setAllUsers] = useState();
    const [updatedActiveUsers, setUpdatedActiveUsers] = useState();

    //confrim box
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmToTrainingPlan, setConfirmToTrainingPlan] = useState(false);
    const [confirmOpenForActiveToInactive, setConfirmOpenForActiveToInactive] = useState(false);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
        getUserList(COMPANY_CODES_URL).then(res => {
            setASCode(res.data.sort((a, b) => parseInt(a.id) - parseInt(b.id)))
            let longestName = longestString(res.data.map(item => { return { name: item.name } }))
            setLongest(longestName < 15 ? 4 : longestName >= 15 ? 5 : longestName >= 20 ? 6 : longestName >= 25 ? 7 : 8)
        })
        window.scrollTo(0, 0)

        if (!isApigwEnv() && (access_token === '' || access_token === undefined)) {
            history.push("/Logout");
        }
        company_at_selection_of_recruiter !== '' && setSelectedASCode(company_at_selection_of_recruiter.AS_code)

        if (isTrainerManagerOrTrainee()) {
            makeAllReset(!reset)
            return
        }
        setRecentPath(location.pathname);
    }, [syncStatus])

    useEffect(() => {
        selectedASCode && getRecruiterForTraining(selectedASCode.id).then(res => {
            setAllUsers(res.data.map(item => { return { userId: item.userId, userName: item.userName, userSts: item.userSts, newlyAdded: item.userSts, checked: false, modifyFlag: false } }))
        })
        // selectedASCode && setEditState(false)
    }, [selectedASCode, editState])

    const onEdit = () => {
        setEditState(true);
    }

    const onBack = () => {
        setEditState(false)
    }
    const onApply = () => {
        setFlag(false)
        // setConfirmOpenForActiveToInactive(true)
        // setConfirmOpen(true)
        setConfirmOpenForActiveToInactive((allUsers && allUsers.filter(user => user.modifyFlag && !user.newlyAdded).length > 0) ? true : false);
        setConfirmOpen((!confirmOpenForActiveToInactive && allUsers && allUsers.filter(user => user.modifyFlag && user.newlyAdded).length > 0) ? true : false);
        if ((allUsers && allUsers.filter(user => user.modifyFlag && user.newlyAdded).length > 0) && (allUsers && allUsers.filter(user => user.modifyFlag && !user.newlyAdded).length > 0)) {
            setFlag(true)
        }
        if (allUsers && allUsers.filter(user => user.modifyFlag).length === 0) {
            EventBus.dispatch('show_snack', { message: "更新データがありません。", type: 'error' })
        }
    }

    const onMovedToActive = () => {
        EventBus.dispatch('close_snack')
        let selectedUser = [...allUsers]
        selectedUser.map(user => {
            if (user.checked && !user.newlyAdded) {
                user.newlyAdded = !user.newlyAdded
                user.checked = !user.checked
                user.modifyFlag = !user.modifyFlag
            }
            return user
        })
        setAllUsers(selectedUser)
    }
    const onMovedToInactive = () => {
        EventBus.dispatch('close_snack')
        let selectedUser = [...allUsers]
        selectedUser.map(user => {
            if (user.checked && user.newlyAdded) {
                user.newlyAdded = !user.newlyAdded
                user.checked = !user.checked
                user.modifyFlag = !user.modifyFlag
            }
            return user
        })
        setAllUsers(selectedUser)
    }

    const toTrainingPlan = () => {
        setSelectedCompanyCodeAtSelectionOfRecruiter({ AS_code: selectedASCode, active_users: updatedActiveUsers })
        history.push("/TrainingPlan")
    }

    const onTraineeAdded = () => {
        let updated = allUsers.filter(data => data.modifyFlag).length > 0 && allUsers.filter(data => data.modifyFlag && data.newlyAdded).map(item => { return { userId: item.userId, userName: item.userName, userSts: item.newlyAdded } })
        updated && updated.length > 0 && setUpdatedActiveUsers(updated)
        updated && updated.length > 0 && updateRecruiterForTraining(selectedASCode.id, updated).then(res => {
            if (res.status === 200) {
                setEditState(false)
                setConfirmToTrainingPlan(true)
                EventBus.dispatch('show_snack', { message: "操作成功", type: 'success' })
            }
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: "更新に失敗しました", type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }

    const onTraineeRemoved = () => {
        let updated = allUsers.filter(data => data.modifyFlag).length > 0 && allUsers.filter(data => data.modifyFlag && !data.newlyAdded).map(item => { return { userId: item.userId, userName: item.userName, userSts: item.newlyAdded } })
        updated && updated.length > 0 && updateRecruiterForTraining(selectedASCode.id, updated).then(res => {
            if (res.status === 200) {
                !flag && setEditState(false)
                setConfirmOpen(flag ? true : false)
                EventBus.dispatch('show_snack', { message: "操作成功", type: 'success' })
            }
        }).catch(err => {
            if (err.response.status === 400) {
                EventBus.dispatch('show_snack', { message: "更新に失敗しました", type: 'error' })
            }
            else {
                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
            }
        })
    }

    return (
        <>
            <Sidebar />
            <div id="main-content" className={`${isOpen ? 'content-large' : 'content-small'}`}>
                <div className="main-content-inr" id="selection_recruiter">
                    <Row>
                        <Col lg="12" className="cmn-logout-btn-sec">
                            <h3 className="text-left d-flex justify-content-between" id="selection_recruiter_title">
                                {t('selection of recruiter.title')}
                            </h3>
                            <LogoutButton title={t('general.button.logout')} className={'font-14'} id="logout_btn" reset={reset} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="12" md="12">
                            <Row className="mb-4">
                                <Col lg={`${isOpen ? longest + 1 : longest} `} className={`${styles.selection_recuiter_dropdown}`}>
                                    <div className="cmn-bg-box-inr p-3">
                                        <label className="mb-2 font-weight-bold" id="AS_code">AS コード</label>
                                        <GeneralDropdown
                                            isStatic={true}
                                            staticData={ASCode}
                                            onSelect={(value) => { setSelectedASCode({ id: value.value, name: value.textContent }); setEditState(false) }}
                                            dropdown_id="selection_recuiter_dropdown"
                                            placeholder={t('general.text.theme select')}
                                            selectedData={selectedASCode && selectedASCode.name} />
                                    </div>
                                </Col>
                            </Row>
                            <ConfirmDialog
                                title="保存しますか？"
                                open={confirmOpen}
                                setOpen={setConfirmOpen}
                                onConfirm={onTraineeAdded}
                                id="save_confirm" />

                            <ConfirmDialog
                                title="今すぐ育成計画を入力しますか？"
                                open={confirmToTrainingPlan}
                                setOpen={setConfirmToTrainingPlan}
                                onConfirm={toTrainingPlan}
                                id="moveTo_confirm" />

                            <ConfirmDialog
                                title="育成対象から除外すると、これまでのロープレ・検定履歴が表示されなくなりますがよろしいですか？"
                                open={confirmOpenForActiveToInactive}
                                setOpen={setConfirmOpenForActiveToInactive}
                                onConfirm={onTraineeRemoved}
                                id="delete_confirm" />

                            {selectedASCode &&
                                <div className={`cmn-bg-box `}>
                                    <Row>
                                        <Col>
                                            {editState ?
                                                <>
                                                    <BackButton id="back_btn" className="back-btn mr-3" title={t('general.button.return')} onClick={onBack} />
                                                    <GeneralButton id="apply_btn" className="cmn-btn font-weight-normal mb-4" title={t('edit keywords.apply')} onClick={onApply} />
                                                </>
                                                :
                                                <GeneralButton id="edit_btn" className="cmn-btn font-weight-normal mb-4" title={t('edit keywords.edit')} onClick={onEdit} />
                                            }
                                            <p className="mb-3 font-weight-bold" id="selected_company">アソシエイツ名：{getLastString(selectedASCode.name)} </p>
                                        </Col>
                                        <Col className={`back-btn-box  text-right`}>
                                            <BackButton id={`go_back_btn`} className="back-btn mb-3" title={t('general.button.top')} onClick={() => { history.push('/RecruiterSummary') }} />
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col>
                                        <div className="cmn-bg-box-inr p-32 ">
                                            <EditSelectionOfRecruiter
                                                allUsers={allUsers} setAllUsers={setAllUsers}
                                                editState={editState}
                                                onMovedToInactive={onMovedToInactive} onMovedToActive={onMovedToActive}
                                            />
                                        </div>
                                        </Col>
                                    </Row>
                                </div>}
                        </Col>
                    </Row>
                </div>
            </div>
        </>

    )
}
const stateToProps = state => {
    return {
        isOpen: state.vAgent.isOpen,
        access_token: state.vAgent.access_token,
        recent_path: state.vAgent.recent_path,
        company_at_selection_of_recruiter: state.vAgent.company_at_selection_of_recruiter,
        syncStatus: state.vAgent.syncStatus,
    }
}

const dispatchToProps = dispatch => {
    return {
        setRecentPath: (recent_path) => {
            dispatch(setRecentPath(recent_path));
        },
        setSelectedCompanyCodeAtSelectionOfRecruiter: (company_at_selection_of_recruiter) => {
            dispatch(setSelectedCompanyCodeAtSelectionOfRecruiter(company_at_selection_of_recruiter));
        }
    }
}
export default connect(stateToProps, dispatchToProps)(SelectionOfRecruitersForTrainingPage);