import React, { useRef, useState, useEffect } from 'react';
import up_arrow from '../../../assets/images/up_arrow.png';
import down_arrow from '../../../assets/images/down_arrow.png';
import disabled_down_arrow from '../../../assets/images/disabled_down_arrow.png';
import { getUserList, getTraineeFilterList } from '../../../api/api'
import classes from './styles.module.css';
import { split } from "../../../utils/General"

const GeneralDropdown = ({ placeholder, onSelect, className, selectedData, dropdown_id, isStatic = false, staticData, url, filter, disabled = false, role, uniqueFilterCode, sevenFilterCode, threeFilterCode, secnerioBox = false, secnerioText, isShowPlaceholder, setBranchCode, agent_id = false }) => {
    let lastId = 0;
    const autoId = (prefix = dropdown_id) => {
        lastId++;
        prefix = prefix ? prefix + '_item_' : 'dropdown-item-'
        return `${prefix}${lastId}`;
    }
    const list = useRef();
    const [userList, setUserList] = useState([]);
    useEffect(() => {
        if (isStatic) {
            setUserList(staticData)
        } else {
            if (filter === 'true') {
                getUserList(url).then(res => {
                    setUserList(res.data.map(item => { return { id: item.id, name: item.name } }))
                })
            } else {
                let routeName = url && url.split('/')
                if (sevenFilterCode && threeFilterCode) {
                    let data = {
                        roleId: role ? role.id : '',
                        sevenFilterCode: sevenFilterCode ? sevenFilterCode.id : '',
                        threeFilterCode: threeFilterCode ? threeFilterCode.id : ''
                    }
                    routeName && parseInt(routeName[routeName.length - 1]) === parseInt(agent_id) && getTraineeFilterList(url, data).then(res => {
                        res.data.length > 0 && "userSts" in res.data[0]
                            ? setUserList(res.data.filter(user => user.userSts).map(item => { return { id: item.userId, name: item.userName } }))
                            : setUserList(res.data.map(item => { return { id: item.id, name: item.name } }))
                    })

                } else if (uniqueFilterCode) {
                    routeName && parseInt(routeName[routeName.length - 1]) === parseInt(uniqueFilterCode.id) &&
                        getUserList(url).then(res => {
                            res.data.length > 0 && "userSts" in res.data[0]
                                ? Array.isArray(res.data) && setUserList(res.data.filter(user => user.userSts).map(item => { return { id: item.userId, name: item.userName, userBranch: item.userBranch } }))
                                : Array.isArray(res.data) && setUserList(res.data && res.data.map(item => { return { id: item.id, name: item.name } }))
                        })
                }

            }
        }
    }, [staticData, url, threeFilterCode, uniqueFilterCode])


    useEffect(() => {
        setSelectedText(selectedData)
    }, [selectedData])

    const [height, setHeight] = useState();
    const [open, setOpen] = useState(false);
    selectedData = (selectedData) ? selectedData : '';
    const [selectedText, setSelectedText] = useState(selectedData);

    const onOpen = () => {
        !disabled && setOpen(true);
        list.current.focus();
    }

    const close = () => {
        setOpen(false);
    }

    const itemClick = (e) => {
        uniqueFilterCode && setBranchCode && setBranchCode(e.target.getAttribute('data-userbranch'))
        setSelectedText(e.target.textContent);
        setOpen(false);
        onSelect(e.target);
    }
    return (
        <div className={`${classes.select_box} ${className} ${open ? classes.select_box_radius : ''}`} id={autoId()}>
            <div onClick={onOpen} className={'d-flex justify-content-between  align-items-center h-100'} id={autoId()}>
                {open && secnerioBox && secnerioText ? <span>{secnerioText}</span>
                    : <span className={`MeriyoRegular font-10 ${disabled ? classes.gray : ""}`} id={autoId()}>{selectedText === '' || (open && isShowPlaceholder) ? placeholder : selectedText}</span>}

                {disabled ? <img src={disabled_down_arrow} className={`${classes.arrows} `} id={autoId()} />
                    : <img src={`${open ? up_arrow : down_arrow} `} className={`${classes.arrows} `} id={autoId()} />
                }
            </div>
            <div
                tabIndex="0"
                ref={list}
                onBlur={close}
                id={autoId()}
            >
                <ul className={`cmn-scroll-bar cmn-scroll-bar-blue ${open ? classes.showbox : classes.hidebox} ${classes.min_height}`} id={autoId()} >
                    {
                        filter !== undefined && <li key='default' value="0" id={`${dropdown_id}0`} onClick={itemClick}>
                            {placeholder}
                        </li>
                    }
                    {userList && userList.map((item, index) => (
                        <li key={index} value={item.id} id={`${dropdown_id}${item.id}`} onClick={itemClick} className={`RobotoRegular font-12`} name={autoId()} data-userbranch={item.userBranch ? item.userBranch : ''}>
                            {item.name}
                        </li>
                    ))
                    }
                </ul>
            </div>
        </div>
    )
}

export { GeneralDropdown };