const initialState = {
  examId: '',
  themeId: 1,
  scenarioId: 1,
  examStartTime: '',
  examEndTime: '',
  examStatus: 1
}
const startExam = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "SET_NEW_EXAM":
      return { ...state, ...action.paramData }
    case "RESET_NEW_EXAM":
      return initialState;
    default:
      return state
  }
}

export default startExam