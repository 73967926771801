import React, { useState, useEffect } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ORCard from '../../components/Cards/ORCard';
import styles from './styles.module.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getAIScoringConditions } from '../../api/api'

const AllKeywordSelection = ({ keywords, setKeywords, editState, expanded, setExpanded, selected }) => {

    const [sectionId, setSectionId] = useState();
    const [criteriaId, setCriteriaId] = useState();
    const [list, setList] = useState();
    const [flag, setFlag] = useState();

    const handleChange = (panel, flag, section_id, criteria_id) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setFlag(flag)
        setSectionId(section_id)
        setCriteriaId(criteria_id)
    };

    let lastId = 0;
    const autoId = (prefix = 'edit-condition-') => {
        lastId++;
        return `${prefix}${lastId}`;
    }
    useEffect(() => {
        expanded && sectionId && criteriaId && getAIScoringConditions(keywords[0].id, sectionId, criteriaId).then(res => {
            if (res.data.length >= 1) {
                setList(res.data.sort((a, b) => a.sort - b.sort)
                    .map(item => {
                        return {
                            keyword: item.keyword.filter(keywordItem => { return keywordItem.checked === '1' }),
                            sort: item.sort,
                        }
                    }))
            }
            else {
                setList([{ keyword: [], sort: `1` }])
            }

        })

    }, [selected, expanded, sectionId, criteriaId])

    useEffect(() => {
        setExpanded(false)
    }, [selected])

    useEffect(() => {
        const newArray = list && keywords && keywords.map(section => {
            section.sections.map(data => {
                if (data.id === sectionId) {
                    data.criteria.map(criteria => {
                        if (criteria.id === criteriaId && !flag) {
                            criteria.keywordConditionsJSON = list
                        }
                    })
                }
            })
            return section
        })
        newArray && setKeywords(newArray)
    }, [list])

    return (
        <div className={`cmn-scroll-bar px-2`}>
            {keywords && keywords[0].sections.map((items, key) => {
                return (
                    <div className="edit-keywords-accordian mr-md-4 mr-2" key={key}>
                        <h5 className="mb-3 font-14" id={`section_title_${key}`}>{items.name}</h5>
                        {items.criteria.map((item, index) => {
                            return (
                                <Accordion expanded={expanded === 'panel' + items.id + index} onChange={handleChange('panel' + items.id + index, item.aiScoringFlag, items.id, item.id)} className={`mb-3`} key={index} >
                                    <AccordionSummary
                                        className="keywords-accor-summary-inr"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2bh-content"
                                        id={`${autoId()} panel2bh-header`}
                                    >
                                        <Typography><label className={`m-0 MeiryoRegular font-14`} id={autoId()}>{item.name}</label></Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={`keywords-accor-summary-detail ${styles.keywords_selection_accor_summary_detail}`}>
                                        {item.aiScoringFlag ?
                                            <div className={`cmn-bg-box-inr ${styles.keyword_action} ${styles.keyword_action_del}`} >
                                                <span className="keyword_action_title" id={`AI`}>AIは採点しない</span>
                                            </div>
                                            :
                                            <div className="row">
                                                {
                                                    !item.aiScoringFlag && item.keywordConditionsJSON && item.keywordConditionsJSON.map((keyword, keywordIndex) => {
                                                        return (
                                                            <>
                                                                {
                                                                    <div className={`${styles.keyword_selection_box} col-lg-12 col-xl-6 pr-lg-0  ${keywordIndex % 2 == 1 && styles.px_lg_0}`} key={index + '_' + keywordIndex}>
                                                                        <ORCard className={`${styles.keyword_or_box_out} col-10 px-0`}
                                                                            selectedKeywords={keyword}
                                                                            section_id={items.id}
                                                                            criteria_id={item.id}
                                                                            position={keywordIndex}
                                                                            count={item.keywordConditionsJSON.length}
                                                                            keywords={keywords}
                                                                            key={keywordIndex}
                                                                            editState={editState} />
                                                                        <div className={`text-center col-2 px-0 ${styles.keyword_and_text} ${item.keywordConditionsJSON.length - 1 === keywordIndex || item.keywordConditionsJSON.length === 1 ? styles.none : styles.block}`}><span className="font-weight-bold" id={`text_${item.id}_${keywordIndex}`}>AND</span></div>
                                                                    </div>

                                                                }
                                                            </>
                                                        )
                                                    })
                                                }
                                            </div>
                                        }
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </div >
                );
            })}
        </div>
    );
}


export default AllKeywordSelection;