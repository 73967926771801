import openSocket from "socket.io-client";

let socket;
let domain = document.location.origin;
let subPath = "/socket.io";
// For stage and prod env
if(domain.indexOf("aflac.platformerfuji.com") > -1){
    subPath = "/apigw/va2roleplay" + subPath;
}
const ChatApi = {
    startConnection: (sampleRate) => {
        socket = openSocket(domain, {
            path: subPath,
            transports: ["websocket"]
        });
        socket.binaryType = "arraybuffer";
        socket.emit("startConnection", { sampleRate });
    },
    endConnection: () => {
        socket && socket.emit("endConnection");
        socket && socket.close();
    },
    sendData: (data) => {
        socket.emit("sendData", data);
    },

    setupTranscriptionCallback: (callbackFun) => {
        socket.on("sendTranscription", (transcription) => {
            callbackFun(transcription);
        });
    },

    sendBackTranscription:() => {
        socket.emit('sendBackTranscription');
    }

};
export default ChatApi;
