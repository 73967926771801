import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Single from '../../../assets/images/single.png';
import Combination_Pattern_A from '../../../assets/images/combination_pattern_A.png';
import Combination_Pattern_B from '../../../assets/images/combination_pattern_B.png';
import Comprehensive_Pattern_A from '../../../assets/images/comprehensive_pattern_A.png';
import Comprehensive_Pattern_B from '../../../assets/images/comprehensive_pattern_B.png';
import styles from './styles.module.css';
import { getThemeList } from '../../../api/api'
import { getLastString, split } from '../../../utils/General'
import pdf from '../../../staticData/pdf.json';

const LearningAndAchievementTable = ({ item, TableClassName, TableDivClassName, table_id }) => {
    const { t } = useTranslation();
    const [customerPdf, setCustomerPdf] = useState();
    useEffect(() => {
        getThemeList().then(res => {
            setCustomerPdf(res.data)
        })
        // setCustomerPdf(pdf)
    }, []);
    let col = 0;
    const noData = <tr><td colSpan="100%">There is no available data</td></tr>;
    return (
        <div className={`${TableDivClassName} table-responsive`}>
            <table className={`${TableClassName} cmn-table table ${styles.table}`} id={table_id}>
                <thead>
                    <tr>
                        <th key="single" id={`${table_id}_heading_single`} className="text-nowrap">
                            {t('confirm customer attributes.table.header 1')}
                        </th>
                        <th key="combination" id={`${table_id}_heading_combination`} className="text-nowrap">
                            {t('confirm customer attributes.table.header 2')}
                        </th>
                        <th key="comprehensive" id={`${table_id}_heading_comprehensive`} className="text-nowrap">
                            {t('confirm customer attributes.table.header 3')}
                        </th>
                        <th key="after-follow edition" id={`${table_id}_heading_after_follow_edition`} className="text-nowrap">
                            {t('confirm customer attributes.table.header 4')}
                        </th>
                        <th key="group edition" id={`${table_id}_heading_group_edition`} className="text-nowrap">
                            {t('confirm customer attributes.table.header 5')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {customerPdf && customerPdf.map((file, key) => (
                            <>
                                {file.urls.map((url, index) => (
                                    url.split('?')[0].includes(t('confirm customer attributes.table.header 1')) ?
                                        <td id={`${table_id}_body_${key + '_' + index}`} rowSpan={file.urls.length === 1 ? 10 : ''} key={index}>
                                            <a href={url} target="_blank">{getLastString(url.split('?')[0], '/')}</a>
                                        </td>
                                        : url.split('?')[0].includes(t('confirm customer attributes.table.header 2')) && url.split('?')[0].includes('A') ?
                                            <td id={`${table_id}_body_${key + '_' + index}`} key={index}>
                                                <a href={url} target="_blank">{getLastString(url.split('?')[0], '/')}</a>
                                            </td>
                                            : url.split('?')[0].includes(t('confirm customer attributes.table.header 3')) && url.split('?')[0].includes('A') ?
                                                <td id={`${table_id}_body_${key + '_' + index}`} key={index}>
                                                    <a href={url} target="_blank">{getLastString(url.split('?')[0], '/')}</a>
                                                </td>
                                                : url.split('?')[0].includes(t('confirm customer attributes.table.header 4')) ?
                                                    <td id={`${table_id}_body_${key + '_' + index}`} rowSpan={file.urls.length === 1 ? 10 : ''} key={index}>
                                                        <a href={url} target="_blank">{getLastString(url.split('?')[0], '/')}</a>
                                                    </td>
                                                    : url.split('?')[0].includes(t('confirm customer attributes.table.header 5')) &&
                                                    <td id={`${table_id}_body_${key + '_' + index}`} rowSpan={file.urls.length === 1 ? 10 : ''} key={index}>
                                                        <a href={url} target="_blank">{getLastString(url.split('?')[0], '/')}</a>
                                                    </td>
                                ))}
                            </>
                        ))}
                    </tr>
                    <tr>
                        {customerPdf && customerPdf.map((file, key) => (
                            <>
                                {file.urls.map((url, index) => (
                                    url.split('?')[0].includes(t('confirm customer attributes.table.header 2')) && url.split('?')[0].includes('B') ?
                                        <td id={`${table_id}_body_${key + '_' + index}`} key={index}>
                                            <a href={url} target="_blank">{getLastString(url.split('?')[0], '/')}</a>
                                        </td>
                                        : url.split('?')[0].includes(t('confirm customer attributes.table.header 3')) && url.split('?')[0].includes('B') &&
                                        <td id={`${table_id}_body_${key + '_' + index}`} key={index}>
                                            <a href={url} target="_blank">{getLastString(url.split('?')[0], '/')}</a>
                                        </td>
                                ))}
                            </>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div >

    );
};

export { LearningAndAchievementTable };