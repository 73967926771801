import React from 'react';


import styles from './styles.css';

const GeneralButton02 = ({ title, name, isChecked, className, onClick, id }) => {

    return (
        <label className={`control control--radio ${className}`}>
        <input type="radio" name={name} id={id} readOnly checked={isChecked ? "checked" : ""}  onClick={onClick} />
            <div className="control__indicator" id={`${id}_indicator`} name={`${name}_indicator`}>{title}</div>
        </label>
    )
}

export default GeneralButton02;