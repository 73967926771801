const initialState = {
    value: "",
    pendingValue: "",
    mode: "default",
    isTyping: false
};

const startChat = (
    state = initialState,
    action
) => {
    switch (action.type) {
        case "START_VOICE_INPUPT":
            return {
                ...state,
                mode: "voice-input"
            };
        case "DECLINE_VOICE_INPUT":
            return {
                ...state,
                value: "",
                pendingValue: "",
                mode: "default"

            };
        case "ACCEPT_VOICE_INPUT":
            return {
                ...state,
                mode: state.value + state.pendingValue !== "" ? "send" : "default",
                value: state.value + state.pendingValue,
                pendingValue: ""
            };
        case "SET_IS_TYPING":
            return {
                ...state,
                isTyping: action.isTyping
            };
        case "MESSAGE_INPUT_CHANGED":
            let newMode = "default";
            if (state.input.mode === "voice-input") {
                newMode = "voice-input";
            } else if (action.value.length > 0) {
                newMode = "send";
            }
            return {
                ...state,
                value: action.value,
                mode: newMode
            };
        case "APPEND_PENDING_INPUT":
            return {
                ...state,
                pendingValue: "",
                value: state.value + action.value
            };
        case "SET_PENDING_INPUT":
            return {
                ...state,
                pendingValue: action.value
            };
            case "RESET_START_CHAT":
                return initialState;
        default:
            return state;
    }
};

export default startChat;