import React, { useRef, useState, useEffect } from 'react';
import { Row, Col } from 'reactstrap';
import calendar from '../../../assets/images/calendar_blue.png';
import arrow_left from '../../../assets/images/arrow_left.png';
import arrow_right from '../../../assets/images/arrow_right.png';
import increase from '../../../assets/images/increase.png';
import decrease from '../../../assets/images/decrease.png';
import classes from './styles.module.css';
import staticYear from '../../../staticData/TimePeriod.json';
import disable_arrow_left from '../../../assets/images/disable_arrow_left.png';
import disable_arrow_right from '../../../assets/images/disable_arrow_right.png';
import disable_increase from '../../../assets/images/disable_increase.png';
import disable_decrease from '../../../assets/images/disable_decrease.png';
import {GetGenerateYear,getLastPeriod,getFirstPeriod} from '../../../utils/General';


const TimePeriodDropdown = ({ data, placeholder, className, selectedData, dropdown_id, from, to, setFrom, setTo, selectedTimePeriod, dropdownPosition, timePickerclassName }) => {
    const list = useRef();
    const elementRef = useRef(null);
    const wrapperRef = useRef(null);
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();

    const [open, setOpen] = useState(false);
    selectedData = (selectedData) ? selectedData : '';
    const [selectedText, setSelectedText] = useState({});
    const [fromYear, setFromYear] = useState(from.year);
    const [toYear, setToYear] = useState(to.year);
    const [fromMonth, setFromMonth] = useState(from.month);
    const [toMonth, setToMonth] = useState(to.month);
    const [yearData, setYearData] = useState();
    useEffect(() => {
        if (selectedTimePeriod !== 4) {
            setSelectedText({ from: `${from.year}/${data[from.month - 1]}`, to: to ? `${to.year}/${data[to.month - 1]}` : "" });
        } else {
            setSelectedText({ from: `${from.year}`, to: `${to.year}` });
        }

        setFromYear(from.year);
        setToYear(to.year);
        setFromMonth(from.month);
        setToMonth(to.month)
    }, [from, to])
    useEffect(() => {
        setYearData(GetGenerateYear(selectedTimePeriod,to.month,data.length))
    }, [data])
    useEffect(() => {
        let restrictFromMonth;
        if(selectedTimePeriod === 3 && fromYear === currentYear -5 && 6 >= currentMonth <= 11){
            restrictFromMonth = 2
        }else{
            restrictFromMonth = fromMonth;
        }
        setFrom({ "year": fromYear, "month": restrictFromMonth });
        setTo({ "year": toYear, "month": toMonth });
    }, [fromYear, toYear, fromMonth, toMonth])
    const onOpen = () => {
        setOpen(true);
        list.current.focus();
    }

    const close = () => {
        setOpen(false);
    }

    const itemClick = (status, key) => () => {
        if (status === 'from') {
            setFromMonth(key + 1);
        } else {
            setToMonth(key + 1)
            setOpen(false);
        }
        // setOpen(false);
    }

    const toggleYear = (fromToStatus, preNextStatus, year) => () => {
        if (fromToStatus === "from") {
            if (preNextStatus === "next") {
                let nextIndex = yearData.indexOf(year) + 1;
                fromYear < toYear && setFromYear(yearData[nextIndex] ? yearData[nextIndex] : yearData[nextIndex - 1]);
            } else {
                let nextIndex = yearData.indexOf(year) - 1;
                setFromYear(yearData[nextIndex] ? yearData[nextIndex] : yearData[nextIndex + 1]);
            }
        } else {
            if (preNextStatus === "next") {
                let nextIndex = yearData.indexOf(year) + 1;
                setToYear(yearData[nextIndex] ? yearData[nextIndex] : yearData[nextIndex - 1]);
            } else {
                let nextIndex = yearData.indexOf(year) - 1;
                setToYear(yearData[nextIndex] ? yearData[nextIndex] : yearData[nextIndex + 1]);
            }
        }

    }

    const checkActive = (key, item) => {
        if (key === item - 1) {
            return true;
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", (e) => {
            const concernedElement = document.getElementById(`${dropdown_id}_time_picker___section`);
            if (dropdown_id && concernedElement && concernedElement.contains(e.target)) {
                // console.log("clicked inside");
            } else {
                dropdown_id && document.getElementById(`${dropdown_id}_cancel_btn`) && document.getElementById(`${dropdown_id}_cancel_btn`).click()
                setOpen(false);
                // console.log("clicked outside");
            }
        });
    }, [wrapperRef]);
    return (
        <div className={`${classes.select_box} ${className} ${open ? classes.select_box_radius : ''}`} id={`${dropdown_id}_time_picker___section`} ref={wrapperRef}>
            <div onClick={onOpen} className={'d-flex justify-content-between  align-items-center'}>
                <span>{selectedText === '' ? placeholder : selectedText.from + " ~ " + selectedText.to}</span>
                <img src={calendar} className={classes.calendar} />
            </div>
            <div
                tabIndex="0"
                ref={list}
            // onBlur={close}
            >
                <div className={`${timePickerclassName} ${open ? classes.showbox : classes.hidebox} ${classes.box_body} ${dropdownPosition ? classes.p_right : classes.p_left}`} ref={elementRef}>
                    <Row className="m-0">
                        <Col className="p-0">
                            <div className={`form-group row ${classes.from} m-0 px-0 py-2`}>
                                <label className="col-sm-3 col-form-label text-white">From:</label>
                                <div className="col-sm-9">
                                    <input type="text" readOnly={true} className="form-control" id={`${dropdown_id}_from`} value={selectedText.from} />
                                </div>
                            </div>
                            <div className={`${classes.from_section} py-2`}>
                                <Row className="m-0 py-2">
                                    <Col lg={4} className="text-enter">
                                        {
                                            <img src={selectedTimePeriod === 4 ? decrease : arrow_left} className="d-block m-auto" onClick={toggleYear('from', 'pre', fromYear)} alt="from"></img>
                                        }
                                    </Col>
                                    <Col lg={4} className="text-enter">
                                        <span>{fromYear}</span>
                                    </Col>
                                    <Col lg={4} className="text-enter">
                                        {
                                            fromYear < toYear?
                                                <img src={selectedTimePeriod === 4 ? increase : arrow_right} className="d-block m-auto" onClick={toggleYear('from', 'next', fromYear)}></img>
                                                : <img src={selectedTimePeriod === 4 ? disable_increase : disable_arrow_right} className="d-block m-auto" ></img>
                                        }
                                    </Col>
                                    {/* <div className={`p-2 ${checkActive(key,fromMonth)?classes.active_item:''}`} onClick={itemClick('from',key)}>{selectedTimePeriod !== 1?fromYear:""} {item}</div>*/}
                                </Row>
                                <Row className="m-0">
                                    {
                                        data.map((item, key) => {
                                            return (
                                                <Col lg={data.length === 12 ? 4 : 6} className="text-center" key={key}>
                                                    {
                                                        fromYear === toYear && (key + 1) > toMonth ||currentYear-5 === fromYear && getFirstPeriod(key,currentMonth,selectedTimePeriod) === true ?
                                                            <div className={`p-2 ${classes.bg_gray}`} >
                                                                {selectedTimePeriod !== 1 ? fromYear : ""} {item}
                                                            </div>
                                                            : <div className={`p-2 ${checkActive(key, fromMonth) ? classes.active_item : ''}`} onClick={itemClick('from', key)}>
                                                                {selectedTimePeriod !== 1 ? fromYear : ""} {item}
                                                            </div>
                                                    }

                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </Col>
                        <Col className="p-0">
                            <div className={`form-group row ${classes.to} m-0 px-0 py-2`}>
                                <label className="col-sm-3 col-form-label">To:</label>
                                <div className="col-sm-9">
                                    <input type="text" readOnly={true} className="form-control" id={`${dropdown_id}_to`} value={selectedText.to} />
                                </div>
                            </div>
                            <div className={`${classes.to_section} py-2`}>
                                <Row className="m-0 py-2">
                                    <Col lg={4} className="text-enter">
                                        {
                                            fromYear < toYear ?
                                                <img src={selectedTimePeriod === 4 ? decrease : arrow_left} className="d-block m-auto" onClick={toggleYear('to', 'pre', toYear)} alt="to"></img>
                                                : <img src={selectedTimePeriod === 4 ? disable_decrease : disable_arrow_left} className="d-block m-auto" alt="to" ></img>
                                        }
                                    </Col>
                                    <Col lg={4} className="text-enter"><span>{toYear}</span></Col>
                                    <Col lg={4} className="text-enter">
                                        <img src={selectedTimePeriod === 4 ? increase : arrow_right} className="d-block m-auto" onClick={toggleYear('to', 'next', toYear)} alt="to"></img>
                                    </Col>
                                </Row>
                                <Row className="m-0">
                                    {
                                        data.map((item, key) => {
                                            return (
                                                <Col lg={data.length === 12 ? 4 : 6} className="text-center" key={key}>
                                                    {
                                                        fromYear === toYear && (key + 1) < fromMonth || toYear === currentYear && getLastPeriod(key,currentMonth,selectedTimePeriod) === true ?
                                                        // fromYear === toYear && (key + 1) < fromMonth || toYear === currentYear && getLastPeriod key >= currentMonth?
                                                            <div className={`p-2 ${classes.bg_gray}`} >
                                                                {selectedTimePeriod !== 1 ? toYear : ""} {item}
                                                            </div>
                                                            : <div className={`p-2 ${checkActive(key, toMonth) ? classes.active_item : ''}`} onClick={itemClick('to', key)}>
                                                                {selectedTimePeriod !== 1 ? toYear : ""} {item}
                                                            </div>
                                                    }
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div>

            </div>
        </div>
    )
}

export { TimePeriodDropdown };
