import service from './service';
import qs from 'qs';


export function me() {
  return service({
    method: 'POST',
    url: `/backend/me`,
  })
}
export function traineeInfoSyncToSystem(params) {
  return service({
    method: 'POST',
    url: `/backend/users/me/sync`,
    data: JSON.stringify(params)
  })
}
export function traineeListInfoSyncToSystem(companyCode, branchCode, params) {
  return service({
    method: 'POST',
    url: `/backend/users/trainees/sync/${companyCode}/${branchCode}`,
    data: JSON.stringify(params)
  })
}

export function getUserList(url, params) {
  return service({
    url: url,
    method: 'get',
    paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
    params
  })
}

export function getTraineeFilterList(url, data) {
  return service({
    url: url,
    method: 'post',
    data: JSON.stringify(data)
  })
}

export function getThemeList() {
  return service({
    url: '/backend/learningThemes',
    method: 'get'
  })
}
export function patternsForAgentCompanyCode(agentCompanyCode) {
  return service({
    url: `/backend/learningThemes/patternsForAgentCompanyCode/${agentCompanyCode}`,
    method: 'get'
  })
}

export function patternsForCurrentUsersCompany() {
  return service({
    url: `/backend/learningThemes/patternsForCurrentUsersCompany`,
    method: 'get'
  })
}

export function getScenarioByThemeId(themeId) {
  return service({
    url: `/backend/scenario/scenarioList/${themeId}`,
    method: 'get'
  })
}

export function getCustomerList(data) {
  return service({
    url: `/backend/customers/profile`,
    method: 'post',
    data: JSON.stringify(data)
  })
}

// export function getCustomerDetailById(custId) {
//   return service({
//     url: `/backend/customers/customerDetail/${custId}`,
//     method: 'get'
//   })
// }

export function getTraineeDetailsDashboardByAgentId() {
  return service({
    url: '/backend/getTraineeDetailsDashboard',
    method: 'get'
  })
}

export function sectionCriteriaList(policy, insurance, trainerId, traineeId, data) {
  let request = {
    agentCompanyCode: data.agentCompanyCode?.id,
    branchCode: data.branchCode?.id,
    themeId: policy,
    scenarioId: insurance,
    traineeId
  }
  return service({
    url: `/backend/sectionCriteria/sectionCriteriaList`,
    method: 'post',
    data: JSON.stringify(request)
  })
}

export function getTraineeDetailsById(user_id) {
  return service({
    url: `backend/getTraineeDetailsById/userId/${user_id}`,
    method: 'get'
  })
}
export function updateResultForLevel(data) {
  return service({
    url: '/backend/getTraineeDetailsById/updateResultForLevel',
    method: 'PUT',
    data: JSON.stringify(data),
  })
}
export function updateExamStatus(data) {
  return service({
    url: '/backend/updateExamStatus',
    method: 'PUT',
    data: JSON.stringify(data),
  })
}

export function getTraineeDetailsByDate(user_id) {
  return service({
    url: `backend/getTraineeDetailsById/userByDate/${user_id}`,
    method: 'get'
  })
}
export function getTraineeDetailsSummary(user_id) {
  return service({
    url: `/backend/getTraineeDetailsSummary/${user_id}`,
    method: 'get'
  })
}

export function getRolePlayDetail(rolePlayId = 1) {
  let params = {
    rolePlayId
  }
  return service({
    url: '/backend/aiScoreResult/getRolePlayDetail',
    method: 'get',
    params
  })
}

export function getAiScoreResultList(rolePlayId = 1, themeId = 1, scenarioId = 1) {
  let params = {
    rolePlayId,
    themeId,
    scenarioId
  }
  return service({
    url: '/backend/aiScoreResult/getAiScoreResultList',
    method: 'get',
    params
  })
}

export function getStartRolePlayList(rolePlayId = 1, themeId = 1, scenarioId = 1) {
  let params = {
    rolePlayId,
    themeId,
    scenarioId
  }
  return service({
    url: '/backend/aiScoreResult/getStartRolePlayList',
    method: 'get',
    params
  })
}

export function updateFeedback(rolePlayId = 1, data) {
  return service({
    url: `/backend/aiScoreResult/updateFeedback/${rolePlayId}`,
    method: 'put',
    data
  })
}

export function updateHumanEval(data) {
  return service({
    url: `/backend/aiScoreResult/updateHumanEval`,
    method: 'put',
    data
  })
}

export function Authenticate(data) {
  return service({
    method: 'POST',
    url: `/backend/login`,
    data: JSON.stringify(data),
  })
}

export function AuthenticateUpdate(data) {
  return service({
    method: 'put',
    url: `/backend/getLoginDetails/update`,
    data: data,
  })
}

export function startNewRolePlayInit(data) {
  return service({
    method: 'POST',
    url: `/v1/recs`,
    data: data
  })
}

export function startNextSection(dividedUrl, sectionId) {
  return service({
    method: 'put',
    // url: `/v1/recs/${callId}/divided/${sectionId}`,
    url: `${dividedUrl}/${sectionId}`
  })
}

export function finishRolePlay(finishedUrl) {
  return service({
    method: 'put',
    // url: `/v1/recs/${callId}/finished`,
    url: `${finishedUrl}`
  })
}

export function pauseRecording(pauseUrl) {
  return service({
    method: 'put',
    url: `${pauseUrl}`
  })
}

export function resumeRecording(resumeUrl) {
  return service({
    method: 'put',
    url: `${resumeUrl}`
  })
}

export function startNewRolePlaySave(data) {
  return service({
    method: 'POST',
    url: `/backend/startNewRolePlay`,
    data: JSON.stringify(data)
  })
}

export function updateRolePlayEndTime(rolePlayId) {
  return service({
    method: 'PUT',
    url: `/backend/updateRolePlayEndTime/${rolePlayId}`,
  })
}

export function updateRecordingStatus(rolePlayId) {
  return service({
    method: 'PUT',
    url: `/backend/updateRecordingStatus/${rolePlayId}`,
  })
}

export function updateSectionStatus(data) {
  return service({
    url: `/backend/updateSectionStatus`,
    method: 'put',
    data: JSON.stringify(data)
  })
}


export function getExamSectionCriteria(recruiterId, themeId, scenarioId) {
  return service({
    url: `/backend/sectionCriteria/getExamSectionCriteria/${recruiterId}/${themeId}/${scenarioId}`,
    method: 'get'
  })
}

export function submitExam(data) {
  return service({
    url: `/backend/exam/submitExam`,
    method: 'post',
    data: JSON.stringify(data)
  })
}

export function getKeywords() {
  return service({
    url: `/backend/keywords`,
    method: 'get'
  })
}

export function updateKeywords(data) {
  return service({
    url: `/backend/keywords/updateKeywords`,
    method: 'put',
    data: JSON.stringify(data)
  })
}

export function getAIScoringConditions(scenarioID, sectionID, criteriaID) {
  let params = {
    scenarioID,
    sectionID,
    criteriaID
  }
  return service({
    url: `/backend/keywords/getAIScoringConditions`,
    method: 'get',
    params
  })
}
export function updateKeywordConditions(data) {

  return service({
    url: `/backend/keywords/updateKeywordConditions`,
    method: 'put',
    data: JSON.stringify(data)
  })
}
export function getAllTrainingPlan() {
  return service({
    url: `/backend/trainingPlans`,
    method: 'get'
  })
}
export function getTrainingPlan(agentCompanyCode) {
  return service({
    url: `/backend/trainingPlans/forAgentCompanyCode/${agentCompanyCode}`,
    method: 'get'
  })
}

export function updateTrainingPlan(data) {
  return service({
    url: `/backend/trainingPlans/update`,
    method: 'put',
    data: JSON.stringify(data)
  })
}

export function getLearningThemePattern(agentCompanyCode) {
  return service({
    url: `/backend/learningThemes/patternsForAgentCompanyCode/${agentCompanyCode}`,
    method: 'get'
  })
}

export function updatePatterns(data) {
  return service({
    url: `/backend/learningThemes/updatePatterns`,
    method: 'put',
    data: JSON.stringify(data)
  })
}

export function getCheckCriteriaList(agentCompanyCode) {
  return service({
    url: `/backend/sectionCriteria/getCheckCriteriaList/${agentCompanyCode}`,
    method: 'get',
  })
}

export function updateCheckCriteriaList(data) {
  return service({
    url: `/backend/sectionCriteria/upsertCheckCriteria`,
    method: 'put',
    data: JSON.stringify(data)
  })
}
export function updateFavoriteRolePlay(rolePlayId, favoriteFlag) {
  return service({
    url: `/backend/getTraineeDetailsById/updateFavoriteRolePlay/${rolePlayId}/${favoriteFlag}`,
    method: 'put'
  })
}
export function getRolePlayFavorite() {
  return service({
    url: `/backend/getTraineeDetailsById/getRolePlayFavorite`,
    method: 'get'
  })
}
export function deleteRolePlayDetail(rolePlayId) {
  return service({
    url: `backend/roleplays/${rolePlayId}`,
    method: 'DELETE'
  })
}
export function checkFavoriteRolePlayExist(rolePlayId) {
  return service({
    url: `/backend/roleplays/checkFavoriteRolePlayExist/${rolePlayId}`,
    method: 'get'
  })
}
export function checkRolePlayExist(rolePlayId) {
  return service({
    url: `/backend/roleplays/${rolePlayId}`,
    method: 'get'
  })
}
export function getSynonyms() {
  return service({
    url: `/backend/synonyms`,
    method: 'get'
  })
}
export function addSynonyms(data) {
  return service({
    url: `/backend/synonyms`,
    method: 'post',
    data: JSON.stringify(data)
  })
}
export function deleteSynonyms(synId) {
  return service({
    url: `/backend/synonyms/${synId}`,
    method: 'DELETE',
  })
}

export function getRecruiterForTraining(agentCompanyCode) {
  return service({
    url: `/backend/recruiters/forAgentCompanyCode/${agentCompanyCode}`,
    method: 'get',
  })
}

export function updateRecruiterForTraining(agentCompanyCode, data) {
  return service({
    url: `/backend/recruiters/forAgentCompanyCode/${agentCompanyCode}`,
    method: 'put',
    data: JSON.stringify(data)
  })
}
export function getLearningThemeOrTraineeForMultiAgentCode(url, params) {
  return service({
    url: url,
    method: 'get',
    params
  })
}
export function addBookmarkToSection(roleplayId, sectionId, bookmarkedTime) {
  return service({
    url: `/backend/section/addBoomMark/${roleplayId}/${sectionId}/${bookmarkedTime}`,
    method: 'POST',
  })
}

export function getWordCountDetails(rolePlayId) {
  return service({
    url: `backend/wordCount/getWordCountDetails/${rolePlayId}`,
    method: 'get'
  })
}

export function getLearningThemesForAnalysis() {
  return service({
    url: `/backend/learningThemes/analysis`,
    method: 'get'
  })
}

export function getKeywordsForAnalysis() {
  return service({
    url: `/backend/keywords/analysis`,
    method: 'get'
  })
}
export function getListenTalkChart(rolePlayId) {
  return service({
    url: `backend/chat/listenTalkChart?displayInterval=120&scanInterval=10&rolePlayId=${rolePlayId}`,
    method: 'get'
  })
}

export function getRoleplayCountAndDurationForAnalysis(url, params) {
  return service({
    url: url,
    method: 'get',
    paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}

export function getDigitalizationData(params) {
  return service({
    url: `/backend/access-analysis/learning-theme-metrics`,
    method: 'get',
    paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}

export function getUserCountForAnalysis(url, params) {
  return service({
    url: url,
    method: 'get',
    paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}
export function getLearningThemeCountAndTimeByAgentCode(params) {
  return service({
    url: `/backend/accessAnalysis/getLearningThemeCountAndTimeByAgentCode`,
    method: 'get',
    paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}
export function getLearningThemeDurationCountByAgentCode(params) {
  return service({
    url: `/backend/accessAnalysis/getLearningThemeDurationCountByAgentCode`,
    method: 'get',
    paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}
export function getLearningThemeTimeCountDurationByTrainee(params) {
  return service({
    url: `/backend/accessAnalysis/getLearningThemeTimeCountDurationByTrainee`,
    method: 'get',
    paramsSerializer: () => qs.stringify(params, { arrayFormat: 'repeat' }),
  })
}
export function getGroupedLearningThemeLists() {
  return service({
    url: `/backend/access-analysis/grouped-learning-theme-list`,
    method: 'get'
  })
}
export function getTrainerEmailCheck() {
  return service({
    url: `/backend/trainer/emailCheck`,
    method: 'get'
  })
}
export function getTraineeEmailCheck(trainee_id) {
  return service({
    url: `/backend/trainee/emailCheck/${trainee_id}`,
    method: 'get'
  })
}