import React from 'react';
import { Row, Col } from 'reactstrap';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import styles from './styles.module.css'

const Accordian = ({ keywords }) => {

    let lastId = 0;
    const autoId = (prefix) => {
        lastId++;
        prefix = prefix ? prefix : 'keyword_accuracy_'
        return `${prefix}${lastId}`;
    }

    return (
        <Row>
            <Col lg={9}>
                <div className={`cmn-scroll-bar scroll-bar-height-600 px-2`} id={autoId()}> 
                    {keywords && keywords[0].sections.map((items, index) => {
                        return (
                            <div className={`keywords-accuracy mb-24 mr-md-4 mr-2`} key={index} id={`keyword_accuracy_section_${index}`}>
                                <h5 className="mb-3 font-14" id={`keyword_accuracy_section_name_${index}`} >{items.name}</h5>
                                {items.criteria.map((item, i) => {
                                    return (
                                        <Accordion expanded={true} key={i} className={`${styles.keyword_expend} mb-3`} id={`keyword_accuracy_${index}`} >
                                            <AccordionSummary
                                                className="keywords-accor-summary-inr"
                                                aria-controls="panel2bh-content"
                                                id={`keyword_accuracy_criteria_${index}_${i}`}
                                            >
                                                <Typography><label className={`m-0 MeiryoRegular font-14`} id={`keyword_accuracy_criteria_name_${index}_${i}`}>{item.name}</label></Typography>
                                            </AccordionSummary>
                                            {item.keywords && <AccordionDetails className="keywords-accor-summary-detail">
                                                {
                                                    item.keywords.map((data, key) => {
                                                        return (
                                                            <div className={`cmn-bg-box-inr d-flex mb-3 position-relative ${styles.keyword}`} key={key} id={`keyword_accuracy_keyword_section_${index}_${i}_${key}`}>
                                                                <div className={`${styles.keyword_title}`} id={`keyword_accuracy_keyword_content_${index}_${i}_${key}`} >
                                                                    <span id={`keyword_accuracy_keyword_name_${index}_${i}_${key}`}>{data.name === '' ? 'キーワードが設定されていません' : data.name}</span>
                                                                </div>
                                                                {(!item.aiScoringFlag && data.percentage !== undefined) && <div className={`${styles.keyword_percentage} p-0`} id={`keyword_accuracy_keyword_percentage_content_${index}_${i}_${key}`} >
                                                                    <span id={`keyword_accuracy_keyword_percentage_${index}_${i}_${key}`}>{data.percentage}</span>
                                                                </div>}
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </AccordionDetails>
                                            }
                                        </Accordion>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            </Col>
        </Row>

    );
}

export default Accordian;
