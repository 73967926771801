import React, { useState } from 'react';
import {Row, Col} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import cross_icon from '../../../assets/images/cross.png';
import reset_icon from '../../../assets/images/reset_icon.png';
import plus_icon from '../../../assets/images/plus.png';
import db from '../../../assets/images/db.png';
import CustomChip from "../../../components/Chip/CustomChip";
import ChipPlus from "../../../components/Chip/ChipPlus";
import ConfirmDialog from '../../../components/Dialog/ConfirmDialog';
import EventBus from '../../../components/EventBus';
import GeneralButton from '../../../components/Buttons/GeneralButton';
import classes from '../styles.module.css';
import {addSynonyms,deleteSynonyms} from '../../../api/api';

const SynonymsKeywordSection = ({keywords,setKeywords,refresh,setRefresh}) =>{
    const { t } = useTranslation();
    const noData = <div><div width="100%" className="text-center py-4"><span className="mr-2"><img src={db}></img></span>検索したキーワードは見つかりませんでした。</div></div>;
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [newSynonymValue, setNewSynonymValue] = useState("");
    const [newSynonym, setNewSynonym] = useState(false);
    const [newSynonymIndex, setNewSynonymIndex] = useState();
    const [deleteKeywordIndex, setDeleteKeywordIndex] = useState(0);
    const [deleteSynonymIndex, setDeleteSynonymIndex] = useState(0);

    const toDeleteSynonym = (index,synonymIndex) => {
        setDeleteKeywordIndex(index);
        setDeleteSynonymIndex(synonymIndex);
        setConfirmOpen(true);
    }
    const confirmDeleteSynonym = () => {
        const synId = keywords[deleteKeywordIndex].synonymList[deleteSynonymIndex].synId;
        deleteSynonyms(synId)
            .then(res=>{
                if (res.status == 200) {
                    EventBus.dispatch('show_snack', { message: "操作成功", type: 'success' })
                    const oldData = [...keywords];
                    oldData[deleteKeywordIndex].synonymList.splice(deleteSynonymIndex,1);
                    setKeywords(oldData);
                }
            }).catch(err => {
                if (err.response.status === 400) {
                    EventBus.dispatch('show_snack', { message: "更新に失敗しました", type: 'error' })
                }
                else {
                    EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
                }
            })
    }
    const addSynonym = (index) => {
        setNewSynonym(true);
        setNewSynonymIndex(index);
    }
    const saveNewSynonym = (index,keywordName)  => {
        if(newSynonymValue.length > 50) {
            EventBus.dispatch('show_snack', { message: "同義語の最大長は50文字です。", type: 'error' })
            return 
        }
        // if (event.code === "Enter") {
            let checkSynonymIsExist = keywords[index].synonymList.some(item => item.synName.includes(newSynonymValue));
            if (checkSynonymIsExist === true) {
                EventBus.dispatch('show_snack', { message: "入力同義語はすでに存在しています。", type: 'error' })
                return
            }
            if(newSynonymValue !== ""){
                let value = newSynonymValue, count = 0;
                for (let i = 0; i < value.length; i++) {
                    if (value[i] === ' ') {
                        count++;
                    }
                }
                if (count !== value.length) {
                    const newlySynonym = { "synonymName": newSynonymValue, "keywordName": keywordName }
                    addSynonyms(newlySynonym)
                        .then(res => {
                            if (res.status == 200) {
                                EventBus.dispatch('show_snack', { message: "操作成功", type: 'success' })
                                setRefresh(!refresh);
                                setNewSynonym(false);
                            }

                        }).catch(err => {
                            if (err.response.status === 400) {
                                EventBus.dispatch('show_snack', { message: "更新に失敗しました", type: 'error' })
                            }
                            else {
                                EventBus.dispatch('show_snack', { message: "問題が発生しています", type: 'error' })
                            }
                        })
                }else{
                    EventBus.dispatch('show_snack', { message: "類義語を入力してからEnterキーを押してください。", type: 'error' })
                }
            }
        // }
    }
    const TypeNewSynonym = (e) => {
        setNewSynonymValue(e.target.value)
    }
    const resetNewSynonym = () => {
        setNewSynonym(false);
    }
    return(
        <>
            <ConfirmDialog
                title="削除しますか？"
                open={confirmOpen}
                setOpen={setConfirmOpen}
                onConfirm={confirmDeleteSynonym}
            ></ConfirmDialog>
            <div className={`` }>
                <div className={`${classes.body_top_border}`}  id={`synonym_section`}>
                    <div className={`${classes.header_sec}`}>
                        <div> <CustomChip text="キーワード" className={`font-18 ${classes.custom_keyword_chip}`} id={`keywords`}></CustomChip></div>
                        <div> <CustomChip text="類義語" className={`font-18 ${classes.custom_keyword_chip}`} id={`synonyms`}></CustomChip></div>
                    </div>
                    <div className={`cmn-scroll-bar ${classes.cmn_scroll_bar_xy}`}>
                    {
                        keywords && keywords.length === 0 ?noData:
                            keywords && keywords.map((item,index) => {
                                return(
                                    <div className={`${classes.full_width}`} key={index} id={`keywords_${index}`}>
                                        <Row className={item.keywordName === ""?"d-none":"position-relative  mb-3"}>
                                            <Col xs="3"  className={`${classes.keyword_content}`}  id={`keywords_section_${index}`}>
                                                <div className={`${classes.keyword_card} py-2 pl-4`} id={`keywords_name_${index}`}>
                                                    {item.keywordName}
                                                </div>
                                            </Col>

                                            <Col xs="9" id={`synonym_section_${index}`}>
                                                {
                                                    item.synonymList && item.synonymList.map((synonym,synonymIndex) => {
                                                        return(
                                                            <span key={synonymIndex}>
                                                                <CustomChip text={synonym.synName} icon={cross_icon} onClick={()=>toDeleteSynonym(index,synonymIndex)} className={`mb-2 ${classes.custom_content_chip}`} id={`synonym_name_${index}_${synonymIndex}`}></CustomChip>
                                                            </span>
                                                        )
                                                    })
                                                }
                                                {
                                                    newSynonym === true && newSynonymIndex === index?
                                                        <span className="position-relative">
                                                            <input type="text" className={`${classes.txt_newSynonym} mb-2`} onKeyUp={TypeNewSynonym} id={`new_synonym_input_${index}`}></input>
                                                            <GeneralButton id={`${newSynonymIndex}_register_btn`} className={`${classes.register_btn} ${classes.cancel_btn} mr-2`} title="登録" onClick={()=>saveNewSynonym(index,item.keywordName)}/>
                                                            <GeneralButton id={`${newSynonymIndex}_cancel_btn`} className={`${classes.register_btn}  bg-white text-dark mr-2`} title="キャンセル" onClick={resetNewSynonym}/>
                                                        </span>
                                                        :
                                                        <ChipPlus text="類義語" icon={plus_icon} onClick={()=>addSynonym(index)} className="mb-2" id={`new_synonym_input_${index}`} id={`add_synonym__${index}`}></ChipPlus>

                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            })
                    }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SynonymsKeywordSection;