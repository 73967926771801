import React ,{ useRef,useState, useEffect } from "react";
import { connect } from 'react-redux';
const PieChart = (props) => {

  const {utterRate} = props.aiResultScore
  const {id} = props

  const [items, setItems] = useState([]);
  const [chartSize, setChartSize] = useState(200);
  const circumference = (2 * 3.14 * chartSize) / 4;
  const angleAcum = useRef(0);

  useEffect(() => {
    const DEFAULT_ITEMS = [
      { percentage: parseInt(utterRate), color: '#00A5D9' },
      { percentage: parseInt(100 - utterRate), color: '#e98300' },
    ];
      setItems(DEFAULT_ITEMS)
  }, [utterRate])

  return (
      <svg
      height={chartSize}
      width={chartSize}
      viewBox={`0 0 ${chartSize} ${chartSize}`}
      className="my-3"
      id={id}
    >
      {items.map((elem, index) => {
        const sliceWidth = (elem.percentage * circumference) / 100;
        angleAcum.current = angleAcum.current + (elem.percentage / 100) * 360;

        return (
          <circle
            key={index}
            r={chartSize / 4}
            cx={chartSize / 2}
            cy={chartSize / 2}
            fill="transparent"
            stroke={elem.color}
            strokeWidth={chartSize / 2}
            strokeDasharray={`${sliceWidth} ${circumference}`}
            transform={`rotate(${
              -90 + (angleAcum.current - (elem.percentage / 100) * 360)
            } ${chartSize / 2} ${chartSize / 2})`}
          />
        );
      })}
    </svg>
  )
}

const mapStateToProps = state => {
  return {
    aiResultScore: state.aiResultScore,
  }
}

const mapDispatchToProps = dispatch => ({
  SET_AI_SCORE_RESULT: (res) => dispatch({type: 'SET_AI_SCORE_RESULT', paramData: res}),
})

export default connect(mapStateToProps, mapDispatchToProps)(PieChart);