import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'reactstrap';
import StyledCheckbox from '../../../../components/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import classes from './styles.module.css';
import { sectionCriteriaList } from '../../../../api/api'
import EventBus from '../../../../components/EventBus'

const SelectScenario = ({ className, style, policy, insurance, sectionCriteria, setSectionCriteria, id, trainerId, traineeId, agent_company, role, activeSlider, uniqueFilterCode, sevenFilterCode, threeFilterCode }) => {

    useEffect(() => {
        if (sectionCriteria.length > 0) {
            setSectionCriteria(sectionCriteria)
        } else { 
            let filterData = {
                agentCompanyCode: activeSlider === 1 ? uniqueFilterCode : sevenFilterCode,
                branchCode: activeSlider === 1 ? null : threeFilterCode
            }
            sectionCriteriaList(policy, insurance, trainerId, traineeId, filterData).then(res => {
                let newArray = res.data.map((val, index) => (
                    {
                        sectionId: val.sectionId,
                        sectionName: val.sectionName,
                        criteria: val.criteria && val.criteria.sort((a, b) => a.sortingRecord - b.sortingRecord),
                        checked: val.checked ? true : false
                    }
                ));
                let saved = newArray.filter(value => value.checked);
                if (saved.length < 1) {
                    newArray = newArray.map(it => {
                        it.checked = true;
                        return it;
                    });
                } else {
                    newArray = newArray.map(it => {
                        it.checked = saved.includes(it);
                        return it;
                    });
                }
                setSectionCriteria(newArray)
            })
        }
    }, [])

    const onChange = (e) => {
        setSectionCriteria(
            sectionCriteria.map(data => {
                if (data.sectionId == e.target.value) {
                    data.checked = e.target.checked;
                }
                return data;
            })
        )
        EventBus.dispatch('close_snack')
    };

    console.log('sectionCriteria', sectionCriteria);

    return (
        <div className="scenario_checkGroup">
            {
                sectionCriteria && sectionCriteria.map((sec, index) => {
                    { console.log('checked is ' + sec.checked) }
                    return (
                        <div key={index} className={`${classes.bg_box} ${className}`} id={'scenario_item_'+sec.sectionId}>
                            <div className="w-100"  id={`section_${index}`} >
                                <FormControlLabel style={styles.root} control={
                                    <StyledCheckbox
                                        checked={sec.checked}
                                        onChange={onChange}
                                        value={sec.sectionId}
                                        name="checkbox-button"
                                        color="default"
                                        id={`style-${id}${index}`}
                                    />
                                } label={<span style={styles.label} className={`MeiryoRegular  ${sec.checked ? 'font-weight-bold' : ''}`}>{sec.sectionName}</span>} />
                            </div>
                            <div className="w-100" id={`section_item_${index}`}>
                                <ul className={`d-flex flex-wrap ${classes.custom_list}`}>
                                    {sec.criteria && sec.criteria.map((value, k) => {
                                        console.log(value)
                                        return <li key={k} id={`${id}${index}${k}`} className={`${classes.border} ${value.requiredFlag && classes.required}`}>{value.name}</li>
                                    })
                                    }
                                </ul>
                            </div>
                        </div>
                    );
                })
            }

        </div >
    )

};
const styles = {
    root: {
        marginLeft: 0,
        marginBottom: 16
    },
    container: {
        width: '100%',
        padding: 10,
        display: 'flex',
        alignItems: 'stretch',
    },
    label: {
        marginButtom: 0,
        fontSize: '16px',
        paddingLeft: 8
    }
};
export default SelectScenario;
